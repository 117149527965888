import React from 'react';
import { SvgIcon } from '@adsk/bim360-matrix-react-components';
import _ from 'lodash';
import moment from 'moment';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeGrid as Grid, FixedSizeList as List, areEqual } from 'react-window';

import T from '~/common/i18n';
import 'react-virtualized/styles.css';
import FilterWarning from '../controls/filterWarning.redux';
import {ConnectedPhotoCell} from '../photoCell/photoCell.redux';
import PhotoDragLayer from '../photoCell/photoDragLayer.redux';
import GalleryDropZone from '../uploader/FilesDropzone/galleryDropzone.redux';
import getTimelineGroupCell from './groupCell.redux';
import {DATE_FORMAT, TRASH, ALL_AUX} from '~/common/constants';
import { ConnectedAlbumsModal } from '~/ui/modals/albumsModal.redux';
import ModalDeletePhoto from '~/ui/modals/deletePhotoModal.ui';

const THUMBNAIL_WIDTH = 228;
const THUMBNAIL_HEIGHT = 224;
const TIME_TEXT_ROW_HEIGHT = 50;
const ROWS_OVERSCAN_COUNT = 1;
const SCROLLBAR_WIDTH = 17;

/**
 * React component that displays the photos
 * @public
 */
export class Gallery extends React.Component {
  static TIMELINE_GRANULARITY_TO_MOMENT_UNIT = {
    'day': 'day',
    'week': 'isoWeek',
    'month': 'month'
  };

  /**
   * @inheritdoc
   */
  constructor(props) {
    super(props);

    this.getDataRows = this.getDataRows.bind(this);
    this._onRowsRendered = this._onRowsRendered.bind(this);
    this.renderNoRowMessage = this.renderNoRowMessage.bind(this);
    this._onListRowRenderer = this._onListRowRenderer.bind(this);
    this._onCheckboxClick = this._onCheckboxClick.bind(this);
    this._onThumbnailClick = this._onThumbnailClick.bind(this);
    this._onResize = this._onResize.bind(this);
    this.Cell = React.memo(this.Cell.bind(this), areEqual);
    this._tableRef = React.createRef();
    this._listRef = React.createRef();
    this.props.updateDisplayedPhotos(this.props.filteredPhotosCount);
    this._renderAddToAlbumModal = this._renderAddToAlbumModal.bind(this);
    this.deleteFromAll = this.deleteFromAll.bind(this);
    this.deleteFromActiveAlbum = this.deleteFromActiveAlbum.bind(this);
  }

  /**
   * handles a checkbox click on a photo.
   * @param {String} photoId the id of the photo.
   * @param {Event} event the click event.
   */
  _onCheckboxClick(photoId, event) {
    const newIndex = _.findIndex(this.props.orderedPhotos, orderedPhoto => orderedPhoto === photoId);
    if (event.shiftKey) {
      if (this._lastSelectedIndex || this._lastSelectedIndex === 0 ) {
        const start = Math.min(newIndex, this._lastSelectedIndex);
        const end = Math.max(newIndex, this._lastSelectedIndex);
        const photosToSelect = this.props.orderedPhotos.slice(start, end + 1);

        this.props.togglePhotosSet(photosToSelect);
      }
    } else {
      this.props.onSelect.bind(this)(photoId);
    }
    this._lastSelectedIndex = newIndex;
  }

  /**
   * Handles clicking on a thumbnail and opening the viewer
   * @param {String} photoId the photo to open the viewer with.
   */
  _onThumbnailClick(photoId) {
    let photosList = [];
    let indexCount = 0;
    let index = null;

    _.each(this.props.timeline, photos => {
      _.each(photos, guid => {
        photosList.push(guid);

        if (photoId === guid) {
          index = indexCount;
        }

        indexCount++;
      });
    });

    if (index !== null) {
      this.props.onOpen(index, photosList);
    }
  }

  /**
   * Called whenever a list row is rendered.
   * @param {Object} params Parameters.
   * @param {Number} params.index Index of row within collection.
   * @return {Object} Returns a Dom element.
   * @private
   */
  _onListRowRenderer({index, style}) {
    let cellData;

    let timeGroupKey;
    const currentCell = this._data[index];
    if (currentCell[0].isTimeColumn) {
      timeGroupKey = currentCell[0].timeGroupKey;
    } else {
      timeGroupKey = currentCell.timeGroupKey;
    }
    cellData = {
      isTimeColumn: currentCell[0].isTimeColumn,
      timeGroupKey,
      title: this._getGroupHeader(timeGroupKey)
    };
    return (
      <div style={style} key={cellData.timeGroupKey} className="topTimeGroupContainer">
        {
          React.createElement(getTimelineGroupCell(
            cellData.timeGroupKey,
            this.props.timeline[cellData.timeGroupKey]
          ), { title: cellData.title })
        }
        <div className="topTimeGroupSeparator"></div>
      </div>
    );
  }

  /**
   * @inheritdoc
   */
  componentDidUpdate() {
    if (this._tableRef.current) {
      this._tableRef.current.resetAfterRowIndex(0, true);
    }
    this.props.updateDisplayedPhotos(this.props.filteredPhotosCount);
  }

  /**
   * sort photos in time rows
   * @param {Number} numOfColumns - The number of columns depending on the available width.
   * @return {Array} array of data
   */
  getDataRows(numOfColumns) {
    const timeline = this.props.timeline;
    let data = [];
    const order = this.props.isDescending ? 1 : -1;
    const timelineKeys = Object.keys(timeline).sort((a, b) => order * (b - a));

    _.forEach(timelineKeys, key => {
      const value = timeline[key];

      // Stacking the images into rows as function of the columns number.
      const timeData = _.map(_.chunk(value, numOfColumns), row => {
        const dataRow = {};
        for (let i = 0; i < numOfColumns; i++) {
          dataRow.timeGroupKey = key;
          dataRow[i] = row[i];
        }
        return dataRow;
      });

      // Defining the time data row
      const timeRow = {
        0: {
          title: this._getGroupHeader(key),
          timeGroupKey: key,
          isTimeColumn: true
        }
      };
      for (let i = 1; i < numOfColumns; i++) {
        timeRow[i] = null;
      }

      // Insert the time data row at the start.
      timeData.unshift(timeRow);

      // Concatenate the result to the end of the timeline data
      data = data.concat(timeData);
    });

    return data;
  }

  /**
   * Callback invoked with information about the slice of rows that were just rendered.
   * @param {Number} overscanStartIndex Over scanned rendered row start index.
   * @param {Number} overscanStopIndex  Over scanned rendered row end index.
   * @private
   */
  _onRowsRendered({visibleRowStartIndex}) {

    const currentCell = this._data[visibleRowStartIndex];

    if (!currentCell) {
      // update doesn't seem to clear visibleRowStartIndex seems like a bug in react-window
      // in our case we will just skip this onRowsRendered
      return;
    }
    if (currentCell[0] && currentCell[0].isTimeColumn) {
      this._currentTimeCellIndex = visibleRowStartIndex;
      this._listRef.current.scrollToItem(this._currentTimeCellIndex, 'start');
    } else {
      let currentTimeGroupKey = this._data[this._currentTimeCellIndex][0].timeGroupKey;

      if (currentCell.timeGroupKey !== currentTimeGroupKey) {
        const sortDirection = this.props.isDescending ? 1 : -1;
        let direction = parseInt(currentCell.timeGroupKey, 10) - parseInt(currentTimeGroupKey, 10);
        direction = sortDirection * direction / Math.abs(direction);
        while (this._currentTimeCellIndex <= this._data.length - 1 &&
          this._currentTimeCellIndex >= 0 &&
          currentCell.timeGroupKey !== this._data[this._currentTimeCellIndex][0].timeGroupKey) {
          this._currentTimeCellIndex -= direction;
        }
        this._currentTimeCellIndex = Math.max(0, this._currentTimeCellIndex);
        this._currentTimeCellIndex = Math.min(this._data.length, this._currentTimeCellIndex);
        this._listRef.current.scrollToItem(this._currentTimeCellIndex, 'start');
      }
    }
  }

  /**
   * Returns a displayable text given a timeline group represented by photo guid
   * @param {String} startTime Timestamp for the first day of the group (depends on the grouping granularity).
   * @return {String} displayable text
   * @private
   */
  _getGroupHeader(startTime) {
    const groupGranularity = this.props.groupingBy;
    const startTimeInt = _.toInteger(startTime);
    const unit = Gallery.TIMELINE_GRANULARITY_TO_MOMENT_UNIT[groupGranularity];
    const format = groupGranularity === 'month' ? 'MMM D, YYYY' : DATE_FORMAT;
    const startTimeText = moment(startTimeInt).startOf(unit).format(format);
    if (groupGranularity === 'day') {
      // if the time grouping granularity is by 'day', then we display single date.
      return startTimeText;
    } else {
      // we display a range: {date1} - {date2}
      const endTimeText = moment(startTimeInt).endOf(unit).format(format);
      return `${startTimeText} - ${endTimeText}`;
    }
  }

  /**
   * Render message show when there is no photos or the filter selection results in no photos.
   * @param {String} width the width returned from autosizer
   * @param {String} height the height returned from autosizer
   * @return {ReactComponent} the component to render
   */
  renderNoRowMessage(width, height) {
    if (this.props.albumPhotosCount === 0) {
      const isTrash = this.props.activeAlbum === TRASH;
      const albumName = this.props.activeAlbumData ?
        this.props.activeAlbumData.name :
        T.translate('albumsPanel.allPhotos');
      const emptyGalleryTitle = isTrash ?
        T.translate('gallery.empty.title.trash') :
        T.translate('gallery.empty.title.album', {
          context: albumName
        });
      return (
        <div className="galleryEmptyView" style={{width: width, height: height}}>
          <div className="centered">
            <SvgIcon key="icon" width="88px" height="155px" svgId="empty-box"/>
            <h3 key="title">{emptyGalleryTitle}</h3>
            {!isTrash ?
              <p key="subtitle">{T.translate('gallery.empty.subtitle')}</p> : null
            }
            </div>
        </div>
      );
    } else {
      return (
        <div className="galleryEmptyView" style={{width: width, height: height}}>
          <div className="centered">
            <SvgIcon width="237px" height="150px" svgId="icon-filter-no-result"/>
            <h3>{T.translate('gallery.empty.title.filtersNoResultsMessage')}</h3>
          </div>
        </div>
      );
    }
  }

  /**
   * Handles clicking outside of the photo cells
   */
  _onClickOutside() {
    this.props.deselectAllPhotos();
  }

  /**
   * Callback to recalculate the row sizes when the gallery changes size
   */
  _onResize() {
    if (this._tableRef.current) {
      this._tableRef.current.resetAfterRowIndex(0);
    }
  }

  /**
   * A functional react component to be passed to the autoSizer.
   * @param {Object} props Component's props.
   * @return {ReactDom} The reactDom to render.
   */
  _autoSizerRenderer({width, height}) {
    const numOfColumns = Math.floor( (width - SCROLLBAR_WIDTH) / THUMBNAIL_WIDTH ) || 1;
    const data = this.getDataRows(numOfColumns);
    this._data = data;
    this._currentTimeCellIndex = 0;
    const rowHeightFunction = index => {
      /*
        Given the row index it returns the desired row height,
        as the time line constructed from two types
        of rows:
            1- Rows contains the time group title
            2- Rows that contains photos.
        */
      if (data[index][0].isTimeColumn) {
        return TIME_TEXT_ROW_HEIGHT;
      } else {
        return THUMBNAIL_HEIGHT;
      }
    };

    return data.length === 0 ? this.renderNoRowMessage(width, height) : (
      <>
        <List
          key="GalleryList"
          width={width - SCROLLBAR_WIDTH}
          ref={this._listRef}
          className="GalleryList"
          height={TIME_TEXT_ROW_HEIGHT}
          itemCount={this._data.length}
          itemSize={TIME_TEXT_ROW_HEIGHT}
          style={{outline: 'none', position: 'absolute', overflow: 'hidden' }}
        >
          {this._onListRowRenderer}
        </List>
        <Grid
          ref={this._tableRef}
          columnCount={numOfColumns}
          columnWidth={() => THUMBNAIL_WIDTH}
          className="GalleryGrid"
          height={height}
          width={width}
          rowCount={this._data.length}
          rowHeight={rowHeightFunction}
          overscanRowsCount={ROWS_OVERSCAN_COUNT}
          estimatedRowHeight={THUMBNAIL_HEIGHT}
          itemData={data}
          onItemsRendered={this._onRowsRendered}
          useIsScrolling
        >
          {this.Cell}
        </Grid>
      </>
    );
  }

  /**
   * Function responsible for rendering a cell's contents.
   * The timeline has two type of cells:
   *  1- Viewing the timeline title
   *  2- Photo cell .
   * @return {ReactDom} The reactDom element to render
   */
  Cell({data, columnIndex, rowIndex, isScrolling, style}) {
    const rowData = data[rowIndex];
    const cellData = rowData[columnIndex];
    let cell = null;

    if (cellData) {
      let photoId;
      if (typeof cellData === 'string') {
        photoId = cellData;
      }
      if (cellData.isTimeColumn) {
        if (cellData.title && cellData.timeGroupKey) {
          cell = (
            <div style={{ ...style, width: '100%' }} className="timeGroupContainer">
              {
                React.createElement(getTimelineGroupCell(
                  cellData.timeGroupKey,
                  this.props.timeline[cellData.timeGroupKey]
                ), { title: cellData.title })
              }
              <div className="timeGroupSeparator"></div>
            </div>
          );
        }
      } else {
        cell = (
          <div className="photoCell" style={style}>
            <ConnectedPhotoCell
              photoId={photoId}
              onCheckboxClick={this._onCheckboxClick}
              onThumbnailClick={this._onThumbnailClick}
              light={isScrolling}
              thumbnailMode
            />
          </div>
        );
      }
    }
    return cell;
  }

  /**
   * Returns a warning modal for photo deletion.
   * @return {React.Component} The warning modal.
   * @private
   */
  _renderAddToAlbumModal() {
    const { currentPhotoId, activeAlbum, removePhotoFromActiveAlbum} = this.props;
    return (
      <ConnectedAlbumsModal
        onExited={() => this.props.hideAddToAlbumModal()}
        showCopyOption={activeAlbum !== ALL_AUX}
        photoId={currentPhotoId}
        removePhoto={() => removePhotoFromActiveAlbum(currentPhotoId)}
      />
    );
  }

  /**
   * Delete from "All Photos"
   */
  deleteFromAll() {
    this.props.hidePhotoDeletionWarning();
    this.props.deletePhoto(this.props.currentPhotoId);
  }

  /**
   * Delete from album
   */
  deleteFromActiveAlbum() {
    this.props.hidePhotoDeletionWarning();
    this.props.removePhotoFromActiveAlbum(this.props.currentPhotoId);
  }

  /**
   * React rendering of the Gallery
   * @public
   * @param {[...Object]} photos The list of photos
   * @param {{Number: Array<Object>}} timeline A mapping from time group to photos list
   * @param {Function} onSelect function to execute when clicked
   * @return {ReactComponent} the component to render
   */
  render() {
    const albumName = this.props.activeAlbumData ?
      this.props.activeAlbumData.name :
      this.props.activeAlbum;

    return (
      <div className="galleryContainer" onClick={this._onClickOutside.bind(this)}>
        {this.props.showAddToAlbumModal && this._renderAddToAlbumModal()}
        <PhotoDragLayer />
        <div className="wrapper">
          {this.props.filterEnabled && <FilterWarning />}
          <GalleryDropZone albumId={this.props.activeAlbum}>
            <AutoSizer onResize={this._onResize}>
              {this._autoSizerRenderer.bind(this)}
            </AutoSizer>
          </GalleryDropZone>
        </div>

        <ModalDeletePhoto
          show={this.props.showPhotoDeletionWarning}
          onExited={this.props.hidePhotoDeletionWarning}
          countPhoto={1}
          albumName={albumName}
          deleteFromAlbum={this.deleteFromActiveAlbum}
          deleteFromAll={this.deleteFromAll}
        />
      </div>
    );
  }
}
