import {
  RECEIVE_PHOTO,
  ADD_PHOTO,
  CLEAR_PHOTO_DATA,
  CLEAR_PHOTOS_DATA
} from '~/common/constants';
import {BinaryDataSource} from '@adsk/forge-hfdm';
import { StateTreeManager } from '~/common/helpers/stateTreeManager';


/**
 * A reducer for the composition
 * @param {state} state last state
 * @param {*} action action to reduce
 * @return {state} The next state
 */
function photosContent(state = {}, action) {
  switch (action.type) {
    case ADD_PHOTO: {
      const contentStateTreeManager = new StateTreeManager(state);
      const contents = {
        url: null,
        dataSource: new BinaryDataSource.BlobDataSource()
      };
      const newState = contentStateTreeManager.addValue(action.image.id, contents);
      return newState;
    }
    case RECEIVE_PHOTO: {
      const contentStateTreeManager = new StateTreeManager(state);

      const content = contentStateTreeManager.getValue(action.id);
      content.url = action.url;

      const newState = contentStateTreeManager.addValue(action.id, content);
      return newState;
    }
    case CLEAR_PHOTO_DATA: {
      const contentStateTreeManager = new StateTreeManager(state);
      const photo = contentStateTreeManager.getValue(action.id);
      if (photo && photo.url) {
        // tell the browser to revoke this blob url and clear it from memory.
        // blob url count as a reference to the blob, so it's not collected by the GC unless we also revoke this.
        URL.revokeObjectURL(photo.url);
      }
      const content = {
        dataSource: new BinaryDataSource.BlobDataSource(),
        url: null
      };
      const newState = contentStateTreeManager.addValue(action.id, content);
      return newState;
    }
    case CLEAR_PHOTOS_DATA: {
      const contentStateTreeManager = new StateTreeManager(state);

      action.ids.forEach(photoId => {
        const photo = contentStateTreeManager.getValue(photoId);
        if (photo && photo.url) {
          // tell the browser to revoke this blob url and clear it from memory.
          // blob url count as a reference to the blob, so it's not collected by the GC unless we also revoke this.
          URL.revokeObjectURL(photo.url);
        }
        const content = {
          dataSource: new BinaryDataSource.BlobDataSource(),
          url: null
        };
        contentStateTreeManager.addValue(photoId, content);
      });
      return contentStateTreeManager.getStateTree();
    }
    default: {
      return state;
    }
  }
}

export default photosContent;
