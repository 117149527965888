import classNames from 'classnames';
import {
  Button,
  SvgIcon,
  Label
} from '@adsk/bim360-matrix-react-components';
import * as React from 'react';

export const SpecialAlbum = ({onRowClick, isSpecialAlbumSelected, itemName, svgId}) => {
  const specialAlbumRowClasses = classNames('SpecialAlbumRow', {
    'Selected': isSpecialAlbumSelected
  });
  return (
    <div className={specialAlbumRowClasses} onClick={onRowClick}>
      <Button>
        <SvgIcon svgId={svgId} width="16px" height="16px"/>
        <Label>{itemName}</Label>
      </Button>
    </div>
  );
};
