import {DraggablePhotoElement} from './photoCell.ui';
import { connect } from 'react-redux';
import {
  fetchPhoto,
  fetchThumbnail,
  cancelGalleryThumbnailDownload,
  rejectThumbnail,
  removePhotoFromActiveAlbum,
  duplicatePhotos,
  downloadPhotos,
  restorePhotos,
  setPhotoPrivate,
  triggerModalVisibility
} from '~/redux/actions/photosActions';
import { StateTreeManager } from '~/common/helpers/stateTreeManager';

const mapStateToProps = (state, {photoId, ...props}) => {
  const thumbnailStateTreeManager = new StateTreeManager(state.thumbnailsStatus);
  const photosStateTreeManager = new StateTreeManager(state.photosStatus);
  const photosPrivateTreeManager = new StateTreeManager(state.photosPrivate);

  const thumbnailUploadStatus = state.thumbnailsUploadStatus[photoId];
  const thumbnailContentStatus =  thumbnailStateTreeManager.getValue(photoId);
  const photoUploadStatus = state.photosUploadStatus[photoId];
  const photoContentStatus =  photosStateTreeManager.getValue(photoId);
  const photoIsPrivate =  photosPrivateTreeManager.getValue(photoId);

  return {
    photoId,

    isPrivate: photoIsPrivate,
    isSelected: state.photosSelection[photoId],

    thumbnailContentStatus,
    thumbnailUploadStatus,

    photoContentStatus,
    photoUploadStatus,

    linkedSources: state.photosSources[photoId],
    activeAlbum: state.activeAlbum,

    showPrivacyFeature: state.features.showPrivacy,

    ...props
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchPhoto: photoId => {
      dispatch(rejectThumbnail(photoId));
      dispatch(fetchPhoto(photoId, true, true));
    },
    fetchThumbnail: photoId => {
      dispatch(fetchThumbnail(photoId));
    },
    cancelDownload: photoId => {
      dispatch(cancelGalleryThumbnailDownload(photoId));
    },
    removePhoto: photoId => {
      dispatch(removePhotoFromActiveAlbum(photoId));
    },
    duplicatePhotos: photoId => {
      dispatch(duplicatePhotos([photoId]));
    },
    downloadPhoto: photoId => {
      dispatch(downloadPhotos([photoId]));
    },
    restorePhoto: photoId => {
      dispatch(restorePhotos([photoId]));
    },
    setPhotoPrivate: photoId => {
      dispatch(setPhotoPrivate(photoId, true));
    },
    setPhotoPublic: photoId => {
      dispatch(setPhotoPrivate(photoId, false));
    },
    showPhotoDeletionWarning: photoId => {
      dispatch(triggerModalVisibility('showPhotoDeletionWarning', true, photoId));
    },
    showAddToAlbumModal: photoId => {
      dispatch(triggerModalVisibility('showAddToAlbumModal', true, photoId));
    }
  };
};

export const ConnectedPhotoCell = connect(
  mapStateToProps,
  mapDispatchToProps
)(DraggablePhotoElement);
