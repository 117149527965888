import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ALL_AUX } from '~/common/constants';

import {SelectionInfo} from './selectionInfo.ui';

/**
 * React component for the special Album data view
 * @return {ReactDOM} The react to render
 */
export class SpecialAlbumDataView extends React.Component {
  /**
   * @private
   * @return {Boolean} true if at least one photo within a special album is not selected, false otherwise
   */
  _hasUnselected() {
    const { photosSelection, undeletedPhotos, activeAlbum, deletedPhotos } = this.props;
    const deleteArray = activeAlbum === ALL_AUX ? undeletedPhotos : deletedPhotos;
    const specialAlbumPhotosSelection = _.pick(photosSelection, deleteArray);
    if (_.isEmpty(specialAlbumPhotosSelection)) {
      return true;
    }
    return _.some(specialAlbumPhotosSelection, value => value === false);
  }

  /**
   * @inheritdoc
   */
  render() {

    return (
      <div className="albumAssetViewContainer">
        <SelectionInfo hasUnselected={this._hasUnselected()}
                       selectedPhotosNb={this.props.selectedPhotos.length}
                       totalPhotosNb={this.props.photosCount}
                       {...this.props}
        />
        <div className="centeredAssetName">{this.props.specialAlbumName}</div>
      </div>
    );
  }
}

SpecialAlbumDataView.propTypes = {
  photosCount: PropTypes.number,
  photosSelection: PropTypes.object,
  specialAlbumName: PropTypes.string,
  activeAlbum: PropTypes.string,
  selectedPhotos: PropTypes.array,
  deletedPhotos: PropTypes.array,
  undeletedPhotos: PropTypes.array
};

SpecialAlbumDataView.defaultProps = {
  photosCount: 0,
  photosSelections: {},
  specialAlbumName: '',
  activeAlbum: '',
  selectedPhotos: [],
  deletedPhotos: [],
  undeletedPhotos: []
};
