import {
  REQUEST_CHECKLIST,
  RECEIVE_CHECKLIST,
  REJECT_CHECKLIST,
  FETCHING
} from '~/common/constants';

/**
 * A reducer for retrieved checklists
 * @param {state} state last state
 * @param {*} action action to reduce
 * @return {state} The next state
 */
export function checklists(state = {}, action) {
  switch (action.type) {
    case REQUEST_CHECKLIST: {
      const newState = {
        [action.id]: FETCHING
      };
      return Object.assign({}, state, newState);
    }
    case RECEIVE_CHECKLIST: {
      const newState = {
        [action.id]: action.data
      };
      return Object.assign({}, state, newState);
    }
    case REJECT_CHECKLIST: {
      const newState = {
        [action.id]: 'Failed'
      };
      return Object.assign({}, state, newState);
    }
    default:
      return state;
  }
}
