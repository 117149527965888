/* eslint-disable no-fallthrough */

import {migrate101_103} from './photoAssetHelper.1.0.1-1.0.3';
import {migrate103_110} from './photoAssetHelper.1.0.3-1.1.0';
import {migrate110_120} from './photoAssetHelper.1.1.0-1.2.0';
import {
  PHOTO_ASSET_TYPEID_V101,
  PHOTO_ASSET_TYPEID_V103,
  PHOTO_ASSET_TYPEID_V110
} from './constants';



/**
 * Handles applying the transformations of the asset.
 * @param {Asset} property The property to migrate.
 * @return {Asset} The transformed asset.
 */
export function migratePhotoAssetData(property) {
  // not using break is intentional, we make use of switch fallthrough to cascade migration calls
  let newProperty = property;
  const currentPropertyTypeid = property.getTypeid();
  switch (currentPropertyTypeid) {
    case PHOTO_ASSET_TYPEID_V101:
      newProperty = migrate101_103(property);
    case PHOTO_ASSET_TYPEID_V103:
      newProperty = migrate103_110(newProperty);
    case PHOTO_ASSET_TYPEID_V110:
      newProperty = migrate110_120(newProperty);
    default:
  }
  return newProperty;
}
