
import React, { PureComponent } from 'react';
import Tiff from 'tiff.js';

import { PhotoContentImage } from './photoContentImage.ui';

/**
 * React component for the PhotoContentTiff
 * @public
 */
export class PhotoContentTiff extends PureComponent {

  /**
   * @inheritdoc
   */
  constructor(props) {
    super(props);

    this.state = {
      src: null
    };

    this._fileReader = null;
    this._convertTiffToJpg = this._convertTiffToJpg.bind(this);
    this._readTiffBlob = this._readTiffBlob.bind(this);
    this._setSource = this._setSource.bind(this);
  }

  /**
   * @inheritdoc
   */
  componentDidMount() {
    this._setSource();
  }


  /**
   * @inheritdoc
   */
  componentDidUpdate() {
    if (!this.state.src) {
      this._setSource();
    }
  }

  /**
   * Generates a jpeg with the tiff array source
   * @param {ArrayBuffer} arrayData ArrayBuffer containing the tiff binary
   * @return {String} Base64 dataUrl of the tiff image
   */
  _convertTiffToJpg(arrayData) {
    const tiffInstance = new Tiff({buffer: arrayData});
    const canvas = tiffInstance.toCanvas();
    return canvas.toDataURL('image/jpeg');
  }

  /**
   * Generates a jpeg with the tiff source and sets it as the url
   * @return {Promise<String>} The data Url to set as src
   */
  _readTiffBlob() {
    this._fileReader = new FileReader();
    const tiffDataUrl = new Promise((resolve, reject) => {
      this._fileReader.onload = () => {
        const dataUrl = this._convertTiffToJpg(this._fileReader.result);
        resolve(dataUrl);
      };
    });
    this._fileReader.readAsArrayBuffer(this.props.tiffBlob);
    return tiffDataUrl;
  }

  /**
   * Handles setting the state to the source derived from the tiffBlob
   * @return {Promise} Indicates function is completed
   */
  _setSource() {
    return this._readTiffBlob().then( dataUrl => {
      this.setState({
        src: dataUrl
      });
    });
  }

  /**
   * @inheritdoc
   */
  componentWillUnmount() {
    if (this._fileReader) {
      this._fileReader.onload = null;
      this._fileReader = null;
    }
  }

  /**
   * @inheritdoc
   */
  render() {
    return <PhotoContentImage
      isResponsive={this.props.isResponsive}
      shouldRenderThumbnail={this.props.shouldRenderThumbnail}
      rotation={this.props.rotation}
      src={this.state.src}
    />;
  }
}
