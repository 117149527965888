import _ from 'lodash';
import { BinaryDataSource } from '@adsk/forge-hfdm';

import DeferredPromise from '~/common/helpers/deferredPromise';
import {
  REQUEST_CONCURRENCY,
  DOWNLOADS_TYPES,
  DOWNLOADS_PROPERTIES_PATHS,
  DOWNLOADS_PRIORITY,
  BACKGROUND_REQUEST_TYPE,
  BACKGROUND_REQUEST_CONCURRENCY
} from '~/common/constants';

/**
 * @typedef DownloadRequest
 * @type {object}
 * @property {string} guid The photo guid
 * @property {string} type The download type
 * @property {string} destination The datasource
 * @property {string} bp The binary property
 * @property {string} dp The deferred promise
 */

/**
 * Represent a group of DownloadRequest waiting for the same file actively being downloaded by the SDK.
 * @typedef ActiveDownloadRequestGroup
 * @type {object}
 * @property {string} guid The photo guid
 * @property {string} type The download type of the first request that initiated the request
 * @property {string} destination The datasource
 * @property {string} bp The binary property
 * @property {DownloadRequest[]} downloadRequests The requests waiting for this file.
 * @property {boolean} cancelled Was the request cancelled. BinaryProperties will return
 * a resolved promise in this case so we need to track this on our side.
 */

/**
 * Download manager that handle the downloads of binary property in an order that match their priority.
 * Also expose method to cancel or deprioritize certain downloads types by cancelling their request to S3
 * and replacing them in front of their queue.
 */
export class DownloadManager {
  /**
   * @constructor
   * @param {object} params parameters
   */
  constructor() {
    // an array of queue for each download type.
    this.queues = [];
    // request group in this queue are actively being downloaded by the HFDM SDK.
    this.activeRequestsQueue = [];

    _.each(DOWNLOADS_TYPES, typeId => {
      this.queues[typeId] = [];
    });
  }

  /**
   * Add a download to the queue
   * @param {Asset} photoAsset The asset
   * @param {string} type The image type
   * @param {string} destination The datasource
   * @return {DeferredPromise} Return a deferred promise that will get resolved once the download complete
   * or rejected if HFDM return an error. This promise will not resolve or reject if the download is
   * deprioritized by the manager, even if it need to cancel the binary property download request to achieve that.
   * In this scenario, the request will just be placed back in the front of the queue matching its type.
   * If the download is cancelled the promise will get resolve with false instead of the datasource.
   */
  queue(photoAsset, type, destination) {
    const propertyName = DOWNLOADS_PROPERTIES_PATHS[type];
    const guid = photoAsset.guid;
    const bp = photoAsset.getComponent(propertyName).property;
    const dp = new DeferredPromise();

    if (bp.get('status').getEnumString() !== 'UPLOADED') {
      // Reject right away if the file status is not UPLOADED.
      // There's logic elsewhere in the app to handle that.
      return Promise.reject(new Error('File is not uploaded yet.'));
    }

    const newRequest = { guid, type, destination, dp, bp };

    const currentActiveRequest = _.find(
      this.activeRequestsQueue,
      request => this._requestIsForSameFile(request, newRequest)
    );

    if (currentActiveRequest) {
      const existingRequest = _.find(
        currentActiveRequest.downloadRequests,
        request => this._requestIsForSameFile(request, newRequest)
      );

      if (existingRequest) {
        // if we already have an existing request of the same type in this group, just return the same deferred promise.
        // no need to create a new request as well
        return existingRequest.dp;
      } else {
        currentActiveRequest.downloadRequests.push(newRequest);
      }
    } else {
      const existingRequest = _.find(
        this.queues[type],
        request => this._requestIsForSameFile(request, newRequest)
      );

      if (existingRequest) {
        // if we already have an existing request of the same type in this group, just return the same deferred promise.
        // no need to create a new request as well
        return existingRequest.dp;
      } else {
        this.queues[type].push(newRequest);
      }
    }

    this._update();

    return dp;
  }

  /**
   * Remove a download from the queues.
   * If the file is currently being downloaded from the server two possibility arise.
   * 1 - (Other request are waiting for the same file) In this case we check if another request in this group
   *     has a priority at least as high as us. If we don't find any we cancel the group request and move the
   *     individual requests back in their respective queue.
   * 2 - (This is the only request for this file) The download request to HFDM is cancelled and the deferred promise
   *     is resolved with a `cancelled: true;` param.
   * @param {string} guid the asset guid
   * @param {string} type The image type
   * The request will only be requeued if there's other request in this group.
   */
  dequeue(guid, type) {
    const index = _.findIndex(
      this.activeRequestsQueue,
      request => this._requestIsForSameFile(request, {
        guid, type
      })
    );
    const currentActiveRequest = this.activeRequestsQueue[index];

    if (currentActiveRequest) {
      // if there already an active request for the same file we cancel any request that match our type
      const requestToCancel = _.remove(currentActiveRequest.downloadRequests, request => {
        return request.type === type;
      });

      this._cancelRequests(requestToCancel);

      // if there no request left we cancel the request to S3
      if (!currentActiveRequest.downloadRequests.length) {
        this._cancelGroupRequest(currentActiveRequest, index);
        return;
      }

      // Here it get tricky, we don't want to cancel the request group since other request are waiting
      // for this too. But if they have a lower priority than us, they should probably be put back in
      // the queue. Unless it's for a fullsize image. Those files could be large, we don't want to get
      // stuck in a loop where we keep cancelling them before they finish and they just eat bandwidth.

      // Here we search for at least one request with a priority at least equal to us.
      const foundMatchingPriority = _.some(currentActiveRequest.downloadRequests, request => {
        return request.type >= currentActiveRequest.type ||
          request.type === DOWNLOADS_TYPES.DOWNLOAD ||
          request.type === DOWNLOADS_TYPES.FULLSIZE;
      });

      if (!foundMatchingPriority) {
        this._cancelGroupRequest(currentActiveRequest, index);
        this._requeueRequests(currentActiveRequest.downloadRequests);
      }
    } else {
      // no active request, just remove the request for this guid from their queue.
      this._removeRequestFromMatchingQueue(type, guid);
    }

    this._update();
  }

  /**
   * Remove all download of a type from all queues.
   *
   * Useful to cancel all active large image requests when we close the viewer.
   *
   * @param {number} type The request type
   */
  dequeueType(type) {
    const foundGuid = [];

    const pushWhenMissing = request => {
      if (request.type === type && !foundGuid.includes(request.guid)) {
        foundGuid.push(request.guid);
      }
    };

    // look for matching request in the active requests
    _.each(this.activeRequestsQueue, requestGroup => {
      _.each(requestGroup.downloadRequests, request => {
        pushWhenMissing(request);
      });
    });

    // look for matching request in all the queues
    _.each(this.queues, queue => {
      _.each(queue, request => {
        pushWhenMissing(request);
      });
    });

    // we call dequeue() for each found guids.
    _.each(foundGuid, guid => {
      this.dequeue(guid, type);
    });
  }

  /**
   * Cancel active request of a specific download type and move them back to the queue of that type.
   * Useful, for example when opening the viewer, to deprioritize all active thumbnail request and have the active
   * image downloaded right away.
   * If the request group contain request with a higher priority than this type, we do nothing.
   * @param {number} type The request type
   */
  deprioritizeType(type) {
    _.remove(this.activeRequestsQueue, requestGroup => {
      // first we count how many request match ours
      let matchFound = 0;
      _.each(requestGroup.downloadRequests, request => {
        if (request.type === type) matchFound++;
      });

      let cancelGroup = false;
      if (matchFound === requestGroup.downloadRequests.length) {
        // if no other request are of our type, we cancel the whole group.
        cancelGroup = true;
      } else {
        // if requests are left, we look for at least one other request that has at least the same priority level
        // as us and is not of our type. If we find one we don't cancel the request group.
        // (See other comment in the dequeue method that explain why we always consider fullsize image as
        // top priority when searching for a matching priority.)
        const foundMatchingPriority = _.some(requestGroup.downloadRequests, request => {
          return (request.type >= type ||
            request.type === DOWNLOADS_TYPES.DOWNLOAD ||
            request.type === DOWNLOADS_TYPES.FULLSIZE) &&
            request.type !== type;
        });

        if (!foundMatchingPriority) {
          cancelGroup = true;
        }
      }

      if (cancelGroup) {
        requestGroup.cancelled = true;
        requestGroup.bp.cancelDownload();
        this._requeueRequests(requestGroup.downloadRequests);
      }

      return cancelGroup;
    });

    this._update();
  }

  /**
   * Remove a non active request from it's queue and reject the deferred promise.
   * @param {number} type The request type
   * @param {string} guid The property guid
   * @private
   */
  _removeRequestFromMatchingQueue(type, guid) {
    _.each(this.queues[type], (request, index) => {
      if (guid === request.guid) {
        request.dp.resolve({ dataSource: null, cancelled: true });

        _.pullAt(this.queues[type], index);

        // break, there should only be one
        return false;
      }

      return true;
    });
  }

  /**
   * Cancel a bunch of request deferred promises
   * @param {DownloadRequest[]} requests requests to cancel
   * @private
   */
  _cancelRequests(requests) {
    _.each(requests, request => {
      // Temporary fix for issue with Binary Property in the HFDM SDK
      // https://git.autodesk.com/LYNX/HFDM_SDK/pull/2148
      request.destination = new BinaryDataSource.BlobDataSource();

      request.dp.resolve({ dataSource: null, cancelled: true });
    });
  }

  /**
   * @param {ActiveDownloadRequestGroup} requestGroup the requestGroup
   * @param {number} index current index of this requestGroup in the activeRequestsQueue
   * @private
   */
  _cancelGroupRequest(requestGroup, index) {
    requestGroup.cancelled = true;
    requestGroup.bp.cancelDownload();
    _.pullAt(this.activeRequestsQueue, index);
  }

  /**
   * Put a bunch of request back in their queue.
   * We purposely place them at the beginning of the queue this time.
   * @param {DownloadRequest} requests requests to requeue
   * @private
   */
  _requeueRequests(requests) {
    _.each(requests, request => {
      // Temporary fix for issue with Binary Property in the HFDM SDK
      // https://git.autodesk.com/LYNX/HFDM_SDK/pull/2148
      request.destination = new BinaryDataSource.BlobDataSource();

      this.queues[request.type].unshift(request);
    });
  }

  /**
   * Update the queues.
   * Called when a request is added or removed from a queue.
   * Will start a new download if the REQUEST_CONCURRENCY limit is not reached.
   * @private
   */
  _update() {
    if (this.activeRequestsQueue.length < REQUEST_CONCURRENCY) {
      _.times(REQUEST_CONCURRENCY - this.activeRequestsQueue.length, () => {
        const nextDownload = this._getNextDownload();

        if (nextDownload !== null) {
          this._requestDownload(nextDownload);
        }
      });
    }
  }

  /**
   * Return the queued download with the top priority and remove it from its queue.
   * @return {DownloadRequest|null} Return a DownloadRequest or null if there's nothing.
   * @private
   */
  _getNextDownload() {
    let result = null;

    _.each(DOWNLOADS_PRIORITY, type => {
      const isBackgroundRequest = BACKGROUND_REQUEST_TYPE[type];
      const queueSize = this.queues[type].length;

      const foundTopPriority = (queueSize && (!isBackgroundRequest || !this._backgroundRequestLimitReached()));
      if (foundTopPriority) {
        result = this.queues[type].splice(0, 1)[0];

        // break
        return false;
      }

      return true; // useless but eslint really care about it
    });

    // all queues are empty
    return result;
  }

  /**
   * @return {boolean} return whether or not we reached the maximum number of background request we allow.
   * @private
   */
  _backgroundRequestLimitReached() {
    let foundCount = 0;

    // for each active queue
    _.each(this.activeRequestsQueue, requestGroup => {
      let topBackgroundPriorityFound = 0;
      let topPriorityFound = 0;

      // find the top priority of normal and background request
      _.each(requestGroup.downloadRequests, request => {
        const priority = this._getPriority(request.type);
        if (priority > topPriorityFound) topPriorityFound = priority;
        if (BACKGROUND_REQUEST_TYPE[request.type] && (priority > topBackgroundPriorityFound) ) {
          topBackgroundPriorityFound = priority;
        }
      });

      if (topBackgroundPriorityFound >= topPriorityFound) {
        foundCount++;
      }
    });

    return foundCount >= BACKGROUND_REQUEST_CONCURRENCY;
  }

  /**
   * @param {number} type The request type
   * @return {number} return the index of the priority matching a type
   * Useful when comparing the priority level between two requests.
   * @private
   */
  _getPriority(type) {
    return _.findIndex(DOWNLOADS_PRIORITY, priorityType => (priorityType === type));
  }

  /**
   * Function that does the download request to the HFDM SDK and handle the result
   * @param {DownloadRequest} downloadRequest The download request to process
   * @private
   */
  _requestDownload(downloadRequest) {
    // Find any other request for the same file
    const dependentRequests = this._getDependentRequest(downloadRequest);

    const newRequestGroup = {
      guid: downloadRequest.guid,
      type: downloadRequest.type,
      destination: downloadRequest.destination,
      bp: downloadRequest.bp,
      downloadRequests: [downloadRequest, ...dependentRequests],
      cancelled: false
    };

    this.activeRequestsQueue.push(newRequestGroup);

    // Make sure again that the status of the file didn't somehow change while it was in queue
    // The joy of collaboration..
    if (newRequestGroup.bp.get('status').getEnumString() !== 'UPLOADED') {
      this._finalizeRequests(new Error('File is not uploaded yet.'), newRequestGroup);
    } else {
      newRequestGroup.bp.download({ destination: newRequestGroup.destination })
        .then(() => this._finalizeRequests(null, newRequestGroup))
        // HFDM SDK is already handling retries for us, we probably don't need to do that too.
        .catch(error => this._finalizeRequests(error, newRequestGroup));
    }
  }

  /**
   * Called once a request complete, with success or not.
   * Take care of resolving or rejecting the deferred promise for each requests in the group.
   * @param {Error} error Error if any.
   * @param {ActiveDownloadRequestGroup} requestGroup The download request group to process
   * @private
   */
  _finalizeRequests(error, requestGroup) {
    if (requestGroup.cancelled) {
      // When you cancel a download in the HFDM SDK, the promise succeed as if it completed.
      // So if this get called and the request group is tagged as cancelled we stop here.
      // The DownloadRequest will already have all been moved back to their queue if needed and the
      // ActiveDownloadRequestGroup removed from the the active queue
      return;
    }

    // resolve or reject all the requests.
    _.each(requestGroup.downloadRequests, request => {
      if (error) {
        request.dp.reject(error);
      } else {
        request.dp.resolve({ dataSource: request.destination, cancelled: false });
      }
    });

    this._removeFromActiveQueue(requestGroup);

    this._update();
  }

  /**
   * Remove a request group from the active queue
   * @param {ActiveDownloadRequestGroup} requestGroup the requestGroup
   * @private
   */
  _removeFromActiveQueue(requestGroup) {
    _.each(this.activeRequestsQueue, (request, index) => {
      if (this._requestIsForSameFile(request, requestGroup)) {
        _.pullAt(this.activeRequestsQueue, index);

        // break, there should only be one
        return false;
      }

      return true;
    });
  }

  /**
   * Find all request of the same type and path and remove them from their respective queue.
   * @param {DownloadRequest} downloadRequest The request to compare with.
   * @return {DownloadRequest[]} Return an array of all the DownloadRequest found.
   * @private
   */
  _getDependentRequest(downloadRequest) {
    let result = [];

    _.each(this.queues, queue => {
      const found = _.remove(queue, request => {
        return this._requestIsForSameFile(downloadRequest, request);
      });

      if (found) {
        result = result.concat(found);
      }
    });

    return result;
  }

  /**
   * Check if two request of either DownloadRequest or ActiveDownloadRequestGroup type are for the same file and path.
   * and therefore can be grouped together in one request to the SDK/S3
   * @param {DownloadRequest|ActiveDownloadRequestGroup} requestOne requestOne
   * @param {DownloadRequest|ActiveDownloadRequestGroup} requestTwo requestTwo
   * @return {boolean} whether request is for the same file and path.
   * @private
   */
  _requestIsForSameFile(requestOne, requestTwo) {
    const sameGuid = (requestOne.guid === requestTwo.guid);
    const samePath = (DOWNLOADS_PROPERTIES_PATHS[requestOne.type] === DOWNLOADS_PROPERTIES_PATHS[requestTwo.type]);
    const sameCancellationState = (
      _.isBoolean(requestOne.cancelled) &&
      _.isBoolean(requestTwo.cancelled) &&
      requestOne.cancelled === requestTwo.cancelled
    );

    return (sameGuid && samePath && sameCancellationState);
  }
}
