import {Checkbox} from '@adsk/bim360-matrix-react-components';
import connect from 'react-redux/es/connect/connect';
import React from 'react';
import _ from 'lodash';
import {selectPhotosSet} from '~/redux/actions/photosActions';
import { memoize } from '~/common/helpers/utils';

const getTimelineGroupCell = (timeGroupKey, timelineGroup) => {
  const getCheckedStatus = memoize( photosSelection => {
    let checked = true;
    _.forEach(timelineGroup, (currentPhotoId => {
      checked = checked && photosSelection[currentPhotoId];
    }));

    return checked;
  });

  const mapStateToProps = state => {
    const checked = getCheckedStatus(state.photosSelection);

    return {
      checked,
      timelineGroup
    };
  };

  const mapDispatchToProps = dispatch => {
    return {
      onTimeCellClick: value => {
        dispatch(selectPhotosSet(timelineGroup, value));
      }
    };
  };

  const TimelineGroupCell = connect()(({checked, title, onTimeCellClick}) => (
    <Checkbox
      className="largeCheckbox"
      checked={checked}
      label={title}
      onClick={ e => e.stopPropagation()}
      onChange={onTimeCellClick}
    />
  ));

  return connect(mapStateToProps, mapDispatchToProps)(TimelineGroupCell);
};


export default getTimelineGroupCell;
