import {DataBinding} from '@adsk/forge-appfw-databinder';
import {PropertyUtils} from '@adsk/forge-hfdm';

const PathHelper = PropertyUtils.PathHelper;
/**
 * This is a Binding for UDP relationships, responsible for building the look-up tables, which allow fast fetching
 * of relationships with different directions.
 */
export class UDPRelationshipBinding extends DataBinding {
  /**
   * @inheritdoc
   */
  constructor(params) {
    super(params);
    const {relationships} = this.getUserData();
    const relationship = this.getProperty();
    const fromGuid = relationship.resolvePath('../../').getId();
    const toProperty = relationship.get('to');
    let toGuid;
    if (toProperty) {
      toGuid = toProperty.getId();
    } else {
      // if the toGuid is broken, this relationship is broken elect to not render it
      console.warn(`Skipping Relationship with GUID: ${fromGuid}. Reason: Could not find "to" property`);
      return;
    }
    const relationshipGuid = relationship.getId();
    if (!relationships.incoming[toGuid]) {
      relationships.incoming[toGuid] = [];
    }
    relationships.incoming[toGuid].push(relationshipGuid);
    relationships.allFrom[relationshipGuid] = fromGuid;
    relationships.allTo[relationshipGuid] = toGuid;
  }

  /**
   * @inheritdoc
   */
  onRemove(in_removalContext) {
    const path = PathHelper.tokenizePathString(in_removalContext.getAbsolutePath());
    const relationshipGuid = path[path.length - 1];
    const {relationships} = this.getUserData();
    const toAssetGuid = relationships.allTo[relationshipGuid];
    const incoming = relationships.incoming[toAssetGuid];
    const indexOfRelationshipIn = incoming.indexOf(relationshipGuid);
    incoming.splice(indexOfRelationshipIn, 1);
    delete relationships.allFrom[relationshipGuid];
    delete relationships.allTo[relationshipGuid];
  }
}
