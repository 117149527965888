import React from 'react';
import {SimpleModal} from '@adsk/bim360-matrix-react-components';
import {CustomLargeButton} from '~/ui/widgets/customLargeButton.ui';
import {ALL_AUX} from '~/common/constants';
import T from '~/common/i18n';


/**
 * Modal delete photos
 * @param {object} props for the component
 * @return {DeletePhotoModal} component
 */
export default function DeletePhotoModal(props) {

  const options = {
    context: props.countPhoto,
    albumName: props.albumName
  };

  const title = T.translate('modalDeletePhoto.title', options);
  const headerAll = T.translate('modalDeletePhoto.deleteFromAll.header', options);
  const headerAlbum = T.translate('modalDeletePhoto.deleteFromAlbum.header', options);
  const bodyAll = T.translate('modalDeletePhoto.deleteFromAll.body', options);
  const bodyAlbum = T.translate('modalDeletePhoto.deleteFromAlbum.body', options);
  const inAlbum = props.albumName !== ALL_AUX;

  return (
    <SimpleModal
      title={title}
      show={props.show}
      onExited={props.onExited}
      className="deleteModal"
      modalBodyProps={{
        className: 'CustomModal'
      }}
    >
      {inAlbum ?
        <CustomLargeButton
          id="deleteFromAlbumBtn"
          header={headerAlbum}
          body={bodyAlbum}
          onClick={props.deleteFromAlbum}
        /> : null
      }
      <CustomLargeButton
        id="deleteFromAllBtn"
        header={headerAll}
        body={bodyAll}
        onClick={props.deleteFromAll}
      />
    </SimpleModal>
  );
}
