import React from 'react';
import { InlineLoader, SvgIcon } from '@adsk/bim360-matrix-react-components';

/**
 * React component for the PhotoContentUploading
 * @return {Div} Photo uploading icon and loader
 * @public
 */
export function PhotoContentUploading({className}) {
  return (
    <div className={className}>
      <SvgIcon width="103px" height="77px" svgId="icon-camera" />
      <InlineLoader />
    </div>
  );
}
