export const createQueryStringFromParameters = parameters => {
  let queryString = '';

  parameters.forEach((currParam, index) => {
    queryString += `${index === 0 ? '?' : '&'}${currParam.parameter}=${encodeURIComponent(currParam.value)}`;
  }
  );

  return queryString;
};
