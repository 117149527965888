import React from 'react';
import { EmptyState } from '@adsk/bim360-matrix-react-components';
import { PromiseRejectionMessages } from '~/common/helpers/authenticationConsts';
import T from '~/common/i18n';

/**
 * React component for the PhotosErrorBoundary
 * @public
 */
export default class PhotosErrorBoundary extends React.Component {

  /**
   * constructor
   * @param {Object} props inherited properties
   */
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorType: 'generic',
      errorMessage: ''
    };
    this.reloadPage = this.reloadPage.bind(this);
    this.setError = this.setError.bind(this);
  }

  /**
   * @inheritdoc
   */
  async componentDidMount() {
    try {
      const hfdm = await this.props.hfdmConnection;

      // HFDM not connected
      if (!hfdm.isConnected()) {
        this.setError();
      }
    } catch (error) {
      // Not able to access HFDMConnectionComponent
      if (error.message !== PromiseRejectionMessages.TOKEN_PROMISE_REJECTION) {
        this.setError();
      }
    }
  }

  /**
   * @inheritdoc
   */
  componentDidCatch(error) {
    // We can catch custom error from anywhere in the code by throwing an error
    // e.g: throw({type: custom_type, message: 'Optional custom message', ...})
    const errorType = (error && error.type) || 'generic';
    const errorMessage = (error && error.customMessage);
    this.setError(errorType, errorMessage);
  }

  /**
   * Set the component state in error mode
   * @public
   * @param {String} type of the error
   * @param {String} message optional message for the error
   */
  setError(type = 'server', message = '') {
    this.setState({
      hasError: true,
      errorType: type,
      errorMessage: message
    });
  }

  /**
   * Return an object describing the error
   * @public
   * @param {String} errorType type of the error
   * @return {Object} error description
   */
  getErrorMessage() {
    const { errorType, errorMessage } = this.state;
    return {
      title: errorMessage || T.translate(`errors.${errorType}.title`),
      subtitle: T.translate(`errors.${errorType}.subtitle`),
      contact: T.translate('errors.contact')
    };
  }

  /**
   * Reload the page
   * @public
   */
  reloadPage() {
    location.reload();
  }

  /**
   * React rendering of the PhotosErrorBoundary
   * @public
   * @return {React.Component} children or error view
   */
  render() {
    if (this.state.hasError) {
      const error = this.getErrorMessage();
      return (
        <div className="EmptyPageContainer">
          <EmptyState
            icon={EmptyState.Icons.NOT_FOUND}
            text={error.title}
            footer={
              <div className="footer">
                <div className="clickable" onClick={this.reloadPage}>{error.subtitle}</div>
                <div>{error.contact}</div>
              </div>
            }
          />
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}
