import {
  IconButton
} from '@adsk/bim360-matrix-react-components';
import * as React from 'react';
import T from '~/common/i18n';


/**
 * A ui component to display the header of the album panel
 */
export class AlbumsPanelHeader extends React.Component {

  /**
 * @inheritdoc
 */
  render() {
    const sortingIconID = this.props.isDescending ? 'alphabetical-desc' : 'alphabetical-asc';
    return (
      <div className="AlbumsPanelHeader">
        <label className="title">{T.translate('albumsPanel.title')}</label>
        <div className="AlbumActions">
          <IconButton
            svgId={sortingIconID}
            onClick={this.props.onSortAlbumsClick}
          />
          <IconButton
            svgId="add-icon"
            onClick={this.props.onNewAlbumButtonClick}
          />
        </div>
      </div>
    );
  }
}
