import React from 'react';
import _ from 'lodash';
import {
  Button,
  SvgIcon,
  SelectDropdown
} from '@adsk/bim360-matrix-react-components';

import ActionButtons from './actionButtons.ui';
import {CommonControls} from './commonControls.ui';

import {
  ACCEPTED_IMAGE_FORMAT,
  TRASH,
  SORTING_PHOTOS_OPTIONS
} from '~/common/constants';
import ModalDeletePhoto from '~/ui/modals/deletePhotoModal.ui';
import T from '~/common/i18n';


/**
 * Render sorting button
 * @param {Object} props the props passed down
 * @return {SortingButton} sorting button
 */
function SortingButton(props) {
  const { isDescending, oldestOnTop, recentOnTop } = props;
  const sortAction = isDescending ? recentOnTop : oldestOnTop;
  const sortIcon = isDescending ? 'date-desc' : 'date-asc';

  return (
    <Button className="sortingOrderBtn" onClick={sortAction}>
      <SvgIcon width="20px" height="20px" svgId={sortIcon} />
      {T.translate(`sortingMode.${~~!isDescending}`)}
    </Button>
  );
}

/**
 * Render restore button
 * @param {Object} props the props passed down
 * @return {Button} the button for restoring a photo
 */
function RestoreButton(props) {
  const { restorePhotos, selectedPhotos } = props;
  const hasSelectedPhotos = selectedPhotos.length > 0;

  return (
    <Button
      className="restoreButton"
      styleType={Button.StyleType.PRIMARY}
      onClick={restorePhotos}
      disabled={!hasSelectedPhotos}
    >
      <SvgIcon width="15px" height="15px" svgId="restore-small" />
      {T.translate('controls.restoreBtn')}
    </Button>
  );
}

/**
 * Render create issue button
 * @param {Object} props the props passed down
 * @return {div} the button for creating an issue
 */
function CreateIssueButton(props) {
  return (
    <div className="createIssueButton">
      {
        (props.selectedPhotos.length > 0) && (
          <Button
            styleType={Button.StyleType.LINK_SECONDARY}
            onClick={props.openIssueForm}
          >
            {T.translate('controls.issueBtn')}
          </Button>
        )
      }
    </div>
  );
}

/**
 * Render upload button
 * @param {Object} props the props passed down
 * @return {Button} the button for uploading a photo
 */
function UploadButton(props) {
  const { uploadPhotos, activeAlbum } = props;
  return (
    <div className="uploadButton">
      <input id="fileInput"
        type="file"
        name="file"
        multiple
        accept={ACCEPTED_IMAGE_FORMAT}
        onChange={e => uploadPhotos(e.target.files, activeAlbum)}
      />
      <Button styleType={Button.StyleType.PRIMARY}>
        {T.translate('controls.uploadBtn')}
      </Button>
    </div>
  );
}

/**
 * React component for the photo album actions.
 */
class Controls extends React.Component {
  /**
   * Constructor
   * @param {Object} props the props passed down
   */
  constructor(props) {
    super(props);
    this.state = {
      showDeleteModal: false
    };
    this._handleSortTypeChange = this._handleSortTypeChange.bind(this);
    this.showDeleteModal = this.showDeleteModal.bind(this);
    this.hideDeleteModal = this.hideDeleteModal.bind(this);
    this.deleteFromAll = this.deleteFromAll.bind(this);
    this.deleteFromAlbum = this.deleteFromAlbum.bind(this);
    this._sortingOptions = [
      {
        value: SORTING_PHOTOS_OPTIONS.CAPTURE_TIME,
        label: T.translate('controls.sorting.captureTime')
      },
      {
        value: SORTING_PHOTOS_OPTIONS.DELETION_TIME,
        label: T.translate('controls.sorting.deletionTime')
      }
    ];

  }

  /**
   * Show delete modal
   */
  showDeleteModal() {
    this.setState({showDeleteModal: true});
  }

  /**
   * Hide delete modal
   */
  hideDeleteModal() {
    this.setState({showDeleteModal: false});
  }

  /**
   * Render actions buttons controls
   * @param {Object} photosSelection an object with photoId key and boolean value indicating if the photo
   * is selected or not
   * @param {Array} photosSubset the subset of photos: an array of photos ids
   * @return {Array} an array of selected photos within the photosSubset
   */
  getSelectedPhotos(photosSelection, photosSubset) {
    let subsetPhotosSelection = photosSubset ? _.pick(photosSelection, photosSubset) : photosSelection;
    return _.reduce(subsetPhotosSelection, (acc, isSelected, photoId) => {
      if (isSelected) {
        acc.push(photoId);
      }
      return acc;
    }, []);
  }

  /**
   * return selected option in the sort control dropdown
   * @param {String} selectedSortType the selected sort type state
   * @return {Object} the selected object item from the sorting options
   * @private
   */
  _getSelectedOption(selectedSortType) {
    return this._sortingOptions.find(option => option.value === selectedSortType);
  }

  /**
   * return the array of options for sorting control
   * @param {object} option the selected option
   * @private
   */
  _handleSortTypeChange(option) {
    this.props.setSortingType(option.value);
  }

  /**
   * verify if the active album is trash
   * @return {Boolean}  return true if the active album is the trash, false else
   */
  isTrash() {
    return this.props.activeAlbum === TRASH;
  }

  /**
   * Delete from all
   */
  deleteFromAll() {
    this.hideDeleteModal();
    this.props.deletePhotos();
  }

  /**
   * Delete from album
   */
  deleteFromAlbum() {
    this.hideDeleteModal();
    this.props.removePhotosFromActiveAlbum();
  }

  /**
   * @inheritdoc
   */
  render() {
    if (!this.props.photoGallery) {
      return null;
    }
    const isTrash = this.isTrash();
    const selectedPhotos = this.getSelectedPhotos(this.props.photosSelection, this.props.undeletedPhotos);
    const selectedOptions = this._getSelectedOption(this.props.sortingType);
    const countPhoto = selectedPhotos.length;
    const albumName = this.props.albumsData.hasOwnProperty(this.props.activeAlbum) ?
      this.props.albumsData[this.props.activeAlbum].name :
      this.props.activeAlbum;

    return (
      <div className="controlPanel">
        <div className="controls">
          <div className="left column">
            <CommonControls
              {...this.props}
            />
            <SortingButton
              isDescending={this.props.isDescending}
              oldestOnTop={this.props.oldestOnTop}
              recentOnTop={this.props.recentOnTop}
            />
            {isTrash ?
              <SelectDropdown
                className="propertySortingDropdown"
                selectedOptions={[selectedOptions]}
                options={this._sortingOptions}
                onChange={this._handleSortTypeChange}
              /> : null
            }
            <ActionButtons
              downloadLocked={this.props.downloadLocked}
              downloadPhotos={this.props.downloadPhotos}
              duplicatePhotos={this.props.duplicatePhotos}
              setPhotosPrivate={this.props.setPhotosPrivate}
              deletePhotos={this.showDeleteModal}
              isTrash={isTrash}
              selectedPhotos={selectedPhotos}
              showPrivacyFeature={this.props.showPrivacyFeature}
            />
          </div>
          <div className="right column">
            {isTrash ?
              <RestoreButton
                restorePhotos={this.props.restorePhotos}
                selectedPhotos={this.getSelectedPhotos(this.props.photosSelection)}
              /> :
              <>
                <CreateIssueButton
                  openIssueForm={this.props.openIssueForm}
                  selectedPhotos={selectedPhotos}
                />
                <UploadButton
                  activeAlbum={this.props.activeAlbum}
                  uploadPhotos={this.props.uploadPhotos}
                />
              </>
            }
          </div>
        </div>
        <ModalDeletePhoto
          show={this.state.showDeleteModal}
          onExited={this.hideDeleteModal}
          countPhoto={countPhoto}
          albumName={albumName}
          deleteFromAlbum={this.deleteFromAlbum}
          deleteFromAll={this.deleteFromAll}
        />
      </div>
    );
  }

}
export default Controls;
