import _ from 'lodash';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import saveAs from 'file-saver';
import T from '~/common/i18n';
import exifReader from 'exif-js';
import moment from 'moment';

// We disable this since we don't need it right now. It will make thing faster.
// If we ever need image rotation from EXIF data we might need to remove this line.
exifReader.disableXmp();

import {
  addPhoto as addPhotoToTracker,
  clearFromUploaderTracker,
  openPanel,
  showLargeFilesAlert
} from './uploaderTrackerActions.js';

import { closeViewer } from './photoViewerActions';

import {
  ACTIVITY,
  PHOTO_RESTORED,
  PHOTO_REMOVED,
  ADD_PHOTO,
  REQUEST_PHOTO,
  RECEIVE_PHOTO,
  REJECT_PHOTO,
  SELECT_PHOTO,
  SET_PHOTO_PRIVACY,
  UPDATE_UPLOAD_STATUS,
  UPDATE_PHOTO,
  UPDATE_PHOTO_LOCATION,
  SELECT_ALL_PHOTOS,
  DESELECT_ALL_PHOTOS,
  UPDATE_TRACKABLE,
  SELECT_PHOTOS_SET,
  SET_SORTING_ORDER,
  SET_SORTING_TYPE,
  UPDATE_COMPOSITION,
  SET_TIME_GROUPBY,
  CLEAR_PHOTO_DATA,
  CLEAR_PHOTOS_DATA,
  ADD_THUMBNAIL,
  REJECT_THUMBNAIL,
  RECEIVE_THUMBNAIL,
  REQUEST_THUMBNAIL,
  UPDATE_THUMBNAIL_UPLOAD_STATUS,
  UPDATE_FILTER,
  UPDATE_DISPLAYED_PHOTOS_COUNT,
  UPLOADED,
  FETCHED,
  MAX_FILE_SIZE,
  LOCK_DOWNLOAD,
  UNLOCK_DOWNLOAD,
  DOWNLOADS_TYPES,
  ACCEPTED_IMAGE_FORMAT,
  CANCEL_PHOTO,
  CANCEL_THUMBNAIL,
  ALL_AUX,
  TRIGGER_MODAL_VISIBILITY,
  OPEN_FAILED_DOWNLOAD_WARNING,
  CLOSE_FAILED_DOWNLOAD_WARNING,
  RESET_FAILED_DOWNLOAD_STATUS,
  DEFAULT_TAKEN_DATE
} from '~/common/constants';
import { StateTreeManager } from '~/common/helpers/stateTreeManager';
import { batchActions } from 'redux-batched-actions';

/**
 * Handles opening the viewer
 * Also deprioritize thumbnail download so the fullsize image request start right away.
 * @return {Function} A redux action wrapped in thunk
 */
export function deprioritizeThumbnailRequests() {
  return (dispatch, getState, PhotosComponent) => {
    PhotosComponent._downloadManager.deprioritizeType(DOWNLOADS_TYPES.THUMBNAIL);
  };
}

/**
 * Closes the photoViewer
 * Also cancel fullsize image download so the fullsize image request start right away.
 * @return {Function} A redux action wrapped in thunk
 */
export function closeViewerThenCancelFullsizeImageRequests() {
  return (dispatch, getState, PhotosComponent) => {
    PhotosComponent._downloadManager.dequeueType(DOWNLOADS_TYPES.FULLSIZE);

    dispatch(closeViewer());
  };
}

/**
 * Clears binary data from photos, and cancels active requests on it
 * @param {String[]} photoIds The list of photoIds to clear and cancel
 * @return {Function} redux thunk action
 */
export function clearPhotosAndCancelFullsizeRequests(photoIds) {
  return (dispatch, getState, PhotosComponent) => {
    photoIds.forEach(photoId => {
      PhotosComponent._downloadManager.dequeue(photoId, DOWNLOADS_TYPES.FULLSIZE);
    });
    dispatch(clearPhotosData(photoIds));
  };
}

/**
 * Add given photoId or selected photo id(s) to album
 * @param {string} photoId id of the dragged photo
 * @param {string} albumId id of the album
 * @return {Function} A redux action wrapped in thunk
 */
export function addPhotosToAlbum(photoId, albumId) {
  return (dispatch, getState, PhotosComponent) => {
    const photosSelection = getState().photosSelection;
    const selectedPhotoIds = Object.keys(photosSelection).filter(id => photosSelection[id]);

    if (selectedPhotoIds.length === 0) {
      selectedPhotoIds.push(photoId);
    }

    const photosSpace = PhotosComponent.getSpace();
    photosSpace.getAsset(albumId).addPhotos(selectedPhotoIds);
  };
}

/**
 * fetch a photo, uses thunk middleware to handle async.
 * @param {Number} id the id of the photo to fetch
 * @param {Boolean} [shouldGenerateThumbnail=false] Should it generate the thumbnail
 * @param {Boolean} [backgroundRequest=false] is it a low priority background request
 * @return {Function} A redux action wrapped in thunk
 */
export function fetchPhoto(id, shouldGenerateThumbnail = false, backgroundRequest = false) {
  return (dispatch, getState, PhotosComponent) => {
    const photoState = getState().photosUploadStatus[id];
    const photoStatus = photoState && photoState.status;
    if (photoStatus === UPLOADED) {
      // Photo is not already being fetched or is fetched.
      dispatch(requestPhoto(id));

      return PhotosComponent.fetchPhoto(id, backgroundRequest)
        .then(({ url, cancelled }) => {
          if (cancelled) {
            dispatch(cancelPhoto(id));
            return null;
          } else {
            dispatch(receivePhoto(id, url));
            const asset = PhotosComponent.getSpace().getAsset(id);
            const extension = asset.property.get(['photo', 'format']).getValue();
            const currentDateTaken = asset.property.get(['photo', 'dateTaken', 'iso8601']).getValue();

            if (currentDateTaken === '') {
              getDateTakenFromExifData(url, '.' + extension, true)
                .then(date => {
                  return asset.updateTakenDate(date);
                });
            }

            if (shouldGenerateThumbnail) {
              if (asset) {
                const thumbnailFile = asset.getComponent('thumbnail').property;

                if (thumbnailFile) {
                  const photosContentTreeManager = new StateTreeManager(getState().photosContent);
                  const dataSource = photosContentTreeManager.getValue(id).dataSource;
                  PhotosComponent._generateAndUploadThumbnail(dataSource, thumbnailFile);
                } else {
                  console.error('Thumbnail node not found while fetching photo.');
                  return Promise.reject();
                }
              } else {
                console.error('asset is not defined while fetching photo.');
                return Promise.reject();
              }
            }

            return url;
          }
        })
        .catch(err => {
          console.error(err);
          dispatch(rejectPhoto(id));
        });
    } else {
      return Promise.reject(new Error('Photo wasn\'t uploaded. ' + photoStatus));
    }
  };
}

/**
 * fetch a thumbnail, uses thunk middleware to handle async.
 * @param {Number} id the id of the photo to fetch
 * @param {boolean} prioritize Whether to prioritize the fetching of this thumbnail or not
 * @return {Function} A redux action wrapped in thunk
 */
export function fetchThumbnail(id, prioritize = false) {
  return (dispatch, getState, PhotosComponent) => {
    if (getState().thumbnails[id] && getState().photosUploadStatus[id].status === UPLOADED) {
      dispatch(requestThumbnail(id));
      PhotosComponent.fetchThumbnail(id, prioritize)
        .then(({ url, cancelled }) => {
          if (cancelled) {
            dispatch(cancelThumbnail(id));
          } else {
            dispatch(receiveThumbnail(id, url));
          }
        })
        .catch(message => {
          console.warn(message);
          dispatch(rejectThumbnail(id));
          dispatch(openDownloadFailedWarning());
          const { photosStatus, photosContent} = getState();
          const photosStatusTreeManager = new StateTreeManager(photosStatus);
          const photosContentTreeManager = new StateTreeManager(photosContent);
          const photoIdStatus = photosStatusTreeManager.getValue(id);
          if (photoIdStatus && photoIdStatus.status === FETCHED) {
            const asset = PhotosComponent.getSpace().getAsset(id);
            if (asset) {
              const thumbnailFile = asset.getComponent('thumbnail').property;
              if (thumbnailFile) {
                PhotosComponent._generateAndUploadThumbnail(
                  photosContentTreeManager.getValue(id).dataSource,
                  thumbnailFile
                  );
              } else {
                console.error('Thumbnail node not found while fetching thumbnail');
              }
            } else {
              console.error('asset is not defined while fetching thumbnail');
            }
          } else {
            dispatch(fetchPhoto(id, true, true));
          }
        });
    }
  };
}

/**
 * reads a file's header to get its extension
 * @param {Buffer} data the input file
 * @return {String} the extension
 */
function getExtension(data) {
  let extension;
  let magicNum = getMagicNumber(data, 4);
  switch (magicNum) {
    case '89504e47':
      extension = '.png';
      break;
    case '47494638':
      extension = '.gif';
      break;
    case 'ffd8ffe0':
    case 'ffd8ffe1':
    case 'ffd8ffe2':
    case 'ffd8ffe3':
    case 'ffd8ffe8':
    case 'ffd8ffdb':
      extension = '.jpg';
      break;
    case '49492a0':
    case '4d4d02a':
    case '4d4d002a':
    case '4d4d002b':
      extension = '.tiff';
      break;
    default:
      extension = evaluateTiffBmpMagicNum(data);
      break;
  }
  return extension;
}

/**
 * Read the EXIF metadata of a valid image file (jpeg, tiff) and extract the date the image was taken.
 * @param {object|string} file The file either as File from a form or a blob url.
 * @param {string} extension the file extension including the . at the start.
 * @return {string} return the data or the default date value if it's missing.
 */
export function getDateTakenFromExifData(file, extension) {
  let fileToParse = file;

  if (typeof fileToParse === 'string') {
    fileToParse = { src: file };
  }

  return new Promise(resolve => {
    if (!['.tif', '.tiff', '.jpg', '.jpeg'].includes(extension)) {
      // Other format don't support EXIF, so we won't bother parsing them.
      resolve(DEFAULT_TAKEN_DATE);
    } else {
      exifReader.getData(fileToParse, () => {
        if (fileToParse.exifdata && fileToParse.exifdata.DateTimeOriginal) {
          var date = moment(fileToParse.exifdata.DateTimeOriginal, 'YYYY:MM:DD HH:mm:ss');

          if (date.isValid()) {
            resolve(date.toISOString());
          } else {
            resolve(DEFAULT_TAKEN_DATE);
          }
        } else {
          resolve(DEFAULT_TAKEN_DATE);
        }
      });
    }
  });
}

/**
 * evaluate the buffer against fringe tiff cases and bitmap
 * @param {Buffer} data the input file to evaluate.
 * @return {string} the extension.
 */
function evaluateTiffBmpMagicNum(data) {
  if (getMagicNumber(data, 3) === '492049') {
    return '.tiff';
  } else if (getMagicNumber(data, 2) === '424d') {
    return '.bmp';
  } else {
    return ''; // Or you can use the blob.type as fallback
  }
}

/**
 * Retrieve the magic number from a specific amount of bytes.
 * @param {Buffer} data the data to validate.
 * @param {int} maxBytes the number of bytes read for the magic number.
 * @return {string} the header
 */
function getMagicNumber(data, maxBytes) {
  const arr = (new Uint8Array(data)).subarray(0, maxBytes);
  let header = '';
  for (let i = 0; i < arr.length; i++) {
    header += arr[i].toString(16);
  }
  return header;
}

/**
 * Helper function that handles downloading a single photo
 * @param {String} name name of the photo to be downloaded
 * @param {String} url url of the fetched binary
 * @param {JSZip} zip instance of JSZip to create the download file
 * @param {Object} toDownloadTrack track the number of items to process
 * @param {Function} resolve callback to use when we've processed all files
 */
function _downloadPhoto(name, url, zip, toDownloadTrack, resolve) {
  JSZipUtils.getBinaryContent(url, (err, data) => {
    if (err) {
      console.error('Problem happened when download img: ' + url);
      resolve(zip);
    } else {
      const ext = getExtension(data);
      if (!ext) {
        console.error('Format not accepted on img: ' + url);
        resolve(zip);
      } else {
        zip.file(name + ext, data, {binary: true});
        toDownloadTrack.zipped++;
        if (toDownloadTrack.zipped === toDownloadTrack.toDownload) {
          resolve(zip);
        }
      }
    }
  });
}

/**
 * Helper function
 * @return {String} DD_HH_MM
 */
function getDateString() {
  let today = new Date();
  let month = T.translate('months.' + (today.getMonth() + 1));
  let day = today.getDate();
  let hours = today.getHours();
  let minutes = today.getMinutes();

  if (day < 10)
    day = '0' + day;
  if (hours < 10)
    hours = '0' + hours;
  if (minutes < 10)
    minutes = '0' + minutes;

  return (month + '_' + day + '_' + hours + '_' + minutes);
}

/**
 * Download photos given photos ids.
 * @param {Array<String>} [photosIds= []] Array of photos ids to be downloaded.
 * @return {Function} A redux thunk action
 */
export function downloadPhotos(photosIds = []) {
  return (dispatch, getState, PhotosComponent) => {
    let toDownloadTrack = {
      toDownload: 0,
      zipped: 0
    };
    let {
      photos,
      photosStatus,
      photosContent
    } = getState();

    dispatch(lockDownload());

    photosIds.forEach(() => {
      toDownloadTrack.toDownload++;
    });

    let zipPromise = new Promise((resolve, reject) => {
      let zip = new JSZip();

      const photosStatusTreeManager = new StateTreeManager(photosStatus);
      const photosContentTreeManager = new StateTreeManager(photosContent);

      // This dictionary should be used with name as key and number of hits as value.
      const listOfNames = {};
      photosIds.forEach(photoID => {
        if (photosStatusTreeManager.getValue(photoID).status !== FETCHED) {
          dispatch(fetchPhoto(photoID, false, true)).then(url => {
            if (url) {

              let nameOfFile = getProperNameForDownload(photos[photoID].name, listOfNames);

              _downloadPhoto(nameOfFile, url, zip, toDownloadTrack, resolve);
            }
          }).catch(err => {
            dispatch(unlockDownload());
            console.error('Error while fetching ' + photos[photoID].name + ', ' + err);
            toDownloadTrack.zipped++;
            if (toDownloadTrack.zipped === toDownloadTrack.toDownload)
              resolve(zip);
          });
        } else {
          const url = photosContentTreeManager.getValue(photoID).url;

          let nameOfFile = getProperNameForDownload(photos[photoID].name, listOfNames);

          _downloadPhoto(nameOfFile, url, zip, toDownloadTrack, resolve);
        }
        dispatch(logActivity(ACTIVITY.VERBS.DOWNLOAD_PHOTO, photoID));
      });
    });

    zipPromise.then(zip => {
      zip.generateAsync({type: 'blob'}).then(blob => { // 1) generate the zip file
        dispatch(unlockDownload());
        saveAs(blob, 'BIM360Photos_' + getDateString() + '.zip'); // 2) trigger the download
      });
    });
  };
}

/**
 * Download all selected photos
 * @return {Function} A redux thunk action
 */
export function downloadSelectedPhotos() {
  return (dispatch, getState, PhotosComponent) => {
    const {photosSelection} = getState();
    const selectedPhotos = _.pickBy(photosSelection, isSelected => isSelected);
    downloadPhotos(Object.keys(selectedPhotos))(dispatch, getState, PhotosComponent);
  };
}

/**
 * Will determine the name of the file for download.
 * @param {string} nameOfFile name of the file to download.
 * @param {Object} listOfNames dictionary of the names already zipped.
 * @return {string} the name of the file.
 */
function getProperNameForDownload(nameOfFile, listOfNames) {
  if (!nameOfFile) {
    nameOfFile = 'untitled';
  }
  const baseName = nameOfFile;
  if (listOfNames[baseName]) {
    nameOfFile += '(' + listOfNames[baseName] + ')';
    listOfNames[baseName]++;
  } else {
    listOfNames[baseName] = 1;
  }
  return nameOfFile;
}

/**
 * upload a new photo
 * @param {Object} files files from browser
 * @param {String|null} [albumGuid] Album guid. If given, it will upload the photos and it to this album, otherwise the
 * photos will be orphan.
 * @return {Function} The redux thunk action
 */
export function uploadPhotos(files, albumGuid = null) {
  return (dispatch, getState, PhotosComponent) => {
    const max_size = MAX_FILE_SIZE;
    const tooLargeFiles = [];
    const space = PhotosComponent.getSpace();
    const uploads = [];
    space.executeAtomicChange(() => {
      _.each(files, file => {
        if (file.size > max_size) {
          tooLargeFiles.push({
            'name': file.name,
            'size': file.size
          });
        } else if (ACCEPTED_IMAGE_FORMAT.includes(file.type)) {
          // We now validate that we really have this type and not just the extention.
          let aFileReader = new FileReader();
          aFileReader.onload = () => {
            const arrayData = aFileReader.result;
            const header = getExtension(arrayData);
            if (!header) {
              // Pop Alert with all failed files afterward?
              console.error('File format not supported. File : ' + file.name);
            } else {
              const {photoId, upload} = space.createPhoto(file, file.name);
              uploads.push(upload);
              if (albumGuid && albumGuid !== ALL_AUX) {
                space.getAsset(albumGuid).addPhoto(photoId, false);
              }

              dispatch(batchActions([
                addPhotoToTracker(photoId),
                openPanel()
              ]));
              getDateTakenFromExifData(file, header).then(date => {
                return PhotosComponent.getSpace().getAsset(photoId).updateTakenDate(date);
              });
            }
          };
          aFileReader.readAsArrayBuffer(file);
        }
      });
    });

    Promise.all(uploads)
      .then(space.getWorkspace().commit());

    if (tooLargeFiles.length) {
      dispatch(showLargeFilesAlert(tooLargeFiles));
    }
    document.getElementById('fileInput').value = null;
    return false;
  };
}

/**
 * delete selected photos
 * @param {Array} [photoIDs=null] array of photo ids to delete
 * @return {Function} The redux thunk action
 */
export function deletePhotos(photoIDs) {
  return (dispatch, getState, PhotosComponent) => {
    const {photosSelection, undeletedPhotos} = getState();
    const space = PhotosComponent.getSpace();
    let promises = [];

    // Extract photo ids to delete
    if (!photoIDs) {
      const undeletedPhotosSelection = _.pick(photosSelection, undeletedPhotos);
      photoIDs = _.reduce(Object.keys(undeletedPhotosSelection), (results, id) => {
        const isSelected = undeletedPhotosSelection[id];
        if (isSelected) {
          results.push(id);
        }
        return results;
      }, []);
    }

    // Delete photos
    space.executeAtomicChange(() => {
      photoIDs.forEach(photoID => {
        dispatch(clearFromUploaderTracker(photoID));
        const photoAsset = space.getAsset(photoID);
        promises.push(photoAsset.delete(false));
      });
    });

    // Commit
    Promise.all(promises).then(() => {
      dispatch(deselectAllPhotos());
      return PhotosComponent.getWorkspace().commit();
    });
  };
}

/**
 * restore selected photos
 * @param {Array} [photoIDs=null] array of photo ids to delete
 * @return {Function} The redux thunk action
 */
export function restorePhotos(photoIDs) {
  return (dispatch, getState, PhotosComponent) => {
    const {photosSelection, deletedPhotos} = getState();
    const space = PhotosComponent.getSpace();
    let promises = [];

    // Extract photo ids to restore
    const deletedPhotosSelection = _.pick(photosSelection, deletedPhotos);
    if (!photoIDs) {
      photoIDs = _.reduce(Object.keys(deletedPhotosSelection), (results, id) => {
        const isSelected = deletedPhotosSelection[id];
        if (isSelected) {
          results.push(id);
        }
        return results;
      }, []);
    }

    // Restore photos
    space.executeAtomicChange(() => {
      photoIDs.forEach(photoID => {
        const photoAsset = space.getAsset(photoID);
        promises.push(photoAsset.restore(false));
      });
    });

    // Commit
    Promise.all(promises).then(() => {
      return PhotosComponent.getWorkspace().commit();
    });
  };
}

/**
 * cancel upload for a list of photos and then delete their property from the workspace
 * @param {string[]} photoIDs array of guids
 * @return {Function} The redux thunk action
 */
export function cancelUploads(photoIDs) {
  return (dispatch, getState, PhotosComponent) => {
    photoIDs.forEach(photoID => {
      PhotosComponent.cancelUpload(photoID);
    });
    dispatch(deletePhotos(photoIDs));
  };
}

/**
 * cancel download for a list of thumbnail
 * @param {string} photoId guid of the photo
 * @return {Function} The redux thunk action
 */
export function cancelGalleryThumbnailDownload(photoId) {
  return (dispatch, getState, PhotosComponent) => {
    PhotosComponent.cancelDownload(photoId, DOWNLOADS_TYPES.THUMBNAIL);
  };
}

/**
 * retry upload for a list of photos
 * Note: This assume the upload in question is already tracked by the upload panel.
 * @param {string[]} photoIDs array of guids
 * @return {Function} The redux thunk action
 */
export function retryUploads(photoIDs) {
  return (dispatch, getState, PhotosComponent) => {

    _.each(photoIDs, photoID => {
      PhotosComponent.getSpace().getAsset(photoID).retryUpload();
    });
  };
}

/**
 * Deleting selected photos from the active album.
 * @param {string} photoID GUID of the photo to remove from the current active album.
 * @param {boolean} [commit=true] enable the commit to the server.
 * @return {Function} The redux thunk action
 */
export function removePhotoFromActiveAlbum(photoID, commit = true) {
  return (dispatch, getState, PhotosComponent) => {
    const { albums, activeAlbum } = getState();
    const currentAlbum = albums[activeAlbum];
    if (currentAlbum) {
      PhotosComponent.getSpace().deleteRelationship(currentAlbum.relationships[photoID]);
      dispatch(logActivity(ACTIVITY.VERBS.REMOVE_PHOTO_FROM_ALBUM, photoID, currentAlbum.id));
      if (commit) {
        return PhotosComponent.getWorkspace().commit();
      }
    }
    return false;
  };
}

/**
 * delete photos-album relationship from active album and selected photos
 * @return {Function} The redux thunk action
 */
export function removePhotosFromActiveAlbum() {
  return (dispatch, getState, PhotosComponent) => {
    const {photosSelection} = getState();
    const selectedPhotoIds = Object.keys(photosSelection).filter(photoId => photosSelection[photoId]);
    selectedPhotoIds.forEach(photoId => {
      dispatch(removePhotoFromActiveAlbum(photoId, false));
    });
    return PhotosComponent.getWorkspace().commit();
  };
}

/**
 * Duplicate given photoId or selected photo id(s)
 * @param {string} [photoIDs=null] array of photo ids to duplicate
 * @return {Function} The redux thunk action
 */
export function duplicatePhotos(photoIDs) {
  return (dispatch, getState, PhotosComponent) => {
    const {activeAlbum, photosSelection} = getState();
    const space = PhotosComponent.getSpace();
    const albumAsset = activeAlbum !== ALL_AUX ? space.getAsset(activeAlbum) : null;
    let promises = [];

    // Extract photo ids to duplicate
    if (!photoIDs) {
      photoIDs = Object.keys(photosSelection).filter(photoId => photosSelection[photoId]);
    }

    // Duplicate photos
    space.executeAtomicChange(() => {
      photoIDs.forEach(photoId => {
        const promise = new Promise(resolve => {
          space.getAsset(photoId).duplicate(false).then(duplicateAssetGuid => {
            if (albumAsset) {
              albumAsset.addPhoto(duplicateAssetGuid.value, false);
            }
            resolve();
          });
        });
        promises.push(promise);
      });
    });

    // Commit
    return Promise.all(promises).then(() => {
      return PhotosComponent.getWorkspace().commit();
    });
  };
}

/**
 * Update composition of image (transformations)
 * @param {Number} id The id of the image
 * @param {Object} composition The new composition of the image
 * @return {Object} A redux action
 */
export function updateImageComposition(id, composition) {
  return ({
    type: UPDATE_COMPOSITION,
    id,
    composition
  });
}

/**
 * Handles rotating the photo and calls HFDM
 * @param {String} id the guid of the image to update
 * @param {Boolean} isClockWise determines direction of rotation
 * @return {Function} A function to handle the call to SDK
 */
export function rotatePhoto(id, isClockWise) {
  return (dispatch, getState, PhotosComponent) => {
    const {photosComposition, photoViewer} = getState();
    const photoId = id || photoViewer.activePhotoId;
    // do something sdk like
    let value = (photosComposition[photoId] && photosComposition[photoId].rotation) || 0;
    if (isClockWise) {
      value = (value + 90) % 360;
    } else {
      value = (value - 90 + 360) % 360;
    }
    return PhotosComponent.getSpace().getAsset(photoId).modifyComposition({ rotation: value });
  };
}

/**
 * Logs an activity
 * @param {String} verb the verb of the action
 * @param {String} guid the guid of asset
 * @param {String} secondaryGuid secondary guid for album only
 * @param {String} value the value of the asset
 * @param {String} customAttrs custom attributes
 * @return {Function} The redux thunk action
 */
export function logActivity(verb, guid, secondaryGuid, value, customAttrs) {
  return (dispatch, getState, PhotosComponent) => {
    const space = PhotosComponent.getSpace();
    const asset = space.getAsset(guid);
    if (asset.constructor.name === 'PhotoAsset') {
      const albumAsset = secondaryGuid ? space.getAsset(secondaryGuid) : null;
      space.getActivityLogService().logPhotoActivity(verb, asset, albumAsset, customAttrs);
    } else {
      space.getActivityLogService().logAlbumActivity(verb, asset, customAttrs);
    }
  };
}

/**
 * fetch a photo
 * @param {Number} id the id of the photo to fetch
 * @return {Object} Redux action
 */
export function requestPhoto(id) {
  return ({
    type: REQUEST_PHOTO,
    id
  });
}

/**
 * receive the fetched photo
 * @param {Number} id the id of the photo to fetch
 * @param {String} url representing the photo to download
 * @return {Object} Redux action
 */
export function receivePhoto(id, url) {
  return ({
    type: RECEIVE_PHOTO,
    id,
    url
  });
}

/**
 * receive the fetched thumbnail
 * @param {Number} id the id of the thumbnail to fetch
 * @param {String} url representing the thumbnail to download
 * @return {Object} Redux action
 */
export function receiveThumbnail(id, url) {
  return ({
    type: RECEIVE_THUMBNAIL,
    id,
    url
  });
}

/**
 * Reject a specific thumbnail
 * @param {Number} id thumbnail id
 * @return {Object} Redux action
 */
export function rejectThumbnail(id) {
  return ({
    type: REJECT_THUMBNAIL,
    id
  });
}

/**
 * Cancel a specific thumbnail
 * @param {Number} id thumbnail id
 * @return {Object} Redux action
 */
export function cancelThumbnail(id) {
  return ({
    type: CANCEL_THUMBNAIL,
    id
  });
}

/**
 * Requesting a specific thumbnail.
 * @param {Number} id thumbnail id
 * @return {Object} Redux action
 */
export function requestThumbnail(id) {
  return ({
    type: REQUEST_THUMBNAIL,
    id
  });
}

/**
 * Fetching photo failed
 * @param {Number} id the id of the photo that failed
 * @return {Object} Redux action
 */
export function rejectPhoto(id) {
  return ({
    type: REJECT_PHOTO,
    id
  });
}

/**
 * Fetch was cancelled
 * @param {Number} id the id of the photo that was cancelled
 * @return {Object} Redux action
 */
export function cancelPhoto(id) {
  return ({
    type: CANCEL_PHOTO,
    id
  });
}


/**
 * @param {Object} image Image data to be added to the list
 * @return {Object} A redux action
 */
export function addPhoto(image) {
  return ({
    type: ADD_PHOTO,
    image
  });
}

/**
 * Adding a new thumbnail
 * @param {Object} thumbnail Thumbnail data to be added to the list
 * @return {Object} A redux action
 */
export function addThumbnail(thumbnail) {
  return ({
    type: ADD_THUMBNAIL,
    thumbnail
  });
}

/**
 * Update status of uploading images
 * @param {Number} id The id of the image
 * @param {String} status The new status
 * @return {Object} A redux action
 */
export function updateUploadStatus(id, status) {
  return ({
    type: UPDATE_UPLOAD_STATUS,
    id,
    status
  });
}

/**
 * Update status of uploading thumbnail
 * @param {Number} id The id of the thumbnail
 * @param {String} status The new status
 * @return {Object} A redux action
 */
export function updateThumbnailUploadStatus(id, status) {
  return ({
    type: UPDATE_THUMBNAIL_UPLOAD_STATUS,
    id,
    status
  });
}

/**
 * Update status of uploaded image
 * @param {Number} id The id of the image
 * @param {Object} trackable A photo trackable info
 * @return {Object} A redux action
 */
export function updateTrackable(id, trackable) {
  return ({
    type: UPDATE_TRACKABLE,
    id,
    trackable
  });
}

/**
 * Update extension (format) of image
 * @param {Number} id The id of the image
 * @param {Object} photo asset photo component values
 * @return {Object} A redux action
 */
export function updatePhotoData(id, photo) {
  return ({
    type: UPDATE_PHOTO,
    id,
    photo
  });
}

/**
 * Update photo location data
 * @param {Number} id The id of the image
 * @param {Object} location asset location component values
 * @return {Object} A redux action
 */
export function updatePhotoLocationData(id, location) {
  return ({
    type: UPDATE_PHOTO_LOCATION,
    id,
    location
  });
}

/**
 * select/deselect a photo
 * @param {Number} id the id of the photo to select/deselect
 * @param {Boolean} isSelect whether this is select mode
 * @return {Object} A redux action
 */
export function selectPhoto(id, isSelect) {
  return ({
    type: SELECT_PHOTO,
    id,
    isSelect
  });
}

/**
 * select all photos from Album
 * @return {Object} Redux action
 */
export function selectAllPhotos() {
  return ({
    type: SELECT_ALL_PHOTOS
  });
}

/**
 * select all photos from Album
 * @return {Object} Redux action
 */
export function deselectAllPhotos() {
  return ({
    type: DESELECT_ALL_PHOTOS
  });
}

/**
 * select set of photos
 * @param {String[]} photoIDs the ids of the photos to select
 * @param {Boolean} isSelect a boolean to define whether to select or unselect
 * @return {Object} Redux action
 */
export function selectPhotosSet(photoIDs, isSelect) {
  let payload = {photoIDs, isSelect};
  return {
    type: SELECT_PHOTOS_SET,
    payload
  };
}

/**
 * This function will deselect all of the photoIds if they are all selected
 * It will select all the photos otherwise.
 * @param {String[]} photoIDs The list of photos to toggle select on.
 * @return {Function} A redux thunk action
 */
export function togglePhotosSet(photoIDs) {
  return (dispatch, getState) => {
    const { photosSelection } = getState();
    let isSelect = false;
    photoIDs.forEach(photoId => {
      // if even a single photo is unselected this will be true
      isSelect = isSelect || !photosSelection[photoId];
    });

    dispatch(selectPhotosSet(photoIDs, isSelect));
  };
}

/**
 * Delete the photo in the redux state
 * @param {Number} ids the ids of the photo to remove
 * @return {Object} Redux action
 */
export function photoRemoved(ids) {
  return ({
    type: PHOTO_REMOVED,
    ids
  });
}

/**
 * Restore the photo in the redux state
 * @param {Number} ids the ids of the photo to restore
 * @return {Object} Redux action
 */
export function photoRestored(ids) {
  return ({
    type: PHOTO_RESTORED,
    ids
  });
}

/**
 * Set the value of isPrivate on the given photo
 * @param {Number} id the id of the photo
 * @param {Boolean} isPrivate determines the privacy of the photo
 * @return {Object} Redux action
 */
export function setPhotoPrivate(id, isPrivate) {
  return (dispatch, getState, PhotosComponent) => {
    const space = PhotosComponent.getSpace();
    space.getAsset(id).setAsPrivate(isPrivate);
  };
}

/**
 * Set the selected photos as private or public
 * @param {Boolean} isPrivate determines the privacy of the photo
 * @return {Object} Redux action
 */
export function setSelectedPhotosPrivate(isPrivate) {
  return (dispatch, getState, PhotosComponent) => {
    const photosSelection = getState().photosSelection;
    const selectedPhotoIds = Object.keys(photosSelection).filter(photoId => photosSelection[photoId]);
    const space = PhotosComponent.getSpace();

    selectedPhotoIds.forEach(photoId => {
      space.getAsset(photoId).setAsPrivate(isPrivate, false);
    });

    return PhotosComponent.getWorkspace().commit();
  };
}

/**
 * Set the value of isPrivate on the given photo in the redux state
 * @param {Number} id the id of the photo
 * @param {Boolean} isPrivate determines the privacy of the photo
 * @return {Object} Redux action
 */
export function setPhotoPrivacy(id, isPrivate) {
  return {
    type: SET_PHOTO_PRIVACY,
    id,
    isPrivate
  };
}

/**
 * Setting the sorting order.
 * @param {Boolean} isDescending a boolean to define whether the timeline sorting order is ascending or not.
 * @return {Object} Redux action
 */
export function setSortingOrder(isDescending) {
  return {
    type: SET_SORTING_ORDER,
    isDescending
  };
}

/**
 * Setting the sorting type.
 * @param {String} sortingType the sorting type
 * @return {Object} Redux action
 */
export function setSortingType(sortingType) {
  return {
    type: SET_SORTING_TYPE,
    sortingType
  };
}

/**
 * Setting the time grouping granularity.
 * @param {'day'|'week'|'month'} groupingBy Defines how timeline should group the photos
 * @return {Object} Redux action
 */
export function setTimelineGrouping(groupingBy) {
  return {
    type: SET_TIME_GROUPBY,
    groupingBy: groupingBy
  };
}

/**
 * Clearing binary data from the photo runtime object.
 * @param {Number} id the id of the photo to clear
 * @return {Object} Redux action
 */
export function clearPhotoData(id) {
  return {
    type: CLEAR_PHOTO_DATA,
    id
  };
}

/**
 *  Clear the binary data from a set of photos
 * @param {String[]} ids a list of Ids to clear photos from.
 * @return {Object} Redux action
 */
export function clearPhotosData(ids) {
  return {
    type: CLEAR_PHOTOS_DATA,
    ids
  };
}

/**
 * Update filter.
 * @param {Object} filter Defines the current filters used.
 * @return {Object} Redux action
 */
export function updateFilter(filter) {
  return {
    type: UPDATE_FILTER,
    filter
  };
}

/**
 * Update the number of photos currently displayed by the gallery.
 * @param {Number} count Number of currently displayed photos.
 * @return {Object} Redux action
 */
export function updateDisplayedPhotosCount(count) {
  return {
    type: UPDATE_DISPLAYED_PHOTOS_COUNT,
    count
  };
}

/**
 * Lock the download photos btn when a download is in progress.
 * @return {Object} A redux action
 */
export function lockDownload() {
  return ({
    type: LOCK_DOWNLOAD
  });
}

/**
 * Unlock the download photos btn.
 * @return {Object} A redux action
 */
export function unlockDownload() {
  return ({
    type: UNLOCK_DOWNLOAD
  });
}

/**
 * @param {string} name The warning name.
 * @param {boolean} value true iff the modals should be visualized.
 * @param {string} [currentPhotoId] The currentPhotoId used in the modals
 * @return {Object} A redux action
 */
export function triggerModalVisibility(name, value, currentPhotoId) {
  return ({
    type: TRIGGER_MODAL_VISIBILITY,
    name,
    value,
    currentPhotoId
  });
}

/**
 * Open the failed download snackbar warning.
 * @return {Object} A redux action
 */
export function openDownloadFailedWarning() {
  return ({
    type: OPEN_FAILED_DOWNLOAD_WARNING
  });
}

/**
 * Close the failed download snackbar warning.
 * @return {Object} A redux action
 */
export function closeDownloadFailedWarning() {
  return ({
    type: CLOSE_FAILED_DOWNLOAD_WARNING
  });
}

/**
 * Reset the status of failed thumbnail to init.
 * @return {object} A redux action
 */
export function resetFailedThumbnailStatus() {
  return ({
    type: RESET_FAILED_DOWNLOAD_STATUS
  });
}
