/**
 * @fileoverview This file defines a component serve for migration data using DataBinder.
 */

import {AppComponent} from '@adsk/forge-appfw-di';
import {UDP_SDK_BINDING_TYPE} from '~/udp/udpSdk';
import {SpaceMigrationBinding} from '../bindings/migrating/space.1.0.1-2.0.0';
import {AssetMigrationBinding} from '../bindings/migrating/photoAsset.binding.js';
import {
  SPACE_TYPEID_V101,
  PHOTO_ASSET_TYPEID_V101,
  PHOTO_ASSET_TYPEID_V103,
  PHOTO_ASSET_TYPEID_V110
} from '../bindings/helpers/constants';

/**
 * BIM360 photos data migration component.
 * All data migration scripts should happen in the initialization of this component as it makes sure it runs the
 * migration before other app dependencies.
 * @public
 * @extends external:AppComponent
 */
export class DataMigrationComponent extends AppComponent {

  /**
   * @inheritdoc
   */
  initialize(dependencies) {
    const {DataBinderComponent} = dependencies;
    this._dataBinder = DataBinderComponent;

    const workspace = this._dataBinder.getWorkspace();

    this._dataBinder.defineDataBinding(UDP_SDK_BINDING_TYPE, SPACE_TYPEID_V101, SpaceMigrationBinding);
    this._dataBinder.defineDataBinding(UDP_SDK_BINDING_TYPE, PHOTO_ASSET_TYPEID_V101, AssetMigrationBinding);
    this._dataBinder.defineDataBinding(UDP_SDK_BINDING_TYPE, PHOTO_ASSET_TYPEID_V103, AssetMigrationBinding);
    this._dataBinder.defineDataBinding(UDP_SDK_BINDING_TYPE, PHOTO_ASSET_TYPEID_V110, AssetMigrationBinding);

    this._dataBinder.activateDataBinding(UDP_SDK_BINDING_TYPE, SPACE_TYPEID_V101);
    this._dataBinder.activateDataBinding(UDP_SDK_BINDING_TYPE, PHOTO_ASSET_TYPEID_V101);
    this._dataBinder.activateDataBinding(UDP_SDK_BINDING_TYPE, PHOTO_ASSET_TYPEID_V103);
    this._dataBinder.activateDataBinding(UDP_SDK_BINDING_TYPE, PHOTO_ASSET_TYPEID_V110);

    workspace.commit();

    return Promise.resolve();
  }

  /**
   * @inheritdoc
   */
  static defineDependencies() {
    return [
      {
        type: 'DataBinderComponent'
      }
    ];
  }
}
