import React from 'react';
import _ from 'lodash';
import bytes from 'bytes';

import {
  IconButton,
  ModalConfirmation,
  SvgIcon
} from '@adsk/bim360-matrix-react-components';
import 'react-virtualized/styles.css';
import { List } from 'react-virtualized';
import UploaderPanelRow from '../uploaderPanelRow.ui';
import T from '~/common/i18n';
import { MAX_FILE_SIZE } from '~/common/constants';

const uncompletedStatus = ['init', 'new', 'uploading', 'uploadFailed'];

const ROWS_OVERSCAN_COUNT = 5;
const ROWS_HEIGHT = 75;
const LIST_HEIGHT = 270;
const LIST_WIDTH = 343;

const fileSizeLimitText = bytes(MAX_FILE_SIZE, { unitSeparator: ' ' });

/**
 * React component representing the panel in the bottom right corner that show
 * the status of current uploads.
 */
class UploaderPanel extends React.Component {
  /**
   * @inheritdoc
   */
  constructor(props) {
    super(props);

    this.state = {
      collapsed: false,
      showWarningModal: false
    };

    this.confirmClosePanel = this.confirmClosePanel.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.confirmModal = this.confirmModal.bind(this);
    this.renderRow = this.renderRow.bind(this);
  }

  /**
   * Expand the panel
   */
  expandPanel() {
    this.setState({ 'collapsed': false });
  }

  /**
   * Collapse the panel
   */
  collapsePanel() {
    this.setState({ 'collapsed': true });
  }

  /**
   * Remove completed uploads from the panel.
   */
  clearCompleted() {
    let foundUncompleted = false;

    _.each(this.props.uploadingPhotos, photo => {
      if (!uncompletedStatus.includes(photo.status)) {
        this.props.removePhoto(photo.photoID);
      } else {
        foundUncompleted = true;
      }
    });

    if (!foundUncompleted) {
      this.props.closePanel();
    }
  }

  /**
   * If uploads are currently running it will show a confirm modal before closing
   * the panel. If not it will close it right away.
   */
  confirmClosePanel() {
    const noActiveUploads = _.every(this.props.uploadingPhotos, photo => {
      return !uncompletedStatus.includes(photo.status);
    });

    if (noActiveUploads) {
      this.props.closePanel();
    } else {
      this.setState({ showWarningModal: true });
    }
  }

  /**
   * Close the modal only
   */
  closeModal() {
    this.setState({ showWarningModal: false });
  }

  /**
   * Close the modal and the upload panel
   */
  confirmModal() {
    this.setState({ showWarningModal: false });
    this.props.closePanel();
  }

  /**
   * Render the close panel confirmation modal
   * @return {ModalConfirmation} The confirmation modal
   */
  renderCloseWarningModal() {
    return (
      <ModalConfirmation
        id="uploaderWarningModal"
        title={T.translate('uploadTracker.closeModal.title')}
        message={T.translate('uploadTracker.closeModal.message')}
        onConfirm={this.confirmModal}
        onExited={this.closeModal}
        confirmationButtonLabel={T.translate('uploadTracker.closeModal.confirm')}
      />
    );
  }

  /**
   * Render message for large files warning modal
   * @return {modalWarningFileSize} return message
   */
  renderLargeFilesWarningMessage() {
    const listFiles = this.props.uploadsTooLarge.map((file, index) =>
      <li key={`${file.name}_${index}`}>
        <div className="file-info-container">
          <SvgIcon width="20px" height="20px" svgId="uploader-panel-icon-failed" />
          <div className="file-name-text"> {file.name} </div>
          <div className="file-size-text">({bytes(file.size, { unitSeparator: ' ' })})</div>
        </div>
      </li>
    );

    return (
      <div className="modalWarningFileSize">
        <p>{T.translate('uploadTracker.largeFilesModal.message', {
          context: this.props.uploadsTooLarge.length,
          sizeLimit: fileSizeLimitText
        })}</p>
        <ul>{listFiles}</ul>
      </div>
    );
  }

  /**
   * Render the large file ignored warning modal
   * @return {ModalConfirmation} The confirmation modal
   */
  renderLargeFilesWarningModal() {
    return (
      <ModalConfirmation
        id="filesTooLargeWarningModal"
        noCancelButton={true}
        title={T.translate('uploadTracker.largeFilesModal.title', {
          sizeLimit: fileSizeLimitText
        })}
        message={this.renderLargeFilesWarningMessage(this.props.uploadsTooLarge)}
        onConfirm={this.props.clearLargeFilesAlert}
        onExited={this.props.clearLargeFilesAlert}
        confirmationButtonLabel={T.translate('uploadTracker.largeFilesModal.confirm')}
      />
    );
  }

  /**
   * Render a row.
   * @return {UploaderPanelRow} return the row.
   */
  renderRow({ index, isScrolling, key, style }) {
    return (
      <UploaderPanelRow
        {...this.props.uploadingPhotos[index]}
        key={key}
        style={style}
        openPhoto={this.props.openPhoto}
        cancelUpload={this.props.cancelUpload}
        retryUpload={this.props.retryUpload}
      />
    );
  }

  /**
   * Render stuff.
   * @return {UploaderPanel} The uploader panel
   */
  render() {
    const countUploading = this.props.countUploading;
    const countQueued = this.props.countQueued;
    const countFailed = this.props.countFailed;
    const countDone = this.props.countDone;

    if (this.props.viewerIsOpen) return null;

    const showLargeFileWarningModal = !!this.props.uploadsTooLarge.length;

    return (
      <div>
        {this.state.showWarningModal && this.renderCloseWarningModal()}
        {showLargeFileWarningModal && this.renderLargeFilesWarningModal()}
        {this.props.shown &&
          <div className="uploader-panel">
            <div className="uploader-panel-title">
              <div className="uploader-panel-title-label">{T.translate('uploadTracker.title')}</div>
              <div className="uploader-panel-title-btn">
                {this.state.collapsed ? (
                  <IconButton
                    className="uploader-panel-icon-expand"
                    svgId="uploader-panel-icon-expand"
                    onClick={() => this.expandPanel()}
                  />
                ) : (
                  <IconButton
                    className="uploader-panel-icon-collapse"
                    svgId="uploader-panel-icon-collapse"
                    onClick={() => this.collapsePanel()}
                  />
                )}
                <IconButton
                  className="dm-upload-icons-close"
                  svgId="uploader-panel-icon-close"
                  onClick={() => this.confirmClosePanel()}
                />
              </div>
            </div>
            <div className="uploader-panel-summary">
              <div className="uploader-panel-summary-content">
                {countUploading > 0 &&
                  <span id="contentUploadingMessage">
                    {T.translate('uploadTracker.summary.uploading', { context: countUploading })}
                  </span>
                }
                {countQueued > 0 &&
                  <span id="contentQueuedMessage">
                    {T.translate('uploadTracker.summary.queued', { context: countQueued })}
                  </span>
                }
                {countFailed > 0 &&
                  <span id="contentFailedMessage">
                    {T.translate('uploadTracker.summary.failed', { context: countFailed })}
                  </span>
                }
                {countDone > 0 &&
                  <span id="contentDoneMessage">
                    {T.translate('uploadTracker.summary.done', { context: countDone })}
                  </span>
                }
              </div>
              <div>
                <button className="upload-ui-txt-btn" onClick={() => this.clearCompleted()}>
                  {T.translate('uploadTracker.clearCompleted')}
                </button>
              </div>
            </div>
            {!this.state.collapsed && (
              <div className="uploader-panel-content">
                  <List
                    // pass-through prop to force the component to rerender when the uploads
                    // status change, but the array length stay the same.
                    forceRerender={this.props.uploadingPhotos}
                    rowCount={this.props.uploadingPhotos.length}
                    rowHeight={ROWS_HEIGHT}
                    className="UploaderPanelList"
                    overscanRowCount={ROWS_OVERSCAN_COUNT}
                    rowRenderer={this.renderRow}
                    height={LIST_HEIGHT}
                    width={LIST_WIDTH}
                  />
              </div>
            )}
          </div>
        }
      </div>
    );
  }
}

export default UploaderPanel;
