import { connect } from 'react-redux';

import { StateTreeManager } from '~/common/helpers/stateTreeManager';
import { PhotoContentRaster } from './photoContentRaster.ui';

const mapStateToProps = (state, { photoId, ...props }) => {
  const thumbnailContentTreeManager = new StateTreeManager(state.thumbnailsContent);
  const photosContentTreeManager = new StateTreeManager(state.photosContent);

  const thumbnailContent = thumbnailContentTreeManager.getValue(photoId);
  const photoContent = photosContentTreeManager.getValue(photoId);

  return {
    thumbnailContent,
    photoContent,

    composition: state.photosComposition[photoId],
    extension: state.photos[photoId].extension,

    photoId,
    ...props
  };
};

export const ConnectedPhotoContentRaster = connect(
  mapStateToProps
)(PhotoContentRaster);
