import * as schemas from '~/resources/schemas';
import { CommonAsset } from './commonAsset';
import _ from 'lodash';
import { RelationshipDirection } from '~/udp/helpers';
import { ACTIVITY } from '~/common/constants';

const PHOTO_ALBUM_RELATIONSHIP_TYPEID = schemas.photoAlbumRelationship.typeid;

/**
 * Specialized runtime representation for album asset.
 */
export class AlbumAsset extends CommonAsset {

  /**
   * Returns the current album asset name.
   * @return {String} Album asset name.
   */
  getName() {
    return this.property.get('name').value;
  }

  /**
   * Modifies the current album name.
   * @param {string} name The album asset name.
   * @return {Promise} Fulfilled when the albums name is modified and the change is submitted successfully, o/w
   * rejected.
   */
  modifyName(name) {
    const oldName = this.property.get('name').value;
    this.property.get('name').setValue(name);
    return this.commitChanges().then(() => {
      this.space.getActivityLogService().logAlbumActivity(ACTIVITY.VERBS.RENAME_ALBUM, this, { oldName });
    });
  }

  /**
   * @inheritdoc
   */
  delete() {
    return super.delete().then(() => {
      this.space.getActivityLogService().logAlbumActivity(ACTIVITY.VERBS.DELETE_ALBUM, this);
    });
  }

  /**
   * Deletes an album asset and its photos.
   * @return {Promise} Fulfilled when the album and it's photos are deleted and the change is submitted
   * successfully, o/w rejected.
   */
  deleteWithPhotos() {
    const rels = this.space.getAssetRelationships(this.guid, RelationshipDirection.Outgoing);
    try {
      _.each(rels, rel => {
        const asset = rel.to;
        if (asset.isPhotoAsset) {
          this.space.getAsset(asset.guid).delete();
        }
      });
      this.delete();
    } catch (err) {
      return Promise.reject(err);
    }
    return this.commitChanges();
  }

  /**
   * Checks whether the album contains the given photo.
   * @param {String} photoGuid - The photo asset guid.
   * @return {boolean} true iff the album already contains the given photo.
   */
  hasPhoto(photoGuid) {
    const incomingRelationships = this.space.relationships.incoming[photoGuid];
    return !!_.find(incomingRelationships, rel => {
      return  this.hasRelationship(rel) &&
        (this.getRelationship(rel).property.getTypeid() === PHOTO_ALBUM_RELATIONSHIP_TYPEID);
    });
  }

  /**
   * Adds an existing photo asset to an existing album asset by creating a UDP relationship between the two assets.
   * @param {string} photoGuid The photo asset guid.
   * @param {boolean} [commit=true] enable/disable commit after the photo has been added.
   * @return {Promise} Fulfilled if the photo is added to the album and the change is submitted successfully, o/w
   * Rejected.
   */
  addPhoto(photoGuid, commit = true) {
    let photo;
    try {
      photo = this.space.getAsset(photoGuid);
      if (!this.hasPhoto(photoGuid)) {
        // If the album contains the photo we don't create a new relationship.
        this.space.createRelationship(PHOTO_ALBUM_RELATIONSHIP_TYPEID, this, photo);
      }
    } catch (err) {
      return Promise.reject(err);
    }

    this.space.getActivityLogService().logPhotoActivity(ACTIVITY.VERBS.ADD_PHOTO_TO_ALBUM, photo, this);

    if (commit) {
      return this.commitChanges();
    } else {
      return Promise.resolve();
    }
  }

  /**
   * Adds existing photo assets to an existing album asset by creating a UDP relationship between the assets.
   * @param {array} photoGuids Photo asset guids.
   * @return {Promise} Fulfilled if the photos are added to the album and the change is submitted successfully, o/w
   * Rejected.
   */
  async addPhotos(photoGuids) {
    let promises = [];

    this.space.executeAtomicChange(() => {
      photoGuids.forEach(photoGuid => {
        promises.push(this.addPhoto(photoGuid, false));
      });
    });

    return Promise.all(promises).then(() =>
      this._workspace.commit()
    );
  }

}
