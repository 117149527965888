import {
  REQUEST_PHOTO,
  RECEIVE_PHOTO,
  ADD_PHOTO,
  REJECT_PHOTO,
  CLEAR_PHOTO_DATA,
  CANCELLED,
  CANCEL_PHOTO,

  FETCHING,
  FETCHED,
  FETCH_FAILED,
  INIT,
  CLEAR_PHOTOS_DATA

} from '~/common/constants';
import { StateTreeManager } from '~/common/helpers/stateTreeManager';

/**
 * A reducer for the photos download status
 * @param {state} state last state
 * @param {*} action action to reduce
 * @return {state} The next state
 */
function photosStatus(state = {}, action) {
  switch (action.type) {
    case ADD_PHOTO: {
      const statusStateTree = new StateTreeManager(state);
      const newState = statusStateTree.addValue(action.image.id, {status: INIT});
      return newState;
    }
    case REQUEST_PHOTO: {
      const statusStateTree = new StateTreeManager(state);
      const newState = statusStateTree.addValue(action.id, {status: FETCHING} );
      return newState;
    }
    case RECEIVE_PHOTO: {
      const statusStateTree = new StateTreeManager(state);
      const newState = statusStateTree.addValue(action.id, {status: FETCHED});
      return newState;
    }
    case REJECT_PHOTO: {
      const statusStateTree = new StateTreeManager(state);
      const newState = statusStateTree.addValue(action.id, {status: FETCH_FAILED});
      return newState;
    }
    case CANCEL_PHOTO: {
      const statusStateTree = new StateTreeManager(state);
      const newState = statusStateTree.addValue(action.id, {status: CANCELLED});
      return newState;
    }
    case CLEAR_PHOTO_DATA: {
      const statusStateTree = new StateTreeManager(state);
      const newState = statusStateTree.addValue(action.id, {status: INIT});
      return newState;
    }
    case CLEAR_PHOTOS_DATA: {
      const statusStateTree = new StateTreeManager(state);
      const photoIds = action.ids;
      photoIds.forEach(photoId => {
        statusStateTree.addValue(photoId, {status: INIT});
      });
      return statusStateTree.getStateTree();
    }
    default: {
      return state;
    }
  }
}

export default photosStatus;
