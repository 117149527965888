
import {
  REQUEST_CHECKLIST,
  RECEIVE_CHECKLIST,
  REJECT_CHECKLIST
} from '~/common/constants';

/**
 * Handles fetching a checklist
 * @param {String} checklistId Id of the checklist to fetch
 * @return {Function} Redux thunk
 */
export function fetchChecklist(checklistId) {
  return async (dispatch, getState, {ChecklistsComponent}) => {
    const {checklists} = getState();
    if (!checklists[checklistId]) {
      dispatch(requestChecklist(checklistId));
      try {
        const receivedChecklist = await ChecklistsComponent.getChecklist(checklistId);
        if (receivedChecklist) {
          dispatch(receiveChecklist(checklistId, receivedChecklist));
        } else {
          dispatch(rejectChecklist(checklistId));
        }
      } catch (err) {
        console.error(err);
        dispatch(rejectChecklist(checklistId));
      }
    }
  };
}

/**
 * Requests the checklist
 * @param {String} checklistId Id of checklist to request
 * @return {Object} Action to get checklist
 */
export function requestChecklist(checklistId) {
  return {
    type: REQUEST_CHECKLIST,
    id: checklistId
  };
}

/**
 * Receives the checklist request
 * @param {String} checklistId Id of checklist to receive
 * @param {Object} checklist Full JSON of the checklist
 * @return {Object} Action to get checklist
 */
export function receiveChecklist(checklistId, checklist) {
  return {
    type: RECEIVE_CHECKLIST,
    id: checklistId,
    data: checklist
  };
}

/**
 * Rejects the checklist requests
 * @param {String} checklistId Id of checklist to reject
 * @return {Object} Action to get checklist
 */
export function rejectChecklist(checklistId) {
  return {
    type: REJECT_CHECKLIST,
    id: checklistId
  };
}
