import { ALL_AUX, TRASH } from '~/common/constants';
import {AlbumAssetUI} from './albumAssetDataView.ui';
import {SpecialAlbumDataView} from './specialAlbumDataView.ui';
import T from '~/common/i18n';
import React from 'react';
import _ from 'lodash';
import {
  withAppComponent
} from '@adsk/forge-appfw-react';

const getAsset = (activeAlbum, photosComponent) => photosComponent.getSpace().getAsset(activeAlbum);

export const AlbumAssetDataView = withAppComponent({
  instances: {
    photosComponent: {type: 'PhotosComponent'},
    users: {type: 'UsersComponent'}
  }
})(({appComponents, ...restProps}) => {
  const {photosComponent, users} = appComponents;
  const isSpecialAlbum = restProps.activeAlbum === ALL_AUX || restProps.activeAlbum === TRASH;
  const albumAsset = (isSpecialAlbum ? null : getAsset(restProps.activeAlbum, photosComponent));
  const getSelectedPhotos = photosSubset => {
    let photosSelection = _.pick(restProps.photosSelection, photosSubset);
    return _.reduce(photosSelection, (acc, isSelected, photoId) => {
      if (isSelected) {
        acc.push(photoId);
      }
      return acc;
    }, []);
  };

  const renderAlbumDataView = () => {
    const selectedPhotos = getSelectedPhotos(restProps.undeletedPhotos);
    return (
    <AlbumAssetUI key={restProps.activeAlbum}
                  albumAsset={albumAsset}
                  users={users.getUsers()}
                  selectedPhotos={selectedPhotos}
                  {...restProps}/>);
  };
  const renderAllPhotosDataView = () => {
    const selectedPhotos = getSelectedPhotos(restProps.undeletedPhotos);
    return (
    <SpecialAlbumDataView specialAlbumName={T.translate('albumsPanel.allPhotos')}
                          selectedPhotos={selectedPhotos}
                          {...restProps} />);
  };
  const renderTrashDataView = () => {
    const selectedPhotos = getSelectedPhotos(restProps.deletedPhotos);
    return (
    <SpecialAlbumDataView specialAlbumName={T.translate('albumsPanel.deletedPhotos')}
                          selectedPhotos={selectedPhotos}
                          {...restProps}/>);
  };
  switch (restProps.activeAlbum) {
    case ALL_AUX:
      return renderAllPhotosDataView();
    case TRASH:
      return renderTrashDataView();
    default:
      return renderAlbumDataView();
  }
});
