import { MDText } from 'i18n-react';

// load all locale file from the "src/resources/locale/" folder
let languageCodes;
try {
  // At compile time read the locale dir and list the lang files, excluding English.
  languageCodes = require.context('../resources/locale/', true, /\.yaml$/i).keys().map(
    lang => lang.replace('./', '').replace('.yaml', '')
  );
} catch (err) {
  // Jest doesn't use webpack so we cannot use require.context, so we just return default value
  languageCodes = ['en'];
}

// create mapping table betweek locale key and import proxy
const languages = languageCodes.reduce((langsMap, lang) => {
  langsMap[lang] = () => import(`../resources/locale/${lang}.yaml`);
  return langsMap;
}, {});

/**
 * Return import proxy of the given language
 * @param {string} lang The current locale
 * @return {object} translations proxy
 */
const getResources = lang => {
  return languages[lang];
};

let i18nMap = new MDText({});

/**
 * i18n React module
 */
export default class I18NReact {
  /**
   * Set the current locale
   * @param {string} lang Main current user locale
   */
  static setLanguage(lang = 'en') {
    getResources(lang)().then(resources => {
      i18nMap = new MDText(resources);
    });
  }

  /**
   * return current MDText instance
   */
  static get i18nMap() {
    return i18nMap;
  }

  /**
   * Export the translate method
   * @param  {...any} params Parameters
   * @return {function} Translate function
   */
  static translate(...params) {
    return i18nMap.translate(...params);
  }
}
