import {AppComponent} from '@adsk/forge-appfw-di';
import {UdpSdk} from '~/udp/udpSdk';

/**
 * A component that wraps the UDP SDK.
 * The component can access the DataBinder instance within the component tree and initializes the UDP sdk with this
 * instance.
 */
export class UdpSDKComponent extends AppComponent {
  /**
   * @inheritdoc
   */
  static defineDependencies() {
    return [
      {type: 'DataBinderComponent'},
      {type: 'DataMigrationComponent'}
    ];
  }

  /**
   * Returns the UDP sdk.
   * @return {UdpSdk} The instantiated UDP sdk instance.
   */
  getUDPSDK() {
    return this._udpSdk;
  }

  /**
   * Returns the UDP space representation instance, if the workspace attached to databinder is not a UDP space, it
   * will try to create one.
   * @param {String} [name] The UDP space name, used only when creating a new space.
   * @return {Space} A space representation.
   */
  getSpace(name) {
    return this._udpSdk.getSpace();
  }

  /**
   * @inheritdoc
   */
  initialize(dependencies, params) {
    const {DataBinderComponent} = dependencies;
    this._dataBinder = DataBinderComponent;
    this._udpSdk = new UdpSdk(this._dataBinder);

    if (!this._udpSdk.isUdpSpace()) {
      // If it's not a UDP space it tries to create one.
      this._udpSdk.createSpace(params.spaceName);
    }

    return Promise.resolve();
  }
}
