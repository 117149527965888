import {
  ADD_STORAGE,
  REMOVE_STORAGE,
  UPDATE_STORAGE_URN
} from '~/common/constants';

/**
 * @param {Object} state the old state
 * @param {Object} action the action defining the change of state
 * @return {Object} A new state defining the list of users uploaded photos.
 */
export function storage(state = {}, action) {
  switch (action.type) {
    case ADD_STORAGE: {
      const newStorageId = action.storage.id;
      const newStorage = {
        [newStorageId]: Object.assign({}, state[newStorageId], action.album)
      };
      return {...state, ...newStorage};
    }
    case REMOVE_STORAGE: {
      const newState = Object.assign({}, state);
      const storageId = action.id;
      delete newState[storageId];
      return newState;
    }
    case UPDATE_STORAGE_URN: {
      const newState = Object.assign({}, state);
      const storageUrn = action.urn;
      newState[action.id].urn = storageUrn;
      return newState;
    }
    default: {
      return state;
    }
  }
}
