/**
 * Error messages for the Photo library
 * @alias Photos.MSG
 * @private
 */
const MSG = {
  NON_VALID_SCHEMA: 'GEN-01: The provided schema is invalid',
  ALREADY_REGISTERED: 'GEN-02: The schema is already registered',
  REGISTRATION_SUCCESSFUL: 'GEN-03: The schema has been successfully registered',
  NO_ENDPOINT: 'GEN-04: Must provide an endpoint for the thumbnail service',
  UPLOAD_COMPLETED: 'BIN-01: Upload completed, waiting for permission setup.'
};

export { MSG };
