import React from 'react';
import { MatrixHeader } from '@adsk/bim360-matrix-react-components';
import { getProjectIdFromUrl } from '~/common/helpers/urlHelper';
import { featureFlags } from '~/common/helpers/featureFlags';
import FLAGS from '~/common/featureFlags';

import T from '~/common/i18n';

/**
   * React component for the BIM Photo header
   * @public
   */
class Header extends React.Component {
  /**
   * constructor
   * @param {Object} props inherited properties
   */
  constructor(props) {
    super(props);
    const {currentProject} = this.props;

    this.tabs = [
      {
        name: () => T.translate('header.tabs.folders'),
        id: 'Folders',
        url: this.props.docsConfigs.baseUrl + '/projects/' + currentProject.id + '/folders/',
        onClick: () => {
          window.location.href = this.props.docsConfigs.baseUrl + '/projects/' + currentProject.id + '/folders/';
        }
      },
      {
        name: () => T.translate('header.tabs.photos'),
        id: 'Photos'
      },
      {
        name: () => T.translate('header.tabs.reviews'),
        id: 'Reviews',
        url: this.props.docsConfigs.baseUrl + '/projects/' + currentProject.id + '/reviews/',
        onClick: () => {
          window.location.href = this.props.docsConfigs.baseUrl + '/projects/' + currentProject.id + '/reviews/';
        }
      },
      {
        name: () => T.translate('header.tabs.issues'),
        id: 'Issues',
        url: this.props.docsConfigs.baseUrl + '/projects/' + currentProject.id + '/issues/',
        onClick: () => {
          window.location.href = this.props.docsConfigs.baseUrl + '/projects/' + currentProject.id + '/issues/';
        }
      }
    ];

    let transmittal_tab = {
      name: () => T.translate('header.tabs.transmittals'),
      id: 'Transmittals',
      url: this.props.docsConfigs.baseUrl + '/projects/' + currentProject.id + '/transmittals/',
      onClick: () => {
        window.location.href =
          this.props.docsConfigs.baseUrl + '/projects/' + currentProject.id + '/transmittals/';
      }
    };

    if (featureFlags.get(FLAGS.TRANSMITTAL)) {
      this.tabs.splice(3, 0, transmittal_tab);
    }

    this.modules = this.getModules();
    // TODO: Handle acounts properly to not need the fallback
    this.activeModule = this.modules.find(module => module.type === 'doc_manager') || this.modules[0];
    // TODO: These should actually be reactive to changes to the projects/accounts
    //  but already this is a big improvement.
    this.entitlementsLoadingData = {
      projects: {
        isLoading: false,
        isBatchFailed: false
      },
      accounts: {
        isLoading: false,
        isBatchFailed: false
      },
      onDataLoadAgain: () => {}
    };

    this.onAccountClick = this.onAccountClick.bind(this);
    this.onProjectClick = this.onProjectClick.bind(this);
  }

  /**
   * Handles switching projects when a new project is clicked.
   * @param {Object} project the project to switch to.
   */
  onProjectClick(project) {
    const {currentProject} = this.props;
    if (project.id === currentProject.id) {
      // Do nothing if project doesn't change
      return;
    }

    window.location.href = '/projects/' + project.id + '/photos';
  }

  /**
   * Handles switching projects when a new account is selected.
   * @param {Object} account the new selected project.
   */
  onAccountClick(account) {
    if (!account.url) {
      // No project associated with this account. What do?
      console.warn('selected account is missing the project url');
      return;
    }

    const projectId = getProjectIdFromUrl(account.url);
    if (projectId) {
      window.location.href = '/projects/' + projectId + '/photos';
    } else {
      // Not sure if this is possible.
      console.warn('Account does not have a default project in its url');
    }
  }

   /**
   * Get available feature modules for use to display in the matrix header module picker
   * @return {module[]} The list of modules to load in the modules menu.
   */
  getModules() {
    const {services} = this.props.currentProject;
    return services || [];
  }

  /**
   * React rendering of the Header
   * @public
   * @return {PhotoHeader} Header
   */
  render() {
    const {currentProject, projects, userData, accounts, docsConfigs, forgeConfigs} = this.props;

    return (
      <div key="header" id="header">
        <MatrixHeader
          user={ userData }
          locale={ 'en' }
          projects={ projects }
          accounts={ accounts }
          entitlementsLoadingData= { this.entitlementsLoadingData }
          selectedProject={ currentProject }
          onSelectProject={ this.onProjectClick }
          onSelectAccount={ this.onAccountClick }
          signoutURL={ `${docsConfigs.baseUrl}/logout` }
          profileSettingsLink={forgeConfigs.oxygenUrl}
          onSignout={ () => {} }
          onLogoClick={ () => {} }
          tabs={ this.tabs }
          selectedTab={ this.tabs[1] }
          modules={ this.modules }
          selectedModule={ this.activeModule }
        />
      </div>
    );
  }
}

export default Header;
