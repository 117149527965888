import { closeViewerThenCancelFullsizeImageRequests } from '~/redux/actions/photosActions';
import { connect } from 'react-redux';
import TopBar from './topBar.ui';


const mapStateToProps = ({photos, photoViewer, albums, activeAlbum}) => {
  return {
    album: albums[activeAlbum] || activeAlbum,
    caption: photos[photoViewer.activePhotoId].name,
    activePhotoIndex: photoViewer.activePhotoIndex,
    photoCount: photoViewer.displayedPhotos.length
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => {
      dispatch(closeViewerThenCancelFullsizeImageRequests());
    }
  };
};

const PhotoTopBar = connect(
  mapStateToProps,
  mapDispatchToProps
)(TopBar);

export default PhotoTopBar;
