import { connect } from 'react-redux';
import {
  updateFilter
} from '~/redux/actions/photosActions';
import { FilterControl }  from './filterControl.ui';
import { ALL_AUX } from '~/common/constants';

const mapStateToProps = state => {
  return {
    filters: state.filters,
    isAllPhotosAlbum: state.activeAlbum === ALL_AUX,
    showPrivacyFeature: state.features.showPrivacy
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateFilter: filter => {
      dispatch(updateFilter(filter));
    }
  };
};

export const PhotoFilterControl = connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterControl);
