import {
  ADD_PHOTO,
  UPDATE_COMPOSITION
} from '~/common/constants';

/**
 * A reducer for the composition
 * @param {state} state last state
 * @param {*} action action to reduce
 * @return {state} The next state
 */
function photosComposition(state = {}, action) {
  switch (action.type) {
    case ADD_PHOTO: {
      const composition = {
        [action.image.id]: {
          rotation: 0
        }
      };
      return Object.assign({}, state, composition);
    }
    case UPDATE_COMPOSITION: {
      const composition = {
        [action.id]: action.composition
      };
      return Object.assign({}, state, composition);
    }
    default: {
      return state;
    }
  }
}

export default photosComposition;
