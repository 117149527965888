/**
 * @fileoverview This file defines the issues component
 */

import {AppComponent} from '@adsk/forge-appfw-di';
import {requestHTTP} from '~/common/helpers/utils';

/**
 * The main class for the checklists of a project
 * @public
 * @extends external:AppComponent
 */
export class ChecklistsComponent extends AppComponent {
  /**
   * @inheritdoc
   */
  initialize(dependencies) {
    const {
      AuthenticationComponent,
      BIMContainersComponent,
      ForgeConfiguration,
      Projects
    } = dependencies;
    this._forgeConfig = ForgeConfiguration;
    this._authenticationComponent = AuthenticationComponent;
    this._checklistsContainer = BIMContainersComponent.getChecklistsContainer();
    this._currentProjectId = Projects.getActiveProjectEntitlement().currentProjectId;
  }

   /**
   * Queries a specific Checklist and gets its items
   * @param {string} checklistId Id of the checklist the item is attached to
   * @return {Promise<Object>} Object defining the checklist
   */
  getChecklist(checklistId) {
    return Promise.all([this._checklistsContainer, this._authenticationComponent.getToken()])
      .then( ([checklistsContainerId, {token}]) => {
        const checklistUrl = `${this._forgeConfig.environmentBaseUrl}/${this._forgeConfig.checklistsEndpoint}/` +
          `${checklistsContainerId}/instances/${checklistId}?include=sections.items`;
        return requestHTTP(checklistUrl, {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/vnd.api+json'
        });
      })
      .then(resultJSON => {
        const results = JSON.parse(resultJSON);
        const items = results.included.reduce((acc, inclusion) => {
          if (inclusion.type === 'instance_sections') {
            inclusion.relationships.items.data.forEach(item => {
              if (item.type === 'instance_items') {
                if (acc[item.id]) {
                  acc[item.id].sectionNumber = inclusion.attributes.number;
                } else {
                  acc[item.id] = {
                    sectionNumber: inclusion.attributes.number
                  };
                }
              }
            });
          } else if (inclusion.type === 'instance_items') {
            if (acc[inclusion.id]) {
              inclusion.sectionNumber = acc[inclusion.id].sectionNumber;
            }
            acc[inclusion.id] = {
              sectionNumber: inclusion.sectionNumber,
              number: inclusion.attributes.number,
              title: inclusion.attributes.title,
              index: inclusion.attributes.index
            };
          }
          return acc;
        }, {});

        return {
          status: results.data.attributes.status,
          items
        };
      })
      .catch( err => {
        // request failed or returned unparsable JSON (404 pages)
        console.warn(err);
      });
  }

  /**
   * @inheritdoc
   */
  static defineDependencies() {
    return [
      {
        type: 'ForgeConfiguration'
      },
      {
        type: 'AuthenticationComponent'
      },
      {
        type: 'BIMProjectsEntitlementsComponent', as: 'Projects'
      },
      {
        type: 'BIMContainersComponent'
      }
    ];
  }
}
