import {
  RECEIVE_THUMBNAIL,
  ADD_PHOTO
} from '~/common/constants';
import {BinaryDataSource} from '@adsk/forge-hfdm';
import { StateTreeManager } from '~/common/helpers/stateTreeManager';


/**
 * A reducer for the thumbnail binary content
 * @param {state} state last state
 * @param {*} action action to reduce
 * @return {state} The next state
 */
function thumbnailsContent(state = {}, action) {
  switch (action.type) {
    case ADD_PHOTO: {
      const contentStateTreeManager = new StateTreeManager(state);
      const contents = {
        url: null,
        dataSource: new BinaryDataSource.BlobDataSource()
      };
      const newState = contentStateTreeManager.addValue(action.image.id, contents);
      return newState;
    }
    case RECEIVE_THUMBNAIL: {
      const contentStateTreeManager = new StateTreeManager(state);

      const content = contentStateTreeManager.getValue(action.id);
      content.url = action.url;

      const newState = contentStateTreeManager.addValue(action.id, content);
      return newState;
    }
    default: {
      return state;
    }
  }
}

export default thumbnailsContent;
