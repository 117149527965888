import React from 'react';
import SplashScreen from './splashScreen.ui';

/**
   * React component for the BIM Photo page
   * @public
   */
class PhotoLoading extends React.Component {
  /**
   * React rendering of the Page
   * @public
   * @return {PhotoPage} Page
   */
  render() {
    return (
      <SplashScreen />
    );
  }
}

export default PhotoLoading;
