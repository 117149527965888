/**
 * @fileoverview This file emulates the UDP relationship class
 */

import {UDP_SDK_BINDING_TYPE} from '../udpSdk';

/**
 * Emulation of relationship class
 */
export class Relationship {
  /**
   * Constructor
   * @constructor
   * @param {external:BaseProperty} relationshipProperty - A relationship property.
   * @param {DataBinder} dataBinder - A DataBinder instance attached to a UDP space.
   */
  constructor(relationshipProperty, dataBinder) {
    this._dataBinder = dataBinder;

    this.property = relationshipProperty;
    this.guid = relationshipProperty.getId();
    this.from =  this._dataBinder.getRepresentation(this.property.resolvePath('../../'), UDP_SDK_BINDING_TYPE);
    this.to = this._dataBinder.getRepresentation(this.property.get('to'), UDP_SDK_BINDING_TYPE);
  }

  /**
   * helper function for debug
   * @param {Function} printFct callback for the print
   */
  prettyPrint(printFct) {
    this.property.prettyPrint(printFct);
  }
}

