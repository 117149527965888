import React from 'react';
import {AlbumDataView} from '../index';
import T from '~/common/i18n';
import {ACTIVITY} from '~/common/constants';
import {SelectionInfo} from './selectionInfo.ui';
import _ from 'lodash';
import PropTypes from 'prop-types';

/**
 * React component to display and edit the properties of an album
 * @param {HFDMAsset} asset the album asset that is to be edited
 * @return {ReactDOM} The react to render
 */
export class AlbumAssetUI extends React.Component {
  /**
   * @private
   * @return {Array} active photos in active album
   */
  _getActivePhotos() {
    return this.props.albums[this.props.activeAlbum].photos;
  }

  /**
   * gets current username
   * @param {number} value index of users array
   * @return {string} current username
   */
  valueRenderer(value) {
    const {users} = this.props;
    return users[value] && users[value].name;
  }

  /**
   * @private
   * @return {Boolean} true if at least one photo within an album is not selected, false otherwise
   */
  _hasUnselected() {
    const activePhotosCollection = _.pick(this.props.photosSelection, this._getActivePhotos());
    if (_.isEmpty(activePhotosCollection)) {
      return true;
    }
    return _.some(activePhotosCollection, value => value === false);
  }

  /**
   * @inheritdoc
   */
  render() {
    const {albumAsset, selectedPhotos, photosCount} = this.props;

    if (!albumAsset) return null;

    const albumProperty = albumAsset.property;
    const _nameProperty = albumProperty.get('name');
    const _createdBy = albumProperty.get(['trackable', 'createUserId']);
    const _createTimeProperty = albumProperty.get(['trackable', 'createTime']);

    return (
      <div className="albumAssetViewContainer">
        <SelectionInfo
          id="AlbumSelectionInfo"
          activePhotos={this._getActivePhotos.bind(this)}
          hasUnselected={this._hasUnselected()}
          selectedPhotosNb={selectedPhotos.length}
          totalPhotosNb={photosCount}
          {...this.props}
        />
        <AlbumDataView
          className="editableAlbumName"
          verb={ACTIVITY.VERBS.RENAME_ALBUM}
          guid={albumAsset.guid}
          property={_nameProperty}
        />
        <AlbumDataView
          className="createdBy"
          createdBy={T.translate('albumDataView.createdBy')}
          property={_createdBy}
          readOnly
          valueRenderer={this.valueRenderer.bind(this)}
        />
        <AlbumDataView
          className="createdDate"
          createdOn={T.translate('albumDataView.createdOn')}
          property={_createTimeProperty}
          readOnly
        />
      </div>
    );
  }
}

AlbumAssetUI.propTypes = {
  selectedPhotos: PropTypes.array.isRequired,
  photosSelection: PropTypes.object.isRequired,
  photosCount: PropTypes.number.isRequired,
  activeAlbum: PropTypes.string.isRequired,
  users: PropTypes.object.isRequired,
  albumAsset: PropTypes.object,
  albums: PropTypes.object
};

AlbumAssetUI.defaultProps = {
  albumAsset: null
};
