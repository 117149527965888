import { AppComponent } from '@adsk/forge-appfw-di';


/**
 * The component that aggregates BIM docs authentication data.
 * @public
 * @extends external:AppComponent
 */
export class AuthenticationDataComponent extends AppComponent {

  /**
   * @inheritdoc
   */
  initialize(dependencies) {
    const {
      projects, accounts, AuthenticationComponent
    } = dependencies;
    const projectsEntitlements = projects.getProjectEntitlements();
    const currentProjectEntitlement = projects.getActiveProjectEntitlement();
    const accountEntitlements = accounts.getAccountEntitlements();
    const user = AuthenticationComponent.getUserData();

    this._authComponent = AuthenticationComponent;
    this._config = {
      user,
      currentProjectEntitlement,
      projectsEntitlements,
      accountEntitlements
    };
  }

  /**
   * Gets the authentication component.
   * @return {BIMDocsAuthenticationComponent} - The resolved authentication component dependency.
   */
  getAuthenticationComponent() {
    return this._authComponent;
  }

  /**
   * Gets the users authentication data.
   * @return {object} The aggregated users authentication data: user, project entitlements, account entitlements.
   */
  getAuthenticationData() {
    return this._config;
  }

  /**
   * @inheritdoc
   */
  static defineDependencies() {
    return [
      {
        type: 'BIMAccountsEntitlementsComponent',
        as: 'accounts'
      },
      {
        type: 'BIMProjectsEntitlementsComponent',
        as: 'projects'
      },
      {
        type: 'AuthenticationComponent'
      }
    ];
  }
}
