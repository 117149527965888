import {AlbumAssetDataView} from './albumAssetDataView.appfw';
import { connect } from 'react-redux';
import {
  selectAllPhotos,
  deselectAllPhotos,
  selectPhotosSet
} from '~/redux/actions/photosActions';
const mapStateToProps = state => {
  return {
    activeAlbum: state.activeAlbum,
    photosSelection: state.photosSelection,
    photosCount: state.displayedPhotosCount,
    albums: state.albums,
    deletedPhotos: state.deletedPhotos,
    undeletedPhotos: state.undeletedPhotos
  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectAllPhotos: () => {
      dispatch(selectAllPhotos());
    },
    deselectAllPhotos: () => {
      dispatch(deselectAllPhotos());
    },
    selectPhotosSet: (photosIds, isSelect) => {
      dispatch(selectPhotosSet(photosIds, isSelect));
    }

  };
};
export const AlbumDataEditor = connect(
  mapStateToProps,
  mapDispatchToProps
)(AlbumAssetDataView);
