const getLinkElement = href => {
  let supportsPreload = false;
  const link = document.createElement('link');

  // We'd want to use <link rel="preload" .../> since it tells the browser to load that resource with high priority.
  // But not all browsers support it so we're using <link rel="prefetch" .../> as a fallback which is essentially the
  // same thing but with a lower priority.
  try {
    supportsPreload = link.relList.supports('preload');
  } catch (e) {
    // ignoring
  } finally {
    if (supportsPreload) {
      link.as = 'image';
      link.rel = 'preload';
    } else {
      link.rel = 'prefetch';
    }
    link.href = href;
  }

  return link;
};

// Loading the module icons as soon as the browser can to prevent missing icons or glitches when opening the module
// picker for the first time
export default projectEntitlements => {
  projectEntitlements.services.forEach(service => {
    if (service.icon && !document.head.querySelector(`link[href="${service.icon}"]`)) {
      document.head.appendChild(getLinkElement(service.icon));
    }
  });
};
