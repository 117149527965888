/**
 * @fileoverview This file defines the issues component
 */

import {AppComponent} from '@adsk/forge-appfw-di';
import {requestHTTP} from '~/common/helpers/utils';
import { createIssueFromPhotos } from '~/common/helpers/issuesHelper';

/**
 * The main class for the issues of a project
 * @public
 * @extends external:AppComponent
 */
export class IssuesComponent extends AppComponent {
  /**
   * @inheritdoc
   */
  initialize(dependencies) {
    const {
      AuthenticationComponent,
      BIMContainersComponent,
      ForgeConfiguration,
      Projects
    } = dependencies;
    this._forgeConfig = ForgeConfiguration;
    this._authenticationComponent = AuthenticationComponent;
    this._issuesContainer = BIMContainersComponent.getIssuesContainer();
    this._currentProjectId = Projects.getActiveProjectEntitlement().currentProjectId;

    return this._requestIssueTypes()
    .then(issueTypes => {
      this._issueTypes = issueTypes;
    });
  }

  /**
   * Returns a list of issueTypes assigned to the current project.
   * @return {Promise} - Promise with the users list.
   */
  _requestIssueTypes() {
    return new Promise((resolve, reject) => {
      this._issuesContainer.then(issuesContainerId => {
        const issueTypesUrl =
          `${this._forgeConfig.environmentBaseUrl}/${this._forgeConfig.issuesEndpoint}/` +
          `${issuesContainerId}/ng-issue-types?include=subtypes`;
        this._authenticationComponent.bearerTokenFunction((error, accessToken) => {
          requestHTTP(issueTypesUrl, {
            'Authorization': 'Bearer ' + accessToken,
            'Content-Type': 'application/vnd.api+json'
          })
          .then(resultJSON => {
            const results = JSON.parse(resultJSON).results;
            return resolve(results);
          })
          .catch(err => {
            console.error(err);
            return reject(err);
          });
        });
      });
    });
  }

  /**
   * Queries a specific Quality Issue
   * @param {string} issueId Id of the issue to query
   * @return {Promise<Object>} Object defining the issue
   */
  getQualityIssue(issueId) {
    return Promise.all([this._issuesContainer, this._authenticationComponent.getToken()])
      .then( ([issuesContainerId, {token}]) => {
        const issueUrl =
          `${this._forgeConfig.environmentBaseUrl}/${this._forgeConfig.issuesEndpoint}/` +
          `${issuesContainerId}/quality-issues/${issueId}`;
        return requestHTTP(issueUrl, {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/vnd.api+json'
        });
      })
      .then(resultJSON => {
        const results = JSON.parse(resultJSON).data;
        return results;
      })
      .catch( err => {
        // request failed or returned unparsable JSON (404 pages)
        console.warn(err);
      });
  }

  /**
   * Queries a specific RFI
   * @param {string} rfiId Id of the RFI to query
   * @return {Promise<Object>} Object defining the RFI
   */
  getRFI(rfiId) {
    return Promise.all([this._issuesContainer, this._authenticationComponent.getToken()])
      .then( ([issuesContainerId, {token}]) => {
        const rfiUrl =
          `${this._forgeConfig.environmentBaseUrl}/${this._forgeConfig.rfisEndpoint}/` +
          `${issuesContainerId}/rfis/${rfiId}`;
        return requestHTTP(rfiUrl, {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/vnd.api+json'
        });
      })
      .then(resultJSON => {
        const results = JSON.parse(resultJSON).data;
        return results;
      })
      .catch( err => {
        // request failed or returned unparsable JSON (404 pages)
        console.warn(err);
      });
  }

  /**
   * Posts a new issue to the issues API
   * @param {Object} issueData the payload of the post request
   * @param {Collection} photosFetchPromises a collection of photos to attach to the issue
   * @return {Promise} - Promise with the response
   * @public
   */
  postIssue(issueData, photosFetchPromises) {
    const currentProjectId = this._currentProjectId;
    return createIssueFromPhotos({
      issueData,
      files: photosFetchPromises,
      bearerFunction: this._authenticationComponent.getToken,
      issuesContainer: this._issuesContainer,
      currentProjectId,
      forgeConfig: this._forgeConfig
    });
  }

  /**
   * Provides the list of issuetypes.
   * @return {Array<IssueType>} An arrary of issueTypes.
   * @public
   */
  getIssueTypes() {
    return this._issueTypes;
  }

  /**
   * Returns an object mapping issueType ids to issueType objects
   * @return {Object} - the collection of issueTypes
   * @public
   */
  getIssueTypesMap() {
    if (!this._issueTypesById) {
      this._issueTypesById = this._issueTypes.reduce((issueTypesObj, issueType) => {
        issueTypesObj[issueType.id] = issueType;
        return issueTypesObj;
      }, {});
    }
    return this._issueTypesById;
  }

  /**
   * @inheritdoc
   */
  static defineDependencies() {
    return [
      {
        type: 'ForgeConfiguration'
      },
      {
        type: 'AuthenticationComponent'
      },
      {
        type: 'BIMProjectsEntitlementsComponent', as: 'Projects'
      },
      {
        type: 'BIMContainersComponent'
      }
    ];
  }
}
