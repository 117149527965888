import React from 'react';
import {SimpleModal} from '@adsk/bim360-matrix-react-components';
import {CustomLargeButton} from '~/ui/widgets/customLargeButton.ui';
import T from '~/common/i18n';


/**
 * Modal delete album
 * @param {object} props for the component
 * @return {React.Node} DeleteAlbumModal component
 */
export default function DeleteAlbumModal(props) {

  const title = T.translate('albumDeleteOption.modalTitle');
  const headerAlbumOnly = T.translate('albumDeleteOption.albumOnly.header');
  const headerAlbumWithPhotos = T.translate('albumDeleteOption.albumWithPhotos.header');
  const bodyAlbumOnly = T.translate('albumDeleteOption.albumOnly.body');
  const bodyAlbumWithPhotos = T.translate('albumDeleteOption.albumWithPhotos.body');

  return (
    <SimpleModal
      title={title}
      show={props.show}
      className="deleteModal"
      modalBodyProps={{
        className: 'CustomModal'
      }}
      onExited={props.onExited}
    >
      <CustomLargeButton
        id="isAlbumOnlyDelete"
        header={headerAlbumOnly}
        body={bodyAlbumOnly}
        onClick={props.handleDeleteAlbumOnly}
      />
      <CustomLargeButton
        id="isNotAlbumOnlyDelete"
        header={headerAlbumWithPhotos}
        body={bodyAlbumWithPhotos}
        onClick={props.handleDeleteAlbumWithPhoto}
      />
    </SimpleModal>
  );
}
