import {withAppComponent} from '@adsk/forge-appfw-react';
import {AlbumsConfirmationModal} from './albumsModal.ui';

export const AlbumsModal = withAppComponent({
  instances: {
    PhotosComponent: {type: 'PhotosComponent'}
  },
  mapAppComponents: ({PhotosComponent}) => {
    const albumHasPhoto = (albumId, photoId) => {
      return PhotosComponent.albumHasPhoto(albumId, photoId);
    };

    return {albumHasPhoto};
  }
})(AlbumsConfirmationModal);
