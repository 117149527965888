import React from 'react';
import T from '~/common/i18n';
import {
  Button,
  DateSelector
} from '@adsk/bim360-matrix-react-components';
import {ALL_AUX, DATE_FORMAT, INIT_FILTERS, TRASH} from '~/common/constants';
import {UsersDropdown} from './usersDropdown.appfw';
import {LocationsDropdown} from './locationsDropdown.appfw';

import {AlbumsFilter} from './albumsFilter.ui';
import {PrivacyDropdown} from './privacyDropdown.ui';

/**
 * React component for the BIM Photo Filter control
 * @public
 */
class FilterControl extends React.Component {

  /**
   * Constructor
   * @param {Object} props the props passed down
   */
  constructor(props) {
    super(props);
    this._handleFromDateChange = this._handleFromDateChange.bind(this);
    this._handleToDateChange = this._handleToDateChange.bind(this);
    this._handleLocationChange = this._handleLocationChange.bind(this);
    this._handleUserChange = this._handleUserChange.bind(this);
    this._handlePrivacyChange = this._handlePrivacyChange.bind(this);
    this._applyFilters = this._applyFilters.bind(this);
    this._clearFilters = this._clearFilters.bind(this);
    this._onGroupAlbumChange = this._onGroupAlbumChange.bind(this);

    this.state = {
      // This only works because the parent forces this component to remount when opened
      filters: this.props.filters
    };
  }

  /**
   * @inheritdoc
   */
  componentDidMount() {
    // We need this since we have a Tooltip inside another Tooltip (the dateSelectors).
    // They are mounted on the same tick and the way react-overlays work cause a race condition
    // in this case. When the child component can't find it's parent it append itself to the body
    // directly. Since it's not a direct child of the parent tooltip in the DOM, it's not considered
    // as part of the filter tooltip when checking if the user clicked outside of it.
    // This prevent the user from selecting a date as it just close both tooltip instead.
    // A dirty fix for this is to force an update once on the component containing the child Tooltip.
    // https://github.com/react-bootstrap/react-overlays/issues/94

    this.forceUpdate();
  }

  /**
   * Updates the local filters state.
   * @private
   */
  _clearFilters() {
    this.setState({
      filters: INIT_FILTERS
    });
    this.props.updateFilter(INIT_FILTERS);
    this.props.toggleFilter();
  }

  /**
   * handles user change in selectDropDown
   * @param {object} item selected user item in the user dropdown
   * @private
   */
  _handleUserChange(item) {
    this.setState(prevState => {
      const filters = {
        ...prevState.filters,
        user: item && item.value || ALL_AUX
      };
      return {
        filters
      };
    });
  }

  /**
   * handles location change in selectDropDown
   * @param {object} item selected location item in the location dropdown
   * @private
   */
  _handleLocationChange(item) {
    this.setState(prevState => {
      const filters = {
        ...prevState.filters,
        location: item && item.id || ALL_AUX
      };
      return {
        filters
      };
    });
  }

  /**
   * handles privacy in privacyDropdown
   * @param {object} privacy value of privacy
   * @private
   */
  _handlePrivacyChange(privacy) {
    this.setState(prevState => {
      const filters = {
        ...prevState.filters,
        privacy: privacy && privacy.value || ALL_AUX
      };
      return {
        filters
      };
    });
  }

  /**
   * handles from date change from date picker
   * @param {object} date from date value
   * @private
   */
  _handleFromDateChange(date) {
    this.setState(prevState => {
      const filters =  {
        ...prevState.filters,
        startDate: date.date
      };
      return {
        filters
      };
    });
  }

  /**
   * handles to date change from date picker
   * @param {object} date to date value
   * @private
   */
  _handleToDateChange(date) {
    this.setState(prevState => {
      const filters =  {
        ...prevState.filters,
        endDate: date.date
      };
      return {
        filters
      };
    });
  }

  /**
   *  Handles the grouping by albums filter change.
   * @param {String} item - The grouping by option.
   * @private
   */
  _onGroupAlbumChange(item) {
    this.setState(prevState => {
      const filters =  {
        ...prevState.filters,
        groupByAlbum: item && item.value || ALL_AUX
      };
      return {
        filters
      };
    });
  }

  /**
   * Handles updating the filters in the application
   * @private
   */
  _applyFilters() {
    this.props.updateFilter(this.state.filters);
    this.props.toggleFilter();
  }

  /**
   * verify if the active album is trash
   * @return {Boolean}  return true if the active album is the trash, false else
   */
  isTrash() {
    return this.props.activeAlbum === TRASH;
  }

  /**
   * React rendering of the filter control
   * @public
   * @return {FilterControl} FilterControl
   */
  render() {
    return (
      <div id="filterControl">
        <h2 className="title">{T.translate('filterControl.title')}</h2>
        {this.isTrash() ? null :
          <>
            <label>{T.translate('filterControl.filters.groupingBy.title')}</label>
            <AlbumsFilter
              groupByAlbum={this.state.filters.groupByAlbum}
              onChange={this._onGroupAlbumChange}
              isAllPhotosAlbum={this.props.isAllPhotosAlbum}
            />
          </>
        }
        <label htmlFor="filter-range-date-start">{T.translate('filterControl.filters.date.from')}</label>
        <DateSelector id="filter-range-date-start"
          defaultOpen={false}
          date={this.state.filters.startDate}
          inputProps={{format: DATE_FORMAT}}
          popupProps={{canClear: true, disableFutureDates: true}}
          popupContainer={() => document.getElementById('filterControl')}
          onChange={this._handleFromDateChange}/>
        <label htmlFor="filter-range-date-end">{T.translate('filterControl.filters.date.to')}</label>
        <DateSelector id="filter-range-date-end"
          defaultOpen={false}
          date={this.state.filters.endDate}
          inputProps={{ format: DATE_FORMAT }}
          popupProps={{ canClear: true}}
          popupContainer={() => document.getElementById('filterControl')}
          onChange={this._handleToDateChange}/>
        <hr/>
        <UsersDropdown
          userFilter={this.state.filters.user}
          onUserChange={this._handleUserChange}
        />

        {this.props.showPrivacyFeature ?
          <>
            <label htmlFor="filter-privacy">{T.translate('filterControl.filters.privacy')}</label>
            <PrivacyDropdown
              placeholder={T.translate('filterControl.filters.allText')}
              selectedPrivacy={this.state.filters.privacy}
              onChange={this._handlePrivacyChange}
            />
            <hr/>
          </> : null
        }

        <label htmlFor="filter-location">{T.translate('filterControl.filters.location')}</label>
        <LocationsDropdown
          placeholder={T.translate('filterControl.filters.allText')}
          selectedLocationId={this.state.filters.location}
          onLocationChange={this._handleLocationChange}
        />
        <hr/>
        <div className="filtersButtons">
          <Button id="button-reset"
            key="button-reset"
            onClick={this._clearFilters}>
            {T.translate('filterControl.filters.reset')}
          </Button>
          <Button styleType={Button.StyleType.PRIMARY}
            id="button-apply"
            key="button-apply"
            onClick={this._applyFilters}>
            {T.translate('filterControl.filters.apply')}
          </Button>
        </div>
      </div>
    );
  }
}

export { FilterControl };
