import { connect } from 'react-redux';
import {
  closeDownloadFailedWarning,
  resetFailedThumbnailStatus
} from '~/redux/actions/photosActions';
import { FailedDownloadWarning } from './failedDownloadWarning.ui';

const mapStateToProps = state => {
  return {
    show: state.showDownloadFailedWarning
  };
};

const mapDispatchToProps = dispatch => {
  return {
    close: () => {
      dispatch(closeDownloadFailedWarning());
    },
    retry: () => {
      dispatch(closeDownloadFailedWarning());
      dispatch(resetFailedThumbnailStatus());
    }
  };
};

export const FailedDownloadWarningConnector = connect(
  mapStateToProps,
  mapDispatchToProps
)(FailedDownloadWarning);
