import React from 'react';
import {
  SelectDropdown
} from '@adsk/bim360-matrix-react-components';
import T from '~/common/i18n';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {FILTERS} from '~/common/constants';

const { GROUP_BY } = FILTERS;

/**
 * React component that represent the photos filters by albums criteria.
 * @public
 */
export class AlbumsFilter extends React.Component {

  /**
   * @inheritdoc
   */
  constructor(props) {
    super(props);
    const groupByAlbumsFilters = this.props.isAllPhotosAlbum ?
        [GROUP_BY.NO_ALBUM, GROUP_BY.SINGLE_ALBUM, GROUP_BY.MULTIPLE_ALBUMS] :
        [GROUP_BY.ALBUM_ONLY, GROUP_BY.MULTIPLE_ALBUMS];

    this._options = groupByAlbumsFilters.map(filter => {
      return {
        label: T.translate(`filterControl.filters.groupingBy.${filter}`),
        value: filter
      };
    });

  }

  /**
   * React rendering of group by albums dropdown in the filterControl
   * @public
   * @return {React.Node} A dropdown menu component with multiple options for photos filtering by albums criteria.
   */
  render() {
    const {groupByAlbum, onChange} = this.props;

    const selectedOptions = _.find(this._options, option => {
      return option.value === groupByAlbum;
    });

    return (
      <SelectDropdown id="filter-albums"
                      options={this._options}
                      clearable
                      selectedOptions={selectedOptions}
                      placeholder={T.translate('filterControl.filters.allText')}
                      onChange={onChange}
      />
    );
  }
}

AlbumsFilter.propTypes = {
  isAllPhotosAlbum: PropTypes.bool,
  groupByAlbum: PropTypes.string,
  onChange: PropTypes.func
};
