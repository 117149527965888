import {AppComponent} from '@adsk/forge-appfw-di';
import {getProjectIdFromUrl} from '~/common/helpers/urlHelper';
import {EntitlementProjects} from '@adsk/bim360-mc-interface';
import {getEntitlementsParams, LOCAL_STORAGE} from '~/common/helpers/authenticationConsts';
import T from '~/common/i18n';
import {getResourceData} from '~/common/helpers/resourceCall';

// project entitlements resource name in BIM docs.
const RESOURCE_NAME = 'project_entitlements';

/**
 * BIM docs projects entitlements component.
 * This component can fetch project entitlements for the current user.
 * @public
 * @extends external:AppComponent
 */
class BIMProjectsEntitlementsComponent extends AppComponent {
  /**
   * @inheritdoc
   */
  static defineDependencies() {
    return [
      {type: 'AuthenticationComponent'}
    ];
  }

  /**
   * The function returns the current active project entitlement.
   * @return {Promise<Object>} A promise with the current project entitlement.
   */
  getCurrentProjectEntitlement() {
    return getResourceData({
      token: this._token,
      hq: this._config.hq,
      parameters: {
        ...getEntitlementsParams(true),
        current_project_id: this._getDefaultProjectId()
      },
      modelParser: EntitlementProjects,
      resource: RESOURCE_NAME
    });
  }

  /**
   * The function returns the project entitlements available for the current user.
   * @return {Promise<Object>} A promise with the project entitlements.
   */
  fetchProjectEntitlements() {
    return getResourceData({
      token: this._token,
      hq: this._config.hq,
      parameters: {...getEntitlementsParams(false)},
      modelParser: EntitlementProjects,
      resource: RESOURCE_NAME
    });
  }

  /**
   * Returns the default project id.
   * @return {String|Undefined} A project if it exists otherwise undefined.
   * @private
   */
  _getDefaultProjectId() {
    const projectIdFromUrl = getProjectIdFromUrl(window.location.pathname);
    const userId = this._user.id;
    // The possible outcomes for projectIdFromURL are "undefined", "<project-id>" or "/" in case the pathname doesn't
    // start with "/projects". That's why we test for projectIdFromURL.length > 1 and not projectIdFromURL.length > 0.
    if (projectIdFromUrl && projectIdFromUrl.length > 1) {
      return projectIdFromUrl;
    } else if (localStorage) {
      try {
        const storageData = JSON.parse(localStorage.getItem(LOCAL_STORAGE.LAST_USED_PROJECT_DATA));
        return storageData ? storageData[userId] : undefined;
      } catch (e) {
        localStorage.removeItem(LOCAL_STORAGE.LAST_USED_PROJECT_DATA);
      }
    }

    return undefined;
  }

  /**
   * Returns the fetched active project entitlement.
   * @return {Object} project entitlement.
   */
  getActiveProjectEntitlement() {
    return this._currentProjectEntitlement;
  }

  /**
   * Returns the fetched project entitlements.
   * @return {Object} project entitlements.
   */
  getProjectEntitlements() {
    return this._projectEntitlements;
  }

  /**
   * @inheritdoc
   */
  initialize(dependencies) {
    const {AuthenticationComponent} = dependencies;
    this._config = AuthenticationComponent.getConfiguration();
    this._user = AuthenticationComponent.getUserData();
    return AuthenticationComponent.getToken()
      .then(({token}) => {
        this._token = token;
        return Promise.all([
          this.getCurrentProjectEntitlement(),
          this.fetchProjectEntitlements()
        ]);
      })
      .then(([currentProjectEntitlement, projectEntitlements]) => {
        T.setLanguage(currentProjectEntitlement.locale);
        this._currentProjectEntitlement = currentProjectEntitlement;
        this._projectEntitlements = projectEntitlements;
        return Promise.resolve();
      });
  }
}

export default BIMProjectsEntitlementsComponent;
