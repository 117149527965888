import {DataBinding} from '@adsk/forge-appfw-databinder';
import {PropertyUtils} from '@adsk/forge-hfdm';
import {UDP_SDK_BINDING_TYPE} from '~/udp/udpSdk';

import {
  addPhotoToAlbum,
  removePhotoFromAlbum
} from '~/redux/actions/albumsActions';

const PathHelper = PropertyUtils.PathHelper;
/**
 * A binding for photo album relationship which allows us reacting on modifications in UDP and updates the redux store
 * accordingly.
 * TODO: Ideally this Binding should be a SingletonBinding. Thus, please keep it stateless as it is, this will make
 * using SingletonBindings much easier.
 * @extends external:DataBinding
 */
export class PhotoAlbumDataBinding extends DataBinding {
  /**
   * @inheritdoc
   */
  onPreRemove(in_removalContext) {
    const {store} = this.getUserData();
    const space = this.getDataBinder().getRepresentationAtPath('root', UDP_SDK_BINDING_TYPE);
    const path = PathHelper.tokenizePathString(in_removalContext.getAbsolutePath());
    const relationshipGuid = path[path.length - 1];
    const albumId = space.relationships.allFrom[relationshipGuid];
    const photoId = space.relationships.allTo[relationshipGuid];
    store.dispatch(removePhotoFromAlbum(photoId, albumId));
  }

  /**
   *  Called when the property is created
   *  @param {Object} in_modificationContext - The modification context of the bound photo asset.
   */
  onPostCreate(in_modificationContext) {
    const relationshipGuid = in_modificationContext.getProperty().getId();
    const {store} = this.getUserData();
    const space = this.getDataBinder().getRepresentationAtPath('root', UDP_SDK_BINDING_TYPE);
    const photoId = space.relationships.allFrom[relationshipGuid];
    const albumId = space.relationships.allTo[relationshipGuid];
    store.dispatch(
      addPhotoToAlbum(photoId, albumId, relationshipGuid));
  }
}
