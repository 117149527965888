import React from 'react';
import {
  Field,
  Toggle
} from '@adsk/bim360-matrix-react-components';
import { ACTIVITY } from '~/common/constants';

/**
 * Private Toggle component
 * @param {name} props passed down props
 * @return {ReactDOM} The react to render
 */
export class ButtonPrivateToggleView extends React.Component {

  /**
   * Constructor
   * @param {Object} props the props passed down
   */
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  /**
   * On Toggle Change
   * @param {Boolean} value Value of the new toggle state
   */
  onChange(value) {
    this.props.property.setValue(value);
    this.props.property.getWorkspace().commit().then(() => {
      const verb = value ? ACTIVITY.VERBS.SET_PHOTO_PRIVATE : ACTIVITY.VERBS.SET_PHOTO_PUBLIC;
      this.props.logActivity(verb, this.props.property.getParent().getParent().getGuid(), value);
    });
  }

  /**
   * @inheritdoc
   */
  render() {
    return (
      <Field>
        <div className="togglePrivate">
          <Toggle checked={this.props.value} onChange={this.onChange} /> <b>Private</b>
        </div>
      </Field>
    );
  }
}
