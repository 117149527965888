import {AppComponent} from '@adsk/forge-appfw-di';

/**
 * A component for registering photos workspace configuration
 */
export class PhotosWorkspaceConfiguration extends AppComponent {

  /**
   * @inheritdoc
   */
  static defineDependencies() {
    return [
      {type: 'BIMContainersComponent', as: 'photosContainer'}
    ];
  }

  /**
   * @inheritdoc
   */
  initialize(dependencies, params) {
    const {photosContainer} = dependencies;
    this.urn = photosContainer.getBranchUrn();
    return Promise.resolve();
  }
}
