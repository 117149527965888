import {
  CHANGE_PHOTO_VIEWED,
  OPEN_VIEWER,
  CLOSE_VIEWER,
  SET_PHOTO_INDEX
} from '~/common/constants';



/**
 * A reducer for the state of the photoViewer
 * @param {state} state last state
 * @param {*} action action to reduce
 * @return {state} The next state
 */
function photoViewer(state = { displayedPhotos: []}, action) {
  switch (action.type) {
    case OPEN_VIEWER: {
      const displayedPhotos = action.displayedPhotos;
      const photoIndex = action.photoIndex || 0;
      const changedState = {
        showViewer: true,
        displayedPhotos,
        activePhotoIndex: photoIndex,
        activePhotoId: displayedPhotos[photoIndex],
        photoIndex
      };
      return Object.assign({}, state, changedState);
    }
    case CLOSE_VIEWER: {
      const changedState = {
        showViewer: false,
        activePhotoId: null,
        displayedPhotos: []
      };
      return Object.assign({}, state, changedState);
    }
    case CHANGE_PHOTO_VIEWED: {
      const changedState = {
        activePhotoIndex: action.index,
        activePhotoId: action.id
      };
      return Object.assign({}, state, changedState);
    }
    case SET_PHOTO_INDEX: {
      const changedState = {
        photoIndex: action.index
      };
      return Object.assign({}, state, changedState);
    }
    default: {
      return state;
    }
  }
}

export default photoViewer;
