import PhotosErrorBoundary from './photosErrorBoundary.ui';
import {
  withAppComponent
} from '@adsk/forge-appfw-react';

export default withAppComponent({
  sync: false,
  instances: {
    hfdmConnection: {type: 'HFDMConnectionComponent'}
  },
  mapAppComponents: ({hfdmConnection}) => {
    return {hfdmConnection};
  }
})(PhotosErrorBoundary);
