import React from 'react';
import { withAppComponent } from '@adsk/forge-appfw-react';
import { LocationPicker } from '@adsk/bim360-matrix-react-components';

/**
 * Functional React component for the locations dropdown
 * @public
 */
export const LocationsDropdown = withAppComponent({
  instances: {
    locations: {type: 'LocationsComponent'}
  }
})(({appComponents, ...restProps}) => {
  const {locations} = appComponents;
  const {selectedLocationId, onLocationChange, ...props} = restProps;
  return (
    <LocationPicker
      locationApiPayload={locations.getLocationsArray()}
      selectedEventKey={selectedLocationId}
      onChange={onLocationChange}
      {...props}
    />
  );
});
