import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import { memoize } from '~/common/helpers/utils';
import {Gallery as UIGallery} from './gallery.ui';

import {
  selectPhoto,
  deselectAllPhotos,
  togglePhotosSet,
  updateDisplayedPhotosCount,
  deletePhotos,
  triggerModalVisibility,
  removePhotoFromActiveAlbum
} from '~/redux/actions/photosActions';
import {ALL_AUX, INIT_FILTERS, TRASH, FILTERS, SORTING_PHOTOS_OPTIONS} from '~/common/constants';
import { openViewer } from '~/redux/actions/photoViewerActions';
import { StateTreeManager } from '~/common/helpers/stateTreeManager';

const { GROUP_BY } = FILTERS;

const _sortPhotos = memoize(function(photos, isDescending, sortingType) {
  const order = isDescending ? 1 : -1;
  const sortingTimeProperty = sortingType === SORTING_PHOTOS_OPTIONS.CAPTURE_TIME ? 'createTime' : 'lastModifiedTime';
  const photosIds = Object.keys(photos).sort((a, b) => {
    const timeA = photos[a][sortingTimeProperty];
    const timeB = photos[b][sortingTimeProperty];
    return order * (timeB - timeA);
  });
  return photosIds;
});

const _getTimeline = memoize(function(photos, granularity = 'day', isDescending = true, sortingType) {
  const timeline = {};
  const photosIds = _sortPhotos(photos, isDescending, sortingType);
  const sortingTimeProperty = sortingType === SORTING_PHOTOS_OPTIONS.CAPTURE_TIME ? 'createTime' : 'lastModifiedTime';

  photosIds.forEach(photoID => {
    const photoTime = moment(photos[photoID][sortingTimeProperty]);
    const unit = UIGallery.TIMELINE_GRANULARITY_TO_MOMENT_UNIT[granularity];
    let timelineCode = photoTime.startOf(unit).toDate().getTime();
    if (!timeline[timelineCode]) {
      timeline[timelineCode] = [];
    }
    timeline[timelineCode].push(photoID);
  });
  return timeline;
});

const filterByDate = memoize((photos, startDateFilter, endDateFilter) => {
  let filteredByDate;
  if (!startDateFilter && !endDateFilter) {
    filteredByDate = photos;
  } else if (!endDateFilter) {
    filteredByDate = _.pickBy(photos, photo => {
      return moment(photo.createTime).isSameOrAfter(moment(startDateFilter).startOf('day'), 'ms');
    });
  } else if (!startDateFilter) {
    filteredByDate = _.pickBy(photos, photo => {
      return moment(photo.createTime).isSameOrBefore(moment(endDateFilter).endOf('day'), 'ms');
    });
  } else {
    filteredByDate = _.pickBy(photos, photo => {
      return moment(photo.createTime).isBetween(
        moment(startDateFilter).startOf('day'), moment(endDateFilter).endOf('day'), 'ms', '[]');
    });
  }
  return filteredByDate;
});

const filterByUser = memoize((photos, userFilter, photosByUsers) => {
  let filteredByUser;
  if (userFilter === ALL_AUX) {
    filteredByUser = photos;
  } else {
    filteredByUser = _.pick(photos, photosByUsers[userFilter]);
  }
  return filteredByUser;
});

const filterByLocation = memoize((photos, locationFilter, photosByLocations) => {
  let filteredByLocation;
  if (locationFilter === ALL_AUX) {
    filteredByLocation = photos;
  } else {
    filteredByLocation = _.pick(photos, photosByLocations[locationFilter]);
  }
  return filteredByLocation;

});

const filterByPrivacy = memoize((photos, privacyFilter, photosPrivate) => {
  let filteredByPrivacy = {};
  if (privacyFilter === ALL_AUX) {
    filteredByPrivacy = photos;
  } else {
    const stateManager = new StateTreeManager(photosPrivate);
    const privacyStatus = privacyFilter === 'private';
    stateManager.traverseTree((guid, value) => {
      if (value === privacyStatus && photos[guid]) {
        filteredByPrivacy[guid] = photos[guid];
      }
    });
  }
  return filteredByPrivacy;
});

const photosByAlbumPicker = (photos, photosComponent, predicateFunc) => {
  return _.pickBy(photos, function(photo, photoId) {
    const space = photosComponent.getSpace();
    const photoIncomingRelationships = space.relationships.incoming[photoId] || [];
    const getPhotoAlbumsIds =  () => _.map(photoIncomingRelationships, relId => {
      return space.relationships.allFrom[relId];
    });
    return predicateFunc(photoIncomingRelationships, getPhotoAlbumsIds);
  });
};


const getPhotosInMultipleAlbums = function(photos, photosComponent, albums) {
  return photosByAlbumPicker(photos, photosComponent, (inRelationships, getPhotoAlbumsIds) => {
    const photoAlbumsIds = getPhotoAlbumsIds();
    let numberOfAlbums = 0;
    return _.some(photoAlbumsIds, assetId => {
      if (albums[assetId]) {
        numberOfAlbums++;
      }
      return numberOfAlbums > 1;
    });
  });
};

const getPhotosInNoAlbum = function(photos, photosComponent, albums) {
  return photosByAlbumPicker(photos, photosComponent,
    (inRelationships, getPhotoAlbumsIds) => {
      if (!inRelationships.length) {
        return true;
      } else {
        const photoAlbumsIds = getPhotoAlbumsIds();
        return _.every(photoAlbumsIds, assetId => {
          return !albums[assetId];
        });
      }
    });
};

const getPhotosInSingleAlbum = function(photos, photosComponent, albums) {
  return photosByAlbumPicker(photos, photosComponent,
    (inRelationships, getPhotoAlbumsIds) => {
      if (inRelationships.length !== 1) {
        return false;
      } else {
        const photoAlbumsIds = getPhotoAlbumsIds();
        return _.every(photoAlbumsIds, assetId => {
          return albums[assetId];
        });
      }
    });
};

const getPhotosInCurrentAlbumOnly = function(photos, photosComponent) {
  return photosByAlbumPicker(photos, photosComponent,
    (inRelationships, getPhotoAlbumsIds) => {
      const photoAlbumsIds = getPhotoAlbumsIds();
      return photoAlbumsIds.length === 1;
    });
};

const filterByAlbums = memoize((photos, groupByAlbum, albums, activeAlbum, photosComponent) => {
  let filteredByAlbums;
  if (groupByAlbum === ALL_AUX) {
    // If the filter is reset to show all photo
    filteredByAlbums = photos;
  } else if (activeAlbum === ALL_AUX) {
    // Handles the filter cases for "All Photos" album
    switch (groupByAlbum) {
      case ALL_AUX:
        filteredByAlbums =  photos;
        break;
      case GROUP_BY.NO_ALBUM: {
        filteredByAlbums = getPhotosInNoAlbum(photos, photosComponent, albums);
        break;
      }
      case GROUP_BY.SINGLE_ALBUM: {
        filteredByAlbums = getPhotosInSingleAlbum(photos, photosComponent, albums);
        break;
      }
      case GROUP_BY.MULTIPLE_ALBUMS:
        filteredByAlbums = getPhotosInMultipleAlbums(photos, photosComponent, albums);
        break;
      default:
        filteredByAlbums = photos;
    }
  } else {
    // Handles the filter cases for other album
    switch (groupByAlbum) {
      case GROUP_BY.MULTIPLE_ALBUMS:
        filteredByAlbums = getPhotosInMultipleAlbums(photos, photosComponent, albums);
        break;
      case GROUP_BY.ALBUM_ONLY:
        filteredByAlbums = getPhotosInCurrentAlbumOnly(photos, photosComponent);
        break;
      default:
        filteredByAlbums = photos;
    }
  }

  return filteredByAlbums;
});

const applyAllFilters = memoize((photos, filters, photosByUsers,
  photosByLocations, photosPrivate, albums, activeAlbum, photosComponent) => {
  let filteredPhotos;
  let filteredResults;
  filteredResults = filterByAlbums(photos, filters.groupByAlbum, albums, activeAlbum, photosComponent);
  filteredResults = filterByUser(filteredResults, filters.user, photosByUsers);
  filteredResults = filterByLocation(filteredResults, filters.location, photosByLocations);
  filteredResults = filterByPrivacy(filteredResults, filters.privacy, photosPrivate);
  filteredPhotos = filterByDate(filteredResults, filters.startDate, filters.endDate);
  return filteredPhotos;
});

const getActivePhotos = memoize((photos, activePhotos) => {
  return _.pick(photos, activePhotos);
});

const getUndeletedPhotos = memoize((photos, undeletedPhotos) => {
  return _.pick(photos, undeletedPhotos);
});
const _getFilterState = memoize(filters => {
  return JSON.stringify(filters) !== JSON.stringify(INIT_FILTERS);
});

const getPhotosCount = photos => {
  return Object.keys(photos).length;
};

const _getFilteredCount = memoize(getPhotosCount);
const _getAllPhotosCount = memoize(getPhotosCount);
const _getAlbumPhotosCount = memoize(getPhotosCount);

const mapStateToProps = (state, ownProps) => {
  const { photosComponent } = ownProps;
  const {
    photos,
    undeletedPhotos,
    deletedPhotos,
    activeAlbum,
    albums,
    filters,
    photosByUsers,
    photosByLocations,
    photosPrivate,
    timeline
  } = state;
  let displayedPhotos = {};
  if (activeAlbum === ALL_AUX) {
    displayedPhotos = getActivePhotos(photos, undeletedPhotos);
  } else if (activeAlbum === TRASH) {
    displayedPhotos = getActivePhotos(photos, deletedPhotos);
  } else {
    const activePhotos = albums[activeAlbum].photos;
    const undeletedPhotosMap = getUndeletedPhotos(photos, undeletedPhotos);
    displayedPhotos = getActivePhotos(undeletedPhotosMap, activePhotos);
  }
  const filteredPhotos = applyAllFilters(displayedPhotos, filters, photosByUsers, photosByLocations, photosPrivate,
                                         albums, activeAlbum, photosComponent);

  const {showAddToAlbumModal, showPhotoDeletionWarning, currentPhotoId} = state.modals;

  return {
    orderedPhotos: _sortPhotos(filteredPhotos, timeline.isDescending, timeline.sortingType),
    timeline: _getTimeline(filteredPhotos,  timeline.groupingBy, timeline.isDescending, timeline.sortingType),
    isDescending: timeline.isDescending,
    groupingBy: timeline.groupingBy,
    filteredPhotosCount: _getFilteredCount(filteredPhotos),
    photosCount: _getAllPhotosCount(photos),
    albumPhotosCount: _getAlbumPhotosCount(displayedPhotos),
    activeAlbum: activeAlbum,
    activeAlbumData: albums[activeAlbum],
    showAddToAlbumModal: !!showAddToAlbumModal,
    showPhotoDeletionWarning: !!showPhotoDeletionWarning,
    currentPhotoId,

    filterEnabled: _getFilterState(filters)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSelect: photoId => {
      dispatch(selectPhoto(photoId));
    },
    deletePhoto: photoId => {
      dispatch(deletePhotos([photoId]));
    },
    deselectAllPhotos: () => {
      dispatch(deselectAllPhotos());
    },
    togglePhotosSet: photoIds => {
      dispatch(togglePhotosSet(photoIds));
    },
    onOpen: (imageIndex, imageList) => {
      dispatch(openViewer(imageList, imageIndex));
    },
    updateDisplayedPhotos: photosCount => {
      dispatch(updateDisplayedPhotosCount(photosCount || 0));
    },
    hidePhotoDeletionWarning: () => {
      dispatch(triggerModalVisibility('showPhotoDeletionWarning', false));
    },
    hideAddToAlbumModal: () => {
      dispatch(triggerModalVisibility('showAddToAlbumModal', false));
    },
    removePhotoFromActiveAlbum: photoId => {
      dispatch(removePhotoFromActiveAlbum(photoId));
    }
  };
};

export const Gallery = connect(
  mapStateToProps,
  mapDispatchToProps
)(UIGallery);
