import { connect } from 'react-redux';
import { StateTreeManager } from '~/common/helpers/stateTreeManager';
import PhotoDragLayer from './photoDragLayer.ui';
import { fetchThumbnail } from '~/redux/actions/photosActions';


const mapStateToProps = state => {

  const getThumbnailUploadStatus = guid => {
    const thumbnailsUploadStatus = state.thumbnailsUploadStatus[guid];
    return thumbnailsUploadStatus;
  };

  const getThumbnailFetchStatus = guid => {
    const thumbnailsStatus = new StateTreeManager(state.thumbnailsStatus);
    return thumbnailsStatus.getValue(guid);
  };

  return {
    photosSelection: state.photosSelection,
    getThumbnailUploadStatus: getThumbnailUploadStatus,
    getThumbnailFetchStatus: getThumbnailFetchStatus
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchThumbnail: photoId => {
      dispatch(fetchThumbnail(photoId));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PhotoDragLayer);
