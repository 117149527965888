import {AppComponent} from '@adsk/forge-appfw-di';
import { initialize as initializeFF } from '~/common/helpers/featureFlags';

/**
 * A component for fetching account entitlements.
 * @public
 * @extends external:AppComponent
 */
export class FeatureFlagsComponent extends AppComponent {
  /**
   * @inheritdoc
   */
  static defineDependencies() {
    return [
      {type: 'AuthenticationComponent'}
    ];
  }

  /**
   * @inheritdoc
   */
  initialize(dependencies) {
    const {AuthenticationComponent} = dependencies;
    this._config = AuthenticationComponent.getConfiguration();
    this._user = AuthenticationComponent.getUserData();
    const region = this._config.sdkConfig.region;
    initializeFF({ key: this._user.analyticsId, custom: { region }})
    .catch(err => {
      console.error(err);
    });
  }
}
