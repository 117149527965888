import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@adsk/bim360-matrix-react-components';
import T from '~/common/i18n';

/**
 * Render the row content
 * @param {string} status this row upload status
 * @return {array} row content
 */
function renderRowContent(status) {
  const iconName = `uploader-panel-icon-${status}`;

  return (
    <div className="uploader-panel-row-content">
      <SvgIcon className={iconName} width="20px" height="20px" svgId={iconName} />
      <span>{T.translate(`uploadTracker.row.state.${status}`)}</span>
    </div>
  );
}

/**
 * React component representing the panel in the bottom right corner that show
 * the status of current uploads.
 */
class UploaderPanelRow extends React.Component {
  /**
   * Render stuff.
   * @return {UploaderPanelRow} The uploader panel row
   */
  render() {
    let status = '';
    let btnMethod, btnText;

    switch (this.props.status) {
      case 'init':
      case 'new':
        status = 'pending';
        break;
      case 'uploading':
        status = 'processing';
        btnMethod = this.props.cancelUpload;
        btnText = T.translate('uploadTracker.row.cancel');
        break;
      case 'uploadFailed':
        status = 'failed';
        btnMethod = this.props.retryUpload;
        btnText = T.translate('uploadTracker.row.retry');
        break;
      default:
        status = 'uploaded';
        btnMethod = this.props.openPhoto;
        btnText = T.translate('uploadTracker.row.open');
        break;
    }

    return (
      <div style={this.props.style} className="uploader-panel-row">
        <div className="uploader-panel-row-info">
          <div className="uploader-panel-row-title" title={this.props.name}>
            {this.props.name}
          </div>
          <div className="uploader-panel-row-content">
            {renderRowContent(status)}
          </div>
        </div>
        {status !== 'pending' && (
          <div className="uploader-panel-row-action">
            <button
              className="upload-ui-txt-btn"
              onClick={() => btnMethod(this.props.photoID)}
            >
              {btnText}
            </button>
          </div>
        )}
      </div>
    );
  }
}

UploaderPanelRow.propTypes = {
  status: PropTypes.string,
  cancelUpload: PropTypes.func,
  retryUpload: PropTypes.func,
  openPhoto: PropTypes.func,
  photoID: PropTypes.string,
  name: PropTypes.string,
  style: PropTypes.object
};

export default UploaderPanelRow;
