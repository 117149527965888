import { SET_PHOTO_PRIVACY } from '~/common/constants';
import { StateTreeManager } from '~/common/helpers/stateTreeManager';

/**
 * A reducer for the photos download status
 * @param {state} state last state
 * @param {*} action action to reduce
 * @return {state} The next state
 */
function photosPrivate(state = {}, action) {
  switch (action.type) {
    case SET_PHOTO_PRIVACY: {
      const statusStateTree = new StateTreeManager(state);
      const newState = statusStateTree.addValue(action.id, action.isPrivate);
      return newState;
    }
    default: {
      return state;
    }
  }
}

export default photosPrivate;
