import { projectBaseURLExtension } from './navigator';

/** URL Helpers **/

export const getIdFromUrl = (url, baseUrlExtension) => {
  const basePart = url.match(new RegExp(`${baseUrlExtension}/([^/?]*)`));
  if (basePart) {
    return basePart[1];
  }
  return undefined;
};

export const getProjectIdFromUrl = url => {
  return getIdFromUrl(url, projectBaseURLExtension);
};
