import React from 'react';
import {
  Button,
  Dropdown,
  DropdownMenu,
  IconButton,
  Tooltip,
  Loader,
  SvgIcon
} from '@adsk/bim360-matrix-react-components';

import T from '~/common/i18n';
import ButtonGroup from '../utils/ButtonGroup';


/**
 * PrivacyDropdown
 * @param {Object} props the props passed down
 * @return {React.Node} Privacy dropdown
 */
function PrivacyDropdown(props) {

  const onPrivacySelect = event => {
    const isPrivate = JSON.parse(event.currentTarget.getAttribute('value'));
    props.setPrivate(isPrivate);
  };

  return (
    <Dropdown onSelect={onPrivacySelect} disabled={props.disabled} >
      <IconButton
        id="buttonPrivacy"
        className="actionButton"
        svgId="icon_privacy_public"
        onClick={props.duplicatePhotos}
        disabled={props.disabled}
        iconWidth="20px"
        iconHeight="20px"
        data-for="shared"
        data-tip={T.translate('controls.privacyBtn.tooltip')}
      />
      <DropdownMenu>
        <li value="false">
          <Button className="Button Button--inline-link AnchorButton MenuItemIcon" >
            <SvgIcon svgId="icon_privacy_public" width="16px" height="16px" />
            <span className="MenuItemIcon__label">{T.translate('controls.setAsPublic')}</span>
          </Button>
        </li>
        <li value="true">
          <Button className="Button Button--inline-link AnchorButton MenuItemIcon">
          <SvgIcon svgId="private" width="16px" height="16px" />
            <span className="MenuItemIcon__label">{T.translate('controls.setAsPrivate')}</span>
          </Button>
        </li>
      </DropdownMenu>
    </Dropdown>
  );
}

/**
 * Render actions buttons controls
 * @param {Object} props the props passed down
 * @return {ButtonGroup} button group for actions controls
 */
export default function ActionButtons(props) {
  if (props.isTrash) {
    return null;
  }
  const actionDisabled = !props.selectedPhotos.length;

  const place = Tooltip.Places.BOTTOM;
  const theme = Tooltip.Themes.DARK;

  const showProps = actionDisabled ? { show: false } : {};

  return (
    <>
      <ButtonGroup className="actionButtons">
        <Tooltip
          content={T.translate('controls.deleteBtn.tooltip')}
          place={place}
          theme={theme}
          {...showProps}
      >
          <IconButton
            id="buttonDelete"
            className="actionButton grayButton"
            svgId="trash"
            onClick={props.deletePhotos}
            disabled={actionDisabled}
            iconWidth="16px"
            iconHeight="16px"
          />
        </Tooltip>

        <Tooltip
          content={T.translate('controls.downloadBtn.tooltip')}
          place={place}
          theme={theme}
          {...showProps}
        >
          {props.downloadLocked ?
            <Button className="actionButton downloadsBtnLoader" disabled={true}>
              <Loader className="scaled" />
            </Button> :
            <IconButton
              id="buttonDownload"
              className="actionButton"
              svgId="icon_download"
              onClick={props.downloadPhotos}
              disabled={actionDisabled}
              iconWidth="18px"
              iconHeight="18px"
            />
          }
        </Tooltip>

        <Tooltip
          content={T.translate('controls.duplicateBtn.tooltip')}
          place={place}
          theme={theme}
          {...showProps}
        >
          <IconButton
            id="buttonDuplicate"
            className="actionButton"
            svgId="create-another"
            onClick={props.duplicatePhotos}
            disabled={actionDisabled}
            iconWidth="18px"
            iconHeight="18px"
          />
        </Tooltip>

        {props.showPrivacyFeature ?
          <PrivacyDropdown
            disabled={actionDisabled}
            setPrivate={props.setPhotosPrivate}
          /> : null
        }
      </ButtonGroup>
    </>
  );
}
