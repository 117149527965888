export const LOCAL_STORAGE = {
  LAST_USED_PROJECT_DATA: 'LAST_USED_PROJECT_DATA',
  USER_SETTINGS: 'USER_SETTINGS'
};

export const ServiceCategories = {
  FEATURE_MODULES: 'next_gen',
  CLASSIC: 'classic',
  ADMIN: 'admin'
};

export const NonClassicServiceCategories = Object.keys(ServiceCategories).reduce((filteredCategories, category) => {
  return ServiceCategories[category] !== ServiceCategories.CLASSIC ?
    Object.assign(filteredCategories, { [category]: ServiceCategories[category] }) :
    filteredCategories;
}, {});

export const getEntitlementsParams = (extendedDetails = false) => ({
  service_categories: Object.values(extendedDetails ? ServiceCategories : NonClassicServiceCategories).join(','),
  current_service_type: 'doc_manager',
  include_services: extendedDetails,
  include_containers: extendedDetails,
  limit: extendedDetails ? 1 : 300
});

export const PromiseRejectionMessages = {
  TOKEN_PROMISE_REJECTION: 'TOKEN_PROMISE_REJECTION'
};
