/**
 * @fileoverview This file emulates the UDP Space class
 */

import {PropertyFactory} from '@adsk/forge-hfdm';
import * as schemas from '../schemas';
import {RelationshipDirection, ReservedPaths} from '../helpers';
import {UDP_SDK_BINDING_TYPE} from '../udpSdk';
import _ from 'lodash';

/**
 * Emulation of parts of the UDP Thick SDK
 */
export class Space {
  /**
   * constructor - from the UDP Space class
   * @constructor
   * @param {external:BaseProperty} spaceProperty  A UDP space property.
   * @param {DataBinder} dataBinder A DataBinder instance attached a UDP space.
   */
  constructor(spaceProperty, dataBinder) {
    this._dataBinder = dataBinder;

    this.property = spaceProperty;
    this.name = spaceProperty.getValue(['name']);
    this.guid = spaceProperty.getGuid();
    this.relationships = {
      incoming: {},
      // A mapping from a relationship guid to the relationship destination asset guid.
      allTo: {},
      // A mapping from a relationship guid to the relationship source asset guid
      allFrom: {}
    };
  }

  /**
   * Get the attached DataBinder instance
   * @return {DataBinder} Current DataBinder.
   */
  getDataBinder() {
    return this._dataBinder;
  }

  /**
   * Get the underlying HFDM workspace
   * @return {Workspace} Current workspace.
   */
  getWorkspace() {
    return this.getDataBinder().getWorkspace();
  }

  /**
   * Gets a specified asset object from within the space.
   * @param {string} assetGuid - Guid of the asset to get.
   * @return {Asset|undefined} The asset with the given Guid or undefined.
   */
  getAsset(assetGuid) {
    const asset = this.getAssetsProperty().get(assetGuid);
    if (!asset) {
      throw new Error(assetGuid);
    }
    return this._dataBinder.getRepresentation(asset, UDP_SDK_BINDING_TYPE);
  }

  /**
   * Returns all assets of a space
   * @return {Map} map of assets
   */
  getAssetsProperty() {
    return this.property.get(ReservedPaths.assets);
  }

  /**
   * Delete the asset along with its components from the space repo
   * @param {string} assetGuid - Guid of the asset to delete.
   */
  deleteAsset(assetGuid) {
    const assets = this.getAssetsProperty();
    const asset = assets.get(assetGuid);
    if (!asset) {
      throw new Error(`Asset ${assetGuid} doesn't exist.`);
    }
    this.deleteRelationships(assetGuid);
    assets.remove(assetGuid);
  }

  /**
   * Lists the asset's outgoing relationships guids for the given asset in the same space.
   * @param {string} assetGuid URN of the asset in which relationships are being queried
   * @return {Array<Relationship>} An array of relationships guids for the given assetGuid and direction.
   */
  getOutgoingRelationshipsGuids(assetGuid) {
    return this.getAssetsProperty().get([assetGuid, ReservedPaths.relationships]).getIds();
  }

  /**
   * Lists the asset's relationships guids for the given asset in the same space.
   * @param {string} assetGuid URN of the asset in which relationships are being queried
   * @param {RelationshipDirection} direction (in|out) direction of the relationships to get incoming or outgoing
   * @return {Array<Relationship>} An array of relationships guids for the given assetGuid and direction.
   * @private
   */
  _getAssetRelationshipsGuids(assetGuid, direction = RelationshipDirection.Outgoing) {
    let relationships = [];
    switch (direction) {
      case RelationshipDirection.Incoming: {
        relationships = _.pick(this.relationships.allFrom, this.relationships.incoming[assetGuid]) || [];
        break;
      }
      case RelationshipDirection.Outgoing: {
        const  assetRelationships = this.getOutgoingRelationshipsGuids(assetGuid);
        relationships = _.pick(this.relationships.allFrom, assetRelationships || []);
        break;
      }
      case RelationshipDirection.Bidirectional: {
        const outgoing = this.getOutgoingRelationshipsGuids(assetGuid) || [];
        const incoming = this.relationships.incoming[assetGuid] || [];
        relationships = _.pick(this.relationships.allFrom, outgoing.concat(incoming));
        break;
      }
      default: {
        throw new Error(`Invalid direction value ${direction}`);
      }
    }
    return relationships;
  }

  /**
   * Lists the asset's relationships for the given asset in the same space.
   * @param {string} assetGuid URN of the asset in which relationships are being queried
   * @param {RelationshipDirection} direction (in|out) direction of the relationships to get incoming or outgoing
   * @return {Array<Relationship>} An array of relationships for the given assetGuid and direction.
   */
  getAssetRelationships(assetGuid, direction = RelationshipDirection.Outgoing) {
    const relationships = this._getAssetRelationshipsGuids(assetGuid, direction);
    return _.map(relationships, (currentAssetGuid, relationshipGuid) => {
      return this.getAsset(currentAssetGuid).getRelationship(relationshipGuid);
    });
  }

  /**
   * Deletes all relationships of an asset
   * @param {string} assetGuid the urn object of the asset
   * @param {RelationshipDirection} direction direction of relationship to be deleted. Possible values: 'in' | 'out' |
   *   'in-out'
   */
  deleteRelationships(assetGuid, direction = RelationshipDirection.Bidirectional) {
    const relationships = this.getAssetRelationships(assetGuid, direction);
    _.values(relationships).forEach(rel => {
      this.deleteRelationship(rel.guid);
    });
  }

  /**
   * Instantiates an asset from the given typeid.
   * @param {String|null|undefined} typeid - The typeid of a registered asset schema, if undefined or null it creates an
   * abstract udp asset.
   * @param {Object} values - Initial values for the given asset typeid.
   * @return {Asset} Created asset.
   */
  createAsset(typeid, values = {}) {
    if (typeid && !PropertyFactory.inheritsFrom(typeid, schemas.asset.typeid, {workspace: this.getWorkspace()})) {
      throw new Error(`Trying to instantiate an asset
      from typeid "${typeid}" that doesn't inherit from abstract asset typeid ${schemas.asset.typeid}.`);
    }
    if (!typeid) {
      typeid = schemas.asset.typeid;
    }
    const templateProperty = PropertyFactory.getTemplate(typeid);
    if (!templateProperty) {
      throw new Error(`No schema registered to this asset typeid:${typeid}`);
    }
    const assetProperty = PropertyFactory.create(typeid, null, values);
    this.getAssetsProperty().insert(assetProperty.getGuid(), assetProperty);
    return this._dataBinder.getRepresentation(assetProperty, UDP_SDK_BINDING_TYPE);
  }

  /**
   * Create relationship between two related assets
   * @param {String} typeid - A typeid for registered relationship schema.
   * @param {Asset} fromAsset - An asset to be set as source of the relationship
   * @param {Asset} toAsset - An asset to be set as destination of the relationship
   * @param {Object} [initialValues] - Initial values for the relationship property.
   * @return {Relationship} - A relationship object.
   */
  createRelationship(typeid, fromAsset, toAsset, initialValues = {}) {
    if (!fromAsset) {
      throw new Error('The relationship source has to be an asset.');
    }
    if (!toAsset) {
      throw new Error('The relationship destination has to be an asset.');
    }
    if (typeid && !PropertyFactory.inheritsFrom(typeid,
      schemas.relationship.typeid, {workspace: this.getWorkspace()})) {
      throw new Error(`Trying to instantiate a relationship
      from typeid "${typeid}" that doesn't inherit from abstract relationship typeid ${schemas.relationship.typeid}.`);
    }
    if (!typeid) {
      typeid = schemas.relationship.typeid;
    }

    const relationship = PropertyFactory.create(typeid, null, {
      ...initialValues,
      to: toAsset.property.getAbsolutePath()
    });

    fromAsset.property.get(ReservedPaths.relationships).insert(relationship.getGuid(), relationship);
    return this._dataBinder.getRepresentation(relationship, UDP_SDK_BINDING_TYPE);
  }

  /**
   * Deletes the relationship between two assets defined in the space for a given relationship guid.
   * @param {String} relationshipGuid - A relationship guid to delete from the space.
   */
  deleteRelationship(relationshipGuid) {
    const assetGuid = this.relationships.allFrom[relationshipGuid];
    const asset = this.getAsset(assetGuid);
    const relationship = asset.getRelationship(relationshipGuid);

    // remove the relationship
    if (relationship.from.hasRelationship(relationshipGuid)) {
      relationship.from.removeRelationship(relationshipGuid);
    }
    if (relationship.to.hasRelationship(relationshipGuid)) {
      relationship.to.removeRelationship(relationshipGuid);
    }
  }

  /**
   * Lists the relationships in the current space.
   * @return {Array<Relationship>} An array of Relationships in the current space.
   */
  listRelationships() {
    return _.map(this.relationships.allFrom, (assetGuid, relationshipGuid) => {
      return this.getAsset(assetGuid).getRelationship(relationshipGuid);
    });
  }
}
