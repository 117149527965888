import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';

import {
  SvgIcon,
  Tooltip
} from '@adsk/bim360-matrix-react-components';

import T from '~/common/i18n';
import {
  ISSUE_CLASSES,
  CHECKLIST_DOMAIN_DEV,
  CHECKLIST_DOMAIN_PRD,
  CHECKLIST_DOMAIN_QA,
  CHECKLIST_DOMAIN_STG
} from '~/common/constants';

/**
 * Render the issues count under an image thumbnail.
 * @return {Object} the issues count
 */
export const IssuesCount = ({linkedSources}) => {
  let countRfis = 0;
  let countIssues = 0;
  let countChecklists = 0;
  let countGeneric = 0;

  _.each(linkedSources, e => {
    switch (e.type) {
      case ISSUE_CLASSES.RFI:
        countRfis++;
        break;

      case ISSUE_CLASSES.GENERIC:
        countGeneric++;
        break;

      case ISSUE_CLASSES.STANDALONE_MARKUP:
      case ISSUE_CLASSES.ISSUE:
      case ISSUE_CLASSES.QUALITY_ISSUE:
      case ISSUE_CLASSES.AREA:
        countIssues++;
        break;

      default:
        break;
    }
    switch (e.domain) {
      case CHECKLIST_DOMAIN_DEV:
      case CHECKLIST_DOMAIN_PRD:
      case CHECKLIST_DOMAIN_QA:
      case CHECKLIST_DOMAIN_STG:
        countChecklists++;
        break;
      default:
    }
  });

  const place = Tooltip.Places.TOP;
  const theme = Tooltip.Themes.DARK;

  return (
    <div className="issuesCount">
      <Tooltip
        content={T.translate('gallery.issuesCountTooltip.issue', {
          context: countIssues
        })}
        place={place}
        theme={theme}
      >
        <div className={classNames('count', 'issue', { none: !countIssues })}>
          <SvgIcon svgId="issues" width="14px" height="14px" />
          <span className="text">{countIssues}</span>
        </div>
      </Tooltip>

      <Tooltip
        content={T.translate('gallery.issuesCountTooltip.rfi', {
          context: countRfis
        })}
        place={place}
        theme={theme}
      >
        <div className={classNames('count', 'rfi', { none: !countRfis })}>
          <SvgIcon svgId="rfi" width="20px" height="20px" />
          <span className="text">{countRfis}</span>
        </div>
      </Tooltip>

      <Tooltip
        content={T.translate('gallery.issuesCountTooltip.checklist', {
          context: countChecklists
        })}
        place={place}
        theme={theme}
      >
        <div className={classNames('count', 'checklist', { none: !countChecklists })}>
          <SvgIcon svgId="icon_checklist" width="16px" height="15px" />
          <span className="text">{countChecklists}</span>
        </div>
      </Tooltip>

      <Tooltip
        content={T.translate('gallery.issuesCountTooltip.generic', {
          context: countGeneric
        })}
        place={place}
        theme={theme}
      >
        <div className={classNames('count', 'generic', { none: !countGeneric })}>
          <SvgIcon svgId="genericIssue" width="16px" height="15px" />
          <span className="text">{countGeneric}</span>
        </div>
      </Tooltip>
    </div>
  );
};
