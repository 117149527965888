import { connect } from 'react-redux';
import { logActivity } from '~/redux/actions/photosActions';
import { EditableInput as EditableInputUI }  from './editableInput.ui';

const mapDispatchToProps = dispatch => {
  return {
    logActivity: (verb, guid, value, customAttrs) => {
      dispatch(logActivity(verb, guid, null, value, customAttrs));
    }
  };
};

export const EditableInput = connect(
  null,
  mapDispatchToProps
)(EditableInputUI);
