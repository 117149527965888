import React from 'react';

import {ConnectedAlbumsPanel} from './albumsPanel/albumsPanel.redux';

/**
 * React component for the BIM Photo sidebar
 * @public
 */
export class Sidebar extends React.Component {
  /**
   * @inheritdoc
   */
  render() {
    return (
      <div id="sidebar">
        <ConnectedAlbumsPanel withHeader={true} editableRowPosition="top" {...this.props}/>
      </div>

    );
  }
}
