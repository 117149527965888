import Controls from './controls.ui';
import { connect } from 'react-redux';
import {
  duplicatePhotos,
  deselectAllPhotos,
  setTimelineGrouping,
  setSortingOrder,
  setSortingType,
  setSelectedPhotosPrivate,
  downloadSelectedPhotos,
  deletePhotos,
  uploadPhotos,
  restorePhotos,
  removePhotosFromActiveAlbum
} from '~/redux/actions/photosActions';

import {openIssueForm} from '~/redux/actions/issuesActions';

const mapStateToProps = state => {
  return {
    photosSelection: state.photosSelection,
    groupingBy: state.timeline.groupingBy,
    isDescending: state.timeline.isDescending,
    sortingType: state.timeline.sortingType,
    photoGallery: state.photoGallery,
    downloadLocked: state.userDownloadLocked,
    activeAlbum: state.activeAlbum,
    undeletedPhotos: state.undeletedPhotos,
    photos: state.photos,
    albumsData: state.albums,
    showPrivacyFeature: state.features.showPrivacy
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setTimelineGrouping: granularity => {
      dispatch(setTimelineGrouping(granularity));
    },
    setSortingType: sortingType => {
      dispatch(setSortingType(sortingType));
    },
    oldestOnTop: () => {
      dispatch(setSortingOrder(true));
    },
    recentOnTop: () => {
      dispatch(setSortingOrder(false));
    },
    uploadPhotos: (files, activeAlbum) => {
      dispatch(uploadPhotos(files, activeAlbum));
    },
    downloadPhotos: () => {
      dispatch(downloadSelectedPhotos());
    },
    deletePhotos: () => {
      dispatch(deletePhotos());
    },
    removePhotosFromActiveAlbum: () => {
      dispatch(removePhotosFromActiveAlbum());
    },
    duplicatePhotos: () => {
      dispatch(duplicatePhotos());
    },
    setPhotosPrivate: isPrivate => {
      dispatch(setSelectedPhotosPrivate(isPrivate));
    },
    openIssueForm: () => {
      dispatch(openIssueForm());
    },
    restorePhotos: () => {
      dispatch(restorePhotos());
      dispatch(deselectAllPhotos());
    }
  };
};

const ControlPanel = connect(
  mapStateToProps,
  mapDispatchToProps
)(Controls);

export default ControlPanel;
