import {
  ADD_STORAGE,
  REMOVE_STORAGE
} from '~/common/constants';

/**
 * @param {Object} storage The added storage object
 * @return {object} A redux action
 */
export function addStorage(storage) {
  return {
    type: ADD_STORAGE,
    storage
  };
}

/**
 * @param {String} photoId The photo asset id that contains the storage
 * @param {String} storageUrn The urn for the storage asset.
 * @return {Object} A redux action
 */
export function removeStorage(photoId, storageUrn) {
  return ({
    type: REMOVE_STORAGE,
    id: photoId,
    storageUrn
  });
}
