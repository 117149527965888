import { connect } from 'react-redux';

import { StateTreeManager } from '~/common/helpers/stateTreeManager';
import { PhotoContent } from './photoContent.ui';


const mapStateToProps = (state, {photoId, ...props}) => {
  const thumbnailStateTreeManager = new StateTreeManager(state.thumbnailsStatus);
  const thumbnailContentTreeManager = new StateTreeManager(state.thumbnailsContent);
  const photosStateTreeManager = new StateTreeManager(state.photosStatus);
  const photosContentTreeManager = new StateTreeManager(state.photosContent);

  const thumbnailUploadStatus = state.thumbnailsUploadStatus[photoId].status;
  const thumbnailContentStatus =  thumbnailStateTreeManager.getValue(photoId).status;
  const thumbnailContent = thumbnailContentTreeManager.getValue(photoId);
  const photoUploadStatus = state.photosUploadStatus[photoId].status;
  const photoContentStatus =  photosStateTreeManager.getValue(photoId).status;
  const photoContent = photosContentTreeManager.getValue(photoId);

  return {
    thumbnailContentStatus,
    thumbnailUploadStatus,
    thumbnailContent,

    photoContentStatus,
    photoUploadStatus,
    photoContent,

    composition: state.photosComposition[photoId],
    extension: state.photos[photoId].extension,

    source: state.photosSources[photoId],

    photoId,
    ...props
  };
};

export const ConnectedPhotoContent = connect(
  mapStateToProps
)(PhotoContent);
