import {AlbumsList} from './albumsPanel.ui';
import {withAppComponent} from '@adsk/forge-appfw-react';

export const AlbumsPanel = withAppComponent({
  instances: {
    PhotosComponent: {type: 'PhotosComponent'}
  },
  mapAppComponents: ({PhotosComponent}) => {
    const photosSpace = PhotosComponent.getSpace();

    const deleteAlbum = albumId => photosSpace.getAsset(albumId).delete();
    const modifyAlbumName = (albumId, name) => photosSpace.getAsset(albumId).modifyName(name);
    const deleteAlbumWithPhotos = albumId => photosSpace.getAsset(albumId).deleteWithPhotos();
    const createAlbum = name => photosSpace.createAlbum(name);
    return {
      deleteAlbum,
      modifyAlbumName,
      createAlbum,
      deleteAlbumWithPhotos
    };
  }
})(AlbumsList);
