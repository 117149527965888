/**
 * @fileoverview This file defines the activityLog component
 */

import { AppComponent } from '@adsk/forge-appfw-di';
// import { requestHTTP } from '~/common/helpers/utils';
import { ACTIVITY } from '~/common/constants';
import T from '~/common/i18n';


/**
 * The main class for ActivityLogComponent
 * @public
 */
export class ActivityLogComponent extends AppComponent {

  /**
   * @inheritdoc
   */
  initialize(dependencies) {
    const {ActivityLogConfiguration, AuthenticationDataComponent} = dependencies;

    this._authService = AuthenticationDataComponent.getAuthenticationComponent();
    this._authData = AuthenticationDataComponent.getAuthenticationData();
    this._activityPath = ActivityLogConfiguration.activityPath;
    this._generatorID = ActivityLogConfiguration.generatorID;
    this._user = this._authService.getUserData();
    this._authConfig = this._authService.getConfiguration();

    return this._authService.bearerTokenFunction((error, accessToken) => {
      this._bearerToken = accessToken;
    });
  }

  /**
   * Send an Activity log.
   * @param {string} actionVerb the action of the being done (verb).
   * @param {Object} objectDescription description of the object
   * @return {Promise} promise of the request.
   */
  _sendActivity(actionVerb, objectDescription) {
    // Temporarily disabled due to https://jira.autodesk.com/browse/LYNXDEV-11069
    return Promise.resolve();

    // const { apigee } = this._authConfig;
    // const requestPath = apigee + this._activityPath;
    // const headers = {'Authorization': 'Bearer ' + this._bearerToken, 'Content-Type': 'application/json'};

    // const actorUser = {
    //   id: this._user.id,
    //   displayName: this._user.name,
    //   objectType: 'user'
    // };
    // const project = this._extractProjectInformations();
    // objectDescription.project = project;

    // const data = {
    //   generator: this._generatorID,
    //   published: new Date().toISOString(),
    //   actor: actorUser,
    //   verb: actionVerb,
    //   object: objectDescription
    // };

    // return requestHTTP(requestPath, headers, 'POST', JSON.stringify(data)).then();
  }

  /**
   * Returns project informations from currentProjectEntitlement.
   * @return {Object} Description of the project.
   */
  _extractProjectInformations() {
    const currentProjectEntitlement = this._authData.currentProjectEntitlement;

    return {
      id: currentProjectEntitlement.currentProjectId,
      displayName: currentProjectEntitlement.projects[0].name,
      accountId: currentProjectEntitlement.projects[0].accountId
    };
  }

  /**
   * Returns album informations from album asset.
   * @param {Object} albumAsset asset representing the image.
   * @param {Object} [customAttributes=undefined] custom attributes.
   * @return {Object} Description of the album.
   */
  _extractAlbumInformations(albumAsset, customAttributes) {
    return {
      id: albumAsset ? albumAsset.guid : '*',
      displayName: albumAsset ? albumAsset.getName() : T.translate('albumsPanel.allPhotos'),
      objectType: ACTIVITY.TYPES.ALBUM,
      visibleTypes: `["${ACTIVITY.TYPES.PHOTO}"]`,
      ...customAttributes
    };
  }

  /**
   * Logs photo activity.
   * @param {string} verb the verb used to define the message type.
   * @param {Object} photoAsset asset representing the image.
   * @param {Object} [albumAsset=null] asset representing the album.
   * @param {Object} [customAttributes=undefined] custom attributes.
   * @return {Promise} Activity log call promise.
   */
  logPhotoActivity(verb, photoAsset, albumAsset, customAttributes) {
    const photoDescription = {
      id: photoAsset.guid,
      displayName: photoAsset.getPhotoName(),
      objectType: ACTIVITY.TYPES.PHOTO,
      ...customAttributes
    };
    const albumDescription = this._extractAlbumInformations(albumAsset);
    photoDescription.album = albumDescription;

    return this._sendActivity(verb, photoDescription);
  }

  /**
   * Logs album activity.
   * @param {string} verb the verb used to define the message type.
   * @param {Object} albumAsset asset representing the album.
   * @param {Object} [customAttributes=undefined] custom attributes.
   * @return {Promise} Activity log call promise.
   */
  logAlbumActivity(verb, albumAsset, customAttributes) {
    const albumDescription = this._extractAlbumInformations(albumAsset, customAttributes);
    return this._sendActivity(verb, albumDescription);
  }

  /**
   * Gets users authentication data.
   * @return {object} Authentication data.
   */
  getAuthenticationData() {
    return this._authData;
  }

  /**
   * @inheritdoc
   */
  static defineDependencies() {
    return [
      {
        type: 'ActivityLogConfiguration'
      },
      {
        type: 'AuthenticationDataComponent'
      }
    ];
  }

}
