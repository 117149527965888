import { ChecklistInfoBox } from './checklistInfoBox.ui';
import { connect } from 'react-redux';
import { fetchChecklist } from '~/redux/actions/checklistsActions';

const mapStateToProps = ({checklists, authenticationData}, {checklistId}) => {

  return {
    checklist: checklists[checklistId],
    projectId: authenticationData.currentProjectEntitlement.projects[0].id
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchChecklist: checklistId => {
      dispatch(fetchChecklist(checklistId));
    }
  };
};

export const ConnectedChecklistInfoBox = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChecklistInfoBox);
