import React from 'react';
import T from '~/common/i18n';
import {Field} from '@adsk/bim360-matrix-react-components';
import {LocationsDropdown} from '../../controls/locationsDropdown.appfw';
import { ACTIVITY } from '~/common/constants';

/**
 * React component for the BIM Photo PhotoDataLocationView
 * @public
 */
export class PhotoDataLocationView extends React.Component {
  /**
   * Constructor
   * @param {Object} props the props passed down
   */
  constructor(props) {
    super(props);
    this._handleLocationChange = this._handleLocationChange.bind(this);
  }

  /**
   * handles location change in selectDropDown
   * @param {object} item selected item in the location dropdown menu
   * @private
   */
  _handleLocationChange(item) {
    const property = this.props.property;
    this.setState( {
      selectedLocationOption: item
    });

    if (property) {
      const oldValue = property.value;
      property.setValue(item ? item.id : '');
      property.getWorkspace().commit().then(() => {
        const value = item ? item.id : '';
        const verb = value ?
          oldValue === '' ?
            ACTIVITY.VERBS.SET_PHOTO_LOCATION : ACTIVITY.VERBS.UPDATE_PHOTO_LOCATION :
          ACTIVITY.VERBS.CLEAR_PHOTO_LOCATION;
        const name = value && this.props.locations[value] && this.props.locations[value].name || '';
        const oldName = oldValue && this.props.locations[oldValue] && this.props.locations[oldValue].name || '';
        this.props.logActivity(
          verb,
          property.getParent().getGuid(),
          value,
          { newLocationName: name, oldLocationName: oldName }
        );
      });
    }
  }

  /**
   * React rendering of the PhotoDataLocationView
   * @public
   * @return {PhotoDataLocationView} PhotoDataLocationView
   */
  render() {
    return (
      <Field className="ImageDataField" label="Location">
        <LocationsDropdown
          placeholder={T.translate('filterControl.filters.allText')}
          selectedLocationId={this.props.value}
          onLocationChange={this._handleLocationChange}
        />
      </Field>
    );
  }
}
