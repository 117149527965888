import { PropertyFactory } from '@adsk/forge-hfdm';
import { CommonAsset } from './commonAsset';
import {RelationshipDirection} from '~/udp/helpers';
import { v4 as getUUID } from 'uuid';
import * as schemas from '~/resources/schemas';
import { ACTIVITY } from '~/common/constants';
const PHOTO_ASSET_TYPEID = schemas.photoAsset.typeid;
const STORAGE_ASSET_TYPEID = schemas.photoStorage.typeid;
const PHOTO_STORAGE_RELATIONSHIP_TYPEID = schemas.photoStorageRelationship.typeid;
const ENTITIES_SCHEMA_TYPEID = schemas.entities.typeid;

/**
 * Specialized runtime representation for photo asset.
 */
export class PhotoAsset extends CommonAsset {
  /**
   * @inheritdoc
   */
  constructor() {
    super(...arguments);
    this.isPhotoAsset = true;
  }

  /**
   * Duplicates the photo asset in the photos space: duplication doesn't include external references
   * @param {Boolean} [commit=true] Commit to the server
   * @return {Promise} The asset guid.
   */
  duplicate(commit = true) {
    let duplicateAsset = this.property.clone();
    const trackable = this.space.generateTrackable();
    // we duplicate a photo without the issues attached to it
    duplicateAsset.get('relationships').clear();
    duplicateAsset.get(['sources', 'references']).clear();
    duplicateAsset.get('trackable').setValues(trackable);
    duplicateAsset.get('guid').setValue(getUUID());

    try {
      this.space.createAsset(PHOTO_ASSET_TYPEID, duplicateAsset.getValues());
    } catch (err) {
      return Promise.reject(err);
    }

    // Log activity
    this.space.getActivityLogService().logPhotoActivity(ACTIVITY.VERBS.DUPLICATE_PHOTO, this);

    // Commit
    if (commit) {
      return this._workspace.commit();
    } else {
      return Promise.resolve();
    }
  }

  /**
   * Set value of isPrivate
   * @param {Boolean} [value=true] Value of isPrivate.
   * @param {Boolean} [commit=true] Commit to the server
   * @return {Promise} The asset guid.
   */
  setAsPrivate(value = true, commit = true) {
    const acp = this.property.get('acp');
    acp.get('isPrivate').setValue(value);
    if (commit) {
      return this._workspace.commit().then(() => {
        const verb = value ? ACTIVITY.VERBS.SET_PHOTO_PRIVATE : ACTIVITY.VERBS.SET_PHOTO_PUBLIC;
        this.space.getActivityLogService().logPhotoActivity(verb, this);
      });
    } else {
      return Promise.resolve();
    }
  }

  /**
   * Set value of dateTaken
   * @param {string} dateTaken Value of dateTaken.
   * @param {Boolean} [commit=true] Commit to the server
   * @return {Promise} The asset guid.
   */
  updateTakenDate(dateTaken, commit = true) {
    const property = this.property.get(['photo', 'dateTaken', 'iso8601']);
    property.setValue(dateTaken);
    if (commit) {
      return this._workspace.commit();
    } else {
      return Promise.resolve();
    }
  }

  /**
   * Handles adding an extra source (like issues) to the UDP space.
   * @param {object} source The source to add to UDP, must follow the schema
   * @return {Promise} Fulfilled if the source is added to the current asset successfully, o/w rejects;
   */
  addSource(source) {
    try {
      const sourceReferences = this.property.get(['sources', 'references']);

      const newValue = PropertyFactory.create(ENTITIES_SCHEMA_TYPEID, 'single', source);
      sourceReferences.push(newValue);
    } catch (err) {
      return Promise.reject(err);
    }
    return this.commitChanges();
  }

  /**
   * Creates a new storage asset linked to a photo. And the relationship for it.
   * @param {string} ossUrn Urn of the oss bucket that hosts the file in docs.
   * @return {Promise} Fulfilled if the storage asset is linked the photo and the changed is submitted, o/w it's
   * Rejected.
   */
  createStorage(ossUrn) {
    try {
      const storageAsset = this.space.createAsset(STORAGE_ASSET_TYPEID, {
        guid: ossUrn,
        urn: ossUrn
      });

      // Storage assets all have a one to one relationship with a photo.
      // Therefore all Storage assets must have an incoming relationship.
      this.space.createRelationship(PHOTO_STORAGE_RELATIONSHIP_TYPEID, this, storageAsset);
    } catch (err) {
      return Promise.reject(err);
    }

    return this._workspace.commit();
  }

  /**
   * Cancel an upload
   * @return {Promise} Fulfilled when the photo upload is cancelled successfully, o/w rejected.
   */
  cancelUpload() {
    const binaryProperty = this.property.get('content');

    if (binaryProperty) {
      return binaryProperty.cancelUpload();
    }

    return Promise.reject('Content BP not found.');
  }

  /**
   * Retry the photo binary upload.
   * @return {Promise} Fulfilled if the binary upload was successfully and the change submitted successfully, o/w
   * Rejected.
   */
  retryUpload() {
    const binaryProperty = this.property.get('content');

    if (binaryProperty) {
      return binaryProperty.upload().then(() => {
        this._workspace.commit();
      });
    } else {
      return Promise.reject('Content BP not found.');
    }
  }

  /**
   * Returns the current photo asset name.
   * @return {String} Photo asset name.
   */
  getPhotoName() {
    return this.property.get(['photo', 'name']).value;
  }

  /**
   * Delete a photo: updates the deleted property of the photo asset to true and delete the icoming relationships
   * @param {Boolean} [commit=true] Commit to the server
   * @return {Promise} Fulfilled if the photo is deleted successfully o/w rejected.
   */
  delete(commit = true) {
    // we delete the incoming relationships to this photo if any
    // this will allow restore a photo of an album to "all photos" only
    this.space.deleteRelationships(this.guid, RelationshipDirection.Incoming);
    return this.modifyDeletedProperty(true, commit);
  }

  /**
   * Restore a photo: updates the deleted property of the photo asset to false
   * @param {Boolean} [commit=true] Commit to the server
   * @return {Promise} Fulfilled if the photo is restored successfully o/w rejected.
   */
  restore(commit = true) {
    return this.modifyDeletedProperty(false, commit);
  }

  /**
   * modify the photo's asset deleted property.
   * @param {Boolean} value the deleted property value
   * @param {Boolean} [commit=true] Commit to the server
   * @return {Promise} Fulfilled if the photo deleted is updated successfully o/w rejected.
   */
  modifyDeletedProperty(value, commit = true) {
    const photoName = this.getPhotoName();
    const deletedNode = this.property.get('deleted');

    // If schema doesn't support deleted we abort
    if (!deletedNode) {
      return Promise.reject(`The photo asset of name ${photoName} is missing 'deleted' component`);
    }

    // Set value
    try {
      deletedNode.setValue(value);
    } catch (err) {
      return Promise.reject(err);
    }

    // Log activity
    const verb = value ? ACTIVITY.VERBS.DELETE_PHOTO : ACTIVITY.VERBS.RECOVER_PHOTO;
    this.space.getActivityLogService().logPhotoActivity(verb, this);

    // Commit
    if (commit) {
      return this.commitChanges();
    } else {
      return Promise.resolve();
    }
  }

  /**
   * Update the image asset composition.
   * @param {object} values The composition values.
   * @return {Promise} Fulfilled if the photo composition is updated successfully o/w rejected.
   */
  modifyComposition(values) {
    const compositionNode = this.property.get('composition');
    const oldRotationValue = compositionNode.getValue('rotation');
    const {rotation} = values;
    const isRightRotation = (rotation > oldRotationValue && !(oldRotationValue === 0 && rotation === 270)) ||
                            (rotation === 0 && oldRotationValue === 270);
    if (compositionNode) {
      try {
        compositionNode.setValues(values);
      } catch (err) {
        return Promise.reject(err);
      }
      return this.commitChanges().then(() => {
        let verb = isRightRotation ? ACTIVITY.VERBS.ROTATE_PHOTO_RIGHT : ACTIVITY.VERBS.ROTATE_PHOTO_LEFT;
        this.space.getActivityLogService().logPhotoActivity(verb, this);
      });
    } else {
      return Promise.reject('Composition node not found.');
    }
  }
}
