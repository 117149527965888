import React from 'react';
import {Field} from '@adsk/bim360-matrix-react-components';
import {EditableInput} from '../albumAssetView/editableInput.redux';
import classNames from 'classnames';

/**
 * A generic react component to view a different photo data property in an editable text field.
 * @param {name} props passed down props
 * @return {ReactDOM} The react to render
 */
export const PhotoDataGenericView = props => {
  const isCaptionDataField = props.name === 'Caption';
  const classes = classNames(
    {'ImageDataField': !isCaptionDataField},
    {'captionDataField': isCaptionDataField}
  );
  return (
    <Field className={classes} label={props.name}>
      <EditableInput {...props}/>
    </Field>
  );
};
