import React from 'react';
import { Loader } from '@adsk/bim360-matrix-react-components';
import '~/styles/splashScreen.scss';

/**
   * React component for the BIM Photo page
   * @public
   */
class SplashScreen extends React.Component {

  /**
   * React rendering of the Page
   * @public
   * @return {SplashScreen} Page
   */
  render() {
    return ([
      <div key="splashScreen" className="splashScreen">
        <Loader />
      </div>
    ]);
  }
}

export default SplashScreen;
