import { PropertyFactory } from '@adsk/forge-hfdm';
import { AppComponent } from '@adsk/forge-appfw-di';

import * as schemas from '~/resources/schemas';
import { MSG } from '~/redux/messages';
/**
 * A component for registering the photos schemas.
 */
export class PhotosSchemasRegistrationComponent extends AppComponent {

  /**
   * This registers our schemas while they are not uploaded on Forge.
   * @private
   * @param {Object} schema schema to be registered
   * @return {String} output of the registration
   */
  _registerSchema(schema) {
    // We first need to check that our schemas are valid structures
    const validation = PropertyFactory.validate(schema);
    if (validation.errors[0]) {
      return MSG.NON_VALID_SCHEMA;
    } else {
      // We first check if these are not already registered
      if (!PropertyFactory.getTemplate(schema.typeid)) {
        PropertyFactory.register(schema);
        return MSG.REGISTRATION_SUCCESSFUL;
      } else {
        return MSG.ALREADY_REGISTERED;
      }
    }
  }

  /**
   * @inheritdoc
   */
  initialize(dependencies, params) {
    Object.keys(schemas).forEach(schema => {
      this._registerSchema(schemas[schema]);
    });
    return Promise.resolve();
  }
}
