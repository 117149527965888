import {createQueryStringFromParameters} from './generateEndpoint';

export const getResourceData = ({token, parameters, modelParser, resource, payloadResponseKey, hq}) => {
  return new Promise((resolve, reject) => {
    const queryString = !parameters.url && createQueryStringFromParameters(
      Object.keys(parameters).map(key => ({parameter: key, value: parameters[key]})));
    const xhr = new XMLHttpRequest();
    const onError = () => {
      reject(xhr);
    };
    xhr.withCredentials = true;
    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        let entitlements = null;
        let next = null;
        try {
          const parsedResponse = JSON.parse(xhr.responseText);
          next = parsedResponse.next;

          entitlements = payloadResponseKey ?
            {[payloadResponseKey]: modelParser.parse(parsedResponse), next} :
            {...modelParser.parse(parsedResponse), next};
        } catch (e) {
          onError();
        }

        if (entitlements) {
          if (next) {
            getResourceData({
              token: token,
              parameters: {...parameters, url: next},
              modelParser: modelParser,
              resource: resource,
              hq: hq
            }).then((nextEntitlements) => {
              entitlements.projects = [...entitlements.projects, ...nextEntitlements.projects];
              entitlements.next = nextEntitlements.next;
              resolve(entitlements);
            })
          } else {
            resolve(entitlements);
          }
        }
      } else {
        onError();
      }
    };

    xhr.onerror = onError;
    xhr.ontimeout = onError;

    xhr.open('GET', parameters.url && `${hq}/${parameters.url}` ||
      `${hq}/${resource}${queryString}`, true);

    // Below line must appear after xhr.open due to an IE11 bug -
    // https://github.com/stephanebachelier/superapi/issues/5
    xhr.timeout = 20 * 1000;

    xhr.setRequestHeader('Content-Type', 'application/vnd.api+json');
    xhr.setRequestHeader('Authorization', 'Bearer ' + token);
    xhr.send();
  });
};
