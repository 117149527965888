import { BreadCrumbs, OverflowTooltip, Tooltip } from '@adsk/bim360-matrix-react-components';
import React from 'react';
import { ALL_AUX, TRASH } from '~/common/constants';
import T from '~/common/i18n';

/**
 * A react component for the TopBar
 * @param {Object} props react props
 * @return {ReactDOM} The DOM to render
 */
export class TopBar extends React.Component {
  /**
   * @inheritdoc
   */
  constructor(props) {
    super(props);

    this._wrapWithTooltip = this._wrapWithTooltip.bind(this);
  }

  /**
   * Wrap the text with a OverflowTooltip component.
   * Hackish way to have BreadCrumbs support OverflowTooltip as the component expect a function
   * that return a string, but doesn't actually validate that the received value is a string.
   * @param {*} text The text to wrap
   * @return {function} Return a function that return a component.
   */
  _wrapWithTooltip(text) {
    return () => {
      return (
        <OverflowTooltip
          singleLine={true}
          autoTipOnEllipsis={true}
          content={text}
          theme={Tooltip.Themes.DARK}
          place={Tooltip.Places.BOTTOM}
        >
          {text}
        </OverflowTooltip>
      );
    };
  }

  /**
  * @inheritdoc
  */
  render() {
    let albumName;
    const { props } = this;

    if (typeof props.album === 'string') {
      switch (props.album) {
        case ALL_AUX: {
          albumName = T.translate('albumsPanel.allPhotos');
          break;
        }
        case TRASH: {
          albumName = T.translate('albumsPanel.deletedPhotos');
          break;
        }
        default: {
          // Album has no name, this should not be possible with this UI
          albumName = T.translate('albumsPanel.unnamed');
        }
      }
    } else {
      albumName = props.album.name;
    }

    const caption = props.caption || T.translate('photoAssetDataView.noCaption');

    return (
      <div className="imageToolbar topBar">
        <BreadCrumbs
          className="breadcrumbs"
          tabs={[
            {
              name: this._wrapWithTooltip(albumName),
              onClick: props.closeModal
            },
            {
              name: this._wrapWithTooltip(caption)
            }
          ]}
        />
        <div className="counter">
          {(props.activePhotoIndex + 1) + '/' + props.photoCount}
        </div>
        {/* Empty div for styling */}
        <div />
      </div>
    );
  }
}

export default TopBar;
