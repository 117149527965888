import {AppComponent} from '@adsk/forge-appfw-di';

import config from 'appConfig';

const useAuthentication = !(config.ENV_NAME === 'qa');
const HEADER_PARAM = 'x-ads-acm-scopes';

/**
 * A component for registering photos workspace configuration
 */
export class PhotosHFDMConnectionConfiguration extends AppComponent {

  /**
   * @inheritdoc
   */
  static defineDependencies() {
    return [
      {type: 'BIMProjectsEntitlementsComponent', as: 'projects'}
    ];
  }

  /**
   * @inheritdoc
   */
  initialize(dependencies, params) {
    const {projects} = dependencies;
    const activeProject = projects.getActiveProjectEntitlement();
    // HFDMConnectionComponent configuration
    if (params.withHeaders) {
      this.headers = {
        [HEADER_PARAM]: `b360project.${activeProject.currentProjectId}`
      };
    }
    this.endpoint = config.HFDM_SETTINGS.endpoint;
    this.useAuthentication = useAuthentication;

    return Promise.resolve();
  }
}
