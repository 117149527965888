import {DataBinding} from '@adsk/forge-appfw-databinder';
import {
  removeStorage,
  addStorage
} from '~/redux/actions/storageActions';

/**
 * A binding for the Storage assets which responds to modifications in UDP and updates the redux store accordingly.
 * TODO: Ideally this Binding should be a SingletonBinding. Thus, please keep it stateless as it is, this will make
 * using SingletonBindings much easier.
 * @extends external:DataBinding
 */
export class StorageDataBinding extends DataBinding {

  /**
   * @inheritdoc
   */
  onRemove() {
    const {store} = this.getUserData();
    store.dispatch(removeStorage(this.storage.id));
  }

  /**
   *  @inheritdoc
   */
  onPostCreate(in_modificationContext) {
    const asset = this.getRepresentation();
    const {store} = this.getUserData();
    this.storage = {
      id: asset.guid,
      photo: []
    };
    store.dispatch(addStorage(this.storage));
  }
}
