import {AppComponent} from '@adsk/forge-appfw-di';
import {PromiseRejectionMessages} from '~/common/helpers/authenticationConsts';
import {Token} from '@adsk/bim360-mc-interface';
import {MCImplementation} from '@adsk/bim360-mc-implementation';


/**
 * Authentication class for the BIM docs.
 * @public
 * @extends external:AppComponent
 */
class BIMDocsAuthenticationComponent extends AppComponent {
  /**
   * @inheritdoc
   */
  static defineDependencies() {
    return [
      {type: 'BIMDocsConfiguration'}
    ];
  }

  /**
   * The constructor of our component.
   * @param {Object} [params] An optional parameter object, which will usually contain some properties specific to
   *  dependency injection, but may also carry user-defined parameters. Since the component might get configuration
   *  settings via dependencies as well, it is generally better to perform the configuration in the initialize()
   *  function. Splitting the configuration between the constructor and the initialize() function may lead to code
   *  duplication.
   */
  constructor(params) {
    super(params);
    this.bearerTokenFunction = this.bearerTokenFunction.bind(this);
    this.getToken = this.getToken.bind(this);
  }

  /**
   * Gets the current token, if it's invalid it generates a new one.
   * @return {Promise<Object>} Returns a promise with the token object.
   */
  getToken() {
    if (this._accessToken) {
      let remainingTime = (this._accessToken.expirationTime - Date.now()) / 1000;
      // Renew token if it expires in less than 30 seconds or has expired
      if (remainingTime <= 30) {
        return this.generateAndSetToken();
      } else {
        return Promise.resolve(this._accessToken);
      }
    } else {
      return this.generateAndSetToken();
    }
  }

  /**
   * Generates a new token and cache it.
   * @return {Promise<T | never>} A promise wih the generated token.
   * If the generation fails, it refresh the cookie.
   */
  generateAndSetToken() {
    return this._generateToken().then(token => {
      this._accessToken = token;
      return Promise.resolve(token);
    })
      .catch(err => {
        this.refreshCookie();
        return Promise.reject(err);
      });
  }

  /**
   * A call to generate a new token.
   * @return {Promise<any>} A promise with the generated token.
   * @private
   */
  _generateToken() {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      const onError = () => {
        reject({...xhr, message: PromiseRejectionMessages.TOKEN_PROMISE_REJECTION});
      };

      xhr.withCredentials = true;
      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          const parsedToken = Token.parse(JSON.parse(xhr.response));
          resolve(parsedToken);
        } else {
          onError();
        }
      };

      xhr.onerror = onError;
      xhr.ontimeout = onError;

      xhr.open('GET', `${this._config.baseUrl}/session/token?validate_token=true`, true);
      xhr.send();
    });
  }

  /**
   * Returns the bearer token
   * @param {function} callback callback function
   * @return {function} returns the token
   */
  bearerTokenFunction(callback) {
    return this.getToken()
      .then(({token}) => {
        return callback(undefined, token);
      });
  }

  /**
   * Getting user data.
   * @return {Promise<Object>} A promise with the user data.
   */
  authenticateUser() {
    return this._matrix.authenticateUser();
  }

  /**
   * Attempts SSO for cookie renewal
   */
  refreshCookie() {
    this._matrix.refreshCookie();
  }

  /**
   * Returns the resolved user data.
   * @return {Object} User data.
   */
  getUserData() {
    return this._user;
  }

  /**
   * Returns the BIM docs configuration used for authentication.
   * @return {Object} BIM docs configuration.
   */
  getConfiguration() {
    return this._config;
  }

  /**
   * @inheritdoc
   */
  initialize(dependencies) {
    this._config = dependencies.BIMDocsConfiguration;
    this._matrix = new MCImplementation(this._config.sdkConfig);
    return new Promise(async (resolve, reject) => {
      try {
        this._accessToken = await this._generateToken();
        this._user = await this.authenticateUser();
        resolve();
      } catch (rejection) {
        // When all else fails - DOCS.
        // Refresh cookie actually redirects to docs to get the new cookie.
        if (rejection && rejection.message === PromiseRejectionMessages.TOKEN_PROMISE_REJECTION) {
          this.refreshCookie();
          reject(rejection);
        }
      }
    });
  }

}

export default BIMDocsAuthenticationComponent;
