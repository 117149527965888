/**
 * @fileoverview This file defines both the Component and the DataBinding
 */
import {AppComponent} from '@adsk/forge-appfw-di';
import {HFDM, BinaryDataSource} from '@adsk/forge-hfdm';

import Thumbnailer from '../helpers/workers/thumbnailer.worker';
import imageType from 'image-type';
import {
  UPLOADED,
  THUMBNAIL_SIZE,
  REQUEST_CONCURRENCY,
  DOWNLOADS_TYPES,
  PHOTOS_BINDING_TYPE
} from '~/common/constants';
import { DownloadManager } from '../helpers/binaryPropertyDownloadManager';
import { StateTreeManager } from '~/common/helpers/stateTreeManager';

const GIF_MIME_TYPE = 'image/gif';
let indexOfNextWorkerToWork = 0;
// We create a list with 'NumberOfWorkers' workers. We will add them when we initialize each thumbnailer.
let listOfWorkers;
const NUMBER_OF_WORKERS = 3;

/**
 * The main class for the Photos library
 * @public
 * @extends external:AppComponent
 */
export class PhotosComponent extends AppComponent {
  /**
   * @inheritdoc
   */
  initialize(dependencies) {
    const {
      store,
      DataBinderComponent,
      IssuesComponent,
      ChecklistsComponent,
      PhotosAnalyticsComponent
    } = dependencies;
    this._store = store;
    this._dataBinder = DataBinderComponent;
    this._workspace = this._dataBinder.getWorkspace();
    this.IssuesComponent = IssuesComponent;
    this.ChecklistsComponent = ChecklistsComponent;

    // the default value here is 13, but some browser do not support that many connections (safari and chrome)
    // which cause S3 to disconnect us and fail some upload.
    // IF the BP team make this relative to the browser used in the future, this line could be removed.
    HFDM.setBinaryConcurrentRequestLimit(REQUEST_CONCURRENCY);
    this._reduxStore = this._store.createReduxStore(this);

    // initializing download manager
    this._downloadManager = new DownloadManager();

    // Tracking screen load event.
    PhotosAnalyticsComponent.track.screenLoad();

    return Promise.resolve();
  }

  /**
   * get the redux store that was created
   * @return {ReduxStore} the redux store that was created.
   */
  getStore() {
    return this._reduxStore;
  }

  /**
   * Returns the UDP photos space.
   * @return {Object} A UDP space.
   * @public
   */
  getSpace() {
    return this._dataBinder.getRepresentationAtPath('root', PHOTOS_BINDING_TYPE);
  }

  /**
   * get the current _workspace
   * @public
   * @return {Object} _workspace
   */
  getWorkspace() {
    return this._workspace;
  }

  /**
   * The function try to generates a thumbnail and upload it.
   * @param {BinaryDataSource} dataSource - Initialized binary data source.
   * @param {BinaryProperty} thumbnailBinary - Thumbnail binary property.
   * @private
   */
  _generateAndUploadThumbnail(dataSource, thumbnailBinary) {
    dataSource.readAllBytes((err, res) => {
      if (err) {
        console.error(err);
      }

      let anImageType = imageType(res);
      if (anImageType) {
        if (anImageType.mime === GIF_MIME_TYPE) {
          this.generateThumbGif(res, thumbnailBinary, anImageType);
        } else {
          this.generateThumbNotGif(res, thumbnailBinary);
        }
      } else {
        console.error(
          "image-type module wasn't able to parse the image mime type when trying to generate a thumbnail.");
      }
    });
  }

  /**
   * Generate a thumbnail for a non-Gif image
   * @param {*} buffer the input buffer
   * @param {*} thumbnailBinary the binary pset
   */
  generateThumbNotGif(buffer, thumbnailBinary) {
    if (!listOfWorkers || listOfWorkers.length !== NUMBER_OF_WORKERS) {
      this.initializeThumbnailers();
    }
    const thumbnailer = listOfWorkers[indexOfNextWorkerToWork];
    indexOfNextWorkerToWork = (indexOfNextWorkerToWork + 1) % NUMBER_OF_WORKERS;

    // Generate the Thumbnail using a worker.
    // We pass the ID as the node is not serializable.
    // This ID will be passed back to retrieve the proper node for uploading the thumbnail.
    const assetGUID = thumbnailBinary.getParent().getId();

    if (assetGUID) {
      thumbnailer.postMessage([buffer, assetGUID]);
    } else {
      console.error("Thumbnail's parent property not found.");
    }
  }

  /**
   * Handles the general process of a worker response.
   * @param {ArrayBuffer} imageData The transformed Data.
   * @param {String} thumbnailBinaryId The the id of the node where we upload the thumbnail.
   * @return {Promise} the result of the uploadBlob.
   */
  handleWorkerResponse(imageData, thumbnailBinaryId) {
    if (!imageData) {
      // Something bad happened, log in activity log?
      // the error from the worker is stored in the Id.
      return Promise.reject(thumbnailBinaryId);
    } else {
      let thumbnailBinary = this.getSpace()
                                .getAsset(thumbnailBinaryId)
                                .getComponent('thumbnail')
                                .property;
      if (thumbnailBinary) {
        let actualImageType = imageType(imageData);
        let blob = new Blob([imageData], {type: actualImageType.mime});
        return this.uploadThumbnailBlob(blob, thumbnailBinary);
      } else {
        return Promise.reject(new Error('The thumbnail node was not found.'));
      }
    }
  }

  /**
   * Generate a thumbnail for a Gif
   * @param {*} buffer the input buffer
   * @param {*} thumbnailBinary binary pset
   * @param {*} anImageType type of image
   */
  generateThumbGif(buffer, thumbnailBinary, anImageType) {
    const tempBuffer = new Blob([buffer], {type: anImageType.mime});

    // This needs to be bigger than the image otherwise we crash.
    // We create an imageData to contain the buffer then put in at coordinate (0, 0)
    const anImageData = new Image();
    anImageData.src = window.URL.createObjectURL(tempBuffer);
    anImageData.onload = () => {
      const canvas = document.createElement('canvas');
      const resizingCanvas = document.createElement('canvas');
      canvas.width = anImageData.naturalWidth;
      canvas.height = anImageData.naturalHeight;

      const ctx = canvas.getContext('2d');
      const resizingContext = resizingCanvas.getContext('2d');

      ctx.drawImage(anImageData, 0, 0);

      this.determineThumbnailSize(anImageData, resizingCanvas);

      // Essentially the second canvas is only there to resize.
      resizingContext.drawImage(canvas,
                                0, 0,
                                anImageData.naturalWidth,
                                anImageData.naturalHeight,
                                0, 0,
                                resizingCanvas.width,
                                resizingCanvas.height);

      resizingCanvas.toBlob(blob => {
        this.uploadThumbnailBlob(blob, thumbnailBinary);
      }, 'image/jpeg');
    };
  }

  /**
   * Upload a thumbnail Blob
   * @param {*} blob the data
   * @param {*} thumbnailBinary Binary pset
   * @return {Promise} Promise
   */
  uploadThumbnailBlob(blob, thumbnailBinary) {
    const DataSourceThumbnail = BinaryDataSource.BlobDataSource;
    const uploadSourceThumbnail = new DataSourceThumbnail(blob);
    const ourThumbnail = thumbnailBinary;
    ourThumbnail.setDataSource(uploadSourceThumbnail);
    return ourThumbnail.upload().then(() => {
      this._workspace.commit();
    });
  }

  /**
   * Figure out the size for the thumbnail
   * @param {*} anImageData image data
   * @param {*} resizingCanvas canvas
   */
  determineThumbnailSize(anImageData, resizingCanvas) {
    const ratio = anImageData.naturalWidth / anImageData.naturalHeight;
    const ratioThumb = THUMBNAIL_SIZE[0] / THUMBNAIL_SIZE[1];

    if (ratioThumb > ratio) {
      resizingCanvas.width = anImageData.naturalWidth * THUMBNAIL_SIZE[1] / anImageData.naturalHeight;
      resizingCanvas.height = THUMBNAIL_SIZE[1];
    } else {
      resizingCanvas.width = THUMBNAIL_SIZE[0];
      resizingCanvas.height = anImageData.naturalHeight * THUMBNAIL_SIZE[0] / anImageData.naturalWidth;
    }
  }

  /**
   * Fetch a binary photo from the server
   * @public
   * @param {Number} id the id of photo in the photos state
   * @param {boolean} backgroundRequest Whether this request is a background request that should have
   * low priority or not.
   * @return {Promise} return a Promise
   */
  fetchPhoto(id, backgroundRequest = false) {
    const photosContentManager = new StateTreeManager(this.getStore().getState().photosContent);
    const photoContent = photosContentManager.getValue(id);
    if (!photoContent) {
      return Promise.reject(new Error('Photo content is empty.'));
    }
    return this._downloadManager.queue(
      this.getSpace().getAsset(id),
      backgroundRequest ? DOWNLOADS_TYPES.DOWNLOAD : DOWNLOADS_TYPES.FULLSIZE,
      photoContent.dataSource
    ).then(PhotosComponent.extractBlobUrl);
  }

  /**
   * Fetch a binary thumbnail from the server, if it didn't succeed it try to fetch the original image.
   * @public
   * @param {Number} id the id of photo in the photos state
   * @param {Number} [prioritize=false] Whether to prioritize the fetching of this thumbnail
   * @return {Promise} return a Promise
   */
  fetchThumbnail(id, prioritize = false) {
    const state = this.getStore().getState();

    if (!id) {
      Promise.reject(new Error('Id not found, cannot fetch thumbnail.'));
    }

    const thumbnailStateTreeManager = new StateTreeManager(state.thumbnailsContent);
    const thumbnailContent = thumbnailStateTreeManager.getValue(id);
    const thumbnailStatusNode = state.thumbnailsUploadStatus[id];

    if (!thumbnailStatusNode) {
      Promise.reject(new Error('ThumbnailStatus node not found, cannot fetch thumbnail.'));
    }

    const thumbnailUploadStatus = state.thumbnailsUploadStatus[id].status;

    if (thumbnailContent && thumbnailContent.url) {
      return Promise.resolve(
        {url: thumbnailContent.url}
      );
    } else if (thumbnailUploadStatus === UPLOADED) {
      // If the thumbnail is uploaded we fetch it.
      return this._downloadManager.queue(
        this.getSpace().getAsset(id),
        prioritize ? DOWNLOADS_TYPES.THUMBNAIL_VIEWER : DOWNLOADS_TYPES.THUMBNAIL,
        thumbnailContent.dataSource
      ).then(PhotosComponent.extractBlobUrl);
    } else {
      // Thumbnail was not uploaded, fetch should fail
      return Promise.reject(new Error('Thumbnail wasn\'t uploaded yet. ' + thumbnailUploadStatus));
    }
  }

  /**
   * Cancel a download
   * @public
   * @param {string} guid Id of photo asset to cancel the download on
   * @param {number} type type of the download
   */
  cancelDownload(guid, type) {
    this._downloadManager.dequeue(guid, type);
  }

  /**
   * Cancel an upload
   * @param {string} photoID Id of photo asset to cancel the upload on
   */
  cancelUpload(photoID) {
    this.getSpace().getAsset(photoID).cancelUpload();
  }

  /**
   * Determines if the given photoId is in the given albumId
   * @param {string} albumId Id of the album
   * @param {string} photoId Id of the photo
   * @return {boolean} true if the photo is in the album, false otherwise
   */
  albumHasPhoto(albumId, photoId) {
    const space = this.getSpace();
    if (space.hasAsset(albumId)) {
      const hasPhoto = space.getAsset(albumId).hasPhoto(photoId);
      return hasPhoto;
    }
    return false;
  }

  /**
   * Extract the image url from the binary data source.
   * @param {object} params parameters
   * @param {BinaryDataSource} params.dataSource The datasource
   * @param {boolean} params.cancelled Whether the download was cancelled or not.
   * @return {Promise<object>} Return a promise.
   */
  static extractBlobUrl(params) {
    const results = { cancelled: params.cancelled, url: null };

    if (params.cancelled) {
      return Promise.resolve(results);
    } else {
      let urlCreator = window.URL || window.webkitURL;
      const blob = params.dataSource.getBlob();

      if (!blob.size) {
        return Promise.reject(new Error('Blob doesn\'t have a size'));
      } else {
        results.url = urlCreator.createObjectURL(blob);
        return Promise.resolve(results);
      }
    }
  }

  /**
   * Initialize ListOfWorkers to be useable.
   */
  initializeThumbnailers() {
    listOfWorkers = [];
    const onMessage = e => {
      this.handleWorkerResponse(e.data[0], e.data[1]);
    };

    for (let i = 0; i < NUMBER_OF_WORKERS; i++) {
      let aThumbnailer = new Thumbnailer();
      // This is the response from the worker event, we define it here.
      aThumbnailer.addEventListener('message', onMessage);
      listOfWorkers.push(aThumbnailer);
    }
  }

  /**
   * @inheritdoc
   */
  static defineDependencies() {
    return [
      {
        type: 'FeatureFlagsComponent'
      },
      {
        type: 'StoreComponent',
        as: 'store'
      },
      {
        type: 'LocationsComponent'
      },
      {
        type: 'UsersComponent'
      },
      {
        type: 'IssuesComponent'
      },
      {
        type: 'ChecklistsComponent'
      },
      {
        type: 'DataBinderComponent'
      },
      {
        type: 'PhotosAnalyticsComponent'
      }
    ];
  }
}
