// Backend dependant FF should be prefixed with BE_<FeatureFlag_Name>
const config = {
  // Every entry that will be added here will be added as a resource hint in the index.html file
  ORIGINS: {
    DOCS: 'https://docs.b360-dev.autodesk.com',
    PM: 'https://pm.b360-dev.autodesk.com',
    FIELD: 'https://field.b360-dev.autodesk.com',
    APIGEE_BACKEND: 'https://developer-stg.api.autodesk.com',
    OXYGEN: 'https://accounts-staging.autodesk.com',
    PHOTOS: 'https://photos.b360-dev.autodesk.com'
  },
  APIGEE: {
    HQ: {
      VERSION: 'v1'
    }
  },
  DOCS_SDK_CONFIG: {
    region: 'US',
    env: 'Development'
  },
  LAUNCH_DARKLY: {
    API_KEY: '597f04676040390cd07c7932',
    USE_DEFAULTS: false
  },
  DOCS_BACKEND: 'dm-dev',
  ENV_NAME: 'dev',
  HFDM_SETTINGS: {
    'endpoint': 'https://developer-stg.api.autodesk.com/hfdm-stable'
  },
  DOCS_ENDPOINT: 'ea-dm-dev/v1',
  CHECKLISTS_ENDPOINT: 'bim360/checklists-dev/v1/containers',
  ISSUES_ENDPOINT: 'issues-dev/v1/containers',
  RFIS_ENDPOINT: 'bim360/rfis-dev/v1/containers',
  LBS_ENDPOINT: 'bim360/locations-dev/v2/containers',
  CP_ENDPOINT: 'dm-staging/v1/cmd-dev',

  ACTIVITY: {
    ENDPOINT: '/activities/v1/activity',
    GENERATOR_ID: 'bim360-photos-dev'
  },

  ANALYTICS: {
    MODULE_NAME: 'Photos',
    ENV: 'Dev',
    REGION: 'US',
    MIXPANEL: {
      TOKEN: '24dd3f06d727b1d4d5d68a81008ba6b6'
    }
  }
};

config.APIGEE_SERVICES = {
  HQ: config.ORIGINS.APIGEE_BACKEND + '/ea-api-dev/' + config.APIGEE.HQ.VERSION
};

module.exports = config;
