import { IssueInfoBox } from './issueInfoBox.ui';
import { connect } from 'react-redux';
import { getQualityIssue, getRFI, getGenericIssue } from '~/redux/actions/issuesActions';

const mapStateToProps = ({issues, authenticationData}, {issueId, issueType}) => {

  return {
    issueType,
    issue: issues[issueId],
    projectId: authenticationData.currentProjectEntitlement.projects[0].id
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getQualityIssue: issueId => {
      dispatch(getQualityIssue(issueId));
    },
    getRFI: rfiId => {
      dispatch(getRFI(rfiId));
    },
    getGenericIssue: issueId => {
      dispatch(getGenericIssue(issueId));
    }
  };
};

export const ConnectedIssueInfoBox = connect(
  mapStateToProps,
  mapDispatchToProps
)(IssueInfoBox);
