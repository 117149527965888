import {withAppComponent, ReactPropertyViewStoreProvider} from '@adsk/forge-appfw-react';
import React from 'react';

export const PropertyViewProvider = withAppComponent({
  sync: true,
  instances: {
    propertyView: {type: 'ReactPropertyViewComponent'}
  }
})(({appComponents, children, ...restProps}) => (
  <ReactPropertyViewStoreProvider ReactPropertyView={appComponents.propertyView} {...restProps}>
    {children}
  </ReactPropertyViewStoreProvider>
));
