// Every level will break the assumed-to-be-evenly-distributed guids into 16 bins.
// Four levels is therefore good for a 'load' of 65K entries.
const LEVELS = 4;

/**
 * A class to manage an immutable state tree
 */
export class StateTreeManager {
  /**
   * @inheritdoc
   */
  constructor(oldStateTree) {
    this.stateTree = oldStateTree || {};
  }

  /**
   * Gets the state tree
   * @return {StateTree} The state tree
   */
  getStateTree() {
    return this.stateTree;
  }

  /**
   * Traverse the tree and call the callback for every value it find.
   * Callback is invoked with (guid, value)
   * @param {*} callback The callback
   * @return {StateTree} The state tree
   */
  traverseTree(callback) {
    const recurse = (node, depth) => {
      const keys = Object.keys(node);

      for (let i = 0; i < keys.length; i++) {
        if (depth === LEVELS) {
          callback(keys[i], node[keys[i]]);
        } else {
          recurse(node[keys[i]], depth + 1);
        }
      }
    };

    recurse(this.getStateTree(), 0);

    return this.getStateTree();
  }

  /**
   * Adds a value to the state tree and returns the resulting tree
   * @param {String} guid guid to store value at
   * @param {*} value the value to store
   * @param {Boolean} newReference if true, new reference will be created for root
   * @return {StateTree} the new resulting state
   */
  addValue(guid, value, newReference = true) {
    if (newReference) {
      this.stateTree = Object.assign({}, this.stateTree);
    }

    let currentNode = this.getStateTree();
    for (let i = 0; i < LEVELS; i++) {
      currentNode = currentNode[guid[i]] = currentNode[guid[i]] || {};
    }
    currentNode[guid] = value;
    return this.getStateTree();
  }

  /**
   * Removes a value from the state tree and returns the resulting tree
   * @param {String} guid guid that stores the value at
   * @param {Boolean} newReference if true, new reference will be created
   * @return {StateTree} the new resulting state
   */
  removeValue(guid, newReference = true) {
    if (newReference) {
      this.stateTree = Object.assign({}, this.stateTree);
    }

    let currentNode = this.getStateTree();
    for (let i = 0; i < LEVELS && currentNode; i++) {
      currentNode = currentNode[guid[i]];
    }

    if (currentNode) {
      // This only removes the leaf reference in the tree
      delete currentNode[guid];
    }
    return this.getStateTree();
  }

  /**
   * Gets the value stored in the tree.
   * @param {String} guid for the item to get.
   * @return {*} The value stored for the Guid.
   */
  getValue(guid) {
    let currentNode = this.getStateTree();
    for (let i = 0; i < LEVELS && currentNode; i++) {
      currentNode = currentNode[guid[i]];
    }

    // If truthy, get the value. If falsy, either not in the tree (undefined), or this is the result and it is falsy
    return currentNode ? currentNode[guid] : currentNode;
  }

  /**
   * Compute the distribution of bin sizes
   *
   * @return {Number[]} the number of items in each leaf node
   */
  getLoad() {
    let load = [];
    const recurse = (node, depth) => {
      const keys = Object.keys(node);
      const n = keys.length;
      if (depth === LEVELS) {
        load[n] = (load[n] || 0) + 1;
      } else {
        for (let i = 0; i < n; i++) {
          recurse(node[keys[i]], depth + 1);
        }
      }
    };
    recurse(this.getStateTree(), 0);
    return load;
  }
}
