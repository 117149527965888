import _ from 'lodash';
import moment from 'moment';
/**
 * generate a http request using given params.
 * @param {string} url the request URL
 * @param {string} headers the header for the request
 * @param {string} type the type of request (GET, POST, PULL)
 * @param {string} data the data of request
 * @return {Promise} the promise of the request.
 */
export function requestHTTP(url, headers, type, data) {
  type = type || 'GET';
  return new Promise(function(resolve, reject) {
    const httpRequest = new XMLHttpRequest();

    httpRequest.responseType = 'text';

    httpRequest.open(type, url);
    _.each(headers, function(value, key) {
      httpRequest.setRequestHeader(key, value);
    });
    httpRequest.onerror = function() { reject(httpRequest.statusText); };
    httpRequest.onload = function() {
      if (httpRequest.readyState === 4) {
        resolve(httpRequest.response);
      }
    };
    if (data) {
      httpRequest.send(data);
    } else {
      httpRequest.send();
    }
  });
}

/**
 * A function to remove the boilerplate of memoizing functions
 * This does a shallow compare for input parameters only, it does not check against default parameters
 * @param {Function} func the function to memoize
 * @return {Function} A memoized function (memory size 1);
 */
export function memoize(func) {
  // This IIFE provides a closure for the private parameters.
  return (() => {
    let lastParameters = [];
    let lastResult = null;
      // This is the function that is returned from memoize
    return (...parameters) => {
      const isSame = parameters.reduce( (acc, value, i) => {
        return acc && lastParameters[i] === value;
      }, true);

      if (!isSame) {
        lastParameters = parameters;
        lastResult = func(...parameters);
      }
      return lastResult;
    };
  })();
}

  /**
   * The function migrates from the old time format to ISO format for existing repos.
   * @param {Asset} asset the asset property
   * @param {String} timeProp the time property to migrate
   * @return {String} the new ISO time format
   * @private
   */
export function toTimeISOFormat(asset, timeProp) {
  const trackable =  asset.get(['trackable']).getValues();
  let ISO_Time;
  if (_.toInteger(trackable[timeProp].iso8601) / 10000 > 1) {
    asset.get(['trackable']).setValues({
      [timeProp]: {
        iso8601: moment(_.toInteger(trackable[timeProp].iso8601)).toISOString()
      }
    });
    ISO_Time = new Date(_.toInteger(trackable[timeProp].iso8601));
  } else {
    ISO_Time = new Date(trackable[timeProp].iso8601);
  }
  return ISO_Time;
}
