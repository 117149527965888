/**
 * @fileoverview This file defines the users component
 */

import {AppComponent} from '@adsk/forge-appfw-di';
import {requestHTTP} from '~/common/helpers/utils';
import config from 'appConfig';

/**
 * The main class for the users of a project
 * @public
 * @extends external:AppComponent
 */
export class UsersComponent extends AppComponent {

  /**
   * @inheritdoc
   */
  initialize(dependencies) {
    const {AuthenticationComponent, ForgeConfiguration, projects} = dependencies;
    this._projects = projects;
    this._forgeConfig = ForgeConfiguration;
    this._authenticationComponent = AuthenticationComponent;
    this._usersByOxygenId = undefined;
    return this._requestUsers()
    .then(users => {
      this._users = users;
      return this._users;
    });
  }

  /**
   * Returns a list of users assigned to the current project.
   * @return {Promise} - Promise with the users list.
   * @private
   */
  _requestUsers() {
    const projectId = this._projects.getActiveProjectEntitlement().currentProjectId;
    const authenticationBaseURL = this._forgeConfig.environmentBaseUrl;
    const userInfoUrl = authenticationBaseURL + `/${config.DOCS_ENDPOINT}/projects/${projectId}/users`;
    return new Promise((resolve, reject) => {
      this._authenticationComponent.bearerTokenFunction((error, accessToken) => {
        requestHTTP(userInfoUrl, {'Authorization': 'Bearer ' + accessToken})
          .then(userInfo => {
            /**
             * User info contains, amongst others:
             * - id: the id you were already using
             * - name: John Doe
             * - nickname: doe
             * - first_name: John
             * - last_name: Doe
             */
            const usersArr = JSON.parse(userInfo).users;
            const users = this._getProjectUsersMap(usersArr);
            return resolve(users);
          }).catch(err => {
            return reject(err);
          });
      });
    });
  }

  /**
   * Returns an object mapping the user oxygen id to the user object
   * @return {Object} - the list of users
   * @public
   */
  getUsers() {
    return this._users;
  }

  /**
   * Returns the user object for the given id
   * @param {String} id user id
   * @return {Object} - user object
   * @public
   */
  getUser(id) {
    return this._usersByOxygenId[id];
  }

  /**
   * Returns an object mapping the user oxygen id to the user object
   * @param {Array} users the list of users
   * @return {Object} - the list of users
   * @private
   */
  _getProjectUsersMap(users) {
    if (!this._usersByOxygenId) {
      this._usersByOxygenId = users.reduce((usersObj, user) => {
        usersObj[user.oxygen_id] = user;
        return usersObj;
      }, {});
    }
    return this._usersByOxygenId;
  }

  /**
   * @inheritdoc
   */
  static defineDependencies() {
    return [
      {
        type: 'ForgeConfiguration'
      },
      {
        type: 'BIMProjectsEntitlementsComponent',
        as: 'projects'
      },
      {
        type: 'AuthenticationComponent'
      }
    ];
  }
}
