
// Actions
export const PHOTO_REMOVED = 'PHOTO_REMOVED';
export const PHOTO_RESTORED = 'PHOTO_RESTORED';
export const ADD_PHOTO = 'ADD_PHOTO';
export const REQUEST_PHOTO = 'REQUEST_PHOTO';
export const RECEIVE_PHOTO = 'RECEIVE_PHOTO';
export const REJECT_PHOTO = 'REJECT_PHOTO';
export const SELECT_PHOTO = 'SELECT_PHOTO';
export const UPDATE_UPLOAD_STATUS = 'UPDATE_UPLOAD_STATUS';
export const SELECT_ALL_PHOTOS = 'SELECT_ALL_PHOTOS';
export const DESELECT_ALL_PHOTOS = 'DESELECT_ALL_PHOTOS';
export const UPDATE_TRACKABLE = 'UPDATE_TRACKABLE';
export const SET_AUTHENTICATION = 'SET_AUTHENTICATION';
export const VIEW_SELECTED_PHOTOS = 'VIEW_SELECTED_PHOTOS';
export const OPEN_VIEWER = 'OPEN_VIEWER';
export const CLOSE_VIEWER = 'CLOSE_VIEWER';
export const CHANGE_PHOTO_VIEWED = 'CHANGE_PHOTO_VIEWED';
export const SELECT_PHOTOS_SET = 'SELECT_PHOTOS_SET';
export const SET_SORTING_ORDER = 'SET_SORTING_ORDER';
export const SET_SORTING_TYPE = 'SET_SORTING_TYPE';
export const UPDATE_COMPOSITION = 'UPDATE_COMPOSITION';
export const SET_TIME_GROUPBY = 'SET_TIME_GROUPBY';
export const CLEAR_PHOTO_DATA = 'CLEAR_PHOTO_DATA';
export const CLEAR_PHOTOS_DATA = 'CLEAR_PHOTOS_DATA';
export const UPDATE_PHOTO = 'UPDATE_PHOTO';
export const UPDATE_PHOTO_LOCATION = 'UPDATE_PHOTO_LOCATION';
export const ADD_THUMBNAIL = 'ADD_THUMBNAIL';
export const RECEIVE_THUMBNAIL = 'RECEIVE_THUMBNAIL';
export const REJECT_THUMBNAIL = 'REJECT_THUMBNAIL';
export const REQUEST_THUMBNAIL = 'REQUEST_THUMBNAIL';
export const UPDATE_THUMBNAIL_UPLOAD_STATUS = 'UPDATE_THUMBNAIL_UPLOAD_STATUS';
export const UPDATE_FILTER = 'UPDATE_FILTER';
export const UPDATE_DISPLAYED_PHOTOS_COUNT = 'UPDATE_DISPLAYED_PHOTOS_COUNT';
export const LOCK_DOWNLOAD = 'LOCK_DOWNLOAD';
export const UNLOCK_DOWNLOAD = 'UNLOCK_DOWNLOAD';
export const SET_SOURCES = 'SET_SOURCES';
export const SET_PHOTO_INDEX = 'SET_PHOTO_INDEX';
export const CANCEL_PHOTO = 'CANCEL_PHOTO';
export const CANCEL_THUMBNAIL = 'CANCEL_THUMBNAIL';
export const SET_ISSUE_TYPES = 'SET_ISSUE_TYPES';
export const UPDATE_IMAGE_STATUS = 'UPDATE_IMAGE_STATUS';
export const TRIGGER_MODAL_VISIBILITY = 'TRIGGER_MODAL_VISIBILITY';
export const OPEN_FAILED_DOWNLOAD_WARNING = 'OPEN_FAILED_DOWNLOAD_WARNING';
export const CLOSE_FAILED_DOWNLOAD_WARNING = 'CLOSE_FAILED_DOWNLOAD_WARNING';
export const RESET_FAILED_DOWNLOAD_STATUS = 'RESET_FAILED_DOWNLOAD_STATUS';
export const SET_PHOTO_PRIVACY = 'SET_PHOTO_PRIVACY';

// Image Status
export const NEW = 'new';
export const FETCHING = 'fetching';
export const FETCHED = 'fetched';
export const INIT = 'init';
export const UPLOADED = 'uploaded';
export const UPLOADING = 'uploading';
export const UPLOAD_FAILED = 'uploadFailed';
export const FETCH_FAILED = 'fetchFailed';
export const CANCELLED = 'cancelled';

// Upload tracker
export const OPEN_UPLOADER_PANEL = 'OPEN_UPLOADER_PANEL';
export const CLOSE_UPLOADER_PANEL = 'CLOSE_UPLOADER_PANEL';
export const ADD_UPLOADING_PHOTO = 'ADD_UPLOADING_PHOTO';
export const REMOVE_UPLOADING_PHOTO = 'REMOVE_UPLOADING_PHOTO';

// Albums actions
export const ADD_ALBUM = 'ADD_ALBUM';
export const REMOVE_ALBUM = 'REMOVE_ALBUM';
export const UPDATE_ALBUM_TRACKABLE = 'UPDATE_ALBUM_TRACKABLE';
export const UPDATE_ALBUM_NAME = 'UPDATE_ALBUM_NAME';
export const ADD_PHOTO_TO_ALBUM = 'ADD_PHOTO_TO_ALBUM';
export const REMOVE_PHOTO_FROM_ALBUM = 'REMOVE_PHOTO_FROM_ALBUM';
export const SET_ACTIVE_ALBUM = 'SET_ACTIVE_ALBUM';

// Storage action
export const ADD_STORAGE = 'ADD_STORAGE';
export const REMOVE_STORAGE = 'REMOVE_STORAGE';
export const UPDATE_STORAGE_URN = 'UPDATE_STORAGE_URN';

// Files too large alert
export const ADD_TOO_LARGE_FILES = 'ADD_TOO_LARGE_FILES';
export const CLEAR_TOO_LARGE_FILES = 'CLEAR_TOO_LARGE_FILES';

// Issues
export const OPEN_ISSUE_FORM = 'OPEN_ISSUE_FORM';
export const CLOSE_ISSUE_FORM = 'CLOSE_ISSUE_FORM';
export const REQUEST_ISSUE = 'REQUEST_ISSUE';
export const RECEIVE_ISSUE = 'RECEIVE_ISSUE';
export const REJECT_ISSUE = 'REJECT_ISSUE';
export const ISSUE_MAX_TITLE_LENGTH = 100;

// Checklists
export const REQUEST_CHECKLIST = 'REQUEST_CHECKLIST';
export const RECEIVE_CHECKLIST = 'RECEIVE_CHECKLIST';
export const REJECT_CHECKLIST = 'REJECT_CHECKLIST';

// Dragging constants IDs
export const PHOTO_ELEMENT = 'PHOTO_ELEMENT';

// Configs
export const ACCEPTED_IMAGE_FORMAT = [
  'image/png',
  'image/jpeg',
  'image/gif',
  'image/tiff',
  'image/bmp'
].join(', ');
export const THUMBNAIL_SIZE = [210, 160];

// This file size is required due to the potential crash when decompressing a big jpeg when generating thumbnails.
// This limitation will disappear once we use a service to delegate the Thumbnail generation.
export const MAX_FILE_SIZE = 30 * 1024 * 1024;
export const REQUEST_TIMEOUT = Infinity;
export const JPEG_QUALITY = 80;
export const DATE_FORMAT = 'dddd, MMM D, YYYY';
export const DATE_TIME_FORMAT = 'dddd MMM D, YYYY, h:mm:ss a';
export const PHOTOS_BINDING_TYPE = 'bim360-photos';

// Finding this as the takenDate for a photo mean EXIF date of this file was processed but either no taken date
// was found or the file is not a jpeg or tiff. In this case this default date is saved in the field to avoid
// reparsing the EXIF data each time we load the image. We need to reparse the EXIF data each time in case
// the image was added before we started recording this value or in case the client crashed before it finished
// parsing it, when the image was first uploaded.
export const DEFAULT_TAKEN_DATE = '0000-00-00T00:00:00.000Z';

export const HFDM_BINARY_UPLOAD_STATUS_MAP = {
  NEW: INIT,
  UPLOADED: UPLOADED,
  UPLOADING: UPLOADING,
  UPLOAD_FAILED: UPLOAD_FAILED
};

export const FILTERS = {
  GROUP_BY: {
    NO_ALBUM: 'noAlbum',
    SINGLE_ALBUM: 'singleAlbum',
    ALBUM_ONLY: 'albumOnly',
    MULTIPLE_ALBUMS: 'multipleAlbums'
  },
  PHOTOS_PRIVACY: {
    PRIVATE: 'private',
    PUBLIC: 'public'
  }
};

// Domains
export const ISSUE_DOMAIN = 'autodesk-bim360-issues';
export const CHECKLIST_DOMAIN_DEV = 'autodesk-bim360-checklists-dev';
export const CHECKLIST_DOMAIN_QA = 'autodesk-bim360-checklists-qa';
export const CHECKLIST_DOMAIN_STG = 'autodesk-bim360-checklists-stg';
export const CHECKLIST_DOMAIN_PRD = 'autodesk-bim360-checklists-prd';

export const ISSUE_CLASSES = {
  GENERIC: 'documentIssue',
  RFI: 'Rfi',
  STANDALONE_MARKUP: 'StandaloneMarkup',
  ISSUE: 'Issue',
  QUALITY_ISSUE: 'QualityIssue',
  AREA: 'Area'
};
export const ISSUE_STATUSES = {
  DRAFT: 'draft',
  OPEN: 'open',
  ANSWERED: 'answered',
  CLOSED: 'closed',
  WORK_COMPLETED: 'work_completed',
  READY_TO_INSPECT: 'ready_to_inspect',
  NOT_APPROVED: 'not_approved',
  IN_DISPUTE: 'in_dispute',
  VOID: 'void'
};

export const CHECKLIST_STATUSES = {
  NOT_STARTED: 7,
  IN_PROGRESS: 3,
  COMPLETED: 4
};

export const ALL_AUX = '*';
export const TRASH = 'TRASH';
export const INIT_FILTERS = {
  startDate: null,
  endDate: null,
  user: ALL_AUX,
  location: ALL_AUX,
  groupByAlbum: ALL_AUX,
  privacy: ALL_AUX
};

export const SORTING_PHOTOS_OPTIONS = {
  CAPTURE_TIME: 'capture_time',
  DELETION_TIME: 'deletion_time'
};

export const REQUEST_CONCURRENCY = 6;
export const BACKGROUND_REQUEST_CONCURRENCY = 3;

export const DOWNLOADS_TYPES = {
  FULLSIZE: 0,
  THUMBNAIL: 1,
  DOWNLOAD: 2,
  THUMBNAIL_VIEWER: 3
};

export const DOWNLOADS_PROPERTIES_PATHS = {
  [DOWNLOADS_TYPES.FULLSIZE]: 'content',
  [DOWNLOADS_TYPES.THUMBNAIL]: 'thumbnail',
  [DOWNLOADS_TYPES.DOWNLOAD]: 'content',
  [DOWNLOADS_TYPES.THUMBNAIL_VIEWER]: 'thumbnail'
};

export const BACKGROUND_REQUEST_TYPE = {
  [DOWNLOADS_TYPES.DOWNLOAD]: true
};

export const DOWNLOADS_PRIORITY = [
  DOWNLOADS_TYPES.THUMBNAIL_VIEWER,
  DOWNLOADS_TYPES.FULLSIZE,
  DOWNLOADS_TYPES.THUMBNAIL,
  DOWNLOADS_TYPES.DOWNLOAD
];

export const ACTIVITY = {
  VERBS: {
    VIEW_PHOTO: 'view-photo',
    UPLOAD_PHOTO: 'upload-photo',
    DOWNLOAD_PHOTO: 'download-photo',
    RECOVER_PHOTO: 'recover-photo',
    DUPLICATE_PHOTO: 'duplicate-photo',
    ROTATE_PHOTO_LEFT: 'rotate-photo-left',
    ROTATE_PHOTO_RIGHT: 'rotate-photo-right',
    RENAME_PHOTO: 'rename-photo',
    DELETE_PHOTO: 'delete-photo',
    SET_PHOTO_LOCATION: 'set-photo-location',
    UPDATE_PHOTO_LOCATION: 'update-photo-location',
    CLEAR_PHOTO_LOCATION: 'clear-photo-location',
    SET_PHOTO_PRIVATE: 'set-photo-private',
    SET_PHOTO_PUBLIC: 'set-photo-public',
    ADD_PHOTO_TO_ALBUM: 'add-photo-to-album',
    REMOVE_PHOTO_FROM_ALBUM: 'remove-photo-from-album',
    CREATE_ALBUM: 'create-photo-album',
    RENAME_ALBUM: 'rename-photo-album',
    DELETE_ALBUM: 'delete-photo-album'
  },
  TYPES: {
    ALBUM: 'folders:autodesk.bim360:Album',
    PHOTO: 'items:autodesk.bim360:Photo'
  }
};
