import {REMOVE_ALBUM,
        ADD_ALBUM,
        UPDATE_ALBUM_NAME,
        ADD_PHOTO_TO_ALBUM,
        REMOVE_PHOTO_FROM_ALBUM,
        UPDATE_ALBUM_TRACKABLE
} from '~/common/constants';

/**
 * @param {Object} state the old state
 * @param {Object} action the action defining the change of state
 * @return {Object} A new state defining the list of users uploaded photos.
 */
export function albums(state = {}, action) {
  switch (action.type) {
    case ADD_ALBUM: {
      const newAlbumId = action.album.id;
      const newPhoto = {
        [newAlbumId]: Object.assign({}, state[newAlbumId], action.album)
      };
      return {...state, ...newPhoto};
    }
    case REMOVE_ALBUM: {
      const newState = Object.assign({}, state);
      const albumId = action.albumId;
      delete newState[albumId];
      return newState;
    }
    case UPDATE_ALBUM_NAME: {
      const newState = Object.assign({}, state);
      const {name, albumId} = action;
      newState[albumId] = {
        ...newState[albumId],
        name
      };
      return newState;
    }
    case ADD_PHOTO_TO_ALBUM: {
      const newState = Object.assign({}, state);
      const {albumId, photoId, relationshipId} = action;
      newState[albumId] = {
        ...newState[albumId],
        photos: [...newState[albumId].photos, photoId],
        relationships: {
          ...newState[albumId].relationships,
          [photoId]: relationshipId
        }
      };
      return newState;
    }
    case REMOVE_PHOTO_FROM_ALBUM: {
      const newState = Object.assign({}, state);
      const {albumId, photoId} = action;
      const albumPhotos = newState[albumId].photos.slice();
      const albumRelationships = newState[albumId].relationships;
      albumPhotos.splice(albumPhotos.indexOf(photoId), 1);
      newState[albumId].photos = albumPhotos;
      delete albumRelationships[photoId];
      return newState;
    }
    case UPDATE_ALBUM_TRACKABLE: {
      const newState = Object.assign({}, state);
      const {albumId, trackable} = action;
      newState[albumId].trackable = {
        createTime: new Date(trackable.createTime.iso8601),
        lastModifiedTime: new Date(trackable.lastModifiedTime.iso8601),
        uploadUserId: trackable.createUserId
      };
      return newState;
    }
    // TODO: handle the case when photo is removed from all albums
    default: {
      return state;
    }
  }
}
