

export const ResourceType = {
  Space: 'space',
  Asset: 'asset'
};

/**
 * Urn = urn:adsk.udp:[env]:[ace_ce_identifier_guid]:[resourceType]:[optionalSpaceGuid.resourceGuid]
 */
export class Urn {
  /**
   * @constructor
   * @param {String} env UDP environment
   * @param {String} resourceType TypeId of the resource
   * @param {String} resourceGuid Guid of the resource
   * @param {String} spaceGuid Guid of the space
   * @param {String} scopeGuid Guid of the scope
   */
  constructor(env, resourceType, resourceGuid, spaceGuid, scopeGuid) {
    this.env = env;
    this.resourceType = resourceType;
    this.resourceGuid = resourceGuid;
    this.spaceGuid = spaceGuid;
    this.scopeGuid = scopeGuid;
  }

  /**
   * Parses a urn String into a Urn object
   * @param {String} urn urn to parse
   * @return {Urn} parsed result
   */
  static parse(urn) {
    if (urn === undefined || urn === null) {
      return undefined;
    }

    const components = urn.split(':');
    // scope urn
    if (components.length === 5 && `${components[0]}:${components[1]}` === 'urn:adsk.ace.stg') {
      return new Urn(components[2], components[3], components[4]);
    }
    if (components.length !== 6) {
      return undefined;
    }

    if (`${components[0]}:${components[1]}` !== Urn.ASSIGNED_NAME) {
      return undefined;
    }
    let resourceGuid = components[5];
    let spaceGuid;

    const spaceResourceGuids = components[5].split('.');
    if (spaceResourceGuids.length === 2) {
      spaceGuid = spaceResourceGuids[0];
      resourceGuid = spaceResourceGuids[1];
    }

    return new Urn(components[2], components[4], resourceGuid, spaceGuid, components[3]);
  }

  /**
   * Creates a new Urn for a UDP space
   * @param {String} env UDP environment
   * @param {String} scopeGuid UDP scope
   * @param {String} resourceId Id of the resource
   * @return {Urn} Urn from parameters
   */
  static generateSpaceUrn(env, scopeGuid, resourceId) {
    return new Urn(env, ResourceType.Space, resourceId, undefined, scopeGuid);
  }

  /**
   * Creates a Urn for an asset in a UDP space
   * @param {Urn} spaceUrn Urn of the current space
   * @param {String} assetResourceGuid HFDM guid of the resource
   * @return {Urn} Urn from parameters
   */
  static generateAssetUrn(spaceUrn, assetResourceGuid) {
    return new Urn(spaceUrn.env, ResourceType.Asset, assetResourceGuid, spaceUrn.resourceGuid, spaceUrn.scopeGuid);
  }

  /**
   * Creates a Urn for a relationship in a UDP space
   * @param {Urn} spaceUrn Urn of the current space
   * @param {String} relationshipResourceGuid HFDM guid of the resource
   * @return {Urn} Urn from parameters
   */
  static generateRelationshipUrn(spaceUrn, relationshipResourceGuid) {
    return new Urn(spaceUrn.env,
      ResourceType.Asset, relationshipResourceGuid, spaceUrn.resourceGuid, spaceUrn.scopeGuid);
  }

  /**
   * Creates a Urn
   * @param {String} env UDP environment
   * @param {String} resourceType TypeId of a resource
   * @param {String} spaceGuid HFDM guid of the space
   * @param {String} resourceId Id of the resource
   * @param {String} scopeGuid UDP scope
   * @return {Urn} the generated Urn
   */
  static generateUrn(env, resourceType, spaceGuid, resourceId, scopeGuid) {
    if (resourceType === ResourceType.Asset) {
      return new Urn(env, resourceType, resourceId, spaceGuid, scopeGuid);
    }
    return null;
  }

  /**
   * Check if a given key is a valid Urn
   * @param {String} key input key
   * @return {Boolean} True if valid, False if undefined or null
   */
  static isValidUrn(key) {
    const urn = Urn.parse(key);
    return (urn !== undefined && urn !== null);
  }

  /**
   * Given the URN of an asset, the space URN is obtained from it.
   * @param {Urn} assetUrn The URN of an asset
   * @return {Urn} output
   */
  static getAssetSpaceUrn(assetUrn) {
    return new Urn(assetUrn.env, ResourceType.Space, assetUrn.spaceGuid, undefined, assetUrn.scopeGuid);
  }

  /**
   * Generate the HFDM branch Urn for the resource
   * @param {String} guid Global unique identifier of a resource
   * @return {String} converted guid
   */
  static toHFDMBranchUrn(guid) {
    return `urn:adsk.lynx:branch:${guid}`;
  }

  /**
   * Extract the global unique id of the resource from the branchUrn.
   * @param {String} branchUrn HFDM branch Urn
   * @return {String} unique id of the branch
   */
  static parseGuidFromHFDMBranchUrn(branchUrn) {
    return branchUrn.substr('urn:adsk.lynx:branch:'.length);
  }

  /**
   * Converts a Urn to String
   * @return {String} the conversion result
   */
  toString() {
    const base = `${Urn.ASSIGNED_NAME}:${this.env}:${this.scopeGuid}:${this.resourceType}`;

    if (this.spaceGuid === undefined || this.spaceGuid === null) {
      return `${base}:${this.resourceGuid}`;
    } else {
      return `${base}:${this.spaceGuid}.${this.resourceGuid}`;
    }
  }
}

Urn.ASSIGNED_NAME = 'urn:adsk.udp';
