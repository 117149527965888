import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { SelectDropdown } from '@adsk/bim360-matrix-react-components';

import T from '~/common/i18n';
import {FILTERS} from '~/common/constants';

const { PHOTOS_PRIVACY } = FILTERS;


/**
 * React component that represent the photo privacy filter.
 * @public
 */
export class PrivacyDropdown extends React.Component {

  /**
   * @inheritdoc
   */
  constructor(props) {
    super(props);
    const privacyDropdownOptions = [PHOTOS_PRIVACY.PRIVATE, PHOTOS_PRIVACY.PUBLIC];
    this._options = privacyDropdownOptions.map(filter => {
      return {
        label: T.translate(`filterControl.filters.privacyOptions.${filter}`),
        value: filter
      };
    });
  }

  /**
   * PrivacyDropdown Component
   * @public
   * @return {React.Node} A dropdown component with options for photos privacy.
   */
  render() {
    const {selectedPrivacy, onChange} = this.props;

    const selectedOptions = _.find(this._options, option => {
      return option.value === selectedPrivacy;
    });

    return (
      <SelectDropdown
        id="filter-privacy"
        options={this._options}
        clearable
        selectedOptions={selectedOptions}
        placeholder={T.translate('filterControl.filters.allText')}
        onChange={onChange}
      />
    );
  }
}

PrivacyDropdown.propTypes = {
  selectedPrivacy: PropTypes.string,
  onChange: PropTypes.func
};
