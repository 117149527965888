/**
 * Object containing reserved names
 * used in internal bookkeeping structures
 */
export const ReservedPaths = {
  root: 'root',
  assets: 'assets',
  components: 'components',
  relationships: 'relationships'
};

export const RelationshipDirection = {
  Outgoing: 'out',
  Incoming: 'in',
  Bidirectional: 'in-out'
};
