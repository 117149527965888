import config from 'appConfig';
import {
  PhotosWorkspaceConfiguration,
  PhotosHFDMConnectionConfiguration,
  BIM360AnalyticsConfiguration
} from '~/appfw/components/configuration';
import { LOG_LEVELS, SUPPORTED_TARGETS } from '@adsk/bim360-analytics-sdk-js';

const BIMDocsConfiguration = {
  baseUrl: config.ORIGINS.DOCS,
  sdkConfig: config.DOCS_SDK_CONFIG,
  apigee: config.ORIGINS.APIGEE_BACKEND,
  backEnd: config.DOCS_BACKEND,
  hq: config.APIGEE_SERVICES && config.APIGEE_SERVICES.HQ
};

const forgeConfiguration = {
  environmentBaseUrl: config.ORIGINS.APIGEE_BACKEND,
  oxygenUrl: config.ORIGINS.OXYGEN,
  issuesEndpoint: config.ISSUES_ENDPOINT,
  rfisEndpoint: config.RFIS_ENDPOINT,
  checklistsEndpoint: config.CHECKLISTS_ENDPOINT,
  lbsEndpoint: config.LBS_ENDPOINT
};

const ActivityLogConfiguration = {
  activityPath: config.ACTIVITY.ENDPOINT,
  generatorID: config.ACTIVITY.GENERATOR_ID
};

const AnalyticsConfiguration = {
  featureModule: 'Docs',
  moduleName: config.ANALYTICS.MODULE_NAME,
  targets: {[SUPPORTED_TARGETS.MIXPANEL]: {token: config.ANALYTICS.MIXPANEL.TOKEN}},
  logLevel: config.ANALYTICS.ENV.toLowerCase() !== 'prod' ? LOG_LEVELS.INFO : LOG_LEVELS.ERROR,
  environment: config.ANALYTICS.ENV,
  region: config.ANALYTICS.REGION,
  disableAutoSessionStartEvent: true
};

const appConfigurations = [
  {type: 'HFDMConnectionConfiguration', useClass: PhotosHFDMConnectionConfiguration},
  {type: 'HFDMWorkspaceConfiguration', useClass: PhotosWorkspaceConfiguration},
  {type: 'ForgeConfiguration', useValue: forgeConfiguration},
  {type: 'BIMDocsConfiguration', useValue: BIMDocsConfiguration},
  {type: 'ActivityLogConfiguration', useValue: ActivityLogConfiguration},
  {type: 'BIM360AnalyticsConfiguration', useClass: BIM360AnalyticsConfiguration}
];

const appParams = [
  {type: 'HFDMConnectionConfiguration', withHeaders: config.ENV_NAME !== 'qa'},
  {type: 'BIM360AnalyticsConfiguration', ...AnalyticsConfiguration}
];

export {appConfigurations, appParams};
