import {PhotoAssetDataView} from './photoAssetDataView.appfw';
import { connect } from 'react-redux';

const mapStateToProps = state => {
  const photoId = state.photoViewer.activePhotoId;
  const sources = state.photosSources[photoId];
  const projectId = state.authenticationData.currentProjectEntitlement.projects[0].id;
  const activeAlbum = state.activeAlbum;

  return {
    photoId,
    sources,
    projectId,
    activeAlbum,
    key: photoId,
    showPrivacyFeature: state.features.showPrivacy
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

const PhotoDataEditor = connect(
  mapStateToProps,
  mapDispatchToProps
)(PhotoAssetDataView);

export default PhotoDataEditor;
