import { connect } from 'react-redux';
import {
  addPhotosToAlbum,
  uploadPhotos
} from '~/redux/actions/photosActions';
import {AlbumItem} from './albumItem.ui';

const mapDispatchToProps = dispatch => {
  return {
    uploadPhotos: (files, albumGuid) => {
      dispatch(uploadPhotos(files, albumGuid));
    },
    addPhotosToAlbum: (photoId, albumId) => {
      dispatch(addPhotosToAlbum(photoId, albumId));
    }
  };
};

export const PhotoAlbumItem = connect(
  null,
  mapDispatchToProps
)(AlbumItem);

