import {
  withAppComponent
} from '@adsk/forge-appfw-react';
import { IssueFormUI } from './issueForm.ui';

/**
 * A react component for the issues form.
 * @public
 */
export const IssueForm = withAppComponent({
  instances: {
    users: {type: 'UsersComponent'},
    locations: {type: 'LocationsComponent'},
    issues: {type: 'IssuesComponent'}
  },
  mapAppComponents: ({users, locations, issues}) => {
    return {
      users: users.getUsers(),
      locations: locations.getPhotosLocationsMap,
      issueTypes: issues.getIssueTypesMap()
    };
  }
})(IssueFormUI);
