import { FilesDropZone } from './galleryDropzone.ui';
import { connect } from 'react-redux';
import {
  uploadPhotos
} from '~/redux/actions/photosActions';

const mapDispatchToProps = dispatch => {
  return {
    uploadPhotos: (files, albumId) => {
      dispatch(uploadPhotos(files, albumId));
    }
  };
};


const mapStateToProps = state => {
  const albumId = state.activeAlbum;
  return {
    albumId
  };
};

const galleryDropzone = connect(
  mapStateToProps,
  mapDispatchToProps
)(FilesDropZone);

export default galleryDropzone;
