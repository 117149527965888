import { AppComponent } from '@adsk/forge-appfw-di';
import _ from 'lodash';
import {
  MANDATORY_SUPER_PROPERTIES, MANDATORY_SUPER_PROPERTIES_ALLOWED_VALUES
} from '@adsk/bim360-analytics-sdk-js';


/**
 * A component that can fetch parameters required for initializing BIM360 Analytics SDK.
 */
export class BIM360AnalyticsConfiguration extends AppComponent {
  /**
   * @inheritdoc
   */
  static defineDependencies() {
    return [
      {
        type: 'AuthenticationDataComponent',
        as: 'authData'
      }
    ];
  }

  /**
   * BIM360 Analytics configuration.
   * @return {Object} The required analytics parameters to initialize the Analytics SDK.
   */
  getConfig() {
    return this._config;
  }

  /**
   * @inheritDoc
   */
  initialize(dependencies, parameters) {
    const {authData} = dependencies;
    const {
      user,
      currentProjectEntitlement,
      projectsEntitlements
    } = authData.getAuthenticationData();

    const {environment, moduleName, targets, region,
      logLevel, featureModule, disableAutoSessionStartEvent} = parameters;
    const currentProject = _.find(projectsEntitlements.projects,
      ({id}) => id === currentProjectEntitlement.currentProjectId);

    this._config = {
      disableAutoSessionStartEvent,
      moduleName,
      targets,
      logLevel,
      analyticsId: user.analyticsId,
      mandatorySuperProps: {
        [MANDATORY_SUPER_PROPERTIES.FEATURE_MODULE]: featureModule,
        [MANDATORY_SUPER_PROPERTIES.PLATFORM]: MANDATORY_SUPER_PROPERTIES_ALLOWED_VALUES.PLATFORM.WEB,
        [MANDATORY_SUPER_PROPERTIES.ENVIRONMENT]: environment,
        [MANDATORY_SUPER_PROPERTIES.DATA_CENTER]: region,
        [MANDATORY_SUPER_PROPERTIES.ACCOUNT_ID]: currentProject.accountId,
        [MANDATORY_SUPER_PROPERTIES.ACCOUNT_NAME]: currentProject.accountName,
        [MANDATORY_SUPER_PROPERTIES.PROJECT_ID]: currentProject.id,
        [MANDATORY_SUPER_PROPERTIES.PROJECT_NAME]: currentProject.name,
        [MANDATORY_SUPER_PROPERTIES.USED_LANGUAGE_CODE]: currentProjectEntitlement.locale
      },
      mandatoryPeopleProps: {
        userMail: user.email,
        firstName: user.firstName,
        lastName: user.lastName
      }
    };
  }
}
