import Header from './header.ui';
import {
  withAppComponent
} from '@adsk/forge-appfw-react';

export const PhotoHeader = withAppComponent({
  instances: {
    authDataComponent: {type: 'AuthenticationDataComponent'},
    forgeConfigs: {type: 'ForgeConfiguration'}
  },
  mapAppComponents: ({authDataComponent, forgeConfigs}) => {
    const docsConfigs = authDataComponent.getAuthenticationComponent().getConfiguration();
    const authData = authDataComponent.getAuthenticationData();

    return {
      userData: authData.user,
      currentProject: authData.currentProjectEntitlement.projects[0],
      accounts: authData.accountEntitlements.accounts,
      projects: authData.projectsEntitlements.projects,
      docsConfigs: docsConfigs,
      forgeConfigs: forgeConfigs
    };
  }
})(Header);
