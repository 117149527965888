import {
  ADD_ALBUM,
  REMOVE_ALBUM,
  UPDATE_ALBUM_NAME,
  UPDATE_ALBUM_TRACKABLE,
  ADD_PHOTO_TO_ALBUM,
  REMOVE_PHOTO_FROM_ALBUM,
  SET_ACTIVE_ALBUM
} from '~/common/constants';


/**
 * @param {Object} album The added album object
 * @param {String} album.id The album id
 * @param {String} album.name The album name
 * @return {object} A redux action
 */
export function addAlbum(album) {
  return {
    type: ADD_ALBUM,
    album
  };
}

/**
 * @param {string} albumId The album asset id.
 * @return {object} A redux action
 */
export function removeAlbum(albumId) {
  return ({
    type: REMOVE_ALBUM,
    albumId
  });
}

/**
 * @param {string} albumId - The album id
 * @param {string} name - The album name
 * @return {object} A redux action
 */
export function updateAlbumName(albumId, name) {
  return ({
    type: UPDATE_ALBUM_NAME,
    albumId,
    name
  });
}

/**
 *
 * @param {string} albumId An album id.
 * @param {object} trackable - The album trackable object (check the schema specification
 * autodesk.bim360:components.trackable-{*})
 * @return {object} A redux action
 */
export function updateAlbumTrackable(albumId, trackable) {
  return ({
    type: UPDATE_ALBUM_TRACKABLE,
    albumId,
    trackable
  });
}

/**
 * @param {string} albumId An album id.
 * @param {string} photoId A photo id.
 * @param {string} relationshipId The relation id between the given photo and album.
 * @return {object} A redux action
 */
export function addPhotoToAlbum(albumId, photoId, relationshipId) {
  return ({
    type: ADD_PHOTO_TO_ALBUM,
    albumId,
    photoId,
    relationshipId
  });
}

/**
 * @param {string} photoId A photo id.
 * @param {string} albumId An album id.
 * @return {object} A redux action
 */
export function removePhotoFromAlbum(photoId, albumId) {
  return ({
    type: REMOVE_PHOTO_FROM_ALBUM,
    albumId,
    photoId
  });
}

/**
 * @param {string} albumId An album id.
 * @return {object} A redux action
 */
export function setActiveAlbum(albumId) {
  return ({
    type: SET_ACTIVE_ALBUM,
    albumId
  });
}
