import React, { Component } from 'react';
import { DragLayer as dragLayer } from 'react-dnd';
import PhotoDragPreview from './photoDragPreview.ui';
import { UPLOADED, FETCHED, FETCHING, FETCH_FAILED } from '~/common/constants';
import { NativeTypes } from 'react-dnd-html5-backend';
const { FILE } = NativeTypes;

/**
 * React component for the PhotoDragLayer
 * @public
 */
class PhotoDragLayer extends Component {

  /**
   * @inheritdoc
   */
  componentDidUpdate() {
    const { item, itemType, photosSelection, isDragging } = this.props;
    if (isDragging) {
      const selectedPhotoIds = Object.keys(photosSelection).filter(photoId => photosSelection[photoId]);

      if (selectedPhotoIds.length === 0 && item.photoId && itemType !== FILE) {
        selectedPhotoIds.push(item.photoId);
      }

      selectedPhotoIds.forEach(photoId => {
        if (this.props.getThumbnailUploadStatus(photoId).status === UPLOADED &&
          ![FETCHING, FETCHED, FETCH_FAILED].includes(this.props.getThumbnailFetchStatus(photoId).status)
          ) {
          this.props.fetchThumbnail(photoId);
        }
      });
    }
  }

  /**
   * @inheritdoc
   */
  shouldComponentUpdate(nextProps) {
    const { item, itemType, currentOffset, isDragging } = this.props;
    return item !== nextProps.item ||
        itemType !== nextProps.itemType ||
        currentOffset !== nextProps.currentOffset ||
        isDragging !== nextProps.isDragging;
  }

  /**
   * Returns layer to show
   * @public
   * @param {string} type dragging element
   * @param {string} item dragging data
   * @param {object} currentOffset of the mouse
   * @return {PhotoElement} Element
   */
  renderItem(type, item, currentOffset) {
    const photosSelection = this.props.photosSelection;
    const selectedPhotoIds = Object.keys(photosSelection).filter(photoId => photosSelection[photoId]);

    if (selectedPhotoIds.length === 0) {
      selectedPhotoIds.push(item.photoId);
    }

    switch (type) {
      case 'PHOTO_ELEMENT': {
        return (
          <PhotoDragPreview
            photos={selectedPhotoIds}
            currentOffset={currentOffset}
          />
        );
      }
      default:
        return null;
    }
  }

  /**
   * Get computed styles for the drag layer
   * @public
   * @param {object} currentOffset of the item
   * @return {object} styles for the drag layer
   */
  getItemStyles(currentOffset) {
    if (!currentOffset) {
      return {
        display: 'none'
      };
    }
    const { x, y } = currentOffset;
    const transform = `translate(${x}px, ${y}px)`;
    return {
      transform: transform,
      WebkitTransform: transform
    };
  }

  /**
   * React rendering of the PhotoDragLayer
   * @public
   * @return {PhotoElement} Element
   */
  render() {
    const { item, itemType, currentOffset, isDragging } = this.props;
    return (isDragging ?
      <div className="photoDragLayer">
        <div style={this.getItemStyles(currentOffset)}>
          {this.renderItem(itemType, item, currentOffset)}
        </div>
      </div> : null
    );
  }
}

const collect = monitor => ({
  item: monitor.getItem(),
  itemType: monitor.getItemType(),
  currentOffset: monitor.getSourceClientOffset(),
  isDragging: monitor.isDragging()
});

export default dragLayer(collect)(PhotoDragLayer);
