import { connect } from 'react-redux';
import { updateFilter } from '~/redux/actions/photosActions';
import { INIT_FILTERS } from '~/common/constants';
import FilterWarning  from './filterWarning.ui';

const mapDispatchToProps = dispatch => {
  return {
    clearFilter: () => {
      dispatch(updateFilter(INIT_FILTERS));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(FilterWarning);
