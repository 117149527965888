
import { requestHTTP } from './utils';
import config from 'appConfig';

/**
 * Creates storage for the image file
 * @param {String} fileName name of the file
 * @param {Function} tokenProvider The function to get the bearer token in a promise
 * @param {String} projectId the id of the project
 * @return {Promise<JSON>} The response of the storage request.
 */
const createStorageRequest = (fileName, tokenProvider, projectId) => {
  return tokenProvider().then(({token}) => requestHTTP(
    `${config.ORIGINS.APIGEE_BACKEND}/${config.DOCS_BACKEND}/v2/projects/${projectId}/storage`,
    {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    'POST',
    JSON.stringify({
      fileName
    })
  ));
};

/**
 * Uploads the file to the Bim360 storage service
 * @param {File} file the file to upload
 * @param {JSON} storageResponse The response from storage request to say where the file should be stored
 * @param {Function} tokenProvider The function to get the bearer token in a promise
 * @return {JSON} Response from OSS PUT request.
 */
const uploadFileToOss = (file, storageResponse, tokenProvider) => {
  const storageResponseJson = JSON.parse(storageResponse);
  const storageUrn = storageResponseJson['objectId'];
  // prepare data for the upload file endpoint in oss api
  const data = {
    ossBucketKey: storageUrn.substring(storageUrn.lastIndexOf(':') + 1, storageUrn.lastIndexOf('/')),
    ossObjectKey: storageUrn.substring(storageUrn.lastIndexOf('/') + 1, storageUrn.length)
  };

  return tokenProvider().then(({token}) => requestHTTP(
    `${config.ORIGINS.APIGEE_BACKEND}/oss/v2/buckets/${data.ossBucketKey}/objects/${data.ossObjectKey}`,
    {
      'Content-Type': 'text/plain; charset=UTF-8',
      'Authorization': `Bearer ${token}`
    },
    'PUT',
    file
  ));
};

/**
 * Generates the Object for the command processor parameter
 * @param {String} fileName Name of the file to upload
 * @param {String} folderUrn URN of the docs folder to attach to
 * @param {String} objectId ID of the storage object
 * @return {Object} the Data to request in the command processor
 */
const getCommandsRequestData = (fileName, folderUrn, objectId) => {
  return {
    data: {
      type: 'commands',
      attributes: {
        extension: {
          type: 'commands:autodesk.core:Upload',
          version: '1.0.0'
        }
      },
      relationships: {
        resources: {
          data: [{type: 'versions', id: '1'}]
        }
      }
    },
    included: [
      {
        type: 'items',
        id: '2',
        attributes: {
          extension: {
            type: 'items:autodesk.bim360:File',
            version: '1.0'
          }
        },
        relationships: {
          tip: {
            data: {type: 'versions', id: '1'}
          },
          parent: {
            data: {type: 'folders', id: folderUrn}
          }
        }
      },
      {
        type: 'versions',
        id: '1',
        attributes: {
          name: fileName,
          extension: {
            type: 'versions:autodesk.bim360:File',
            version: '1.0'
          }
        },
        relationships: {
          storage: {
            data: {type: 'objects', id: objectId}
          }
        }
      }
    ]
  };
};

/**
 * Handles getting the URN of the photos folder in DOCS
 * @param {String} projectId The id of the project to get the URN from
 * @param {Function} tokenProvider The function to get the bearer token in a promise
 * @return {Promise<String>} A promise that resolves to the urn of the photos folder
 */
const getPhotosFolderUrn = (projectId, tokenProvider) => {
  return tokenProvider()
  .then(({token}) =>
    requestHTTP(
      `${config.ORIGINS.APIGEE_BACKEND}/${config.DOCS_ENDPOINT}/projects`,
      {
        'Authorization': 'Bearer ' + token
      }
    )
  )
  .then( response => {
    const parsedProjects = JSON.parse(response).projects;
    const currentProject = parsedProjects.find( project => {
      return project.id === projectId;
    });
    return Promise.resolve(currentProject.photos_folder_urn);
  });
};

/**
 * Creates the command to request to the command processor.
 * @param {JSON} ossResponse The response of the storage request
 * @param {String} projectId The Id of the project to make the command for
 * @param {Function} tokenProvider The function to get the bearer token in a promise
 * @return {Promise<JSON>} A promise resolving to the response of the command creation request
 */
const createCommandRequest = (ossResponse, projectId, tokenProvider) => {
  const ossResponseJson = JSON.parse(ossResponse);
  const objectId = ossResponseJson.objectId;
  const objectKey = ossResponseJson.objectKey;

  return Promise.all([
    tokenProvider(),
    getPhotosFolderUrn(projectId, tokenProvider)
  ])
  .then( ([{token}, photosFolderUrn]) => {
    const commandsRequestData = getCommandsRequestData(objectKey, photosFolderUrn, objectId);
    return requestHTTP(
      `${config.ORIGINS.APIGEE_BACKEND}/${config.CP_ENDPOINT}/projects/${projectId}/commands`,
      {
        'Content-Type': 'application/vnd.api+json',
        'Authorization': `Bearer ${token}`
      },
      'POST',
      JSON.stringify(commandsRequestData)
    );
  });
};

/**
 * A promisified version of setTimeout
 * @param {Number} ms Number of milliseconds to wait before resolving.
 * @return {Promise} The promise that resolves after the specified time in millis.
 */
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

/**
 * A function that monitors the status of the command created on the command processor.
 * @param {String} commandId the id of the command to monitor
 * @param {String} projectId the id of the project the command was created for
 * @param {Function} tokenProvider The function to get the bearer token in a promise
 * @param {Function} resolve The callback to use when the command succeeds
 * @param {Function} reject the callback to use when the command fails
 */
const monitorCommandStatus = (commandId, projectId, tokenProvider, resolve, reject) => {

  tokenProvider().then(({token}) => requestHTTP(
    `${config.ORIGINS.APIGEE_BACKEND}/${config.CP_ENDPOINT}/projects/${projectId}/commands/${commandId}`,
    {
      'Content-Type': 'application/vnd.api+json',
      'Authorization': `Bearer ${token}`
    }
  )).then(commandStatusResponse => {
    const commandStatus = JSON.parse(commandStatusResponse);
    const status = commandStatus.data.attributes.status;
    const commandsStatusCompleted = status === 'committed' || status === 'completed';
    if (commandsStatusCompleted) {
      resolve();
    } else if (status === 'failed') {
      reject();
    } else {
      sleep(1000).then(() =>
        monitorCommandStatus(commandId, projectId, tokenProvider, resolve, reject));
    }
  });
};

/**
 * A helper to create the command monitor
 * @param {JSON} commandResponse Response from creating the command to find the command.
 * @param {String} projectId The Id of the project the command was created for
 * @param {Function} tokenProvider The function to get the bearer token in a promise
 * @return {Promise} The promise that will resolve with the result of the command.
 */
const createMonitorCommandStatusPromise = (commandResponse, projectId, tokenProvider) => {
  return new Promise((resolve, reject) => {
    // extract the id of the command, we use it later to query the command status
    const commandId = JSON.parse(commandResponse).data.id;
    monitorCommandStatus(commandId, projectId, tokenProvider, resolve, reject);
  });
};

/**
 * The function that handles attaching all the photos to the created issue
 * @param {Object} options The parameters for this function:
 * @param {Array<Object>} options.fileWrappers The wrappers containing the fetched file promise, filename, and callback
 * @param {JSON} options.response the response from creating an issue
 * @param {String} options.projectId the Id of the project to attach the photo to the issue
 * @param {Function} options.getToken The function to get the bearer token in a promise
 * @param {JSON} options.forgeConfig the config files of the project
 * @param {Promise<String>} options.issuesContainer the promise containing the issuesContainer string
 * @return {Promise} Promise that resolves when all files were attached to the issue
 */
const attachFilesToIssue = options => {
  const {fileWrappers, response, projectId, getToken, forgeConfig, issuesContainer} = options;
  if (!fileWrappers || !projectId) {
    return Promise.reject();
  }

  const issueResponse = JSON.parse(response);
  if ( !issueResponse.data ) {
    return Promise.reject();
  }

  return Promise.all(fileWrappers.map(fileWrapper => {
    const fileName = fileWrapper.name;
    const filePromise = fileWrapper.file;
    // The following functions are used to handle the changes in HFDM
    const linkPhotoToIssueFunction = fileWrapper.linkPhotoToIssueFunction;
    const linkPhotoToStorageFunction = fileWrapper.linkPhotoToStorageFunction;

    const ossBucketUrn = fileWrapper.ossUrn;

    let ossPromise,
        monitorPromise;

    if (ossBucketUrn) {
      // Already have a bucket, just need to use it.
      ossPromise = Promise.resolve(
        JSON.stringify({
          objectId: ossBucketUrn
        })
      );
      monitorPromise = Promise.resolve();
    } else {
      ossPromise = Promise.all([
        createStorageRequest(fileName, getToken, projectId),
        filePromise
      ])
      .then(([storageResponse, file]) =>
        uploadFileToOss(file, storageResponse, getToken)
      );

      monitorPromise = ossPromise
      .then(uploadFileToOssResponse =>
        createCommandRequest(uploadFileToOssResponse, projectId, getToken)
      )
      .then(commandResponse =>
        createMonitorCommandStatusPromise(commandResponse, projectId, getToken)
      );

      // Add the photo to a storage asset and create the relationship.
      Promise.all([ ossPromise, monitorPromise ])
      .then( ([ ossResponse ]) => {
        const ossUrn = JSON.parse(ossResponse).objectId;
        linkPhotoToStorageFunction(ossUrn);
        return Promise.resolve();
      });
    }

    return Promise.all([
      getToken(),
      ossPromise,
      issuesContainer,
      monitorPromise
    ]).then( ([{token}, ossResponse, containerId]) => {
      const data = issueResponse.data;
      const issueId = data.id;
      const objectId = JSON.parse(ossResponse).objectId;

      return requestHTTP(
        `${forgeConfig.environmentBaseUrl}/${forgeConfig.issuesEndpoint}/` +
          `${containerId}/attachments`,
        {
          'Content-Type': 'application/vnd.api+json',
          'Authorization': `Bearer ${token}`
        },
        'POST',
        JSON.stringify( {
          data: {
            type: 'attachments',
            attributes: {
              name: fileName,
              urn: objectId,
              urn_type: 'oss',
              issue_id: issueId,
              attachment_type: 'photo'
            }
          }
        })
      ).then(() => {
        return linkPhotoToIssueFunction(issueId);
      });
    })
    .catch(err => {
      console.error(err);
    });
  }));
};

/**
 * Posts a new issue to the issues API
 * @param {Object} issueData the payload of the post request
 * @param {Function} getToken function that handles getting the bearer token.
 * @param {Promise} issuesContainer a promise
 * @param {Object} forgeConfig the forge configurations
 * @return {Promise} - Promise with the response
 */
export const createIssue = (issueData, getToken, issuesContainer, forgeConfig) => {
  const issueTypesUrlPromise = issuesContainer.then(issuesContainerId =>
    `${forgeConfig.environmentBaseUrl}/${forgeConfig.issuesEndpoint}/` +
    `${issuesContainerId}/quality-issues`);

  return Promise.all([issueTypesUrlPromise, getToken()])
  .then(([issueTypesUrl, accessToken]) => {
    return requestHTTP(
      issueTypesUrl,
      {
        'Authorization': 'Bearer ' + accessToken.token,
        'Content-Type': 'application/vnd.api+json'
      },
      'POST',
      JSON.stringify({
        data: {
          type: 'quality_issues',
          attributes: {
            title: issueData.title,
            ng_issue_type_id: issueData.ng_issue_type_id,
            ng_issue_subtype_id: issueData.ng_issue_subtype_id,
            lbs_location: issueData.lbs_location,
            'assigned_to': issueData.assigned_to,
            'assigned_to_type': issueData.assigned_to ? 'user' : undefined
          }
        }
      })
    );
  });
};

/**
 * Function to create an issue and then attach all of the photos to the issue
 * @param {Object} options The form submission data ass well as all that is needed to create and attach issues.
 * @return {Promise} The promise that resolves when creation of the photos is completed.
 */
const createIssueFromPhotos = options => {
  const {
    issueData,
    files,
    bearerFunction,
    issuesContainer,
    currentProjectId,
    forgeConfig
  } = options;
  return Promise.resolve()
  .then(() =>
    createIssue(issueData, bearerFunction, issuesContainer, forgeConfig)
  )
  .then(response =>
    attachFilesToIssue({
      fileWrappers: files,
      response,
      projectId: currentProjectId,
      getToken: bearerFunction,
      forgeConfig,
      issuesContainer
    })
  );
};

export { createIssueFromPhotos };
