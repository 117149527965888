import {DataBinding} from '@adsk/forge-appfw-databinder';
import {
  HFDM_BINARY_UPLOAD_STATUS_MAP
} from '~/common/constants';

import {
  photoRemoved,
  photoRestored,
  updateUploadStatus,
  updateImageComposition,
  updateTrackable,
  updatePhotoData,
  updatePhotoLocationData,
  addPhoto,
  updateThumbnailUploadStatus,
  setPhotoPrivacy
} from '~/redux/actions/photosActions';
import {setSources} from '~/redux/actions/sourcesActions';
import {photoAsset} from '~/resources/schemas/assets';
import _ from 'lodash';
import moment from 'moment';

const PHOTOS_COMPONENTS = {};
photoAsset.properties.forEach(prop => {PHOTOS_COMPONENTS[prop.id] = prop.typeid;});

/**
 * The data binding for the images. An instance will be created for every photo property in the _workspace.
 * The onModify() and onRemove() call react to the property being modified or remove from the _workspace.
 * TODO: Ideally this Binding should be a SingletonBinding. Thus, please keep it stateless as it is, this will make
 * using SingletonBindings much easier.
 * @extends external:DataBinding
 */
export class PhotoDataBinding extends DataBinding {

  /** Triggered when the deleted property is modified.
   * @param {ModificationContext} in_modificationContext the photo's deleted modification context
   * **/
  onDeletedUpdate(in_modificationContext) {
    const {store} = this.getUserData();
    const property = in_modificationContext.getProperty();
    if (!in_modificationContext.isSimulated()) {
      if (property.getValue()) {
        store.dispatch(photoRemoved([this.image.id]));
      } else {
        store.dispatch(photoRestored([this.image.id]));
      }
    }
  }

  /** Update the status property of an image
   * @param {ModificationContext} in_modificationContext the modification context of the photo binary property status.
   */
  updateImageStatus(in_modificationContext) {
    const property = in_modificationContext.getProperty();
    const {store} = this.getUserData();
    const status = HFDM_BINARY_UPLOAD_STATUS_MAP[property.getEnumString()];
    if (!in_modificationContext.isSimulated()) {
      store.dispatch(updateUploadStatus(this.image.id, status));
    }
  }

  /** Update the status property of a thumbnail
   * @param {ModificationContext} in_modificationContext the modification context of the thumbnail binary property
   * status.
   */
  updateThumbnailStatus(in_modificationContext) {
    const {store} = this.getUserData();
    const property = in_modificationContext.getProperty();
    const status = HFDM_BINARY_UPLOAD_STATUS_MAP[property.getEnumString()];
    if (!in_modificationContext.isSimulated()) {
      store.dispatch(updateThumbnailUploadStatus(this.image.id, status));
    }
  }

  /** Triggered when the asset trackable is updated.
   * @param {ModificationContext} in_modificationContext - The asset's trackable modification context.
   */
  onImageTrackableUpdate(in_modificationContext) {
    const property = in_modificationContext.getProperty();
    const {store} = this.getUserData();
    store.dispatch(updateTrackable(this.image.id, property.getValues()));
  }

  /**
   * Triggered when there are new modifications to the photo
   * @param {ModificationContext} in_modificationContext The assets Composition modification context.
   */
  onImageCompositionUpdate(in_modificationContext) {
    const {store} = this.getUserData();
    const property = in_modificationContext.getProperty();
    if (!in_modificationContext.isSimulated()) {
      store.dispatch(updateImageComposition(this.image.id, {
        rotation: property.get('rotation').value
      }));
    }
  }

  /**
   * Triggered when the format is updated
   * @param {ModificationContext} in_modificationContext The asset's composition modification context.
   */
  onPhotoUpdate(in_modificationContext) {
    const property = in_modificationContext.getProperty();
    const {store} = this.getUserData();
    store.dispatch(updatePhotoData(this.image.id, property.getValues()));
  }

  /**
   * Triggered when the location is updated
   * @param {ModificationContext} in_modificationContext the photo's location modification context
   */
  onLocationUpdate(in_modificationContext) {
    const property = in_modificationContext.getProperty();
    const {store} = this.getUserData();
    if (!in_modificationContext.isSimulated()) {
      store.dispatch(updatePhotoLocationData(this.image.id, property.value));
    }
  }

  /** Triggered when the asset's sources is updated.
   * @param {ModificationContext} in_modificationContext - The asset's source modification context.
   */
  onSourcesUpdate(in_modificationContext) {
    const property = in_modificationContext.getProperty();
    const {store} = this.getUserData();
    const references = property.get('references').getValues();
    if (!in_modificationContext.isSimulated() && references && references.length) {
      store.dispatch(setSources(this.image.id, references));
    }
  }

  /** Triggered when the asset's privacy is updated.
   * @param {ModificationContext} in_modificationContext - The asset's source modification context.
   */
  onPrivacyStatusUpdate(in_modificationContext) {
    const property = in_modificationContext.getProperty();
    const {store} = this.getUserData();
    if (!in_modificationContext.isSimulated()) {
      store.dispatch(setPhotoPrivacy(this.image.id, property.value));
    }
  }

  /**
   *  Called when the property is created
   *  @param {ModificationContext} in_modificationContext - The modification context of the bound photo asset.
   */
  onPostCreate(in_modificationContext) {
    const asset = this.getRepresentation();
    const {store} = this.getUserData();
    this.image = {};
    this.image.id = asset.guid;

    // Adding missing components to the photo asset.
    const currentComponents = asset.property.getIds();
    const missingComponents = {};
    _.forEach(PHOTOS_COMPONENTS, ((schema, id) => {
      if (!currentComponents.includes(id)) {
        if (id === 'trackable') {
          const timeNow = moment().toISOString();
          const newSchema = Object.assign({}, schema);
          newSchema.value = {
            createTime: {
              iso8601: timeNow
            },
            lastModifiedTime: {
              iso8601: timeNow
            }
          };
          missingComponents[id] = newSchema;
        } else {
          missingComponents[id] = schema;
        }
      }
    }));
    if (Object.keys(missingComponents).length) {
      this.getDataBinder().requestChangesetPostProcessing(() => {
        asset.createComponents(missingComponents);
        this.getDataBinder().getWorkspace().commit();
      });
    }
    if (!in_modificationContext.isSimulated()) {
      const trackable = asset.getComponent('trackable').getData();
      const photo = asset.getComponent('photo').getData();
      const deleted = asset.getComponent('deleted').getData();
      const {uploadUserId, createTime, lastModifiedTime} = trackable;
      this.image.uploadUserId = uploadUserId;
      this.image.createTime = new Date(createTime.iso8601);
      this.image.lastModifiedTime = new Date(lastModifiedTime.iso8601);
      this.image.name = photo.name;
      this.image.extension = photo.format;
      this.image.deleted = deleted;
      store.dispatch(addPhoto(this.image));
    }
  }

  /**
   * @inheritdoc
   */
  static initialize() {
    this.registerOnPath('composition',
      ['insert', 'modify'], this.prototype.onImageCompositionUpdate);
    this.registerOnPath('content.status',
      ['insert', 'modify'], this.prototype.updateImageStatus);
    this.registerOnPath('thumbnail.status',
      ['insert', 'modify'], this.prototype.updateThumbnailStatus);
    this.registerOnPath('acp.isPrivate',
      ['modify'], this.prototype.onPrivacyStatusUpdate);
    this.registerOnPath('sources',
      ['insert', 'modify'], this.prototype.onSourcesUpdate);
    this.registerOnPath('trackable',
      ['modify'], this.prototype.onImageTrackableUpdate);
    this.registerOnPath('photo',
      ['modify'], this.prototype.onPhotoUpdate);
    this.registerOnPath('location',
      ['insert', 'modify'], this.prototype.onLocationUpdate);
    this.registerOnPath('deleted',
      ['modify'], this.prototype.onDeletedUpdate);

  }
}

PhotoDataBinding.initialize();
