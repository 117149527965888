import classNames from 'classnames';
import {DropTarget as dropTarget} from 'react-dnd';
import * as React from 'react';
import PropTypes from 'prop-types';
import {NativeTypes} from 'react-dnd-html5-backend';
import {
  SvgIcon
} from '@adsk/bim360-matrix-react-components';
import {PHOTO_ELEMENT} from '~/common/constants';

const {FILE} = NativeTypes;

export const collect = function(connect, monitor) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDropTarget: connect.dropTarget(),
    // You can ask the monitor about the current drag state:
    isOver: monitor.isOver(),
    isOverCurrent: monitor.isOver({shallow: true}),
    canDrop: monitor.canDrop(),
    itemType: monitor.getItemType()
  };
};

/**
 * @inheritdoc
 */
export class AlbumElement extends React.Component {

  /**
   * @inheritdoc
   */
  render() {
    const svgId = this.props.disabled ? 'photos-24' : 'photos-16';
    // These props are injected by React DnD,
    // as defined by your `collect` function above:
    const {isOver, canDrop, connectDropTarget} = this.props;
    const classes = classNames('AlbumItemRoot', {
      'accept': isOver && canDrop
    });
    return connectDropTarget(
      <div className={classes}>
        <div className="AlbumItemContainer">
          <SvgIcon svgId={svgId}/>
          {this.props.albumItemContent}
        </div>
      </div>
    );
  }
}

AlbumElement.propTypes = {
  uploadPhotos: PropTypes.func.isRequired,
  addPhotosToAlbum: PropTypes.func.isRequired,
  albumItemContent: PropTypes.element.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  albumId: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  isOver: PropTypes.bool.isRequired,
  canDrop: PropTypes.bool.isRequired,
  disabled: PropTypes.bool
};

AlbumElement.defaultProps = {
  disabled: false
};

/**
 * drop will not be called if TYPE is not in the array
 * @type {DndComponentClass<any>}
 */
export const AlbumItem = dropTarget([PHOTO_ELEMENT, FILE], {
  drop(props, monitor) {
    const type = monitor.getItemType();
    const {albumId} = props;

    const methods = {
      [FILE]: () => {
        const droppedFiles = monitor.getItem().files;
        props.uploadPhotos(droppedFiles, albumId);
      },
      [PHOTO_ELEMENT]: () => {
        const item = monitor.getItem();
        props.addPhotosToAlbum(item.photoId, albumId);
      }
    };

    methods[type]();
  }
}, collect)(AlbumElement);
