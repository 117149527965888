import _ from 'lodash';

import UploaderPanel from './uploaderPanel.ui';
import { connect } from 'react-redux';
import {
  openPanel,
  closePanel,
  removePhoto,
  clearLargeFilesAlert
} from '~/redux/actions/uploaderTrackerActions';
import { INIT, UPLOADING, UPLOAD_FAILED } from '~/common/constants';
import {
  cancelUploads,
  retryUploads
} from '~/redux/actions/photosActions';
import { openViewer } from '~/redux/actions/photoViewerActions';

/**
 * Build the list of current uploading file
 * @param {object} state The state
 * @return {array} The list of current uploads
 */
function buildUploadList(state) {
  const uploading = [];
  const awaiting = [];
  const failed = [];
  const done = [];

  let countUploading = 0;
  let countQueued =  0;
  let countFailed = 0;
  let countDone = 0;

  _.each(state.uploadingPhotos, (value, photoID) => {
    const photo = state.photos[photoID];
    const photoStatus = state.photosUploadStatus[photoID];

    if (photo) {
      const element = {
        photoID,
        name: photo.name + ((photo.extension) ? ('.' + photo.extension) : ''),
        status: photoStatus.status
      };

      // We group the uploads based on status.
      switch (photoStatus.status) {
        case INIT:
          awaiting.push(element);
          countQueued++;
          break;
        case UPLOADING:
          uploading.push(element);
          countUploading++;
          break;
        case UPLOAD_FAILED:
          failed.push(element);
          countFailed++;
          break;
        // fetching, fetched, fetchFailed, etc.. mean upload succeeded
        default:
          done.push(element);
          countDone++;
          break;
      }
    }
  });

  return {
    uploads: [
      // order the uploads by status
      ...uploading,
      ...awaiting,
      ...failed,
      ...done
    ],
    countUploading,
    countQueued,
    countFailed,
    countDone
  };
}

const mapStateToProps = state => {
  const uploadList = buildUploadList(state);

  return {
    uploadingPhotos: uploadList.uploads,
    shown: state.uploadingPanel,
    countUploading: uploadList.countUploading,
    countQueued: uploadList.countQueued,
    countFailed: uploadList.countFailed,
    countDone: uploadList.countDone,
    viewerIsOpen: state.photoViewer.showViewer,
    uploadsTooLarge: state.uploadsTooLarge
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openPanel: () => {
      dispatch(openPanel());
    },
    closePanel: () => {
      dispatch(closePanel());
    },
    openPhoto: photoID => {
      dispatch(openViewer([photoID]));
    },
    removePhoto: photoID => {
      dispatch(removePhoto(photoID));
    },
    clearLargeFilesAlert: () => {
      dispatch(clearLargeFilesAlert());
    },
    cancelUpload: photoID => {
      dispatch(cancelUploads([photoID]));
    },
    retryUpload: photoID => {
      dispatch(retryUploads([photoID]));
    }
  };
};

const UploaderTracker = connect(
  mapStateToProps,
  mapDispatchToProps
)(UploaderPanel);

export default UploaderTracker;
