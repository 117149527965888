import {
  FETCH_FAILED,
  FETCHED,
  FETCHING,
  INIT,
  NEW,
  UPLOAD_FAILED,
  UPLOADED,
  UPLOADING,
  CANCELLED
} from '~/common/constants';

export const contentStatuses = [
  INIT,
  CANCELLED,
  NEW,
  FETCHING,
  FETCHED,
  FETCH_FAILED
];

export const uploadingStatuses = [
  INIT,
  UPLOADED,
  UPLOADING,
  UPLOAD_FAILED
];
