import { initialize as ldClientInitialize } from 'ldclient-js';
import config from 'appConfig';
import defaultFeatureFlags from '../defaultFeatureFlags';
import { v4 as getUUID } from 'uuid';

const anonymousUser = { key: getUUID() };
let client;
let wasClientInitialized = false;

/**
 * Helper function, returns true if parameter is null or undefined
 * @param {Object} x variable to test
 * @return {Boolean} True if x is null or undefined
 */
function isNullOrUndefined(x) {
  return typeof x === 'undefined' || x === null;
}

export const initialize = (user = {}) => new Promise(resolve => {
  client = ldClientInitialize(config.LAUNCH_DARKLY.API_KEY, Object.assign({}, anonymousUser, user), {
    bootstrap: config.LAUNCH_DARKLY.USE_DEFAULTS ? defaultFeatureFlags : undefined
  });
  client.on('ready', () => {
    // If the LD client didn't initialize in time it means that we're now using default values.
    // So it's much safer to continue using them instead of switching between the two because they may be different.
    if (!wasClientInitialized) {
      wasClientInitialized = true;
      resolve();
    }
  });

  setTimeout(() => {
    if (!wasClientInitialized) {
      wasClientInitialized = true;
      // Faking out the client in case it didn't initialize in time.
      client = {
        variation(key, defaultValue) {
          return defaultValue;
        }
      };
      resolve();
    }
  }, 5000);
});

const toUpperAndUnderscore = key => {
  return key && key.toUpperCase().replace(/-/g, '_');
};

export const featureFlags = {
  get(key) {
    if (config.LOCAL_FEATURE_FLAGS && !isNullOrUndefined(config.LOCAL_FEATURE_FLAGS[toUpperAndUnderscore(key)])) {
      return config.LOCAL_FEATURE_FLAGS[toUpperAndUnderscore(key)];
    }
    const value = client ? client.variation(key, defaultFeatureFlags[key]) : defaultFeatureFlags[key];
    // The convention for multi-variant values is that the "empty" value should be 'null'.
    // The rest of the values return as is.
    return value === 'null' ? null : value;
  }
};

// This assumes the value of a multi-variant flag can sometimes have several values in it.
// The format for all these kinds of values would be the same, and it would be:
// "to;-;1.4.2018;-;from;-;2.4.2018" -> { to: "1.4.2018", from: "2.4.2018" }
export const parseMultiVariantValue = value => {
  const parts = value.split(';-;');
  const result = {};
  for (let i = 0; i < parts.length; i += 2) {
    result[parts[i]] = parts[i + 1];
  }

  return result;
};
