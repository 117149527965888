import { Asset } from '../../udp/representations';
import moment from 'moment';

/**
 * Define common behavior of photos module assets.
 */
export class CommonAsset extends Asset {
  /**
   * @inheritdoc
   */
  constructor(property, dataBinder) {
    super(...arguments);
    this._workspace = dataBinder.getWorkspace();
  }

  /**
   * Deletes the Asset from the space
   * @return {Promise} Fulfilled if the asset is deleted and the change is submitted successfully, o/w Rejects.
   */
  delete() {
    try {
      this.space.deleteAsset(this.guid);
      return this.commitChanges();
    } catch (err) {
      return Promise.reject(err);
    }
  }

  /**
   * The method updates the assets' trackable information upon modification and it commits the changes.
   * @return {Promise} Fulfilled when trackable information is updated
   */
  commitChanges() {
    const trackableProp = this.property.get('trackable');
    const userId = this.space.getAuthenticationData().user.id;
    const timeNow = moment().toISOString();
    trackableProp.get('lastModifiedTime').get('iso8601').setValue(timeNow);
    trackableProp.get('lastModifiedUserId').setValue(userId);
    return this._workspace.commit();
  }
}
