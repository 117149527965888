import {
  REQUEST_THUMBNAIL,
  RECEIVE_THUMBNAIL,
  REJECT_THUMBNAIL,
  UPDATE_THUMBNAIL_UPLOAD_STATUS,
  FETCHING,
  FETCHED,
  FETCH_FAILED,
  INIT,
  UPLOADED,
  ADD_PHOTO,
  CANCELLED,
  CANCEL_THUMBNAIL,
  RESET_FAILED_DOWNLOAD_STATUS
} from '~/common/constants';
import { StateTreeManager } from '~/common/helpers/stateTreeManager';

/**
 * A reducer for the thumbnails download status
 * @param {state} state last state
 * @param {*} action action to reduce
 * @return {state} The next state
 */
function thumbnailsStatus(state = {}, action) {
  switch (action.type) {
    case ADD_PHOTO: {
      const stateManager = new StateTreeManager(state);
      const newState = stateManager.addValue(action.image.id, {status: INIT});
      return newState;
    }
    case REQUEST_THUMBNAIL: {
      const stateManager = new StateTreeManager(state);
      const newState = stateManager.addValue(action.id, {status: FETCHING});
      return newState;
    }
    case RECEIVE_THUMBNAIL: {
      const stateManager = new StateTreeManager(state);
      const newState = stateManager.addValue(action.id, {status: FETCHED});
      return newState;
    }
    case UPDATE_THUMBNAIL_UPLOAD_STATUS: {
      const stateManager = new StateTreeManager(state);
      if (action.status === UPLOADED && stateManager.getValue(action.id).status !== FETCHING ) {
        const newState = stateManager.addValue(action.id, {status: INIT});
        return newState;
      } else {
        return state;
      }
    }
    case REJECT_THUMBNAIL: {
      const stateManager = new StateTreeManager(state);
      const newState = stateManager.addValue(action.id, {status: FETCH_FAILED});
      return newState;
    }
    case CANCEL_THUMBNAIL: {
      const stateManager = new StateTreeManager(state);
      const newState = stateManager.addValue(action.id, {status: CANCELLED});
      return newState;
    }
    case RESET_FAILED_DOWNLOAD_STATUS: {
      const stateManager = new StateTreeManager(state);

      const newState = stateManager.traverseTree((guid, value) => {
        if (value.status === FETCH_FAILED) {
          stateManager.addValue(guid, { status: INIT });
        }
      });

      return newState;
    }
    default: {
      return state;
    }
  }
}

export default thumbnailsStatus;
