import {PhotoViewer} from './photoViewer.ui';
import { connect } from 'react-redux';
import {
  rotatePhoto,
  fetchPhoto,
  fetchThumbnail,
  clearPhotosAndCancelFullsizeRequests,
  deprioritizeThumbnailRequests,
  closeViewerThenCancelFullsizeImageRequests,
  logActivity
} from '~/redux/actions/photosActions';
import { changePhoto, setPhotoIndex } from '~/redux/actions/photoViewerActions';

const mapStateToProps = state => {
  // slideNumber is only used for init, photoIndex is used over activePhotoIndex to reduce renders
  return {
    displayedPhotos: state.photoViewer.displayedPhotos,
    showViewer: state.photoViewer.showViewer,
    slideNumber: state.photoViewer.photoIndex,
    activeAlbum: state.activeAlbum,
    photosUploadStatus: state.photosUploadStatus,
    thumbnailsUploadStatus: state.thumbnailsUploadStatus
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => {
      dispatch(closeViewerThenCancelFullsizeImageRequests());
    },
    fetchPhoto: id => {
      dispatch(fetchPhoto(id));
    },
    fetchThumbnail: id => {
      dispatch(fetchThumbnail(id, true));
    },
    clearPhotosAndCancelFullsizeRequests: ids => {
      dispatch(clearPhotosAndCancelFullsizeRequests(ids));
    },
    rotatePhoto: (id, isCW = true) => {
      dispatch(rotatePhoto(id, isCW));
    },
    changePhoto: (id, index) => {
      dispatch(changePhoto(id, index));
    },
    setPhotoIndex: index => {
      dispatch(setPhotoIndex(index));
    },
    deprioritizeThumbnailRequests: () => {
      dispatch(deprioritizeThumbnailRequests());
    },
    logActivity: (verb, guid, value, customAttrs) => {
      dispatch(logActivity(verb, guid, null, value, customAttrs));
    }
  };
};

const PhotoViewerWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(PhotoViewer);

export default PhotoViewerWrapper;
