import _ from 'lodash';

import {
  OPEN_UPLOADER_PANEL,
  CLOSE_UPLOADER_PANEL,
  ADD_UPLOADING_PHOTO,
  REMOVE_UPLOADING_PHOTO,
  ADD_TOO_LARGE_FILES,
  CLEAR_TOO_LARGE_FILES
} from '~/common/constants';

/**
 * @return {Object} A redux action
 */
export function openPanel() {
  return ({
    type: OPEN_UPLOADER_PANEL
  });
}

/**
 * @return {Object} A redux action
 */
export function closePanel() {
  return ({
    type: CLOSE_UPLOADER_PANEL
  });
}

/**
 * @param {string} photoID The id of the photo
 * @return {Object} A redux action
 */
export function addPhoto(photoID) {
  return ({
    type: ADD_UPLOADING_PHOTO,
    photoID
  });
}

/**
 * @param {string} photoID The id of the photo
 * @return {Object} A redux action
 */
export function removePhoto(photoID) {
  return ({
    type: REMOVE_UPLOADING_PHOTO,
    photoID
  });
}

/**
 * @param {object[]} rejectedFiles Array of rejected files
 * @return {Object} A redux action
 */
export function showLargeFilesAlert(rejectedFiles) {
  return ({
    type: ADD_TOO_LARGE_FILES,
    rejectedFiles
  });
}

/**
 * @param {object[]} rejectedFiles Array of rejected files
 * @return {Object} A redux action
 */
export function clearLargeFilesAlert(rejectedFiles) {
  return ({
    type: CLEAR_TOO_LARGE_FILES,
    rejectedFiles
  });
}

/**
 * Remove the photo from the tracker and close it if no uploads
 * are left after that.
 * @param {string} photoID The id of the photo
 * @return {Object} A redux action
 */
export function clearFromUploaderTracker(photoID) {
  return (dispatch, getState) => {
    dispatch(removePhoto(photoID));

    // close the panel if that was the last upload in it.
    if (_.isEmpty(getState().uploadingPhotos)) {
      dispatch(closePanel());
    }
  };
}
