import { AppComponent } from '@adsk/forge-appfw-di';

/**
 * BIM360 Photos Analytics component.
 * The component provide analytics methods to track events on photos module for the different analytics tools.
 * @public
 * @extends external:AppComponent
 */
export class PhotosAnalyticsComponent extends AppComponent {
  /**
   * @inheritdoc
   */
  static defineDependencies() {
    return [
      {type: 'BIM360AnalyticsComponent'}
    ];
  }

  /**
   * @inheritdoc
   */
  constructor(params) {
    super(params);

    this._trackScreenLoad = this._trackScreenLoad.bind(this);
  }

  /**
   * Returns tracking methods available for photos modules.
   * @return {Object} tracking methods.
   */
  getWrappedInstance() {
    return {
      track: {
        screenLoad: this._trackScreenLoad
      }
    };
  }

  /**
   * Tracks `Load Screen` event.
   * @private
   */
  _trackScreenLoad() {
    this._analyticsSDK.track({eventName: 'Load Screen', properties: {['Screen Name']: 'Photos'}}, 'Docs');
  }

  /**
   * @inheritdoc
   */
  initialize(dependencies) {
    const { BIM360AnalyticsComponent } = dependencies;

    this._analyticsSDK = BIM360AnalyticsComponent;

    return Promise.resolve();
  }
}
