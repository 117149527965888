import React, { PureComponent } from 'react';

import { PhotoContentImage } from './photoContentImage.ui';
import { PhotoContentTiff } from './photoContentTiff.ui';

/**
 * React rendering of the PhotoContentRaster
 * @public
 * @return {PhotoContentRaster} PhotoContentRaster
 */
export class PhotoContentRaster extends PureComponent {
  /**
   * @inheritdoc
   */
  render() {
    let imageSrc, tiffBlob;

    const isTiff = this.props.extension.toLowerCase() === 'tif' || this.props.extension.toLowerCase() === 'tiff';

    const rotation = this.props.composition.rotation;

    if (isTiff && !this.props.shouldRenderThumbnail) {
      tiffBlob = this.props.photoContent.dataSource._blob;

      return <PhotoContentTiff
        isResponsive={this.props.isResponsive}
        shouldRenderThumbnail={this.props.shouldRenderThumbnail}
        rotation={rotation}
        tiffBlob={tiffBlob}
      />;
    }

    if (this.props.shouldRenderThumbnail) {
      imageSrc = this.props.thumbnailContent.url;
    } else {
      imageSrc = this.props.photoContent.url;
    }

    return (
      <PhotoContentImage
        isResponsive={this.props.isResponsive}
        shouldRenderThumbnail={this.props.shouldRenderThumbnail}
        rotation={rotation}
        src={imageSrc}
      />
    );
  }
}
