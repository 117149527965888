import {
  ADD_PHOTO,
  SET_SOURCES
} from '~/common/constants';

/**
 * A reducer for the composition
 * @param {state} state last state
 * @param {*} action action to reduce
 * @return {state} The next state
 */
function photosSources(state = {}, action) {
  switch (action.type) {
    case ADD_PHOTO: {
      const issue = {
        [action.image.id]: []
      };
      return Object.assign({}, state, issue);
    }
    case SET_SOURCES: {
      const newIssues = {
        [action.photoID]: action.issues
      };
      return Object.assign({}, state, newIssues);
    }
    default: {
      return state;
    }
  }
}

export default photosSources;
