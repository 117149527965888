import {DataBinding} from '@adsk/forge-appfw-databinder';
import {
  removeAlbum,
  updateAlbumTrackable,
  addAlbum,
  updateAlbumName
} from '~/redux/actions/albumsActions';

/**
 * A binding for album assets which allows us reacting on modifications in UDP and updates the redux store accordingly.
 * TODO: Ideally this Binding should be a SingletonBinding. Thus, please keep it stateless as it is, this will make
 * using SingletonBindings much easier.
 * @extends external:DataBinding
 */
export class AlbumDataBinding extends DataBinding {
  /** Called when the property is removed
   */
  onRemove() {
    const {store} = this.getUserData();
    store.dispatch(removeAlbum(this.album.id));
  }

  /** Triggered when the asset trackable is updated.
   * @param {BaseProperty} property - The asset's trackable property/component.
   */
  onAlbumTrackableModify(property) {
    const {store} = this.getUserData();
    store.dispatch(updateAlbumTrackable(this.album.id, property.getValues()));
  }

  /**
   * The callback is triggered whenever the album's name component is inserted or modified.
   * @param {BaseProperty} property - The name property of the album asset
   */
  onAlbumNameModify(property) {
    const {store} = this.getUserData();
    store.dispatch(updateAlbumName(this.album.id, property.getValue()));
  }

  /**
   *  Called when the property is created
   *  @param {ModificationContext} in_modificationContext - The modification context of the bound album asset.
   */
  onPostCreate(in_modificationContext) {
    const asset = in_modificationContext.getProperty();
    const trackable = asset.get('trackable').getValues();
    const {store} = this.getUserData();
    this.album = {
      id: asset.getId(),
      name: asset.getValue('name') || '',
      trackable,
      photos: [],
      // object mapping a photo asset guid to a photo-album relationship guid
      relationships: {}
    };
    this.album.trackable.createTime = new Date(trackable.createTime.iso8601);
    this.album.trackable.lastModifiedTime = new Date(trackable.lastModifiedTime.iso8601);
    store.dispatch(addAlbum(this.album));
  }

  /**
   * @inheritdoc
   */
  static initialize() {
    this.registerOnProperty('name',
      ['insert', 'modify'], this.prototype.onAlbumNameModify);
    this.registerOnProperty('trackable',
      ['insert', 'modify'], this.prototype.onAlbumTrackableModify);

  }
}

AlbumDataBinding.initialize();
