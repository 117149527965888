import { connect } from 'react-redux';
import {AlbumsModal} from './albumsModal.appfw';
import {
  addPhotosToAlbum
} from '~/redux/actions/photosActions';


const mapDispatchToProps = dispatch => {
  return {
    addPhotoToAlbum: (photoId, albumId) => {
      dispatch(addPhotosToAlbum(photoId, albumId));
    }
  };
};

const mapStateToProps = state => {
  return {
    albumsData: state.albums,
    activeAlbum: state.activeAlbum
  };
};

export const ConnectedAlbumsModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(AlbumsModal);
