
import _ from 'lodash';
import {
  OPEN_ISSUE_FORM,
  CLOSE_ISSUE_FORM,
  REQUEST_ISSUE,
  RECEIVE_ISSUE,
  REJECT_ISSUE,
  ISSUE_DOMAIN,
  ISSUE_CLASSES
} from '~/common/constants';

import {
  fetchPhoto
} from './photosActions';

import {
  addSource
} from './sourcesActions';
import { StateTreeManager } from '~/common/helpers/stateTreeManager';


/**
 * Handles fetching an issue
 * @param {Function} dispatch Redux dispatch function
 * @param {Function} getState Redux-Thunk getState function
 * @param {Function} fetchIssueFunction Function called to get the issue
 * @param {String} issueId Id of the issue to fetch
 */
function getIssue(dispatch, getState, fetchIssueFunction, issueId) {
  const {issues} = getState();
  if (!issues[issueId]) {
    dispatch(requestIssue(issueId));
    fetchIssueFunction(issueId)
    .then(receivedIssue => {
      if (receivedIssue) {
        dispatch(receiveIssue(issueId, receivedIssue));
      } else {
        dispatch(rejectIssue(issueId));
      }
    })
    .catch(err => {
      console.error(err);
      dispatch(rejectIssue(issueId));
    });
  }
}


/**
 * Handles fetching a Quality Issue
 * @param {String} issueId The guid of the issue to fetch
 * @return {Function} redux thunk action
 */
export function getQualityIssue(issueId) {
  return (dispatch, getState, {IssuesComponent}) => {
    getIssue(dispatch, getState, IssuesComponent.getQualityIssue.bind(IssuesComponent), issueId);
  };
}

/**
 * Handles fetching an RFI
 * @param {String} rfiId The guid of the issue to fetch
 * @return {Function} redux thunk action
 */
export function getRFI(rfiId) {
  return (dispatch, getState, {IssuesComponent}) => {
    getIssue(dispatch, getState, IssuesComponent.getRFI.bind(IssuesComponent), rfiId);
  };
}

/**
 * Handles requesting an issue when the type isn't known
 * @param {*} issueId Id of the generic issue to get
 * @return {Function} redux thunk
 */
export function getGenericIssue(issueId) {
  console.warn('Unknown Issue Type. The following warnings and 404s are expected');
  return (dispatch, getState, {IssuesComponent}) => {
    const {issues} = getState();
    if (!issues[issueId]) {
      dispatch(requestIssue(issueId));
      Promise.all([
        IssuesComponent.getQualityIssue(issueId),
        IssuesComponent.getRFI(issueId)
      ])
      .then(([qualityIssue, rfi]) => {
        const resolvedIssue = qualityIssue ? qualityIssue : rfi;
        if (resolvedIssue) {
          dispatch(receiveIssue(issueId, resolvedIssue));
        } else {
          // Both failed
          dispatch(rejectIssue(issueId));
        }
      })
      .catch(err => {
        console.error(err);
        dispatch(rejectIssue(issueId));
      });
    }
  };
}

/**
 * Requests the issue
 * @param {String} issueId Id of issue to request
 * @return {Object} Action to get issue
 */
export function requestIssue(issueId) {
  return {
    type: REQUEST_ISSUE,
    id: issueId
  };
}

/**
 * Receives the issue request
 * @param {String} issueId Id of issue to receive
 * @param {Object} issue Full JSON of the issue
 * @return {Object} Action to get issue
 */
export function receiveIssue(issueId, issue) {
  return {
    type: RECEIVE_ISSUE,
    id: issueId,
    data: issue
  };
}

/**
 * Rejects the issue requests
 * @param {String} issueId Id of issue to reject
 * @return {Object} Action to get issue
 */
export function rejectIssue(issueId) {
  return {
    type: REJECT_ISSUE,
    id: issueId
  };
}


/**
 * Handles creating an issue from photos
 * @param {Object} params the parameters of the issue creation
 * @return {Function} A redux thunk action.
 */
export function createIssue(params) {
  return (dispatch, getState, PhotosComponent) => {
    const { photosSelection, photosContent, photos} = getState();

    const photoIds = _.filter( Object.keys(photosSelection), key => {
      return photosSelection[key];
    });

    const space = PhotosComponent.getSpace();
    const photosFetchPromises = _.map(photoIds, photoId => {
      const photo = photos[photoId];
      const name = photo.name || photoId;
      const extension = '.' + photo.extension;
      // Find the first relationship that is storage (it has a 'urn' property)
      const relationships = space.getAssetRelationships(photoId);
      const storageRelationship = relationships.find(relationship => {
        return relationship.to.property.has('urn');
      });

      const ossUrn = storageRelationship ? storageRelationship.to.property.getValue('urn') : '';

      return {
        name: name + extension,
        ossUrn,
        linkPhotoToIssueFunction: issueId => {
          dispatch(addSource(photoId, {
            id: issueId,
            domain: ISSUE_DOMAIN,
            type: ISSUE_CLASSES.QUALITY_ISSUE
          }));
        },
        linkPhotoToStorageFunction: newOssUrn => {
          PhotosComponent.getSpace().getAsset(photoId).createStorage(newOssUrn);
        },
        file: dispatch(fetchPhoto(photoId))
        .then(() => {
          return new Promise( (resolve, reject) => {
            const fr = new FileReader();
            fr.onload = event => {
              resolve(event.target.result);
            };
            fr.onerror = event => {
              reject('Could not load into file');
            };

            const photosContentStateTree = new StateTreeManager(photosContent);
            fr.readAsArrayBuffer(photosContentStateTree.getValue(photoId).dataSource.getBlob());
          });
        })
      };
    });

    return PhotosComponent.IssuesComponent.postIssue(params, photosFetchPromises).then(response => {
      dispatch(closeIssueForm());
      return Promise.resolve();
    });
  };
}

/**
 * Handles opening the issue creation form
 * @return {Object} A redux action
 */
export function openIssueForm() {
  return ({
    type: OPEN_ISSUE_FORM
  });
}

/**
 * Closes the issue creation form
 * @return {Object} Redux action to close the issue form
 */
export function closeIssueForm() {
  return ({
    type: CLOSE_ISSUE_FORM
  });
}
