import React from 'react';
import PropTypes from 'prop-types';
import {ConnectedPhotoContent} from './photoContent/photoContent.redux';
import T from '~/common/i18n';

const NB_MAX_PHOTO_TO_SHOW = 5;

/**
 * React component for the PhotoContent
 * @public
 * @return {PhotoDragPreview} PhotoDragPreview
 */
const PhotoDragPreview = ({photos, getThumbnailContent, getThumbnailStatus, currentOffset}) => {
  let photoDraggedStyle = {};
  let photoCaptionStyle = {};
  let caption = T.translate('gallery.dragLayer.captionOverGallery', {
    nbPhotos: photos.length
  });
  if (currentOffset && currentOffset.x < 200) {
    photoDraggedStyle.width = '80px';
    photoDraggedStyle.height = '50px';
    photoDraggedStyle.left = '65px';
    photoDraggedStyle.top = '55px';
    photoCaptionStyle.top = '120px';
    caption = T.translate('gallery.dragLayer.captionOverAlbum', {
      nbPhotos: photos.length
    });
  }
  return (
    <>
      {photos.slice(0, NB_MAX_PHOTO_TO_SHOW).map((photoId, i) => (
        <div
          key={photoId}
          className="photoDragged"
          style={{
            zIndex: photos.length - i,
            transform: `rotateZ(${-i * 3.5}deg)`,
            ...photoDraggedStyle
          }}
        >
          <ConnectedPhotoContent
            photoId={photoId}
            isResponsive={false}
            shouldRenderThumbnail={() => true}
          />
        </div>
      ))}
      {photos.length > 1 ?
        <div className="photoDragLayerCaption" style={photoCaptionStyle}>{caption}</div> : null
      }
    </>
  );
};

PhotoDragPreview.propTypes = {
  photos: PropTypes.array,
  getThumbnailContent: PropTypes.func,
  getThumbnailStatus: PropTypes.func,
  currentOffset: PropTypes.object
};

export default PhotoDragPreview;
