import React from 'react';
import {Gallery} from './gallery/gallery.appfw';
import ControlPanel from '../ui/controls';
import UploadTracker from './uploader/uploaderPanel/uploaderPanel.redux';
import PhotoViewer from '../ui/photoViewer';
import {IssueCreator} from '../ui/issueForm';
import {AlbumDataEditor} from './propertyViews/albumAssetView/albumAssetDataView.redux';
import {Sidebar} from './sidebar/sidebar.ui';
import { FailedDownloadWarningConnector as FailedDownloadWarning} from './errors/failedDownloadWarning.redux';

/**
 * React component for the BIM Photo page
 * @public
 */
class PhotoPage extends React.Component {
  /**
   * React rendering of the Page
   * @public
   * @return {PhotoPage} Page
   */
  render() {
    return (
      <React.Fragment>
        <div className="mainContainer" key="controlPanel">
          <AlbumDataEditor/>
          <ControlPanel/>
          <Gallery/>
        </div>
        <Sidebar key="sidebar"/>
        <PhotoViewer key="photoViewer"/>
        <IssueCreator key="issueCreator"/>
        <UploadTracker key="uploadTracker"/>
        <FailedDownloadWarning key="failedDownloadWarning"/>
      </React.Fragment>
    );
  }
}

export default PhotoPage;
