import { connect } from 'react-redux';
import {AlbumsPanel} from './albumsPanel.appfw';
import {setActiveAlbum} from '~/redux/actions/albumsActions';
import {deselectAllPhotos, uploadPhotos} from '~/redux/actions/photosActions';

const mapDispatchToProps = dispatch => {
  return {
    onRowClick: id => {
      dispatch(setActiveAlbum(id));
      dispatch(deselectAllPhotos());
    },
    uploadPhotos: (photos, albumId) => {
      dispatch(uploadPhotos(photos, albumId));
    }
  };
};

const mapStateToProps = state => {
  return {
    albumsData: state.albums,
    activeAlbum: state.activeAlbum
  };
};

export const ConnectedAlbumsPanel = connect(mapStateToProps, mapDispatchToProps)(AlbumsPanel);
