import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  UPLOAD_FAILED,
  FETCHING,
  FETCHED,
  FETCH_FAILED,
  CANCELLED,
  UPLOADING,
  UPLOADED,
  PHOTO_ELEMENT,
  ALL_AUX,
  TRASH
} from '~/common/constants';

import {
  Checkbox,
  ActionButton,
  SvgIcon,
  Tooltip
} from '@adsk/bim360-matrix-react-components';

import {DragSource as dragSource} from 'react-dnd';
import {getEmptyImage} from 'react-dnd-html5-backend';
import {ConnectedPhotoContent} from './photoContent/photoContent.redux';
import {ConnectedIssuesCount} from './IssuesCount/issuesCount.redux';
import T from '~/common/i18n';
import {contentStatuses, uploadingStatuses} from '~/common/propTypes';

export const PHOTO_ACTIONS = {
  DOWNLOAD: 'download',
  SET_PRIVATE: 'private',
  SET_PUBLIC: 'public',
  MOVETO: 'move',
  DUPLICATE: 'duplicate',
  SHARE: 'share',
  REMOVE: 'remove',
  DELETE: 'delete',
  RESTORE: 'restore'
};


/**
 * PrivacyIcon
 * @param {Object} props the props passed down
 * @return {React.Node} Component
 */
function PrivacyIcon(props) {
  if (!props.isPrivate) {
    return null;
  }
  return (
    <div className="privacyIcon">
      <Tooltip
        content={T.translate('gallery.privateIcon')}
        place={Tooltip.Places.TOP}
        theme={Tooltip.Themes.DARK}
      >
        <SvgIcon
          svgId="private"
          width="16px"
          height="16px"
        />
      </Tooltip>
    </div>
  );
}



/**
 * React component for the BIM Photo Element
 * @public
 */
export class PhotoElement extends React.Component {
  /**
   * constructor
   * @param {Object} props the props passed down
   */
  constructor(props) {
    super(props);

    this.fetchThumbnail = _.throttle(() => {
      if (
        this.props.thumbnailUploadStatus.status === UPLOADED &&
        ![FETCHED, FETCHING, FETCH_FAILED].includes(this.props.thumbnailContentStatus.status)
      ) {
        this.props.fetchThumbnail(this.props.photoId);
      } else if (
        this.props.photoUploadStatus.status === UPLOADED &&
        ![UPLOADED, UPLOADING].includes(this.props.thumbnailUploadStatus.status) &&
        ![FETCHED, FETCHING, FETCH_FAILED].includes(this.props.photoContentStatus.status)
      ) {
        this.props.fetchPhoto(this.props.photoId);
      }
    }, 100);

    this._actionButtonOnSelect = this._actionButtonOnSelect.bind(this);
    this._shouldRenderThumbnail = this._shouldRenderThumbnail.bind(this);
    this._onCheckboxClick = this._onCheckboxClick.bind(this);
    this._onThumbnailClick = this._onThumbnailClick.bind(this);
  }

  /**
   * @inheritdoc
   */
  shouldComponentUpdate(nextProps) {
    const propsChanged = _.some(Object.keys(nextProps), key => {
      return nextProps[key] !== this.props[key];
    });

    // The component should not go from full to light mode
    return !nextProps.light && propsChanged;
  }

  /**
   * @inheritDoc
   */
  componentDidMount() {
    const {connectDragPreview, light} = this.props;
    if (!light) {
      this.fetchThumbnail();
      connectDragPreview(getEmptyImage(), {
        captureDraggingState: true
      });
    }
  }

  /**
   * @inheritDoc
   */
  componentDidUpdate(prevProps) {
    const {connectDragPreview, light} = this.props;
    if (prevProps.light && !light) {
      connectDragPreview(getEmptyImage(), {
        captureDraggingState: true
      });
    }
    if (!light) {
      this.fetchThumbnail();
    }
  }

  /**
   * Triggered when user clicks on the checkbox
   * @param {Event} event the click event to handle
   */
  _onCheckboxClick(event) {
    event.stopPropagation();
    this.props.onCheckboxClick(this.props.photoId, event);
  }

  /**
   * Triggered when user clicks on the photo thumbnail
   * @param {Event} event the click event to handle
   */
  _onThumbnailClick(event) {
    event.stopPropagation();
    this.props.onThumbnailClick(this.props.photoId, event);
  }

  /**
   * Check if it should render thumbnail or not.
   * @private
   * @param {Object} thumbnailStatus Contains both fetchStatus and uploadStatus
   * @param {Object} photoStatus Contains both fetchStatus and uploadStatus
   * @return {Boolean} shouldRenderThumbnail
   */
  _shouldRenderThumbnail(thumbnailStatus, photoStatus) {
    return thumbnailStatus.uploadStatus !== UPLOAD_FAILED &&
      thumbnailStatus.uploadStatus !== CANCELLED &&
      thumbnailStatus.fetchStatus !== FETCH_FAILED &&
      (thumbnailStatus.fetchStatus === FETCHED || photoStatus.fetchStatus !== FETCHED);
  }

  /**
   * @inheritdoc
   */
  componentWillUnmount() {
    this.props.cancelDownload(this.props.photoId);
  }

  /**
   * The options used in photo action button.
   * @return {Array<Object>} Array of options that can be injected into ActionButton options prop.
   * @private
   */
  _getPhotoOptions() {
    const insertIf = function(condition, element) {
      return condition ? [element] : [];
    };

    const isAllPhotosOption = this.props.activeAlbum === ALL_AUX;
    const isTrashOption = this.props.activeAlbum === TRASH;
    const isPhotoAlbumOption = !isAllPhotosOption && !isTrashOption;
    const isPhotoPublic = !this.props.isPrivate;
    const showPrivacyFeature = this.props.showPrivacyFeature;

    return [
      ...insertIf(isTrashOption, {
        key: PHOTO_ACTIONS.RESTORE,
        label: T.translate('photoElement.options.restore'),
        svgId: 'restore-small',
        disabled: false
      }),
      ...insertIf(!isTrashOption, {
        key: PHOTO_ACTIONS.SHARE,
        label: T.translate('photoElement.options.share'),
        disabled: true
      }),
      ...insertIf(showPrivacyFeature && !isTrashOption && isPhotoPublic, {
        key: PHOTO_ACTIONS.SET_PRIVATE,
        label: T.translate('photoElement.options.private'),
        svgId: 'private'
      }),
      ...insertIf(showPrivacyFeature && !isTrashOption && !isPhotoPublic, {
        key: PHOTO_ACTIONS.SET_PUBLIC,
        label: T.translate('photoElement.options.public'),
        svgId: 'icon_privacy_public'
      }),
      ...insertIf(!isTrashOption, {
        key: PHOTO_ACTIONS.DOWNLOAD,
        label: T.translate('photoElement.options.download'),
        svgId: 'icon_download'
      }),
      ...insertIf(!isTrashOption, {
        key: PHOTO_ACTIONS.DUPLICATE,
        label: T.translate('photoElement.options.duplicate'),
        svgId: 'create-another',
        className: 'adjustIconDuplicate'
      }),
      ...insertIf(!isTrashOption, {
        key: PHOTO_ACTIONS.MOVETO,
        label: isPhotoAlbumOption ?
          T.translate('photoElement.options.moveOrCopy') : T.translate('photoElement.options.addToAlbum'),
        svgId: 'add-document'
      }),
      ...insertIf(!isTrashOption, {
        key: PHOTO_ACTIONS.DELETE,
        label: T.translate('photoElement.options.delete'),
        svgId: 'trash',
        className: 'adjustIconTrash'
      })
    ];
  }

  /**
   * Handles user's selection for the photo button action.
   * @param {String} optionKey  It's one of action button options keys.
   * @private
   */
  _actionButtonOnSelect(optionKey) {
    const photoId = this.props.photoId;
    switch (optionKey) {
      case PHOTO_ACTIONS.DELETE:
        this.props.showPhotoDeletionWarning(photoId);
        break;
      case PHOTO_ACTIONS.REMOVE:
        this.props.removePhoto(photoId);
        break;
      case PHOTO_ACTIONS.DUPLICATE:
        this.props.duplicatePhotos(photoId);
        break;
      case PHOTO_ACTIONS.DOWNLOAD:
        this.props.downloadPhoto(photoId);
        break;
      case PHOTO_ACTIONS.MOVETO:
        this.props.showAddToAlbumModal(photoId);
        break;
      case PHOTO_ACTIONS.RESTORE:
        this.props.restorePhoto(photoId);
        break;
      case PHOTO_ACTIONS.SET_PRIVATE:
        this.props.setPhotoPrivate(photoId);
        break;
      case PHOTO_ACTIONS.SET_PUBLIC:
        this.props.setPhotoPublic(photoId);
        break;
      default:
        console.warn(`${optionKey} doesn't exist.`);
    }
  }

  /**
   * React rendering of the Element
   * @public
   * @return {PhotoElement} Element
   */
  render() {
    // connectDragSource is injected by React DnD.
    const {light, isSelected, connectDragSource} = this.props;

    const classes = classNames('photoFrame', {
      'selected': isSelected
    });
    const container = document.querySelector('.GalleryGrid') || document.querySelector('#root');

    if (light) {
      return (
        <div className={classes}>
          {
            isSelected ? <Checkbox
              onClick={this._onCheckboxClick}
              className="largeCheckbox selectedCheckbox"
              checked={isSelected}
            /> : null
          }
          <ConnectedPhotoContent
            photoId={this.props.photoId}
            light={this.props.light}
            shouldRenderThumbnail={this._shouldRenderThumbnail}
            isResponsive={false}
          />
        </div>
      );
    }

    return connectDragSource(
      <div
        className={classes}
        onClick={this._onThumbnailClick}
      >
        <Checkbox
          onClick={this._onCheckboxClick}
          className="largeCheckbox selectedCheckbox"
          checked={isSelected}
        />
        <ActionButton
          options={this._getPhotoOptions()}
          container={container}
          onSelect={this._actionButtonOnSelect}
        />
        {this.props.showPrivacyFeature ?
          <PrivacyIcon
            isPrivate={this.props.isPrivate}
          /> : null
        }
        <ConnectedPhotoContent
          photoId={this.props.photoId}
          light={this.props.light}
          shouldRenderThumbnail={this._shouldRenderThumbnail}
          isResponsive={false}
        />
        <ConnectedIssuesCount photoId={this.props.photoId} />
      </div>
    );
  }
}

const collect = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  };
};

export const DraggablePhotoElement = dragSource(PHOTO_ELEMENT, {
  beginDrag(props) {
    return {
      photoId: props.photoId
    };
  }
}, collect)(PhotoElement);

PhotoElement.propTypes = {
  // Actions
  fetchPhoto: PropTypes.func,
  fetchThumbnail: PropTypes.func,
  cancelDownload: PropTypes.func,
  removePhoto: PropTypes.func,
  duplicatePhotos: PropTypes.func,
  downloadPhoto: PropTypes.func,
  restorePhoto: PropTypes.func,
  onCheckboxClick: PropTypes.func,
  onThumbnailClick: PropTypes.func,
  showPhotoDeletionWarning: PropTypes.func,
  showAddToAlbumModal: PropTypes.func,
  // Action Parameters
  activeAlbum: PropTypes.string,
  photoId: PropTypes.string,
  // Statuses
  thumbnailUploadStatus: PropTypes.shape({
    status: PropTypes.oneOf(uploadingStatuses)
  }),
  thumbnailContentStatus: PropTypes.shape({
    status: PropTypes.oneOf(contentStatuses)
  }),
  photoUploadStatus: PropTypes.shape({
    status: PropTypes.oneOf(uploadingStatuses)
  }),
  photoContentStatus: PropTypes.shape({
    status: PropTypes.oneOf(contentStatuses)
  }),
  // Rendering
  isSelected: PropTypes.bool,
  light: PropTypes.bool
};
