import {
  OPEN_ISSUE_FORM,
  CLOSE_ISSUE_FORM,
  REQUEST_ISSUE,
  RECEIVE_ISSUE,
  REJECT_ISSUE,
  FETCHING
} from '~/common/constants';

/**
 * A reducer for the issue form
 * @param {state} state last state
 * @param {*} action action to reduce
 * @return {state} The next state
 */
function issueForm(state = {}, action) {
  switch (action.type) {
    case OPEN_ISSUE_FORM: {
      const newState = {
        showIssueForm: true
      };
      return Object.assign({}, state, newState);
    }
    case CLOSE_ISSUE_FORM: {
      const newState = {
        showIssueForm: false
      };
      return Object.assign({}, state, newState);
    }

    default: {
      return state;
    }
  }
}

/**
 * A reducer for the issues
 * @param {state} state last state
 * @param {*} action action to reduce
 * @return {state} The next state
 */
function issues(state = {}, action) {
  switch (action.type) {
    case REQUEST_ISSUE: {
      const newState = {
        [action.id]: FETCHING
      };
      return Object.assign({}, state, newState);
    }
    case RECEIVE_ISSUE: {
      const newState = {
        [action.id]: action.data
      };
      return Object.assign({}, state, newState);
    }
    case REJECT_ISSUE: {
      const newState = {
        [action.id]: 'Failed'
      };
      return Object.assign({}, state, newState);
    }
    default:
      return state;
  }
}

export {issueForm, issues};
