import React from 'react';
import {Text} from '@adsk/bim360-matrix-react-components';
import {EditableInput} from './editableInput.redux';

/**
 * A generic react component to view a different photo data property in an editable text field.
 */
export class AlbumDataGenericView extends React.Component {
  /**
   * @inheritdoc
   */
  render() {
    const className = 'albumAssetView ' + (this.props.className || '');
    return (
      <div className={className}>
        <Text>{this.props.createdBy} </Text>
        <EditableInput {...this.props}/>
      </div>
    );
  }
}
