import React from 'react';
import {
  Checkbox, SimpleModal, Button, Text
} from '@adsk/bim360-matrix-react-components';

import {AlbumsPanel} from '../sidebar/albumsPanel/albumsPanel.appfw';

import _ from 'lodash';
import T from '~/common/i18n';
import { ALL_AUX } from '~/common/constants';

/**
 * A Modal which behaves as a user dialog widget which shows the list of albums available in the folder for moving and
 * copying photo into a selected album.
 */
export class AlbumsConfirmationModal extends React.Component {
  /**
   * constructor
   * @param {Object} props the props passed down
   */
  constructor(props) {
    super(props);

    this.state = {
      copyMode: false,
      selectedAlbumId: null,
      newAlbumRowMode: false
    };

    this._setCopyMode = this._setCopyMode.bind(this);
    this._setSelectedAlbumId = this._setSelectedAlbumId.bind(this);
    this._onNewAlbumButtonClick = this._onNewAlbumButtonClick.bind(this);
    this._moveToAlbum = this._moveToAlbum.bind(this);
  }

  /**
   * Sets the current copy mode state to the given mode.
   * @param {Boolean} [mode=false] True iff we are interested in keeping a copy of the photo in the current album,
   * o/w it will remove it.
   * @private
   */
  _setCopyMode(mode = false) {
    this.setState({
      copyMode: mode
    });
  }

  /**
   * Sets the current selected album id to the given id.
   * @param {String} [albumId=null] The selected album id.
   * @private
   */
  _setSelectedAlbumId(albumId = null) {
    this.setState({
      selectedAlbumId: albumId
    });
  }

  /**
   * The method creates an editable row for creating a new album.
   * @private
   */
  _onNewAlbumButtonClick() {
    this.setState({
      newAlbumRowMode: true
    });
  }

  /**
   * Moving the current photo to the selected album.
   * @private
   */
  _moveToAlbum() {
    const {photoId, addPhotoToAlbum, removePhoto, onExited} = this.props;
    const {selectedAlbumId, copyMode} = this.state;

    if (selectedAlbumId && photoId) {
      addPhotoToAlbum(photoId, selectedAlbumId);
      if (!copyMode) {
        removePhoto();
      }
    }

    onExited();
  }

  /**
   * Renders the albums modal body.
   * @return {React.Node} The modal body.
   * @private
   */
  _renderModalBody() {
    const {selectedAlbumId, newAlbumRowMode} = this.state;
    const {albumHasPhoto, albumsData, photoId} = this.props;

    return (<>
      <Text>{T.translate('gallery.addToAlbum.description')}</Text>

      <AlbumsPanel
        showSpecialAlbum={false}
        withHeader={false}
        newAlbumRowPosition={AlbumsPanel.ROW_POSITION.BOTTOM}
        activeAlbum={selectedAlbumId}
        onRowClick={this._setSelectedAlbumId}
        newAlbumRowMode={newAlbumRowMode}
        onNewAlbumRowClose={() => {
          this.setState({
            newAlbumRowMode: false
          });
        }}
        albumRowOptions={[
          AlbumsPanel.ROW_ACTION_KEYS.RENAME
        ]}
        disabledRow={albumId => {
          return albumHasPhoto(albumId, photoId);
        }}
        albumsData={albumsData}
      />
    </>);
  }

  /**
   * Renders the albums modal footer.
   * @return {React.Node} The modal footer.
   * @private
   */
  _renderFooter() {
    const {newAlbumRowMode, selectedAlbumId} = this.state;
    return (
      <>
        <div className="FooterOption">
          {
            this.props.showCopyOption &&
            <Checkbox
              id="ShowCopyOption"
              onChange={this._setCopyMode}
              label={T.translate('gallery.addToAlbum.copyText')}
            />
          }
        </div>
        <div className="FooterButtons">
          <div className="LeftButtons">
            <Button
              id="AddNewAlbumButton"
              styleType={Button.StyleType.LINK_SECONDARY}
              onClick={this._onNewAlbumButtonClick}
              disabled={newAlbumRowMode}>
              {T.translate('gallery.addToAlbum.buttons.addNewAlbum')}
            </Button>
          </div>
          <div className="RightButtons">
            <Button
              id="CancelAddAlbum"
              onClick={this.props.onExited}
              styleType={Button.StyleType.CANCEL}>
              {T.translate('gallery.addToAlbum.buttons.cancel')}
            </Button>
            <Button
              id="MoveToAlbumButton"
              onClick={this._moveToAlbum}
              disabled={!selectedAlbumId}
              styleType={Button.StyleType.PRIMARY}>
              {T.translate('gallery.addToAlbum.buttons.confirm')}
            </Button>
          </div>
        </div>
      </>
    );
  }

  /**
   * @inheritdoc
   */
  render() {
    const modalTitle = this.props.activeAlbum === ALL_AUX ?
      T.translate('gallery.addToAlbum.addTitle') :
      T.translate('gallery.addToAlbum.moveTitle');
    return <SimpleModal
      show={true}
      title={modalTitle}
      confirmationButtonLabel={T.translate('gallery.addToAlbum.buttons.confirm')}
      onExited={this.props.onExited}
      footer={this._renderFooter()}
      modalBodyProps={{
        className: 'CustomModal AddToAlbumModal'
      }}
      modalFooterProps={{
        className: 'AlbumsModalFooter AddToAlbumModal'
      }}
    >
      {this._renderModalBody()}
    </SimpleModal>;
  }
}

AlbumsConfirmationModal.defaultProps = {
  showCopyOption: false,
  onExited: _.noop,
  removePhoto: _.noop,
  addPhotoToAlbum: _.noop
};
