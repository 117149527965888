import { AppComponent } from '@adsk/forge-appfw-di';
import AnalyticsSDK from '@adsk/bim360-analytics-sdk-js';


/**
 * BIM360 Analytics component.
 * The component integrating with multiple analytics tools used by BIM360 modules.
 * @public
 * @extends external:AppComponent
 */
export class BIM360AnalyticsComponent extends AppComponent {
  /**
   * @inheritdoc
   */
  static defineDependencies() {
    return [
      {type: 'BIM360AnalyticsConfiguration', as: 'analyticsConfigs'}
    ];
  }

  /**
   * Returns AnalyticsSDK instance.
   * @return {AnalyticsSDK} AnalyticsSDK instance.
   */
  getWrappedInstance() {
    return this._analyticsSDK;
  }

  /**
   * @inheritdoc
   */
  initialize(dependencies) {
    const { analyticsConfigs } = dependencies;

    try {
      this._analyticsSDK = AnalyticsSDK.init(analyticsConfigs.getConfig());
    } catch (err) {
      return Promise.reject(err);
    }

    return Promise.resolve(this._analyticsSDK);
  }
}
