import {withAppComponent} from '@adsk/forge-appfw-react';

import { Gallery as ReduxGallery} from './gallery.redux';


export const Gallery = withAppComponent({
  instances: {
    PhotosComponent: {type: 'PhotosComponent'}
  },
  mapAppComponents: ({PhotosComponent}) => {
    return {photosComponent: PhotosComponent};
  }
})(ReduxGallery);
