import { Space } from '../../udp/representations';
import moment from 'moment';

import * as schemas from '~/resources/schemas';
import { ACTIVITY } from '~/common/constants';
import { BinaryDataSource } from '@adsk/forge-hfdm';

const ALBUM_ASSET_TYPEID = schemas.photoAlbum.typeid;
const PHOTO_ASSET_TYPEID = schemas.photoAsset.typeid;

/**
 * Define the specialized runtime representation for photos space.
 */
export class PhotosSpace extends Space {
  /**
   * @inheritdoc
   */
  constructor(property, dataBinder, activityLog) {
    super(property, dataBinder);
    this._workspace = dataBinder.getWorkspace();
    this._activityLogService = activityLog;
    this._authData = activityLog.getAuthenticationData();
  }

  /**
   * Gets the activityLog component.
   * @return {ActivityLogComponent} activity log instance.
   */
  getActivityLogService() {
    return this._activityLogService;
  }

  /**
   * Gets users authentication data.
   * @return {object} Authentication data.
   */
  getAuthenticationData() {
    return this._authData;
  }

  /**
   * Returns whether the asset is part of the space or not.
   * @param {string} assetId - The asset id.
   * @return {boolean} true iff the asset belongs to the space.
   */
  hasAsset(assetId) {
    return this.getAssetsProperty().has(assetId);
  }

  /**
   * Creates a photo asset given the webfile form.
   * @public
   * @param {object} file WebForm file
   * @param {string} name name of the file
   * @return {{upload: Promise<any>, photoId: *}} Returns upload promise and the inserted photo id.
   */
  createPhoto(file, name) {
    const splitname = name.split('.');
    const filename = splitname.slice(0, -1).join('.');
    const extension = splitname[splitname.length - 1];
    const WebFormFileDataSource = BinaryDataSource.WebFormFileDataSource;
    const uploadSource = new WebFormFileDataSource(file);

    const asset = this.createAsset(PHOTO_ASSET_TYPEID, {
      photo: {
        name: filename,
        format: extension
      },
      trackable: this.generateTrackable()
    });
    const photoContent = asset.property.get('content');
    photoContent.setDataSource(uploadSource);
    return {
      upload: new Promise((resolve, reject) => {
        photoContent.upload().then(() => {
          // We could generate the Thumbnail here instead of when ever we view the full image.
          // This would diminish the amount of total processing time by a lot as we would only have 1 user generating
          // it. It was not done that way for 2 reasons : First, there was a BP issue where multipart uploads returned
          // promise.resolve when it wasn't finished, causing parts of the upload to trigger the generation. We can
          // work around that but it requires some work. Second, since we don't have a server/service to generate the
          // Thumbnail, a failure would leave the property without a thumbnail and no way to regenerate it.
          return this._workspace.commit();
        }).then(() => {
          this._activityLogService.logPhotoActivity(ACTIVITY.VERBS.UPLOAD_PHOTO, asset);
          resolve();
        }).catch(err => {
          console.error('error while uploading content. ' + err);
          reject(err);
        });
      }),
      photoId: asset.guid
    };
  }

  /**
   * Creates a new album asset with the given name.
   * @param {string} name The album asset name.
   * @return {Promise} Fulfilled if the asset was created and the change was submitted successfully. o/w rejected.
   */
  createAlbum(name) {
    try {
      const asset = this.createAsset(ALBUM_ASSET_TYPEID, {
        name,
        trackable: this.generateTrackable()
      });

      return asset.commitChanges().then(() => {
        this._activityLogService.logAlbumActivity(ACTIVITY.VERBS.CREATE_ALBUM, asset);
        return asset;
      });
    } catch (err) {
      return Promise.reject(err);
    }
  }

  /**
   * The method generates initial trackable data.
   * @return {object} trackable data.
   */
  generateTrackable() {
    const userId = this._authData.user.id;
    const timeNow = moment().toISOString();
    return {
      createTime: {
        iso8601: timeNow
      },
      lastModifiedTime: {
        iso8601: timeNow
      },
      createUserId: userId,
      lastModifiedUserId: userId
    };
  }

  /**
   * The function makes sure to batch all changes in data caused by the given function.
   * @param {Function} changeFunc - A function that introduce a data change.
   */
  executeAtomicChange(changeFunc = () => {}) {
    this.getWorkspace().pushModifiedEventScope();
    try {
      changeFunc();
    } catch (err) {
      this.getWorkspace().popModifiedEventScope();
      throw err;
    }
    this.getWorkspace().popModifiedEventScope();
  }
}
