/**
 * @fileoverview This file defines the locations component
 */

import {AppComponent} from '@adsk/forge-appfw-di';
import {requestHTTP} from '~/common/helpers/utils';
/**
 * The main class for the locations of a project
 * @public
 * @extends external:AppComponent
 */
export class LocationsComponent extends AppComponent {
  /**
   * @inheritdoc
   */
  initialize(dependencies) {
    const {AuthenticationComponent, BIMContainersComponent, ForgeConfiguration} = dependencies;
    this._forgeConfig = ForgeConfiguration;
    this._authenticationComponent = AuthenticationComponent;
    this._lbsContainer = BIMContainersComponent.getLBSContainer();
    this._locationsById = undefined;
    return this._requestLocations()
    .then(locations => {
      this._locations = locations;
    });
  }

  /**
   * Returns a list of locations assigned to the current project.
   * @return {Promise} - Promise with the locations list.
   * @private
   */
  _requestLocations() {
    return this._lbsContainer
      .then(lbsContainerId => {
        return new Promise((resolve, reject) => {
          this._authenticationComponent.bearerTokenFunction((error, accessToken) => {
            if (error) {
              reject(error);
            } else {
              resolve({ accessToken, lbsContainerId });
            }
          });
        });
      })
      .then(({ accessToken, lbsContainerId }) => {
        const locationInfoUrl =
          `${this._forgeConfig.environmentBaseUrl}/${this._forgeConfig.lbsEndpoint}/` +
          `${lbsContainerId}/trees/default/nodes`;

        return requestHTTP(locationInfoUrl, { 'Authorization': 'Bearer ' + accessToken })
          .then(locationInfo => {
            return JSON.parse(locationInfo).results;
          });
      });
  }

  /**
 * Returns a list of locations assigned to the current project.
 * @return {Array} - the list of locations
 * @public
 */
  getLocationsArray() {
    return this._locations;
  }

  /**
 * Returns an object mapping location ids to location objects
 * @return {Object} - the list of locations
 * @public
 */
  getPhotosLocationsMap() {
    if (!this._locationsById) {
      this._locationsById = this._locations.reduce((locationsObj, location) => {
        locationsObj[location.id] = location;
        return locationsObj;
      }, {});
    }
    return this._locationsById;
  }


  /**
   * @inheritdoc
   */
  static defineDependencies() {
    return [
      {
        type: 'ForgeConfiguration'
      },
      {
        type: 'AuthenticationComponent'
      },
      {
        type: 'BIMContainersComponent'
      }
    ];
  }
}
