import {AppComponent} from '@adsk/forge-appfw-di';
import {getResourceData} from '~/common/helpers/resourceCall';
import {getEntitlementsParams} from '~/common/helpers/authenticationConsts';
import {EntitlementAccounts} from '@adsk/bim360-mc-interface';

// account entitlements resource name in BIM docs.
const RESOURCE_NAME = 'account_entitlements';

/**
 * A component for fetching account entitlements.
 * @public
 * @extends external:AppComponent
 */
class BIMAccountEntitlementsComponent extends AppComponent {
  /**
   * @inheritdoc
   */
  static defineDependencies() {
    return [
      {type: 'AuthenticationComponent'},
      {type: 'BIMProjectsEntitlementsComponent', as: 'projects'}
    ];
  }

  /**
   * @inheritdoc
   */
  constructor(params) {
    super(params);
    this.fetchAccountEntitlements = this.fetchAccountEntitlements.bind(this);
  }

  /**
   * Fetching account entitlements.
   * @param {Object} token Current access token used.
   * @return {Promise<Object>} A promise with the current account entitlements data.
   */
  fetchAccountEntitlements(token) {

    return getResourceData(
      {
        modelParser: EntitlementAccounts,
        resource: RESOURCE_NAME,
        token: token.token,
        hq: this._config.hq,
        parameters: {
          ...getEntitlementsParams(),
          current_project_id: this._currentProjectId
        }
      });
  }

  /**
   * Gets the fetched account entitlements.
   * @return {Object} account entitlements.
   */
  getAccountEntitlements() {
    return this._accountEntitlements;
  }

  /**
   * @inheritdoc
   */
  initialize(dependencies) {
    const {AuthenticationComponent, projects} = dependencies;
    this._config = AuthenticationComponent.getConfiguration();
    this._currentProjectId = projects.getActiveProjectEntitlement().currentProjectId;
    return AuthenticationComponent.getToken()
      .then(this.fetchAccountEntitlements)
      .then(accountEntitlements => {this._accountEntitlements = accountEntitlements;});
  }
}

export default BIMAccountEntitlementsComponent;
