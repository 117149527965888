import {AppComponent} from '@adsk/forge-appfw-di';
import {setProjectIdInLocalStorage} from '~/common/helpers/localStorage';
import {projectBaseURLExtension} from '~/common/helpers/navigator';
import preloadServiceIcons from '~/common/helpers/preloadServiceIcons';
import {getProjectIdFromUrl} from '~/common/helpers/urlHelper';

/**
 * BIM docs containers component.
 * The component help with fetching and creating containers for BIM projects.
 * @public
 * @extends external:AppComponent
 */
class BIMContainersComponent extends AppComponent {
  /**
   * @inheritdoc
   */
  static defineDependencies() {
    return [
      {type: 'BIMDocsConfiguration', as: 'configs'},
      {type: 'BIMProjectsEntitlementsComponent', as: 'projects'},
      {type: 'AuthenticationComponent', as: 'authentication'}
    ];
  }

  /**
   * Creates a new photo container to the given projectId.
   * @param {String} projectId BIM project id.
   * @param {String} containerType container type
   * @return {Promise<any>} promise with the resolved container branchGuid.
   */
  createNewContainer(projectId, containerType) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      const onError = () => {
        reject(xhr);
      };

      xhr.withCredentials = true;
      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          const branchId = JSON.parse(xhr.response).id;
          resolve(branchId);
        } else {
          onError();
        }
      };

      xhr.onerror = onError;
      xhr.ontimeout = onError;
      xhr.open('POST', `${this._config.apigee}/${this._config.backEnd}/v2/projects/${projectId}/containers`, true);

      // Below line must appear after xhr.open due to an IE11 bug -
      // https://github.com/stephanebachelier/superapi/issues/5
      xhr.timeout = 20 * 1000;

      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('Authorization', 'Bearer ' + this._token);
      xhr.send(JSON.stringify({'type': containerType}));
    });
  }

  /**
   * Gets the current containers branchGuids
   * @return {Promise<array>} promise with the resolved containers branchGuids.
   */
  getContainers() {
    const containers = [
      this.getPhotosContainer(),
      this.getLBSContainer(),
      this.getIssuesContainer(),
      this.getChecklistsContainer()
    ];
    return Promise.all(containers).then(function(branchIds) {
      return Promise.resolve(branchIds);
    });
  }

  /**
  * Given a container type, gets the current container branchGuid if it exists. Otherwise it creates a new one.
  * @param {string} containerType the container type
  * @return {Promise<String>} A promise with the resolved container branchGuid.
  */
  getContainerByType(containerType) {
    const chosenProjectId = this._activeProject.currentProjectId;
    return new Promise((resolve, reject) => {
      const containers = this._activeProject.projects[0].containers;
      let foundContainer = false;
      let myContainer = containers.find(container => container.type === containerType);
      if (myContainer && myContainer.id) {
        foundContainer = true;
        resolve(myContainer.id);
      } else {
        if (containerType === 'photos') {
          // Set this to true so that this promise is not rejected below.
          foundContainer = true;
          this.createNewContainer(chosenProjectId, containerType).then(resolve, reject);
        }
      }
      if (!foundContainer) {
        reject(`No container of type ${containerType} found for the project ${this._activeProject.currentProjectId}`);
      }
    });
  }

  /**
  * Gets the current lbs container branchGuid
  * @return {Promise<String>} A promise with the resolved lbs container branchGuid.
  */
  getLBSContainer() {
    return this.getContainerByType('lbs');
  }

  /**
  * Gets the current issue container guid
  * @return {Promise<String>} A promise with the resolved issue container guid.
  */
  getIssuesContainer() {
    return this.getContainerByType('issue');
  }

  /**
  * Gets the current checklist container guid
  * @return {Promise<String>} A promise with the resolved checklist container guid.
  */
  getChecklistsContainer() {
    return this.getContainerByType('checklist');
  }

  /**
   * Gets the current photo container branchGuid
   * @return {Promise<String>} A promise with the resolved photo container branchGuid.
   */
  getPhotosContainer() {
    return this.getContainerByType('photos');
  }

  /**
   * Updates the current project data if it changed.
   * @private
   */
  _handleProjectView() {
    const userId = this._user.id;
    const projectIdFromUrl = getProjectIdFromUrl(window.location.pathname);
    if (this._activeProject.validServiceLocation) {
      window.location.href = this._activeProject.validServiceLocation;
    }
    const chosenProjectId = this._activeProject.currentProjectId;
    // In case the chosen project is the same as the current one we only need to set that project in the local storage.
    if (projectIdFromUrl === chosenProjectId) {
      setProjectIdInLocalStorage(chosenProjectId, userId);
    } else if (projectIdFromUrl && projectIdFromUrl.length > 1) {
      // In case we actually sent a current project id to HQ, but it's different from the one they sent us back -
      // redirect to DOCS. We don't get the docs url from the request since HQ returns a url to a project the user can
      // access in Field rather then a url to the most logical(?) module the user can access for the given project.
      window.location.href = `${this._config.baseUrl}/projects/${projectIdFromUrl}/photos`;
    } else {
      // This means that there was no project in the url, we can just override it.
      setProjectIdInLocalStorage(chosenProjectId, userId);
      window.history.replaceState({project: chosenProjectId}, '',
        `${projectBaseURLExtension}/${chosenProjectId}/photos`);
    }

    preloadServiceIcons(this._activeProject.projects[0]);
  }

  /**
   * The current photo container branchGuid.
   * @return {String} The photo container branchGuid.
   */
  getBranchUrn() {
    return this._urn;
  }

  /**
   * @inheritdoc
   */
  initialize(dependencies) {
    const {configs, authentication, projects} = dependencies;
    this._config = configs;
    this._activeProject = projects.getActiveProjectEntitlement();
    this._user = authentication.getUserData();
    return (authentication.getToken()
      .then(({token}) => {
        this._token = token;
        return this.getPhotosContainer();
      })
      .then(branchId => {
        this._handleProjectView();
        this._urn = branchId;
        return Promise.resolve();
      })
      .catch(err => {
        console.error(err);
      })
    );
  }

}

export default BIMContainersComponent;
