import {DataBinding} from '@adsk/forge-appfw-databinder';
import {PropertyUtils} from '@adsk/forge-hfdm';

import {
  removeStorage
} from '~/redux/actions/storageActions';
import { UDP_SDK_BINDING_TYPE  } from '~/udp/udpSdk';

const PathHelper = PropertyUtils.PathHelper;
/**
 * A binding for photo storage relationship which allows us reacting on modifications in UDP and updates the redux store
 * accordingly.
 * TODO: Ideally this Binding should be a SingletonBinding. Thus, please keep it stateless as it is, this will make
 * using SingletonBindings much easier.
 * @extends external:DataBinding
 */
export class PhotoStorageDataBinding extends DataBinding {
  /**
   * @inheritdoc
   */
  onPreRemove(in_removalContext) {
    const {store} = this.getUserData();
    const space = this.getDataBinder().getRepresentationAtPath('root', UDP_SDK_BINDING_TYPE);
    const path = PathHelper.tokenizePathString(in_removalContext.getAbsolutePath());
    const relationshipGuid = path[path.length - 1];
    const photoId = space.relationships.allFrom[relationshipGuid];
    const storageId = space.relationships.allTo[relationshipGuid];
    // A storage should not exist without the relationship.
    // RemoveStorage will remove both the relationship and the storage asset
    store.dispatch(removeStorage(photoId, storageId));
  }
}
