import React from 'react';
import {Text} from '@adsk/bim360-matrix-react-components';
import moment from 'moment';

import { DATE_TIME_FORMAT } from '~/common/constants';

/**
 * A react component to view timestamp with iso8601 format for album data view
 */
export class AlbumDataDateView extends React.Component {

  /**
   * @inheritdoc
   */
  render() {
    const date = moment(this.props.time);
    const className = 'albumAssetView' + (this.props.className || '');
    return (
      <div className={className}>
        <Text>{this.props.createdOn} {date.format(DATE_TIME_FORMAT)}</Text>
      </div>
    );
  }
}
