import {
  ADD_PHOTO,
  SELECT_PHOTO,
  SELECT_ALL_PHOTOS,
  DESELECT_ALL_PHOTOS,
  SELECT_PHOTOS_SET
} from '~/common/constants';

/**
 * A reducer to handle the state tree of selecting photos
 * @param {Object} state the selection states of the photos
 * @param {Action} action An action dictating change on a photo
 * @return {Object} The next state of the photo.
 */
function photosSelection(state = {}, action) {
  switch (action.type) {
    case ADD_PHOTO: {
      const selections = {};
      selections[action.image.id] = false;
      const newState = Object.assign({}, state, selections);
      return newState;
    }
    case SELECT_PHOTO: {
      const selections = {
        [action.id]: !state[action.id]
      };
      const newState = Object.assign({}, state, selections);
      return newState;
    }
    case SELECT_ALL_PHOTOS: {
      let newState = {};
      Object.keys(state).forEach(key => {
        newState[key] = true;
      });
      return newState;
    }
    case DESELECT_ALL_PHOTOS: {
      const newState = {};
      Object.keys(state).forEach(key => {
        newState[key] = false;
      });
      return newState;
    }
    case SELECT_PHOTOS_SET: {
      const selections = {};
      action.payload.photoIDs.forEach(selectedPhotoID => {
        selections[selectedPhotoID] = action.payload.isSelect;
      });
      return Object.assign({}, state, selections);
    }
    default:
      return state;
  }
}

export default photosSelection;
