import { LOCAL_STORAGE } from './authenticationConsts';

const setInLocalStorage = (userId, KEY, value) => {
  if (!localStorage) return;

  let currentStorageData = localStorage.getItem(KEY);

  if (currentStorageData) {
    try {
      currentStorageData = JSON.parse(currentStorageData);
    } catch (e) {
      localStorage.removeItem(KEY);
      currentStorageData = {};
    }
  } else {
    currentStorageData = {};
  }

  currentStorageData[userId] = value;
  localStorage.setItem(KEY, JSON.stringify(currentStorageData));
};

export const setProjectIdInLocalStorage = (projectId, userId) => {
  setInLocalStorage(userId, LOCAL_STORAGE.LAST_USED_PROJECT_DATA, projectId);
};
