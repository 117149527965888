import { connect } from 'react-redux';
import { logActivity } from '~/redux/actions/photosActions';
import { ButtonPrivateToggleView as ButtonPrivateToggleViewUI }  from './photoPrivateToggleView.ui';

const mapDispatchToProps = dispatch => {
  return {
    logActivity: (verb, guid, value, customAttrs) => {
      dispatch(logActivity(verb, guid, null, value, customAttrs));
    }
  };
};

export const ButtonPrivateToggleView = connect(
  null,
  mapDispatchToProps
)(ButtonPrivateToggleViewUI);
