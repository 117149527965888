import React from 'react';

import './style.scss';

/**
 * React component for the BIM Photo uploader
 * @public
 */
class ButtonGroup extends React.Component {
  /**
   * React rendering of a button group
   * @public
   * @return {ButtonGroup} Uploader box
   */
  render() {
    let classes = ['ButtonGroup'];

    if (this.props.className) {
      classes = classes.concat(this.props.className.split(' '));
    }

    return (
      <div className={classes.join(' ')}>
        {this.props.children}
      </div>
    );
  }
}

export default ButtonGroup;
