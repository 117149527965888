/**
 * @fileoverview This file defines the initial state of the app
 */

import { AppComponent } from '@adsk/forge-appfw-di';
import { PhotoDataBinding } from '../bindings/photo.binding';

import * as schemas from '~/resources/schemas';
import { UDP_SDK_BINDING_TYPE} from '../../udp/udpSdk';
import { AlbumDataBinding } from '../bindings/album.binding';
import { PhotoAlbumDataBinding } from '../bindings/photoAlbum.binding';
import { PhotoStorageDataBinding } from '../bindings/photoStorage.binding';
import { StorageDataBinding } from '../bindings/storage.binding';
import { PhotosSpace, PhotoAsset, AlbumAsset } from '../representations';
import { PHOTOS_BINDING_TYPE } from '~/common/constants';

const PHOTO_ASSET_TYPEID = schemas.photoAsset.typeid;
const ALBUM_ASSET_TYPEID = schemas.photoAlbum.typeid;
const STORAGE_ASSET_TYPEID = schemas.photoStorage.typeid;
const PHOTO_ALBUM_RELATIONSHIP_TYPEID = schemas.photoAlbumRelationship.typeid;
const PHOTO_STORAGE_RELATIONSHIP_TYPEID = schemas.photoStorageRelationship.typeid;

/**
 * This component is responsible for registering and activating the photos module DataBindings and
 * RuntimeRepresentations.
 * @public
 * @extends external:AppComponent
 */
export class BindingsComponent extends AppComponent {

  /**
   * @inheritdoc
   */
  initialize(dependencies) {
    const {
      PhotosComponent, ActivityLogComponent, DataBinderComponent, UdpSDKComponent
    } = dependencies;

    this._dataBinder = DataBinderComponent;
    this._store = PhotosComponent.getStore();
    this._activityLogComponent = ActivityLogComponent;

    const udpSpace = UdpSDKComponent.getSpace();

    this._defineRepresentations();
    this._defineBindings();
    this._bind();

    // TODO: Fix this when we have typed space property, then we can override the existing space rr.
    this._photosSpace = this._dataBinder.getRepresentation(udpSpace.property, PHOTOS_BINDING_TYPE);
    this._photosSpace.relationships = udpSpace.relationships;

    return Promise.resolve();
  }

  /**
   * @return {Space} The UDP photo space.
   */
  getSpace() {
    return this._photosSpace;
  }

  /**
   * Returns the DataBinder instance used in the StoreComponent.
   * @return {DataBinder} - DataBinder instance.
   */
  getDataBinder() {
    return this._dataBinder;
  }

  /**
   * Defines the photos module bindings.
   * @private
   */
  _defineBindings() {
    this._dataBinder.defineDataBinding(UDP_SDK_BINDING_TYPE, PHOTO_ASSET_TYPEID, PhotoDataBinding);
    this._dataBinder.defineDataBinding(UDP_SDK_BINDING_TYPE, ALBUM_ASSET_TYPEID, AlbumDataBinding);
    this._dataBinder.defineDataBinding(UDP_SDK_BINDING_TYPE, STORAGE_ASSET_TYPEID, StorageDataBinding);

    // todo: databinder doesn't pass the correct user data for specialized binding LYNXDEV-8788. When it's fixed we can
    //  define the binding under the UDP entity type.
    this._dataBinder.defineDataBinding(PHOTOS_BINDING_TYPE,
      schemas.photoAlbumRelationship.typeid, PhotoAlbumDataBinding);
    this._dataBinder.defineDataBinding(PHOTOS_BINDING_TYPE,
      schemas.photoStorageRelationship.typeid, PhotoStorageDataBinding);
  }

  /**
   * bind UDP assets to PhotoDataBinding
   * @private
   */
  _bind() {
    const bindingUserData = {
      store: this._store
    };

    // The order is important.
    this._dataBinder.pushBindingActivationScope();

    this._dataBinder.activateDataBinding(UDP_SDK_BINDING_TYPE, PHOTO_ASSET_TYPEID, {
      includePrefix: 'root.assets', // We want to activate this binding only to assets.
      userData: bindingUserData
    });
    this._dataBinder.activateDataBinding(UDP_SDK_BINDING_TYPE, ALBUM_ASSET_TYPEID, {
      includePrefix: 'root.assets', // We want to activate this binding only to assets.
      userData: bindingUserData
    });
    this._dataBinder.activateDataBinding(UDP_SDK_BINDING_TYPE, STORAGE_ASSET_TYPEID, {
      includePrefix: 'root.assets', // We want to activate this binding only to assets.
      userData: bindingUserData
    });
    this._dataBinder.popBindingActivationScope();

    this._dataBinder.pushBindingActivationScope();

    this._dataBinder.activateDataBinding(PHOTOS_BINDING_TYPE, PHOTO_ALBUM_RELATIONSHIP_TYPEID, {
      userData: bindingUserData
    });
    this._dataBinder.activateDataBinding(PHOTOS_BINDING_TYPE, PHOTO_STORAGE_RELATIONSHIP_TYPEID, {
      userData: bindingUserData
    });

    this._dataBinder.popBindingActivationScope();
  }

  /**
   * Defines the photo's module runtime representations.
   * @private
   */
  _defineRepresentations() {
    // Defining photos Runtime Representations for UDP
    this._dataBinder.defineRepresentation(PHOTOS_BINDING_TYPE, schemas.space.typeid,
      spaceProperty => new PhotosSpace(spaceProperty,
        this._dataBinder, this._activityLogComponent), {stateless: false});

    const photosSpace = this._dataBinder.getRepresentationAtPath('root', PHOTOS_BINDING_TYPE);

    this._dataBinder.defineRepresentation(UDP_SDK_BINDING_TYPE, PHOTO_ASSET_TYPEID,
      property => new PhotoAsset(property, photosSpace), {stateless: true});

    this._dataBinder.defineRepresentation(UDP_SDK_BINDING_TYPE, ALBUM_ASSET_TYPEID,
      property => new AlbumAsset(property, photosSpace), {stateless: true});

  }

  /**
   * @inheritdoc
   */
  static defineDependencies() {
    return [
      {
        type: 'DataBinderComponent'
      },
      {
        type: 'PhotosComponent'
      },
      {
        type: 'UdpSDKComponent'
      },
      {
        type: 'ActivityLogComponent'
      }
    ];
  }
}
