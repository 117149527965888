import React from 'react';
import T from '~/common/i18n';
import {
  ToggleButton,
  Button,
  SvgIcon,
  Tooltip
} from '@adsk/bim360-matrix-react-components';

import ButtonGroup from '../utils/ButtonGroup';
import { PhotoFilterControl } from './filterControl.redux';

const TimelineGroupingButton = ({groupingBy, title, setTimelineGrouping, currentGroupingBy}) => (
  <ToggleButton
    className="GroupByButton grayButton"
    checked={groupingBy === currentGroupingBy}
    key= {groupingBy}
    onClick={setTimelineGrouping.bind(null, groupingBy)}
    label={title}
  />
);

/**
 * React component for the common controls
 */
export class CommonControls extends React.Component {

  /**
   * @inheritdoc
   */
  constructor(props) {
    super(props);

    this.state = {
      showFilter: false
    };
    this.renderFilterControl = this.renderFilterControl.bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
  }

  /**
   * Render TimelineGroupingButtons in a button group
   * @return {ButtonGroup} the button group for timeline
   */
  renderTimelineGroupingButtons() {
    return (
      <ButtonGroup>
        <TimelineGroupingButton
          title={T.translate('controls.groupingBy.days')}
          groupingBy="day"
          currentGroupingBy={this.props.groupingBy}
          setTimelineGrouping={this.props.setTimelineGrouping}
        />
        <TimelineGroupingButton
          title={T.translate('controls.groupingBy.weeks')}
          groupingBy="week"
          currentGroupingBy={this.props.groupingBy}
          setTimelineGrouping={this.props.setTimelineGrouping}
        />
        <TimelineGroupingButton
          title={T.translate('controls.groupingBy.months')}
          groupingBy="month"
          currentGroupingBy={this.props.groupingBy}
          setTimelineGrouping={this.props.setTimelineGrouping}
        />
      </ButtonGroup>
    );
  }

  /**
   * Toggle filter
   */
  toggleFilter() {
    this.setState(prevState => {
      return {
        showFilter: !prevState.showFilter
      };
    });
  }

  /**
   * Render filter control
   * @return {Dropdown} the dropdown for filter control
   */
  renderFilterControl() {
    const filterBtn = (
      <Button className="filterControl" styleType="icon" onClick={this.toggleFilter}>
        {T.translate('controls.filter')}
        <SvgIcon  width="10px" height="10px" svgId="uploader-panel-icon-collapse"/>
      </Button>
    );
    const dropdownMenu = (
      <PhotoFilterControl activeAlbum={this.props.activeAlbum} toggleFilter={this.toggleFilter} />
    );
    return (
      <Tooltip
        className="popoverFilter"
        content={dropdownMenu}
        show={this.state.showFilter}
        onHide={this.toggleFilter}
        trigger={Tooltip.TriggerEvents.CLICK}
        rootClose={true}
      >
        {filterBtn}
      </Tooltip>
    );
  }

  /**
   * @inheritdoc
   */
  render() {
    return (
      <React.Fragment>
        {this.renderFilterControl()}
        {this.renderTimelineGroupingButtons()}
      </React.Fragment>
    );
  }
}
