import React from 'react';
import {Field, Text} from '@adsk/bim360-matrix-react-components';
import moment from 'moment';

import { DATE_TIME_FORMAT } from '~/common/constants';

/**
 * A react component to view timestamp with iso8601 format for photo data view
 * @param {name} name the property name
 * @param {name} time time property value
 * @return {ReactDOM} The react to render
 */
export const PhotoDataDateView = ({name, time}) => {
  const date = moment(time);
  return (
    <Field className="ImageDataField" label={name}>
      <Text className="dateField">{date.format(DATE_TIME_FORMAT)}</Text>
    </Field>
  );
};
