import { connect } from 'react-redux';
import { IssuesCount } from './issuesCount.ui';

const mapStateToProps = ({photosSources}, {photoId}) => {
  return {
    linkedSources: photosSources[photoId]
  };
};

export const ConnectedIssuesCount = connect(
  mapStateToProps
)(IssuesCount);
