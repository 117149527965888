import BIMDocsAuthenticationComponent from '~/appfw/components/BIM360/docsAuthentication.component';
import BIMProjectsEntitlementsComponent from '~/appfw/components/BIM360/projectsEntitlements.component';
import BIMAccountEntitlementsComponent from '~/appfw/components/BIM360/accountEntitlements.component';
import BIMContainersComponent from '~/appfw/components/BIM360/BIMContainers.component';
import {getComponentTree, render} from '~/application/photos.application';
import PhotoPage from '~/ui/photosPage.ui';
import { UsersComponent } from '~/appfw/components/users.component';
import { LocationsComponent } from '~/appfw/components/locations.component';
import { IssuesComponent } from '~/appfw/components/issues.component';
import { ActivityLogComponent } from '~/appfw/components/activityLog.component';
import { ChecklistsComponent } from '~/appfw/components/checklists.component';



// Control build information
// This string will be overridden by Jenkins at build time
console.info('Build #develop_#414');

// Rendering the application.
render(
  PhotoPage,
  getComponentTree(
    BIMDocsAuthenticationComponent,
    BIMProjectsEntitlementsComponent,
    BIMAccountEntitlementsComponent,
    BIMContainersComponent,
    UsersComponent,
    LocationsComponent,
    IssuesComponent,
    ActivityLogComponent,
    ChecklistsComponent
  )
);

// https://gaearon.github.io/react-hot-loader/getstarted/
if (module.hot) {
  module.hot.accept('~/ui/photosPage.ui', () => {
    const PhotoPageNext = require('~/ui/photosPage.ui').default;
    render(PhotoPageNext);
  });
}
