import React from 'react';
import { FETCHING, ISSUE_CLASSES, ISSUE_STATUSES } from '~/common/constants';
import {Loader, StatusColorIndicator, Tooltip} from '@adsk/bim360-matrix-react-components';
import config from 'appConfig';
import T from '~/common/i18n';

const COLORS_MAPPING = {
  [ISSUE_STATUSES.DRAFT]: StatusColorIndicator.Colors.GRAY_DARK,
  [ISSUE_STATUSES.OPEN]: StatusColorIndicator.Colors.YELLOW,
  [ISSUE_STATUSES.ANSWERED]: StatusColorIndicator.Colors.BLUE,
  [ISSUE_STATUSES.CLOSED]: StatusColorIndicator.Colors.GRAY,
  [ISSUE_STATUSES.WORK_COMPLETED]: StatusColorIndicator.Colors.BLUE,
  [ISSUE_STATUSES.READY_TO_INSPECT]: StatusColorIndicator.Colors.PURPLE,
  [ISSUE_STATUSES.NOT_APPROVED]: StatusColorIndicator.Colors.RED,
  [ISSUE_STATUSES.IN_DISPUTE]: StatusColorIndicator.Colors.RED,
  [ISSUE_STATUSES.VOID]: StatusColorIndicator.Colors.GRAY
};
/**
 * Class to show info stored in an issue
 */
export class IssueInfoBox extends React.Component {

  /**
   * @inheritdoc
   */
  componentDidMount() {
    switch (this.props.issueType) {
      case ISSUE_CLASSES.QUALITY_ISSUE: {
        this.props.getQualityIssue(this.props.issueId);
        break;
      }
      case ISSUE_CLASSES.RFI: {
        this.props.getRFI(this.props.issueId);
        break;
      }
      case ISSUE_CLASSES.GENERIC: {
        // Request both Quality issues and RFI
        this.props.getGenericIssue(this.props.issueId);
        break;
      }
      default:
        break;
    }
  }

  /**
   * @inheritdoc
   */
  render() {
    if (!this.props.issue || this.props.issue === FETCHING) {
      return <Loader/>;
    } else if (this.props.issue && this.props.issue.attributes) {
      const attributes = this.props.issue.attributes;
      const statusColor = COLORS_MAPPING[attributes.status];
      let href, clickTooltipText;
      switch (this.props.issue.type) {
        case 'quality_issues':
          href = config.ORIGINS.DOCS + '/projects/' + this.props.projectId + '/issues/' + this.props.issueId;
          clickTooltipText = T.translate('photoAssetDataView.viewIssue'); // Click to view the issue
          break;
        case 'rfis':
          href = config.ORIGINS.PM + '/projects/' + this.props.projectId + '/rfis?preview=' + this.props.issueId;
          clickTooltipText = T.translate('photoAssetDataView.viewRFI'); // Click to view the RFI
          break;
        default:
          href = '';
          break;
      }

      return (
        <div className="linked-item-box">
          <Tooltip
            tooltipClassName={'issueTooltip StatusColorIndicator__' + statusColor}
            content={T.translate('issueStatuses.' + attributes.status)}
            place={Tooltip.Places.RIGHT}
            theme={Tooltip.Themes.DARK}
            showArrow={false}
          >
            <StatusColorIndicator
              classNames="linked-item-status"
              statusColor={statusColor}
            />
          </Tooltip>

          <div className="linked-item-identifier">
            {attributes.identifier}
          </div>

          <Tooltip
            content={clickTooltipText}
            place={Tooltip.Places.BOTTOM}
            theme={Tooltip.Themes.DARK}
          >
            <a
              className="linked-item-link"
              href={href}
            >
              {attributes.title}
            </a>
          </Tooltip>
        </div>
      );
    }
    // Issue Types not supported yet
    return null;
  }
}
