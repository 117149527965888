import {DataBinding} from '@adsk/forge-appfw-databinder';
import {migratePhotoAssetData} from '../helpers/dataMigrator';
import {
  PHOTO_ASSET_TYPEID_V103,
  PHOTO_ASSET_TYPEID_V101,
  PHOTO_ASSET_TYPEID_V110,
  PHOTO_ASSET_TYPEID_V120
} from '../helpers/constants';

const MAX_ASSETS_COUNT = 1000;
let assetsCount = 0;


/**
 * The following class serves as a DataBinding for migrating asset properties instantiated from schema version 1.0.1
 * to 1.0.3 or/and 1.0.3 to 1.1.0
 */
export class AssetMigrationBinding extends DataBinding {
  /**
   * @inheritdoc
   */
  onPostCreate(in_modificationContext) {
    const currentTypeId = in_modificationContext.getProperty().getTypeid();
    const oldToNewTypeid = {
      [PHOTO_ASSET_TYPEID_V101]: PHOTO_ASSET_TYPEID_V103,
      [PHOTO_ASSET_TYPEID_V103]: PHOTO_ASSET_TYPEID_V110,
      [PHOTO_ASSET_TYPEID_V110]: PHOTO_ASSET_TYPEID_V120
    };
    console.info(`An outdated UDP photo asset typeid is identified.
    Starting migration process from ${currentTypeId} to ${oldToNewTypeid[currentTypeId]}`);
    const property = this.getProperty();

    const asset = migratePhotoAssetData(property);

    this.getDataBinder().requestChangesetPostProcessing(async () => {
      const parent = property.getParent();
      const workspace = property.getWorkspace();
      parent.remove(property.getId());
      parent.insert(asset.getId(), asset);
      if (++assetsCount >= MAX_ASSETS_COUNT) {
        await workspace.commit();
        assetsCount = 0;
      }
      console.info('Migrating asset is done.');
    });
  }

}
