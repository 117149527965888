/**
 * @fileoverview This file defines the initial state of the app
 */

import { AppComponent } from '@adsk/forge-appfw-di';
import _ from 'lodash';
import { BinaryDataSource } from '@adsk/forge-hfdm';
import rootReducer from '~/redux/rootReducer';
import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import {
  INIT,
  HFDM_BINARY_UPLOAD_STATUS_MAP,
  INIT_FILTERS,
  ALL_AUX,
  SORTING_PHOTOS_OPTIONS
} from '~/common/constants';

import * as schemas from '~/resources/schemas';
import { StateTreeManager } from '~/common/helpers/stateTreeManager';
import { toTimeISOFormat } from '~/common/helpers/utils';
import { featureFlags } from '~/common/helpers/featureFlags';
import FLAGS from '~/common/featureFlags';
import { batchedSubscribe } from 'redux-batched-subscribe';
import { enableBatching } from 'redux-batched-actions';

const debounceNotify = _.debounce(notify => notify());


const PHOTO_ASSET_TYPEID = schemas.photoAsset.typeid;
/**
 * The main class for the initial state of the app
 * @public
 * @extends external:AppComponent
 */
export class StoreComponent extends AppComponent {

  /** Constructor
   * @constructor
   * @param {Object} params inherited parameters
   */
  constructor(params) {
    super(params);
    this._workspace = null;
  }

  /**
   * @inheritdoc
   */
  initialize(dependencies) {
    const {
      authData, DataBinderComponent, UsersComponent
    } = dependencies;

    this._config = authData.getAuthenticationData();
    this._workspace = DataBinderComponent.getWorkspace();
    this._usersService = UsersComponent;

    return Promise.resolve();
  }

  /**
   * create and returns the redux store
   * @param {AppComponent} apiArgument the argument we want to attach when creating the store
   * @return {Object} the redux store
   * @public
   */
  createReduxStore(apiArgument) {
    const reduxStore = this._createStore(this._getInitialState(), apiArgument);
    return reduxStore;
  }

  /**
   * The function maps UDP space to initial state for redux store.
   * @return {Object} Initial redux state from the UDP space.
   * @private
   */
  _getInitialState() {
    const assetsEntries = this._workspace.get(['root', 'assets']).getEntriesReadOnly();
    const initialState = {
      photos: {},
      deletedPhotos: [],
      undeletedPhotos: [],
      photosByUsers: {},
      photosByLocations: {},
      thumbnails: {},
      thumbnailsStatus: {},
      thumbnailsContent: {},
      thumbnailsUploadStatus: {},
      photosUploadStatus: {},
      photosStatus: {},
      photosContent: {},
      photosComposition: {},
      photosSelection: {},
      photosSources: {},
      photosPrivate: {},
      authenticationData: this._config
    };
    const photosStatusTreeManager = new StateTreeManager();
    const photosContentTreeManager = new StateTreeManager();
    const photosPrivateTreeManager = new StateTreeManager();
    const thumbnailStatusTreeManager = new StateTreeManager();
    const thumbnailContentTreeManager = new StateTreeManager();
    _.forEach(assetsEntries, (value, id) => {
      if (value.getFullTypeid() !== PHOTO_ASSET_TYPEID) {
        return;
      }
      const deleted = value.get('deleted').getValue();
      const trackable = value.get(['trackable']).getValues();
      const photo = value.get(['photo']).getValues();
      const location = value.getValue('location');
      const isPrivate = value.getValue(['acp', 'isPrivate']);
      let composition = value.get(['composition']);
      if (composition) {
        composition = composition.getValues();
      }
      const sources = value.get(['sources']);
      let references = [];
      if (sources) {
        references = sources.get('references').getValues();
      }
      const thumbnailUploadStatus = HFDM_BINARY_UPLOAD_STATUS_MAP[
        value.get(['thumbnail', 'status']).getEnumString()
      ];
      const photoUploadStatus = HFDM_BINARY_UPLOAD_STATUS_MAP[
        value.get(['content', 'status']).getEnumString()
      ];

      const createTime = toTimeISOFormat(value, 'createTime');
      const lastModifiedTime = toTimeISOFormat(value, 'lastModifiedTime');
      const uploadUserId = trackable.createUserId;
      const extension = photo.format;
      const name = photo.name;

      initialState.photos[id] = {
        name,
        id,
        createTime,
        lastModifiedTime,
        uploadUserId,
        extension
      };
      if (!initialState.photosByUsers[uploadUserId]) {
        initialState.photosByUsers[uploadUserId] = [];
      }
      initialState.photosByUsers[uploadUserId].push(id);
      if (!initialState.photosByLocations[location]) {
        initialState.photosByLocations[location] = [];
      }
      initialState.photosByLocations[location].push(id);

      initialState.photosSelection[id] = false;
      initialState.photosComposition[id] = composition || {rotation: 0};
      initialState.thumbnails[id] = {};
      thumbnailStatusTreeManager.addValue(id, {status: INIT}, false);
      thumbnailContentTreeManager.addValue(id, {
        url: null,
        dataSource: new BinaryDataSource.BlobDataSource()
      }, false);
      initialState.thumbnailsUploadStatus[id] = {status: thumbnailUploadStatus};
      initialState.photosUploadStatus[id] = {status: photoUploadStatus};
      photosStatusTreeManager.addValue(id, {status: INIT}, false);
      photosPrivateTreeManager.addValue(id, isPrivate);
      photosContentTreeManager.addValue(id, {
        url: null,
        dataSource: new BinaryDataSource.BlobDataSource()
      }, false);

      initialState.photosSources[id] = references;
      if (deleted) {
        initialState.deletedPhotos.push(id);
      } else {
        initialState.undeletedPhotos.push(id);
      }
    });

    initialState.photosStatus = photosStatusTreeManager.getStateTree();
    initialState.photosContent = photosContentTreeManager.getStateTree();
    initialState.photosPrivate = photosPrivateTreeManager.getStateTree();
    initialState.thumbnailsStatus = thumbnailStatusTreeManager.getStateTree();
    initialState.thumbnailsContent = thumbnailContentTreeManager.getStateTree();

    return initialState;
  }

  /**
   * This constructor should only be called once by the app. It is used to
   * initialize the redux store. The photosSDK is part of the state out of convenience
   * and should already be initialized before this store is created.
   * @param {Object} initialState The initial state of the app
   * @param {Object} photosSDK the photo SDK that connects to HFDM
   * @return {Object} The store for all the state in the app
   */
  _createStore(initialState, photosSDK) {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const currentUserId = initialState.authenticationData.user.id;
    const currentUser = this._usersService.getUser(currentUserId);
    const userIsAdmin = currentUser.is_project_admin;
    const privacyEnabled = featureFlags.get(FLAGS.PHOTOS_PRIVACY);
    const store = createStore(
      enableBatching(rootReducer),
      {
        ...initialState,
        activeAlbum: ALL_AUX,
        timeline: {
          groupingBy: 'day',
          isDescending: true,
          sortingType: SORTING_PHOTOS_OPTIONS.CAPTURE_TIME
        },
        uploadingPanel: false,
        uploadingPhotos: {},
        filters: INIT_FILTERS,
        displayedPhotosCount: 0,
        uploadsTooLarge: [],
        userDownloadLocked: false,
        showDownloadFailedWarning: false,
        features: {
          showPrivacy: privacyEnabled && userIsAdmin
        }
      },
      composeEnhancers(
        applyMiddleware(thunkMiddleware.withExtraArgument(photosSDK)),
        batchedSubscribe(debounceNotify)
      )
    );

    if (module.hot) {
      module.hot.accept('~/redux/rootReducer', () => {
        const nextRootReducer = require('~/redux/rootReducer');
        store.replaceReducer(nextRootReducer);
      });
    }

    return store;
  }

  /**
   * @inheritdoc
   */
  static defineDependencies() {
    return [
      {
        type: 'DataMigrationComponent'
      },
      {
        type: 'DataBinderComponent'
      },
      {
        type: 'AuthenticationDataComponent',
        as: 'authData'
      },
      {
        type: 'UsersComponent'
      }
    ];
  }
}
