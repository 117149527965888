import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import T from '~/common/i18n';
import {
  IconButton,
  Field,
  SelectDropdown,
  Button,
  TextInput,
  Loader
} from '@adsk/bim360-matrix-react-components';

import { LocationsDropdown } from '../controls/locationsDropdown.appfw';
import { ISSUE_MAX_TITLE_LENGTH } from '~/common/constants';

/**
 * React component for the Issues form.
*/
export class IssueFormUI extends React.Component {
  /**
   * @inheritdoc
   */
  constructor(props) {
    super(props);
    this.state = {
      type: null,
      subType: null,
      location: null,
      assignee: null,
      title: null,
      isSubmitting: false
    };
    this.renderDropdown = this.renderDropdown.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.renderFooter = this.renderFooter.bind(this);
    this.submitIssueForm = this.submitIssueForm.bind(this);
    this.closeForm = this.closeForm.bind(this);
  }

    /**
   * Helper function to map users data into dropdown options.
   * @param {Collection<User>} users A collection of users in the BIM360 service.
   * @return {Array<Object>} An array of dropdown items containing users information.
   * @private
   */
  _getUsersOptions(users) {
    const usersOptions = [];
    _.forEach(users, (user, userId) => {
      if (user.name && userId) {
        const option = {
          label: user.name,
          value: userId
        };
        if (user.roles) {
          option.subtext = user.roles.map(role => role.name).join(',');
        }
        usersOptions.push(option);
      }
    });
    return usersOptions;
  }

  /**
   * A helper to generate options from the issues.
   * @param {Array<IssueTypes>} issueTypes the types to create options from.
   * @return {Array<Object>} An array of options for the dropdown.
   */
  _getIssueTypeOptions(issueTypes) {
    const issueOptions = [];
    _.forEach(issueTypes, issueType => {
      if (issueType.title && issueType.id) {
        const option = {
          label: issueType.title,
          value: issueType.id
        };
        issueOptions.push(option);
      }
    });
    return issueOptions;
  }

  /**
   * A helper to generate options from the issues.
   * @param {Collection<IssueTypes>} issueType the types to create options from.
   * @return {Array<Object>} An array of options for the dropdown.
   */
  _getIssueSubTypeOptions(issueType) {
    if (!issueType) {
      return [];
    }
    const issueOptions = [];
    const issueSubTypes = issueType.subtypes;
    _.forEach(issueSubTypes, issueSubType => {
      if (issueSubType.title && issueSubType.id) {
        const option = {
          label: issueSubType.title,
          value: issueSubType.id
        };
        issueOptions.push(option);
      }
    });
    return issueOptions;
  }

  /**
   * handler for the onChange method in the dropdowns.
   * @param {String} key the name of the attribute in the state.
   * @param {Object} propertyName the property to extract from the object "value" by default.
   * @param {Object} selection the object defining the selected value.
   */
  onSelect(key, propertyName, selection) {
    this.setState({[key]: selection ? selection[propertyName] : null});
  }

  /**
   * Handles submitting the form to create a new issue.
   */
  submitIssueForm() {
    const {title, location, assignee, type, subType} = this.state;

    const params = {
      type: 'quality_issues',
      title: title,
      lbs_location: location,
      assigned_to: assignee,
      assigned_to_type: assignee ? 'user' : null,
      ng_issue_type_id: type,
      ng_issue_subtype_id: subType
    };

    this.setState({
      isSubmitting: true
    });

    this.props.submitIssueForm(params).then( () => {
      this.setState({
        isSubmitting: false,
        isError: false
      });
    })
    .catch(err => {
      // Something went wrong in the submission of the form
      console.error(err);
      this.setState( {
        isSubmitting: false,
        isError: true
      });
    });
  }

  /**
   * Updates the text in the Title field
   * @param {String} input title to be updated
   */
  updateTitle(input) {
    this.setState({
      title: input
    });
  }

  /**
   * Handles closing the form and resetting it's error state.
   */
  closeForm() {
    this.setState({
      isSubmitting: false,
      isError: false
    });
    this.props.closeIssueForm();
  }

  /**
   * Handles creating a dropdown with populated data
   * @param {String} label the label fo this field.
   * @param {Object[]} options the options to choose from in the dropdown.
   * @param {Function} onChange the handler to selecting an option.
   * @param {Boolean} isRequired defines whether this is a required field.
   * @return {SelectDropdown} The reactDOM dropdown element
   */
  renderDropdown(label, options, onChange, isRequired) {
    let className = 'issueField';
    if (isRequired) {
      className += ' mandatoryField';
    }
    const labelText = T.translate('createIssue.fields.' + label);
    return (
      <Field className={className} key={'Issue' + label + 'Field'} label={labelText}>
        <SelectDropdown
          options={options}
          labelKey="label"
          valueKey="value"
          className="IssueCreateForm__field-width"
          disabled={false}
          onChange={onChange}
          clearable={false}
          searchable={true}
          searchIcon={null}
          searchPlaceholder="Search"
        />
      </Field>
    );
  }

  /**
   * @return {Boolean} whether the form can be submitted or not.
   * @private
   */
  _canSubmit() {
    const {title, subType, type} = this.state;
    return title && subType && type && title.length <= ISSUE_MAX_TITLE_LENGTH;
  }

  /**
   * creates the header of the issues form
   * @return {ReactDOM} The react dom for the header.
   */
  renderHeader() {
    const {closeIssueForm} = this.props;
    return (
      <div className="modalHeader">
        <div className="header">
          {T.translate('createIssue.title')}
        </div>
        <IconButton id="ModalClose" onClick={closeIssueForm} svgId="modal-close"/>
      </div>
    );
  }

  /**
   * Renders the footer of the issue form
   * @return {ReactDOM} The reactDom for the footer
   */
  renderFooter() {
    return (
      <div className="footer">
        <Button
          id="SubmitIssueForm"
          styleType={Button.StyleType.PRIMARY}
          onClick={this.submitIssueForm}
          disabled={!this._canSubmit()}
        >
          {T.translate('createIssue.buttons.create')}
        </Button>
        <Button
          id="CloseForm"
          styleType={Button.StyleType.LINK_SECONDARY}
          onClick={this.closeForm}
        >
          {T.translate('createIssue.buttons.cancel')}
        </Button>
      </div>
    );
  }

  /**
   * Handles rendering the body of the form
   * @return {ReactDOM} The dom of the form body
   */
  renderBody() {
    return (
      <div className="formBody">
        {this.state.isError ?
          <div className="errorMessage">
            {T.translate('createIssue.errors.createFailed')}
          </div> : null
        }
        {this.renderDropdown('type',
          this._getIssueTypeOptions(this.props.issueTypes),
          this.onSelect.bind(this, 'type', 'value'),
          true
        )}
        {this.renderDropdown('subtype',
          this._getIssueSubTypeOptions(this.props.issueTypes[this.state.type]),
          this.onSelect.bind(this, 'subType', 'value'),
          true
        )}
        <Field
          className="issueField mandatoryField"
          key="IssueTitleField"
          label={T.translate('createIssue.fields.title')}
        >
          <TextInput
            id="FormTitle"
            onBlur={this.updateTitle.bind(this)}
            value={this.state.title}
            placeholder="Title"
            validationProps={{
              validator: {
                message: T.translate('createIssue.errors.titleTooLong', { limit: ISSUE_MAX_TITLE_LENGTH }),
                validate: text => !(text && text.length > ISSUE_MAX_TITLE_LENGTH)
              }
            }}
          />
        </Field>
        <Field
          className="issueField"
          key="IssueLocationField"
          label={T.translate('createIssue.fields.location')}
        >
          <LocationsDropdown
            placeholder={T.translate('controls.dropdown.placeholder')}
            onLocationChange={this.onSelect.bind(this, 'location', 'id')}
          />
        </Field>
        {this.renderDropdown('assignee',
          this._getUsersOptions(this.props.users),
          this.onSelect.bind(this, 'assignee', 'value')
        )}
      </div>
    );
  }

  /**
   * @inheritdoc
   */
  render() {
    if (!this.props.showIssueForm) return null;
    return (
      <div className="issueModal">
        <div className="issueForm" style={{position: 'relative'}}>
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
          {this.state.isSubmitting ?
            <div className="submittingFormLoader">
              <Loader/>
            </div> : null
          }
        </div>
      </div>
    );
  }
}

IssueFormUI.propTypes = {
  showIssueForm: PropTypes.bool,
  users: PropTypes.object,
  issueTypes: PropTypes.object
};
