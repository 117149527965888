import {
  withAppComponent
} from '@adsk/forge-appfw-react';
import UsersDropdownUI from './usersDropdown.ui';

/**
 * functional React component for users dropdown
 * @public
 */
export const UsersDropdown = withAppComponent({
  instances: {
    users: {type: 'UsersComponent'}
  },
  mapAppComponents: ({users}) => ({users: users.getUsers()})
})(UsersDropdownUI);
