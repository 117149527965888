/**
 * @fileoverview This file emulates the UDP Component class
 */

/**
 * Emulation of parts of the UDP Thick SDK
 */
export class Component {
  /**
   * Constructor
   * @constructor
   * @param {Object} property HFDM property
   */
  constructor(property) {
    this.property = property;
  }

  /**
   * returns the value of the component
   * @return {Object} value or values of the component
   */
  getData() {
    if (this.property.isPrimitiveType()) {
      return (this.property).getValue();
    }
    return (this.property).getValues();
  }

  /**
   * updates the value of the component
   * @param {Object} value or values of the component
   */
  update(value) {
    let updatingProperty = this.property;
    if (this.property.isPrimitiveType()) {
      (updatingProperty).setValue(value);
    } else {
      if (typeof value === 'object') {
        (updatingProperty).setValues(value);
      } else {
        throw new Error('value should be of object type.');
      }
    }
  }

  /**
   * helper function for debug
   * @param {Function} printFct callback for the print
   */
  prettyPrint(printFct) {
    this.property.prettyPrint(printFct);
  }
}
