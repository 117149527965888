import {getReactView, registerReactView} from '@adsk/forge-appfw-react';
import {PhotoDataDateView} from './photoAssetView/photoDataDateView.ui';
import {AlbumDataDateView} from './albumAssetView/albumDataDateView.ui';
import {PhotoDataGenericView} from './photoAssetView/photoDataGenericView.ui';
import {AlbumDataGenericView} from './albumAssetView/albumDataGenericView.ui';
import * as schemas from '~/resources/schemas';
import {PhotoDataLocationView} from './photoAssetView/photoDataLocationView.redux';
import {ButtonPrivateToggleView} from './photoAssetView/photoPrivateToggleView.redux';


const photoBindingType = 'PhotoDataView';
const albumBindingType = 'AlbumDataView';
const photoLocationBindingType = 'PhotoLocationView';
const photoPrivateToggleBindingType = 'PhotoPrivateToggleView';

registerReactView(photoBindingType, 'String', PhotoDataGenericView, {mapHFDMToProps: {'': 'value'}});
registerReactView(photoBindingType, schemas.time.typeid, PhotoDataDateView, {mapHFDMToProps: {'iso8601': 'time'}});
registerReactView(photoLocationBindingType, 'String', PhotoDataLocationView, {mapHFDMToProps: {'': 'value'}});
registerReactView(photoPrivateToggleBindingType, 'Bool', ButtonPrivateToggleView, {mapHFDMToProps: {'': 'value'}});

registerReactView(albumBindingType, 'String', AlbumDataGenericView, {mapHFDMToProps: {'': 'value'}});
registerReactView(albumBindingType, schemas.time.typeid, AlbumDataDateView, {mapHFDMToProps: {'iso8601': 'time'}});

export const PhotoDataView = getReactView(photoBindingType);
PhotoDataView.displayName = 'PhotoDataView';
export const AlbumDataView = getReactView(albumBindingType);
AlbumDataView.displayName = 'AlbumDataView';
export const PhotoLocationView = getReactView(photoLocationBindingType);
PhotoLocationView.displayName = 'PhotoLocationView';
export const PhotoPrivateToggleView = getReactView(photoPrivateToggleBindingType);
PhotoPrivateToggleView.displayName = 'PhotoPrivateToggleView';
