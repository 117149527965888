/**
 * @fileoverview This file emulates the UDP Asset class
 */
import {PropertyFactory} from '@adsk/forge-hfdm';
import {Component} from './component';
import {RelationshipDirection, ReservedPaths} from '../helpers';
import {UDP_SDK_BINDING_TYPE} from '../udpSdk';
import _ from 'lodash';
/**
 * Emulation of parts of the UDP Thick SDK
 */
export class Asset {
  /**
   * Constructor
   * @param {Object} assetProperty HFDM pset
   * @param {Object} space UDP space
   */
  constructor(assetProperty, space) {
    this.guid = assetProperty.getGuid();
    this.property = assetProperty;
    this.space = space;
  }

  /**
   * Create a component
   * @param {String} name name of the property to create
   * @param {Object} component definition of the component
   * @param {'single'|'map'|'set'} context The component context value.
   * @return {BaseProperty} newly created component
   */
  createComponent(name, component, context = 'single') {
    const componentProperty = PropertyFactory.create(component.typeid, context, component.value, {
      workspace: this.space.getWorkspace()
    });
    this.property.insert(name, componentProperty);
    return new Component(name, componentProperty);
  }

  /**
   * Create and add a set of components on a given asset by defining by a key/value map.
   * Where the key describes the component name within the asset and the value is the list
   * of values that will initialize the component.
   * @param {Map<string, BaseProperty | string>} components - Object describing the components to create.
   * @return {Component[]} A list of the created components.
   */
  createComponents(components) {
    const createdComponents = {};
    Object.keys(components).forEach(key => {
      const component = components[key];
      createdComponents[key] = (this.createComponent(key, component));
    });
    return createdComponents;
  }

  /**
   * Lists the asset's outgoing relationships guids for the current asset in the same space.
   * @return {Array<Relationship>} An array of relationships guids for the given assetGuid and direction.
   * @private
   */
  getOutgoingRelationshipsGuids() {
    return this.property.get([ReservedPaths.relationships]).getIds();
  }

  /**
   * Checks whether the current asset has a relationship with another asset given a direction.
   * @param {String} assetGuid - Asset guid.
   * @param {RelationshipDirection} [direction=RelationshipDirection.Outgoing] (in|out) direction of the relationships
   * to get incoming or outgoing relative to the current asset.
   * @return {Boolean} True if the current asset has a relationship with another asset given relationship direction.
   */
  hasRelationshipWith(assetGuid, direction = RelationshipDirection.Outgoing) {
    switch (direction) {
      case RelationshipDirection.Incoming: {
        const incomingRelationships = this.space.relationships.incoming[this.guid];
        return !!_.find(incomingRelationships, rel => {
          return this.space.relationships.allFrom[rel] === assetGuid;
        });
      }
      case RelationshipDirection.Outgoing: {
        const outgoingRelationships = this.getOutgoingRelationshipsGuids();
        return !!_.find(outgoingRelationships, rel => {

          return this.space.relationships.allTo[rel] === assetGuid;
        });
      }
      case RelationshipDirection.Bidirectional:
        return this.hasRelationshipWith(assetGuid, RelationshipDirection.Outgoing) ||
          this.hasRelationshipWith(assetGuid, RelationshipDirection.Incoming);
      default:
        throw new Error(`The direction provided ${direction} is not supported.`);
    }
  }

  /**
   * Returns a relationship runtime representation if the relationship exists.
   * @param {String} relationshipGuid - Relationship property guid.
   * @return {Relationship|undefined} The relationship runtime representation o/w undefined if the property doesn't
   * exist.
   */
  getRelationship(relationshipGuid) {
    const relationships = this.property.get(ReservedPaths.relationships);
    return this.space.getDataBinder().getRepresentation(relationships.get(relationshipGuid), UDP_SDK_BINDING_TYPE);
  }

  /**
   * Checks whether the asset has a specific relationship or not.
   * @param {String} relationshipGuid - Relationship guid.
   * @return {Boolean} True if the asset has relationship with the provided guid.
   */
  hasRelationship(relationshipGuid) {
    const relationships = this.property.get(ReservedPaths.relationships);
    return relationships.has(relationshipGuid);
  }

  /**
   * Deletes the relationship between two assets defined in the space for a given relationship guid.
   * @param {String} relationshipGuid A guid of a relationship to delete.
   */
  removeRelationship(relationshipGuid) {
    const relationships = this.property.get(ReservedPaths.relationships);
    relationships.remove(relationshipGuid);
  }

  /**
   * Get the component object.
   * @param {string} name - Name of the component to get.
   * @return {Component | undefined} Component object with the given name.
   */
  getComponent(name) {
    const component = this.property.get(name);
    if (!component) {
      throw new Error(`The following component doesn't not exist: ${name}.`);
    }
    return new Component(this.property.get(name));
  }

  /**
   * Returns the list of components used in the asset.
   * @return {Array} Component object with the given name.
   */
  getComponents() {
    const entries = this.property.getEntriesReadOnly();
    const components = Object.map(entries, (value, key) => {
      return new Component(key, value);
    });
    return components;
  }
}
