import React from 'react';
import { DropTarget as dropTarget } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';

const { FILE } = NativeTypes;

const collect = function(connect, monitor) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDropTarget: connect.dropTarget(),
    // You can ask the monitor about the current drag state:
    isOver: monitor.isOver(),
    isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop(),
    itemType: monitor.getItemType()
  };
};

/**
 * React component for the BIM Photo uploader
 * @public
 */
export class FilesDropzonePure extends React.Component {
  /**
   * React rendering of file dropzone
   * @public
   * @return {FilesDropzone} Uploader box
   */
  render() {
    let classes = ['Dropzone'];

    // These props are injected by React DnD,
    // as defined by your `collect` function above:
    const { isOver, canDrop, connectDropTarget, selected, children } = this.props;

    if (isOver && canDrop) {
      classes.push('accept');
    }

    if (isOver && !canDrop) {
      classes.push('reject');
    }
    if (selected) {
      classes.push('selected');
    }

    if (this.props.className) {
      classes = classes.concat(this.props.className.split(' '));
    }

    return connectDropTarget(
      <div className={classes.join(' ')}>
        {children}
      </div>
    );
  }
}

export const FilesDropZone = dropTarget(
  FILE,
  {
    drop(props, monitor) {
      const droppedFiles = monitor.getItem().files;
      props.uploadPhotos(droppedFiles, props.albumId);
    }
  },
  collect
)(FilesDropzonePure);
