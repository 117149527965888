import { TextInput, Editable } from '@adsk/bim360-matrix-react-components';
import React, {useState} from 'react';
import {
  InputUITypes,
  ValidationTypes
} from '@adsk/bim360-matrix-react-text-input';

export const EditablePhotoAlbumItem = ({id, initialName, onInlineEditEnd }) => {
  const [name, setName] = useState(initialName);

  return (
    <Editable
      className="Editable"
      readOnly={false}
      multiline={false}
      inEdit={true}
    >
      {closeEditMode => (
        <TextInput
          id={`textInput-${id}`}
          inputUIType={InputUITypes.INLINE_EDIT}
          inputProps={{
            autofocus: true,
            autoselect: true,
            className: 'EditableAlbumName',
            onInlineEditEnd: onInlineEditEnd(closeEditMode, id)
          }}
          validationProps={{
            type: ValidationTypes.POPOVER,
            required: true
          }}
          value={name}
          onChange={text => setName(text)}
        />
      )}
    </Editable>
  );
};
