import _ from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import HTML5Backend from 'react-dnd-html5-backend';
import { DragDropContextProvider } from 'react-dnd';
import { AppContainer } from 'react-hot-loader';

import matrixPolyfills from '@adsk/bim360-matrix-react-components/es/polyfills';
import { HFDMConnectionComponent, HFDMWorkspaceComponent } from '@adsk/forge-appfw-hfdm';
import { ReactPropertyViewComponent, ComponentTreeProvider } from '@adsk/forge-appfw-react';
import { ComponentTree } from '@adsk/forge-appfw-di';
import { DataBinderComponent } from '@adsk/forge-appfw-databinder';
import { SVGStore } from '@adsk/bim360-matrix-react-components';

import { PropertyViewProvider } from '~/appfw/providers/propertyViewProvider.appfw';
import { StoreComponent } from '~/appfw/components/store.component';
import { PhotosSchemasRegistrationComponent } from '~/appfw/components';
import { PhotosComponent } from '~/appfw/components/photos.component';
import { FeatureFlagsComponent } from '~/appfw/components/BIM360/featureFlags.component';
import { DataMigrationComponent } from '~/appfw/components/dataMigration.component';
import { BindingsComponent } from '~/appfw/components/photosBindings.component';
import { AuthenticationDataComponent } from '~/appfw/components/authenticationData.component';
import { PhotosStoreProvider } from '~/appfw/providers/photosStoreProvider.appfw';
import { BIM360AnalyticsComponent } from '~/appfw/components/BIM360/BIM360Analytics.component';
import { PhotosAnalyticsComponent } from '~/appfw/components/photosAnalytics.component';

import PhotosErrorBoundary from '~/ui/errors/photosErrorBoundary.appfw';

import { PhotoHeader } from '~/ui/header/header.appfw';

import { UdpSDKComponent } from '~/appfw/components/UDP/udpSDK.component';

import { appConfigurations, appParams } from './configs';

import svgs from '~/resources/svgs';
import '~/styles/app.scss';

const PHOTOS_SPACE_NAME = 'BIM360-Photos';

/**
 * Handles loading the bim360 components polyfills
 * @return {Promise} An array of resolved polyfills
 */
function initPolyfills() {
  return Promise.all(matrixPolyfills());
}

export const getComponentTree = (
  AuthenticationComponent,
  ProjectsEntitlementsComponent,
  AccountEntitlementsComponent,
  ContainersComponent,
  UsersComponent,
  LocationsComponent,
  IssuesComponent,
  ActivityLogComponent,
  ChecklistsComponent,
  ...extraComponents) => {

  let extraMainProviders = _.filter(extraComponents, ({mainProvider}) => mainProvider).map(({type}) => ({type}));

  const componentTree = new ComponentTree({
    // Registering the providers used for the Photos app.
    providers: [
      // Registering the photo app configuration providers.
      ...appConfigurations,

      // Registering other providers.
      {type: 'AuthenticationComponent', useClass: AuthenticationComponent},
      {type: 'HFDMConnectionComponent', useClass: HFDMConnectionComponent},
      {type: 'HFDMWorkspaceComponent', useClass: HFDMWorkspaceComponent},
      {type: 'DataMigrationComponent', useClass: DataMigrationComponent},
      {type: 'DataBinderComponent', useClass: DataBinderComponent},
      {type: 'ReactPropertyViewComponent', useClass: ReactPropertyViewComponent},
      {type: 'PhotosSchemasRegistrationComponent', useClass: PhotosSchemasRegistrationComponent},
      {type: 'BIMProjectsEntitlementsComponent', useClass: ProjectsEntitlementsComponent},
      {type: 'BIMAccountsEntitlementsComponent', useClass: AccountEntitlementsComponent},
      {type: 'BIMContainersComponent', useClass: ContainersComponent},
      {type: 'UdpSDKComponent', useClass: UdpSDKComponent, spaceName: PHOTOS_SPACE_NAME},
      {type: 'FeatureFlagsComponent', useClass: FeatureFlagsComponent},
      {type: 'PhotosComponent', useClass: PhotosComponent},
      {type: 'StoreComponent', useClass: StoreComponent},
      {type: 'UsersComponent', useClass: UsersComponent},
      {type: 'LocationsComponent', useClass: LocationsComponent},
      {type: 'IssuesComponent', useClass: IssuesComponent},
      {type: 'ChecklistsComponent', useClass: ChecklistsComponent},
      {type: 'ActivityLogComponent', useClass: ActivityLogComponent},
      {type: 'BindingsComponent', useClass: BindingsComponent},
      {type: 'AuthenticationDataComponent', useClass: AuthenticationDataComponent},
      {type: 'BIM360AnalyticsComponent', useClass: BIM360AnalyticsComponent},
      {type: 'PhotosAnalyticsComponent', useClass: PhotosAnalyticsComponent},

      // Register extra providers
      ...extraComponents
    ],
    app: [
      ...appParams,

      // Using the application's main providers.
      {type: 'PhotosSchemasRegistrationComponent'},
      {type: 'ReactPropertyViewComponent'},
      {type: 'PhotosComponent'},
      {type: 'BindingsComponent'},

      // Extra main provider
      ...extraMainProviders
    ]
  });

  return componentTree;
};

export const render = (Component, componentTree) => {
  initPolyfills()
    .then(() => {
      ReactDOM.render(
        // Keep DragDropContextProvider as the top provider or it could get instantiated
        // more than once during hot reload and cause a fatal error.
        // There's other possible fix in this tread if this doesn't fix the random
        // "cannot setup html5backend two times" error.
        // https://github.com/react-dnd/react-dnd/issues/894#issuecomment-367378188
        <AppContainer>
          <div id="root">
            <SVGStore key="SVGStore" svgs={svgs}/>
            <DragDropContextProvider backend={HTML5Backend}>
              <ComponentTreeProvider componentTree={componentTree}>
                <PhotoHeader key="header"/>
                <PhotosErrorBoundary>
                  <PhotosStoreProvider key="PhotosStoreProvider">
                    <PropertyViewProvider>
                      <Component/>
                    </PropertyViewProvider>
                  </PhotosStoreProvider>
                </PhotosErrorBoundary>
              </ComponentTreeProvider>
            </DragDropContextProvider>
          </div>
        </AppContainer>,
        document.getElementById('app')
      );
    });
};
