import React, { PureComponent } from 'react';
import classNames from 'classnames';

/**
 * React component for the PhotoContentImage
 * @public
 */
export class PhotoContentImage extends PureComponent {
  /**
   * @inheritdoc
   */
  constructor(props) {
    super(props);

    this.state = {
      naturalWidth: 0,
      naturalHeight: 0
    };

    this.imageElement = React.createRef();
    this._applyRotation = this._applyRotation.bind(this);
    this._getComputedDimensionsOfImage = this._getComputedDimensionsOfImage.bind(this);
  }

  /**
   * @inheritdoc
   */
  componentDidUpdate(prevProps) {
    if (this.props.isResponsive &&
        this.imageElement.current &&
        prevProps.src !== this.props.src &&
        (!this.state.naturalWidth || !this.state.naturalHeight)
    ) {
      this.imageElement.current.onload = this._getComputedDimensionsOfImage;
    }
  }

  /**
   * Sets the state to be the true calculated dimensions of the image
   */
  _getComputedDimensionsOfImage() {
    const tempWidth = this.imageElement.current.width;
    const tempHeight = this.imageElement.current.height;
    this.imageElement.current.onload = null;
    this.setState({
      naturalWidth: tempWidth,
      naturalHeight: tempHeight
    });
  }

  /**
   * @inheritdoc
   */
  componentWillUnmount() {
    this.imageElement.current.onload = null;
  }

  /**
   * Apply rotation to the style passed in order to rotate, if needed, the image using this style.
   * @public
   * @param {Object} style The style being modified.
   * @return {Object} The modified style.
   */
  _applyRotation() {
    const style = {};
    if (this.props.rotation % 180 &&
        this.state.naturalWidth &&
        this.state.naturalHeight
    ) {
      style.height = this.state.naturalWidth + 'px';
      style.width = this.state.naturalHeight + 'px';
    }
    return style;
  }

  /**
   * React rendering of the PhotoContentImage
   * @public
   * @return {PhotoContentImage} PhotoContentImage
   */
  render() {
    const classes = classNames({
      'rotate90': this.props.rotation === 90,
      'rotate180': this.props.rotation === 180,
      'rotate270': this.props.rotation === 270,

      'image': !this.props.isResponsive,
      'editableImage': this.props.isResponsive,
      'thumbnail': this.props.shouldRenderThumbnail && !this.props.isResponsive
    });
    return (
      <img
        className={classes}
        src={this.props.src}
        ref={this.imageElement}
        style={this._applyRotation()}
      />
    );
  }
}
