import {
  withAppComponent
} from '@adsk/forge-appfw-react';
import {PhotoAssetDataViewUI} from './photoAssetDataView.ui';
import React from 'react';

const getAsset = (() => {
  let lastState = {photoId: null};
  let lastResult;
  return (function(photoId, photosComponent) {
    if (lastState.photoId === photoId) {
      return lastResult;
    } else {
      lastState = {photoId};
    }
    lastResult = photosComponent.getSpace().getAsset(photoId);
    return lastResult;
  });
})();

export const PhotoAssetDataView = withAppComponent({
  instances: {
    photosComponent: {type: 'PhotosComponent'},
    users: {type: 'UsersComponent'},
    locations: {type: 'LocationsComponent'}
  }
})(({appComponents, ...restProps}) => {
  const {photosComponent, users, locations} = appComponents;
  const asset = getAsset(restProps.photoId, photosComponent);
  return (<PhotoAssetDataViewUI asset={asset} users={users.getUsers()}
    locations={locations.getPhotosLocationsMap()} {...restProps}/>
  );
});
