import React from 'react';
import PropTypes from 'prop-types';

import {
  ToggleButton
} from '@adsk/bim360-matrix-react-components';
import T from '~/common/i18n';
import {ALL_AUX, TRASH} from '~/common/constants';

/**
 * React component for the special Album data view
 * @return {ReactDOM} The react to render
 */
export class SelectionInfo extends React.Component {

  /**
   * @private
   * handles select all button click
   * handles the functionality depending on the album type (all photos, trash or album asset)
   */
  _performSelectionAction() {
    switch (this.props.activeAlbum) {
      case ALL_AUX:
        if (this.props.hasUnselected) {
          this.props.selectAllPhotos();
        } else {
          this.props.deselectAllPhotos();
        }
        break;
      case TRASH:
        this.props.selectPhotosSet(this.props.deletedPhotos, this.props.hasUnselected);
        break;
      default:
        this.props.selectPhotosSet(this.props.activePhotos(), this.props.hasUnselected);
    }
  }

  /**
   * @inheritdoc
   */
  render() {
    return (
      <div className="assetsCountDisplay">
        {T.translate('albumDataView.selectionInfo', {
          context: this.props.totalPhotosNb,
          selectedPhotos: this.props.selectedPhotosNb
        })}
        <ToggleButton className="selectAllToggleBtn"
                      label={T.translate('albumDataView.selectAll')}
                      onClick={this._performSelectionAction.bind(this)}
                      checked={!this.props.hasUnselected}
        />
      </div>
    );
  }
}

SelectionInfo.propTypes = {
  selectPhotosSet: PropTypes.func,
  activePhotos: PropTypes.func,
  deselectAllPhotos: PropTypes.func,
  activeAlbum: PropTypes.string,
  selectedPhotosNb: PropTypes.number,
  totalPhotosNb: PropTypes.number,
  hasUnselected: PropTypes.bool
};
