import React from 'react';

import {withAppComponent} from '@adsk/forge-appfw-react';
import Provider from 'react-redux/es/components/Provider';
import PhotoLoading from '~/ui/widgets/photoLoading.ui';

export const PhotosStoreProvider = withAppComponent({
  sync: true,
  fallback: <PhotoLoading/>,
  instances: {
    photos: {type: 'PhotosComponent'}
  }
})(({appComponents, children, ...restProps}) => (
  <Provider store={appComponents.photos.getStore()} {...restProps}>
    {children}
  </Provider>
));
