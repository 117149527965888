/**
 * @fileoverview This file implements some of the functions
 * present in the new UDP SDK proposal. These will become obsolete once
 * the UDP Thick SDK is released
 */

import {PropertyFactory} from '@adsk/forge-hfdm';
import {Urn} from './urn';
import {ReservedPaths} from './helpers';

import * as schemas from './schemas';
import {Asset, Relationship, Space} from './representations';

import config from 'appConfig';
import {UDPRelationshipBinding} from './bindings/UDPRelationshipBinding';

/**
 * All UDP representations/bindings are registered to this entity type.
 * @type {string}
 */
export const UDP_SDK_BINDING_TYPE = 'UDP_SDK';
const BRANCH_GUID_PREFIX = 'urn:adsk.lynx:branch:';
const SCOPE_GUID = '000000000000';

/**
 * An experimental UDP sdk class which offers an utility function for initializing and interacting with UDP space.
 */
export class UdpSdk {
  /**
   * Extract the global unique id of the resource from the branchUrn.
   * @static
   * @param {String} branchUrn HFDM branch Urn
   * @return {String} returns the extracted id
   */
  static parseGuidFromBranchUrn(branchUrn) {
    return branchUrn.substr(BRANCH_GUID_PREFIX.length);
  }

  /**
   * The UDP sdk constructor.
   * @constructor
   * @param {DataBinder} dataBinder A DataBinder instance that is attached to an empty HFDM workspace or an
   * initialized UDP space.
   */
  constructor(dataBinder) {
    this._workspace = dataBinder.getWorkspace();
    this._dataBinder = dataBinder;

    if (!this._workspace) {
      throw Error('The provided DataBinder instance is not attached to a workspace.' +
        ' Please use .attach before creating the UDP SDK.');
    }

    this._defineUDPRepresentations();
    this._dataBinder.defineDataBinding(UDP_SDK_BINDING_TYPE, schemas.relationship.typeid, UDPRelationshipBinding);
  }

  /**
   * Creates a UDP space
   * @param {String} name the name of the space
   * @return {Space} A UDP space representation.
   */
  createSpace(name) {
    const udpRoot = this._workspace.get([ReservedPaths.root]);
    if (!this.isUdpSpace()) {
      console.info('The workspace provided is not a UDP space, will try to create one.');
      if (udpRoot) {
        throw new Error(`The reserved path "${ReservedPaths.root}" already exists, but it's type
         ${udpRoot.getFullTypeid()} is not a valid UDP space typeid.`);
      }
      const branchGuid = UdpSdk.parseGuidFromBranchUrn(this._workspace.getActiveUrn());
      const urn = Urn.generateSpaceUrn(config.ENV_NAME, SCOPE_GUID, branchGuid);
      this._space = PropertyFactory.create(
        schemas.space.typeid,
        null,
        {
          name: name,
          guid: urn
        });
      this._workspace.insert(ReservedPaths.root, this._space);
      this._workspace.commit();
    } else {
      console.warn('Calling create space on already existing UDP space.');
    }
    return this.getSpace();
  }

  /**
   * Getter for UDP space.
   * @return {Space} returns a UDP space representation.
   */
  getSpace() {
    if (!this.isUdpSpace()) {
      throw new Error('Not valid UDP space, did you forget to call first createSpace,' +
        ' before calling this method.');
    }
    const spaceProp = this._workspace.get([ReservedPaths.root]);
    return this._dataBinder.getRepresentation(spaceProp, UDP_SDK_BINDING_TYPE);
  }

  /**
   * Checks whether the given workspace is a valid UDP space or not.
   * @return {boolean} True iff the workspace is a UDP space.
   */
  isUdpSpace() {
    const udpRoot = this._workspace.get([ReservedPaths.root]);
    return !!(udpRoot && PropertyFactory.inheritsFrom(udpRoot.getFullTypeid(),
      schemas.space.typeid, {workspace: this._workspace}));
  }

  /**
   * The function is responsible for registering base representations for UDP schemas.
   * @private
   */
  _defineUDPRepresentations() {
    this._dataBinder.defineRepresentation(UDP_SDK_BINDING_TYPE, schemas.space.typeid,
      property => new Space(property, this._dataBinder),
      {
        /**
         * A method to initialize the UDP space runtime representation.
         * This is an essential step to break a potential cycle caused from creating the Space runtime
         * representation as it has the side effect of creating other representations in the UDP space. Which can be
         * solved by splitting the representation creation into making step and initializing step.
         * @param {Space} spaceRep The runtime representation of the space.
         * @param {BaseProperty} property The UDP space property.
         * @param {String} bindingType The binding type used to define the representation.
         * @private
         */
        initializer: (spaceRep, property, bindingType) => {
          this._dataBinder.activateDataBinding(bindingType, schemas.relationship.typeid, {
            includePrefix: spaceRep.getAssetsProperty().getAbsolutePath(),
            userData: {
              relationships: spaceRep.relationships
            }
          });
        }
      });
    this._dataBinder.defineRepresentation(UDP_SDK_BINDING_TYPE, schemas.asset.typeid,
      property => new Asset(property, this.getSpace(), this._dataBinder), {stateless: true});
    this._dataBinder.defineRepresentation(UDP_SDK_BINDING_TYPE, schemas.relationship.typeid,
      property => new Relationship(property, this._dataBinder), {stateless: true});
  }
}
