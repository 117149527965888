import React from 'react';
import {
  PhotoDataView,
  PhotoLocationView,
  PhotoPrivateToggleView
} from '..';
import { Field } from '@adsk/bim360-matrix-react-components';
import {
  ACTIVITY,
  ISSUE_DOMAIN,
  CHECKLIST_DOMAIN_DEV,
  TRASH,
  CHECKLIST_DOMAIN_PRD,
  CHECKLIST_DOMAIN_QA,
  CHECKLIST_DOMAIN_STG
} from '~/common/constants';
import PropTypes from 'prop-types';
import T from '~/common/i18n';
import { ConnectedIssueInfoBox } from '~/ui/propertyViews/photoAssetView/attachmentViews/issueInfoBox.redux';
import { ConnectedChecklistInfoBox } from '~/ui/propertyViews/photoAssetView/attachmentViews/checklistInfoBox.redux';

const renderLocationView = (isTrash, locationProp, locations) => {
  if (isTrash) {
    const locationNameValue =  locations[locationProp.value] ? locations[locationProp.value].name : 'None';
    return (
      <Field
        id="Location"
        className ="ImageDataField"
        label={T.translate('photoAssetDataView.location')}>
        <div className="dateField">
          {locationNameValue}
        </div>
      </Field>
    );
  } else {
    return (
      <PhotoLocationView
        id="Location"
        name={T.translate('photoAssetDataView.location')}
        property={locationProp}
        locations={locations}/>
    );
  }
};

const renderDeletionTimeView = deletionTime => {
  return (
    <PhotoDataView
      id="DeletedTime"
      name={T.translate('photoAssetDataView.deleteTime')}
      property={deletionTime}/>
  );
};

/**
 * React component to display and edit the properties of a photo
 * @param {HFDMAsset} asset the asset that is to be edited
 * @return {ReactDOM} The react to render
 */
export const PhotoAssetDataViewUI = ({asset, users, sources, locations, activeAlbum, showPrivacyFeature}) => {
  const _nameProperty = asset.property.get(['photo', 'name']);
  const _locationProperty = asset.property.get('location');
  const _createTimeProperty = asset.property.get(['trackable', 'createTime']);
  const _deletionTime = asset.property.get(['trackable', 'lastModifiedTime']);
  const _uploadedBy = asset.property.get(['trackable', 'createUserId']);
  const _isPrivate = asset.property.get(['acp', 'isPrivate']);
  const isTrash = activeAlbum === TRASH;
  let issuesSection;
  if (sources && sources.length) {
    const issuesLinks = sources.reduce((acc, source) => {
      switch (source.domain) {
        case ISSUE_DOMAIN: {
          acc.push(
            <ConnectedIssueInfoBox
              issueType={source.type}
              issueId={source.id}
              key={source.id}
            />
          );
          break;
        }
        case CHECKLIST_DOMAIN_DEV:
        case CHECKLIST_DOMAIN_QA:
        case CHECKLIST_DOMAIN_STG:
        case CHECKLIST_DOMAIN_PRD: {
          const [checklistId, itemId] = source.id.split('/');
          acc.push(
            <ConnectedChecklistInfoBox
              checklistType={source.type}
              checklistId={checklistId}
              itemId={itemId}
              key={source.id}
            />
          );
          break;
        }
        default:
          break;
      }
      return acc;

    }, []);
    issuesSection = (
      <Field
        className="ImageDataField"
        label={T.translate('photoAssetDataView.linkedTo')}>
        <div>
          {issuesLinks}
        </div>
      </Field>
    );
  }

  return (
    <div className="photoDataEditor">
      <PhotoDataView
        id="Name"
        name={T.translate('photoAssetDataView.caption')}
        readOnly={isTrash}
        verb={ACTIVITY.VERBS.RENAME_PHOTO}
        guid={asset.guid}
        property={_nameProperty}/>
      {renderLocationView(isTrash, _locationProperty, locations)}
      <PhotoDataView
        id="UploadedTime"
        name={T.translate('photoAssetDataView.uploadTime')}
        property={_createTimeProperty}/>
      <PhotoDataView
        id="UploadedBy"
        name={T.translate('photoAssetDataView.uploadBy')}
        property={_uploadedBy}
        readOnly
        valueRenderer={value => users[value] && users[value].name}
      />
      {isTrash ? renderDeletionTimeView(_deletionTime) : null}
      {issuesSection}
      {!isTrash && showPrivacyFeature ?
        <PhotoPrivateToggleView
          id="Private"
          name="Private"
          property={_isPrivate}
        /> : null
      }
    </div>
  );
};

PhotoAssetDataViewUI.propTypes = {
  asset: PropTypes.object.isRequired,
  projectId: PropTypes.string.isRequired,
  users: PropTypes.objectOf(PropTypes.object).isRequired,
  issues: PropTypes.arrayOf(PropTypes.object),
  locations: PropTypes.object
};

PhotoAssetDataViewUI.defaultProps = {
  issues: []
};
