import React from 'react';
import {
  SelectDropdown
} from '@adsk/bim360-matrix-react-components';
import T from '~/common/i18n';
import _ from 'lodash';


/**
 * React component for the BIM Users Dropdown UI
 * @public
 */
class UsersDropdown extends React.Component {

  /**
   * Helper function to map users data into dropdown options.
   * @return {Array<Object>} An array of dropdown items containing users information.
   * @private
   */
  _getUsersOptions() {
    const usersOptions = [];
    _.forEach(this.props.users, (user, userId) => {
      if (user.name && userId) {
        const option = {
          label: user.name,
          value: userId
        };
        if (user.roles) {
          option.subtext = user.roles.map(role => role.name).join(',');
        }
        usersOptions.push(option);
      }
    });
    return usersOptions;
  }

  /**
   * React rendering of users dropdownin the filterControl
   * @public
   * @return {UsersDropdown} the react component for UsersDropdown
   */
  render() {
    const options = this._getUsersOptions();
    const value = this.props.userFilter;
    const selectedOptions = _.find(options, option => {
      return option.value === value;
    });

    return (
      <React.Fragment>
        <label htmlFor="filter-uploader">{T.translate('filterControl.filters.uploader')}</label>
        <SelectDropdown id="filter-uploader"
          clearable
          selectedOptions={selectedOptions}
          value={value}
          onChange={this.props.onUserChange}
          options={options}
          searchable
          placeholder={T.translate('filterControl.filters.allText')}
        />
        <hr/>
      </React.Fragment>);
  }
}

export default UsersDropdown;
