import React, {PureComponent} from 'react';

import {PhotoContentBroken} from './photoContentBroken.ui';
import {PhotoContentUploading} from './photoContentUploading.ui';
import {ConnectedPhotoContentRaster} from './photoContentRaster.redux';

import {
  UPLOAD_FAILED,
  FETCHED,
  INIT,
  UPLOADING
} from '~/common/constants';

/**
 * React rendering of the PhotoContent
 * @public
 * @return {PhotoContent} PhotoContent
 */
export class PhotoContent extends PureComponent {

  /**
   * The status the current photo
   * @typedef {object} PhotoStatus
   * @property {string} src The photo src (Full size or thumbnail)
   * @property {string} status The photo status
   */

  /**
   * Return the current status of the photo as well as it's source.
   * Will pick between full image and thumbnail according to availability.
   * @param {Boolean} shouldRenderThumbnail boolean deciding whether to return the photo or thumbnail
   * @return {string} The status.
   */
  getPhotoStatus(shouldRenderThumbnail) {
    let status;

    // Get Status
    if (this.props.photoUploadStatus === UPLOAD_FAILED ||
      this.props.photoUploadStatus === UPLOADING) {
      status = this.props.photoUploadStatus;
    } else if (shouldRenderThumbnail) {
      status = this.props.thumbnailContentStatus;
    } else {
      status = this.props.photoContentStatus;
    }

    return status;
  }

  /**
   * @inheritdoc
   */
  render() {
    const thumbnailStatus = {
      uploadStatus: this.props.thumbnailUploadStatus,
      fetchStatus: this.props.thumbnailContentStatus
    };
    const photoStatus = {
      uploadStatus: this.props.photoUploadStatus,
      fetchStatus: this.props.photoContentStatus
    };
    const shouldRenderThumbnail = this.props.shouldRenderThumbnail(thumbnailStatus, photoStatus);

    const status = this.getPhotoStatus(shouldRenderThumbnail);

    switch (status) {
      case UPLOADING:
        return <PhotoContentUploading className="uploadingImageLoader"/>;
      case UPLOAD_FAILED:
        return <PhotoContentBroken/>;
      case INIT:
        return this.props.light ? <div className="lightImage"/> : null;
      case FETCHED: {
        return (
          <ConnectedPhotoContentRaster
            isResponsive={this.props.isResponsive}
            shouldRenderThumbnail={shouldRenderThumbnail}
            photoId={this.props.photoId}
          />
        );
      }
      default:
        return <div className="lightImage"/>;
    }
  }
}
