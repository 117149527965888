import React from 'react';
import T from '~/common/i18n';
import {
  Button,
  SvgIcon
} from '@adsk/bim360-matrix-react-components';

/**
 * React component for FilterWarning
 * @public
 */
class FilterWarning extends React.Component {

  /**
   * @inheritdoc
   */
  render() {
    return (
      <div className="filterWarning">
        <SvgIcon key="icon" width="20px" height="20px" svgId="info-circle"/>
        <span className="caption">{T.translate('filterControl.warning.caption')}</span>
        <Button
          styleType={Button.StyleType.INLINE_LINK}
          onClick={this.props.clearFilter}
        >
          {T.translate('filterControl.warning.clearAllButton')}
        </Button>
      </div>
    );
  }
}

export default FilterWarning;
