import { combineReducers } from 'redux';

import photosSelection from './reducers/selectionReducer';
import photosStatus from './reducers/statusReducer';
import photosContent from './reducers/contentReducer';
import photosComposition from './reducers/compositionReducer';
import photosPrivate from './reducers/privateReducer';
import photoViewer from './reducers/photoViewerReducer';
import thumbnailsContent from './reducers/thumbnailsContentReducer';
import thumbnailsStatus from './reducers/thumbnailsStatusReducer';
import photosSources from './reducers/sourcesReducer';
import {issues, issueForm} from './reducers/issuesReducer';
import {checklists} from './reducers/checklistsReducer';
import {albums} from './reducers/photoAlbumsReducer';
import {storage} from './reducers/storageReducer';


import {
  PHOTO_REMOVED,
  PHOTO_RESTORED,
  REMOVE_ALBUM,
  ADD_PHOTO,
  ADD_PHOTO_TO_ALBUM,
  UPDATE_TRACKABLE,
  SET_AUTHENTICATION,
  VIEW_SELECTED_PHOTOS,
  CLOSE_VIEWER,
  SET_TIME_GROUPBY,
  SET_SORTING_ORDER,
  SET_SORTING_TYPE,
  UPDATE_THUMBNAIL_UPLOAD_STATUS,
  UPDATE_UPLOAD_STATUS,
  UPDATE_FILTER,
  UPDATE_PHOTO_LOCATION,
  UPDATE_DISPLAYED_PHOTOS_COUNT,
  ADD_TOO_LARGE_FILES,
  CLEAR_TOO_LARGE_FILES,
  OPEN_UPLOADER_PANEL,
  CLOSE_UPLOADER_PANEL,
  ADD_UPLOADING_PHOTO,
  REMOVE_UPLOADING_PHOTO,
  INIT,
  LOCK_DOWNLOAD,
  UNLOCK_DOWNLOAD,
  UPDATE_PHOTO,
  SET_ACTIVE_ALBUM,
  ALL_AUX,
  TRIGGER_MODAL_VISIBILITY,
  TRASH,
  SORTING_PHOTOS_OPTIONS,
  OPEN_FAILED_DOWNLOAD_WARNING,
  CLOSE_FAILED_DOWNLOAD_WARNING
} from '~/common/constants';

/**
 * A reducer to handle the state tree of a photo
 * @param {Photo} state the current state of a photo
 * @param {Action} action An action dictating change on a photo
 * @return {State} The next state of the photo.
 */
function photo(state = {}, action) {
  switch (action.type) {
    case UPDATE_TRACKABLE: {
      const newState = Object.assign({}, state);
      newState.createTime = new Date(action.trackable.createTime.iso8601);
      newState.lastModifiedTime = new Date(action.trackable.lastModifiedTime.iso8601);
      newState.uploadUserId = action.trackable.createUserId;
      return newState;
    }
    case UPDATE_PHOTO: {
      const newState = Object.assign({}, state);
      newState.extension = action.photo.format;
      newState.name = action.photo.name;
      return newState;
    }
    default:
      return state;
  }
}

/**
 * A reducer function for the photos state
 * @param {[Object]} state This state is the photo list
 * @param {Object} action the action to define the change of state
 * @return {Object} The new state after the reducer takes effect
 */
function photos(state = {}, action) {

  switch (action.type) {
    case ADD_PHOTO: {
      const newPhotoID = action.image.id;
      const newPhoto = {
        [newPhotoID]: Object.assign({}, state[newPhotoID], action.image)
      };
      return {...state, ...newPhoto};
    }
    case UPDATE_PHOTO:
    case UPDATE_TRACKABLE: {
      const newState = Object.assign({}, state);
      const photoID = action.id;
      newState[photoID] = photo(newState[photoID], action);
      return newState;
    }
    default: {
      return state;
    }
  }
}

/**
 * A reducer function for deletedPhotos state
 * @param {[Object]} state This state is the array of deleted photos ids
 * @param {Object} action the action to define the change of state
 * @return {Object} The new state after the reducer takes effect
 */
function deletedPhotos(state = {}, action) {
  switch (action.type) {
    case PHOTO_REMOVED: {
      return [...state, ...action.ids];
    }
    case PHOTO_RESTORED: {
      return state.filter(item => action.ids.indexOf(item) === -1);
    }
    default:
      return state;
  }
}

/**
 * A reducer function for undeletedPhotos state
 * @param {[Object]} state This state is the array of undeleted photos ids
 * @param {Object} action the action to define the change of state
 * @return {Object} The new state after the reducer takes effect
 */
function undeletedPhotos(state = {}, action) {
  switch (action.type) {
    case ADD_PHOTO: {
      return [...state, action.image.id];
    }
    case ADD_PHOTO_TO_ALBUM: {
      return [...state, action.photoId];
    }
    case PHOTO_REMOVED: {
      return state.filter(item => action.ids.indexOf(item) === -1);
    }
    case PHOTO_RESTORED: {
      return [...state, ...action.ids];
    }
    default:
      return state;
  }
}
/**
 * A reducer function for the upload status state
 * @param {Object} state This state is the uploader tracker list
 * @param {Object} action the action to define the change of state
 * @return {Object} The new state
 */
function uploadingPhotos(state = {}, action) {
  switch (action.type) {
    case ADD_UPLOADING_PHOTO: {
      const newState = Object.assign({}, state);
      newState[action.photoID] = {};
      return newState;
    }
    case REMOVE_UPLOADING_PHOTO: {
      const newState = Object.assign({}, state);
      delete newState[action.photoID];
      return newState;
    }
    default: {
      return state;
    }
  }
}

/**
 * A reducer function for the upload panel
 * @param {Object} state This state is the uploader tracker list
 * @param {Object} action the action to define the change of state
 * @return {Object} The new state
 */
function uploadingPanel(state = false, action) {
  switch (action.type) {
    case OPEN_UPLOADER_PANEL: {
      return true;
    }
    case CLOSE_UPLOADER_PANEL: {
      return false;
    }
    default:
      return state;
  }
}

/**
 * A reducer function for the upload file size warning
 * @param {Object} state This state is the uploader tracker list
 * @param {Object} action the action to define the change of state
 * @return {Object} The new state
 */
function uploadsTooLarge(state = [], action) {
  switch (action.type) {
    case ADD_TOO_LARGE_FILES: {
      return  [...state, ...action.rejectedFiles];
    }
    case CLEAR_TOO_LARGE_FILES: {
      return [];
    }
    default: {
      return state;
    }
  }
}


/**
 * just a placeholder for the combineReducers for now, do not use.
 * @param {Object} state should be the photosSDK
 * @param {Object} action auth action
 * @return {Object} a new state
 */
function authenticationData(state = {}, action) {
  switch (action.type) {
    case SET_AUTHENTICATION: {
      return Object.assign({}, state, action.data);
    }
    default:
      return state;
  }
}

/** Reducer for the photoGallery state (controls if the viewer is open or not)
 * @param {bool} state the old state
 * @param {Object} action the action defining the change of state
 * @return {bool} A new state defining whether or not the viewer is open
 */
function photoGallery(state = true, action) {
  switch (action.type) {
    case VIEW_SELECTED_PHOTOS: {
      return false;
    }
    case CLOSE_VIEWER: {
      return true;
    }
    default:
      return state;
  }
}

/** Reducer for the download button locking state
 * @param {bool} state the old state
 * @param {Object} action the action defining the change of state
 * @return {bool} Actions
 */
function userDownloadLocked(state = false, action) {
  switch (action.type) {
    case LOCK_DOWNLOAD: {
      return true;
    }
    case UNLOCK_DOWNLOAD: {
      return false;
    }
    default:
      return state;
  }
}

/** Reducer for the features state
 * @param {bool} state the old state
 * @param {Object} action the action defining the change of state
 * @return {bool} Actions
 */
function features(state = {}, action) {
  return state;
}
/**
 * Reducer for timeline, to store all timeline related data as part of the application state.
 * @param {Object} state the old state
 * @param {Object} action the action defining the change of state
 * @return {Object} A new state defining the new timeline properties
 */
function timeline(state = {}, action) {
  switch (action.type) {
    case SET_TIME_GROUPBY: {
      const groupingBy = action.groupingBy;
      return {...state, groupingBy: groupingBy};
    }
    case SET_SORTING_ORDER: {
      const isDescending = action.isDescending;
      return {...state, isDescending};
    }
    case SET_SORTING_TYPE: {
      const sortingType = action.sortingType;
      return {...state, sortingType};
    }
    case SET_ACTIVE_ALBUM: {
      return action.albumId !== TRASH ? {...state, sortingType: SORTING_PHOTOS_OPTIONS.CAPTURE_TIME} : state;
    }
    default:
      return state;
  }
}

/**
 *
 * @param {Object} state the old state
 * @param {Object} action the action defining the change of state
 * @return {Object} A new state defining the thumbnails
 */
function thumbnails(state = {}, action) {
  switch (action.type) {
    case ADD_PHOTO: {
      const newState = Object.assign({}, state);
      newState[action.image.id] = {};
      return newState;
    }
    default : {
      return state;
    }
  }
}

/**
 *
 * @param {Object} state the old state
 * @param {Object} action the action defining the change of state
 * @return {Object} A new state defining the thumbnails
 */
function thumbnailsUploadStatus(state = {}, action) {
  switch (action.type) {
    case ADD_PHOTO:
    case UPDATE_THUMBNAIL_UPLOAD_STATUS: {
      return {...state, ...{[action.id || action.image.id]: {status: action.status || INIT}}};
    }
    default : {
      return state;
    }
  }
}

/**
 *
 * @param {Object} state the old state
 * @param {Object} action the action defining the change of state
 * @return {Object} A new state defining the thumbnails
 */
function photosUploadStatus(state = {}, action) {
  switch (action.type) {
    case ADD_PHOTO:
    case UPDATE_UPLOAD_STATUS: {
      return {...state, ...{[action.id || action.image.id]: {status: action.status || INIT}}};
    }
    default : {
      return state;
    }
  }
}

/**
 * @param {Object} state the old state
 * @param {Object} action the action defining the change of state
 * @return {Object} A new state defining the list of users uploaded photos.
 */
function photosByUsers(state = {}, action) {
  switch (action.type) {
    case UPDATE_TRACKABLE: {
      const trackable = action.trackable;
      const userId = trackable.createUserId;
      if (!userId || state[userId] && state[userId].indexOf(action.id) !== -1) {
        return state;
      }
      const user = {
        [userId]: [action.id, ...(state[userId] || [])]
      };
      const newState = Object.assign({}, state, user);
      return newState;
    }
    default: {
      return state;
    }
  }
}

/**
 * @param {Object} state the old state
 * @param {Object} action the action defining the change of state
 * @return {Object} A new state defining the list of users uploaded photos.
 */
function photosByLocations(state = {}, action) {
  switch (action.type) {
    case UPDATE_PHOTO_LOCATION: {
      const photoId = action.id;
      const newState = Object.assign({}, state);
      const photosByLocation = newState[action.location];
      if (!photosByLocation) {
        newState[action.location] = [photoId];
      } else {
        newState[action.location].push(photoId);
      }
      return newState;
    }
    default: {
      return state;
    }
  }
}

/**
 * @param {Object} state the old state
 * @param {Object} action the action defining the change of state
 * @return {Object} A new state defining the current filters status.
 */
function filters(state = {}, action) {
  switch (action.type) {
    case UPDATE_FILTER: {
      const filter = action.filter;
      return {...state, ...filter};
    }
    default: {
      return state;
    }
  }
}

/**
 * @param {Object} state the old state
 * @param {Object} action the action defining the change of state
 * @return {Object} A new state defining the current filters status.
 */
function activeAlbum(state = {}, action) {
  switch (action.type) {
    case SET_ACTIVE_ALBUM: {
      return action.albumId;
    }
    case REMOVE_ALBUM: {
      return action.albumId === state ? ALL_AUX : state;

    }
    default: {
      return state;
    }
  }
}

/**
 * @param {Object} state the old state
 * @param {Object} action the action defining the change of state
 * @return {Object} A new state defining the current filters status.
 */
function displayedPhotosCount(state = {}, action) {
  switch (action.type) {
    case UPDATE_DISPLAYED_PHOTOS_COUNT: {
      return action.count;
    }
    default: {
      return state;
    }
  }
}

/**
 * @param {Object} state the old state
 * @param {Object} action the action defining the change of state
 * @return {Object} A new state defining the current modals status.
 */
function modals(state = {}, action) {
  switch (action.type) {
    case TRIGGER_MODAL_VISIBILITY: {
      const newState = Object.assign({}, state);

      newState[action.name] = action.value;
      newState['currentPhotoId'] = action.currentPhotoId;

      return newState;
    }
    default:
      return state;
  }
}

/**
 * Reducer for the download failed warning
 * @param {bool} state the old state
 * @param {Object} action the action defining the change of state
 * @return {bool} Actions
 */
function showDownloadFailedWarning(state = false, action) {
  switch (action.type) {
    case OPEN_FAILED_DOWNLOAD_WARNING: {
      return true;
    }
    case CLOSE_FAILED_DOWNLOAD_WARNING: {
      return false;
    }
    default:
      return state;
  }
}

const rootReducer = combineReducers({
  // Updated when connecting to UDP space
  activeAlbum,
  albums,
  photos,
  deletedPhotos,
  undeletedPhotos,
  photosByUsers,
  photosByLocations,
  thumbnails,
  thumbnailsStatus,
  thumbnailsContent,
  thumbnailsUploadStatus,
  photosUploadStatus,
  photosStatus,
  photosContent,
  photosComposition,
  photosPrivate,
  photosSelection,
  photosSources,
  storage,
  // Updated only when interacting with the app
  photoViewer,
  issueForm,
  issues,
  checklists,
  authenticationData,
  photoGallery,
  timeline,
  uploadingPhotos,
  uploadingPanel,
  displayedPhotosCount,
  uploadsTooLarge,
  filters,
  userDownloadLocked,
  showDownloadFailedWarning,
  modals,
  features
});

export default rootReducer;
