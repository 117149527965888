import React from 'react';
import { SvgIcon } from '@adsk/bim360-matrix-react-components';

/**
 * @return {SvgIcon} Component for the PhotoContentBroken
 * @public
 */
export function PhotoContentBroken() {
  return (
    <SvgIcon width="150px" height="150px" svgId="icon_thumbnail-extract-failure"/>
  );
}
