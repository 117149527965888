import React from 'react';
import ReactDOM from 'react-dom';
import {
  Editable,
  TextInput,
  InputUITypes,
  OverflowTooltip,
  Tooltip
} from '@adsk/bim360-matrix-react-components';

import PropTypes from 'prop-types';


/**
 * A generic react component to view an editable text field.
 */
export class EditableInput extends React.Component {

  /**
   * @inheritdoc
   */
  constructor(props) {
    super(props);

    this.state = {
      truncated: false,
      hovered: false
    };

    this.childEditable = React.createRef();

    this._handleChange = this._handleChange.bind(this);
    this._onMouseEnter = this._onMouseEnter.bind(this);
    this._onMouseLeave = this._onMouseLeave.bind(this);
    this._onEditOpen = this._onEditOpen.bind(this);
  }

  /**
   * The method is triggered while the user editing the text field.
   * @param {Function} closeEditMode Function passed to be called in order to close edit mode.
   * @param {String|Number} value The changed value
   * @private
   */
  _handleChange(closeEditMode, value) {
    if (value) {
      const oldValue = this.props.property.value;
      this.props.property.setValue(value);
      this.props.property.getWorkspace().commit().then(() => {
        this.props.logActivity(this.props.verb, this.props.guid, value, { oldName: oldValue });
      });
    }
    closeEditMode();
  }

  /**
   * Event called on onMouseEnter
   */
  _onMouseEnter() {
    if (!this.state.hovered) {
      this.setState({ hovered: true });
    }
  }

  /**
   * Event called on onMouseLeave
   */
  _onMouseLeave() {
    if (this.state.hovered) {
      this.setState({ hovered: false });
    }
  }

  /**
   * Event called on focus of the TextInput
   * Used to know the edit mode was open to hide the tooltip.
   */
  _onEditOpen() {
    if (this.state.hovered) {
      this.setState({ hovered: false });
    }
  }

  /**
   * @inheritdoc
   */
  componentDidUpdate() {
    // Here we look at the Editable text field to see if it's currently overflowing.
    const node = ReactDOM.findDOMNode(this.childEditable.current); // eslint-disable-line react/no-find-dom-node
    const textNode = node.children[0] && node.children[0].children[0] || false;

    if (textNode && textNode.className === 'Editable__text Editable__text--single-line') {
      if (textNode.scrollWidth > textNode.clientWidth && !this.state.truncated) {
        this.setState({ truncated: true });
      } else if (textNode.scrollWidth === textNode.clientWidth && this.state.truncated) {
        this.setState({ truncated: false });
      }
    } else {
      // We opened the edit mode and should hide the tooltip.
      if (this.state.truncated) {
        this.setState({ truncated: false });
      }
    }
  }

  /**
   * @inheritdoc
   */
  render() {
    const displayedValue = this.props.valueRenderer(this.props.value || this.props.defaultValue);
    return (
      <div
        className="EditableWrapper"
        onMouseEnter={() => this._onMouseEnter()}
        onMouseLeave={() => this._onMouseLeave()}
      >
        <OverflowTooltip
          singleLine={true}
          show={(this.state.hovered && this.state.truncated)}
          content={displayedValue}
          theme={Tooltip.Themes.DARK}
          place={Tooltip.Places.BOTTOM}
        >
          <Editable
            value={displayedValue}
            readOnly={this.props.readOnly}
            multiline={false}
            ref={this.childEditable}
          >
            {closeEditMode => (
              <TextInput
                id="EditableTextInput"
                inputUIType={InputUITypes.INLINE_EDIT}
                value={this.props.value}
                inputProps={{
                  autofocus: true,
                  onInlineEditEnd: this._handleChange.bind(this, closeEditMode)
                }}
                onFocus={() => this._onEditOpen()}
              />
            )}
          </Editable>
        </OverflowTooltip>
      </div>
    );
  }
}

EditableInput.propTypes = {
  readOnly: PropTypes.bool,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  valueRenderer: PropTypes.func
};

EditableInput.defaultProps = {
  readOnly: false,
  value: '',
  defaultValue: 'None',
  valueRenderer: value => value
};
