import React from 'react';
import { FETCHING, CHECKLIST_STATUSES } from '~/common/constants';
import {Loader, StatusColorIndicator, Tooltip} from '@adsk/bim360-matrix-react-components';
import config from 'appConfig';
import T from '~/common/i18n';


const COLORS_MAPPING = {
  [CHECKLIST_STATUSES.NOT_STARTED]: StatusColorIndicator.Colors.GRAY,
  [CHECKLIST_STATUSES.IN_PROGRESS]: StatusColorIndicator.Colors.YELLOW,
  [CHECKLIST_STATUSES.COMPLETED]: StatusColorIndicator.Colors.BLUE
};

const STATUS_MAPPING = {
  [CHECKLIST_STATUSES.NOT_STARTED]: 'checklistStatuses.not_started',
  [CHECKLIST_STATUSES.IN_PROGRESS]: 'checklistStatuses.in_progress',
  [CHECKLIST_STATUSES.COMPLETED]: 'checklistStatuses.completed'
};

/**
 * Class to show info stored in an issue
 */
export class ChecklistInfoBox extends React.Component {

  /**
   * @inheritdoc
   */
  componentDidMount() {
    this.props.fetchChecklist(this.props.checklistId);
  }

  /**
   * @inheritdoc
   */
  render() {
    const {checklist, itemId} = this.props;
    if (!checklist || checklist === FETCHING) {
      return <Loader/>;
    } else if (checklist.items && checklist.items[itemId]) {
      const item = checklist.items[itemId];
      const statusColor = COLORS_MAPPING[checklist.status.id];
      let href, clickTooltipText;
      href = config.ORIGINS.FIELD + '/projects/' + this.props.projectId + '/checklists/' + this.props.checklistId;
      clickTooltipText = T.translate('photoAssetDataView.viewChecklist'); // Click to view the checklist

      return (
        <div className="linked-item-box">
          <Tooltip
            tooltipClassName={'issueTooltip StatusColorIndicator__' + statusColor}
            content={T.translate(STATUS_MAPPING[checklist.status.id])}
            place={Tooltip.Places.RIGHT}
            theme={Tooltip.Themes.DARK}
            showArrow={false}
          >
            <StatusColorIndicator
              classNames="linked-item-status"
              statusColor={statusColor}
            />
          </Tooltip>

          <div className="linked-item-identifier">
            {item.sectionNumber + '.' + item.number}
          </div>

          <Tooltip
            content={clickTooltipText}
            place={Tooltip.Places.BOTTOM}
            theme={Tooltip.Themes.DARK}
          >
            <a
              className="linked-item-link"
              href={href}
            >
              {item.title}
            </a>
          </Tooltip>
        </div>
      );
    }
    // Checklist item not in checklist (should never happen)
    return null;
  }
}
