import {
  Button
} from '@adsk/bim360-matrix-react-components';
import * as React from 'react';

/**
 * A ui component to display a custom button used for delete album workflow
 */
export class CustomLargeButton extends React.Component {

/**
 * @inheritdoc
 */
  render() {
    return (
      <Button className="largeCustomBtn" onClick={this.props.onClick}>
        <span className="headerBtn">{this.props.header}</span><br/>
        <div className="bodyBtn">{this.props.body}</div><br/>
        <span className="footerBtn">{this.props.footer}</span>
      </Button>
    );
  }
}
