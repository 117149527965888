import React from 'react';
import T from '~/common/i18n';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { SvgIcon, IconButton, Button } from '@adsk/bim360-matrix-react-components';

/**
 * React component for the BIM Photo Filter control
 * @public
 */
class FailedDownloadWarning extends React.Component {
  /**
   * Constructor
   * @param {Object} props the props passed down
   */
  constructor(props) {
    super(props);

    this.handleClose = this.handleClose.bind(this);
    this.handleRetry = this.handleRetry.bind(this);
  }

  /**
   * Handle closing the snackbar
   * @param {*} event The event
   * @param {*} reason The reason
   */
  handleClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }

    this.props.close();
  }

  /**
   * Handle retry
   */
  handleRetry() {
    this.props.retry();
  }

  /**
   * React rendering of the failed downloads warning
   * @public
   * @return {FailedDownloadWarning} FailedDownloadWarning
   */
  render() {
    return (
      <Snackbar
        className="snackbar"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={this.props.show}
        onClose={this.handleClose}
      >
        <SnackbarContent
          className="snackbarContent"
          aria-describedby="client-snackbar"
          message={
            <span id="downloadFailedWarning" className="snackbarMessage">
              <span className="level">
                <SvgIcon key="icon" width="16px" height="16px" svgId="snackbar-exclamation"/>
              </span>
              <span className="message">{T.translate('downloadFailedWarning.message')}</span>
              <Button
                className="retryBtn"
                onClick={this.handleRetry}
              >
                {T.translate('downloadFailedWarning.retryBtn')}
              </Button>
            </span>
          }
          action={[
            <IconButton
              key="close-btn"
              svgId="snackbar-close"
              onClick={this.handleClose}
              iconWidth="16px"
              iconHeight="16px"
              className="close"
              data-tip={T.translate('controls.rotateRight.tooltip')}
            />
          ]}
        />
      </Snackbar>
    );
  }
}

export { FailedDownloadWarning };
