import {
  SET_SOURCES
} from '~/common/constants';

/**
 * The metadata of an issue
 * @typedef {Object} Issue
 * @property {String} id The id of the linked issue.
 * @property {String} type The type of issue it is.
 * @property {String} domain the domain containing the issue.
 */

/**
 * Handles updating an issue in the state.
 * @param {GUID} photoID The photo id to add the issues to.
 * @param {Issue[]} issues The metadata of the issues in docs.
 * @return {Object} A redux action
 */
export function setSources(photoID, issues) {
  return ({
    type: SET_SOURCES,
    photoID,
    issues
  });
}

/**
 * Handles adding an issue to a photo in the state.
 * @param {GUID} photoId The photo id to add the issue to.
 * @param {Issue} issue The metadata of the issue in docs.
 * @return {Function} A redux thunk action
 */
export function addSource(photoId, issue) {
  return (dispatch, getState, PhotoComponent) => {
    return PhotoComponent.getSpace().getAsset(photoId).addSource(issue);
  };
}
