import classNames from 'classnames';
import {
  MatrixTable,
  OverflowTooltip,
  Tooltip
} from '@adsk/bim360-matrix-react-components';
import * as React from 'react';
import T from '~/common/i18n';

import {PhotoAlbumItem} from '../albumItem/albumItem.redux';
import {AlbumsPanelHeader} from '../albumsPanelHeader.ui';
import {ACCEPTED_IMAGE_FORMAT, ALL_AUX, TRASH} from '~/common/constants';
import {SpecialAlbum} from '../specialAlbum.ui';

import {AutoSizer} from 'react-virtualized';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {EditablePhotoAlbumItem} from '~/ui/sidebar/albumsPanel/editablePhotoAlbumItem.ui';
import ModalDeleteAlbum from '~/ui/modals/deleteAlbumModal.ui';


export const NEW_ALBUM_ROW = {
  id: 'NEW_ALBUM_ROW_ID'
};

/**
 * A ui component to display list of albums items.
 */
export class AlbumsList extends React.Component {

  static ROW_ACTION_KEYS = {
    RENAME: 'rename',
    UPLOAD: 'upload',
    DELETE_ALBUM: 'deleteAlbum'
  };

  static ROW_POSITION =  {
    TOP: 'top',
    BOTTOM: 'bottom'
  };

  /**
   * @inheritdoc
   */
  constructor(props) {
    super(props);
    this._columns = [{
      cellRenderer: this._renderCell.bind(this),
      dataKey: 'albumsList',
      key: 'albumsList',
      title: props.headerTitle,
      width: props.columnWidth
    }];

    this.state = {
      newAlbumRowMode: props.newAlbumRowMode,
      editableAlbumRowId: null,
      showAlbumDeletionModal: false,
      isDescending: false,
      lastRenamedAlbum: null
    };

    this.albumId = null;
    this._generateNewAlbumName = this._generateNewAlbumName.bind(this);
    this._onRowClick = this._onRowClick.bind(this);
    this._rowClasses = this._rowClasses.bind(this);
    this._onInlineEditEnd = this._onInlineEditEnd.bind(this);
    this._onNewAlbumButtonClick = this._onNewAlbumButtonClick.bind(this);
    this._onNewAlbumRowClose = this._onNewAlbumRowClose.bind(this);
    this._onSortAlbumsClick = this._onSortAlbumsClick.bind(this);
    this._handleRowAction = this._handleRowAction.bind(this);
    this._actionOptions = this._actionOptions.bind(this);
    this._handleAlbumDeletionModalVisibility = this._handleAlbumDeletionModalVisibility.bind(this);
    this.trashRowClick = this.props.onRowClick.bind(this, TRASH);
    this.allPhotosRowClick = this.props.onRowClick.bind(this, ALL_AUX);
  }

  /**
   * @inheritdoc
   */
  componentDidUpdate(prevProps) {
    const {newAlbumRowMode} = prevProps;
    if (this.props.newAlbumRowMode !== newAlbumRowMode) {
      this.setState({
        newAlbumRowMode: this.props.newAlbumRowMode
      });
    }
  }

  /**
   * Sort albums data by name and put them in the state
   * @param {Array} albumsData representing albums
   * @return {Array} sorted albumsData
   * @private
   */
  _sortAlbumsData(albumsData) {
    let data = _.sortBy(albumsData, album => album.name.toLowerCase())
                .map(({id, name}) => ({id, name}));

    if (this.state && this.state.isDescending) {
      data = data.reverse();
    }

    return data;
  }

  /**
   * Generate new album name
   * @param {String} defaultName of the new album
   * @return {String} new album name
   */
  _generateNewAlbumName(defaultName = T.translate('albumsPanel.newAlbum')) {
    // Generate array of album names
    const albumsName = _.values(this.props.albumsData).map(({name}) => name);

    /**
     * @param {Array} names list of names
     * @param {String} name to check against the names list
     * @return {Integer} representing the sequence number of the next name or -1 if not existing
     */
    function getNameSequenceNumber(names, name) {
      let nameSequenceNumber = -1;
      const regex = new RegExp(`(${name})[ ]*(\\((\\d+)\\))*`);

      names.forEach(albumName => {
        const result = regex.exec(albumName);
        if (result && result[3]) {
          const number = parseInt(result[3], 10);
          nameSequenceNumber = number > nameSequenceNumber ? number : nameSequenceNumber;
        } else if (result) {
          nameSequenceNumber = 0;
        }
      });

      return nameSequenceNumber;
    }

    let albumNameSequenceNumber = getNameSequenceNumber(albumsName, defaultName);
    return (albumNameSequenceNumber > -1) ? `${defaultName} (${albumNameSequenceNumber + 1})` : defaultName;
  }

  /**
   * custom logic to run when we close row editing
   *
   * @param {function} closeEditMode method called when editmode is closed
   * @param {string} id album id
   * @return {Function} to call when we close row editing
   * @private
   */
  _onInlineEditEnd(closeEditMode, id) {
    const {albumsData, modifyAlbumName, createAlbum} = this.props;

    return (value, isCanceled) => {
      if (value && !isCanceled) {
        if (albumsData[id]) {
          modifyAlbumName(id, value);
          this.setState({ lastRenamedAlbum: id });
        } else {
          createAlbum(value);
        }
      }
      this._onNewAlbumRowClose();
      closeEditMode();
    };
  }

  /**
   * Sets the visibility state of the album deletion modal for an album
   * @param {Boolean} [visibility] indicates if the modal for album deletion is visible or not
   * @private
   */
  _handleAlbumDeletionModalVisibility(visibility) {
    this.setState({
      showAlbumDeletionModal: visibility
    });
  }

  /**
   * The method creates an editable row for creating a new album.
   * @private
   */
  _onNewAlbumButtonClick() {
    this.setState({
      newAlbumRowMode: true
    });
  }

  /**
   * A click event handler for album items.
   * @private
   */
  _onRowClick({rowData}) {
    const {onRowClick} = this.props;
    const isDisabled = this.props.disabledRow(rowData.id);
    if (!isDisabled && rowData.id !== NEW_ALBUM_ROW.id) {
      onRowClick(rowData.id);
    }
  }

  /**
   * Called when closing the editable new album row.
   * @private
   */
  _onNewAlbumRowClose() {
    this.setState({
      newAlbumRowMode: false,
      editableAlbumRowId: null
    });
    this.props.onNewAlbumRowClose();
  }

  /**
   * The method handles actions available for an album item
   * @param {String} key Given key option
   * @param {Object} rowData the row data
   * @private
   */
  _handleRowAction({key, rowData}) {
    switch (key) {
      case AlbumsList.ROW_ACTION_KEYS.RENAME:
        this.setState({
          editableAlbumRowId: rowData.id
        });
        break;
      case AlbumsList.ROW_ACTION_KEYS.UPLOAD: {
        // Set a variable to keep the albumId
        this.albumId = rowData.id;
        // Get the hidden input and call click on it to open the file selector menu.
        document.getElementById('hiddenFileInput').click();
        break;
      }
      case AlbumsList.ROW_ACTION_KEYS.DELETE_ALBUM: {
        this.albumId = rowData.id;
        // we should not show the modal if the album has no photos
        if (this.props.albumsData[this.albumId].photos.length === 0) {
          this.props.deleteAlbum(this.albumId);
        } else {
          this._handleAlbumDeletionModalVisibility(true);
        }
        break;
      }
      default:
        console.warn(`${key} doesn't exist.`);
    }
  }

  /**
   * Handles uploading the files after clicking on upload in the options
   * @param {*} files files
   */
  _uploadPhotos(files) {
    // this.albumId is set by _handleRowAction
    const albumId = this.albumId;
    // Cleanup the variable.
    this.albumId = null;

    this.props.uploadPhotos(files, albumId);
  }

  /**
   * Handles deleting an album only without deleting its photos
   * or an album with its photos
   * @param {Boolean} isAlbumOnlyDelete true when the album should be deleted only, false else
   */
  _handleDeleteAlbum(isAlbumOnlyDelete) {
    const albumId = this.albumId;
    this.albumId = null;
    if (isAlbumOnlyDelete) {
      this.props.deleteAlbum(albumId);
    } else {
      this.props.deleteAlbumWithPhotos(albumId);
    }
    this._handleAlbumDeletionModalVisibility(false);
  }

  /**
   * A cell render function
   * @param {Object} rowData Row data.
   * @return {React.Component} Returns a react component.
   * @private
   */
  _renderCell({rowData}) {
    const {activeAlbum} = this.props;
    const {editableAlbumRowId} = this.state;
    const {id} = rowData;
    const newRow = (id === NEW_ALBUM_ROW.id);
    const editableRow = (id === editableAlbumRowId);
    const isEditable = newRow || editableRow;
    let {name} = rowData;

    if (newRow) {
      name = this._generateNewAlbumName(name);
    }

    const disabled = !isEditable && this.props.disabledRow(id);

    let component = null;
    if (isEditable) {
      component =
        <EditablePhotoAlbumItem
          id={id}
          initialName={name}
          onInlineEditEnd={this._onInlineEditEnd}
        />;
    } else {
      component =
        <OverflowTooltip
          singleLine={true}
          autoTipOnEllipsis={true}
          content={name}
          theme={Tooltip.Themes.DARK}
          place={Tooltip.Places.TOP}
        >
          <label className="AlbumTitle">{name}</label>
        </OverflowTooltip>;
    }

    return (
      <PhotoAlbumItem
        albumId={id}
        albumItemContent={component}
        selected={activeAlbum === id}
        disabled={disabled}
      />
    );
  }

  /**
   * The method toggles the album name sorting direction.
   * @private
   */
  _onSortAlbumsClick() {
    this.setState(({isDescending}) => {
      return {isDescending: !isDescending};
    });
  }

  /**
   * The method return action options for each album item in the album panel
   * @return {Array|null} options for an album item
   * @private
   */
  _actionOptions() {
    const actionOptions = [
      {
        key: AlbumsList.ROW_ACTION_KEYS.RENAME,
        label: T.translate('albumItem.options.rename')
      },
      {
        key: AlbumsList.ROW_ACTION_KEYS.UPLOAD,
        label: T.translate('albumItem.options.upload')
      },
      {
        key: AlbumsList.ROW_ACTION_KEYS.DELETE_ALBUM,
        label: T.translate('albumItem.options.deleteAlbum')
      }
    ];
    const pickedOptions = _.filter(actionOptions, ({key}) => {
      return this.props.albumRowOptions.indexOf(key) !== -1;
    });
    return pickedOptions;
  }

  /**
   *
   * @param {array} data albumData
   * @return {function} method called on ref of matrix table
   */
  _matrixTableRef(data) {
    return mtInstance => {
      if (mtInstance) {
        if (this.state.lastRenamedAlbum !== null) {
          // if an album was renamed before we rerendered, we scroll to it.
          const index = _.findIndex(data, ({ id: rowId }) => rowId === this.state.lastRenamedAlbum);
          mtInstance.scrollToRow(index);
          this.setState({ lastRenamedAlbum: null });
        } else if (this.state.newAlbumRowMode) {
          // the user clicked to add an album, we scroll to top.
          if (this.props.newAlbumRowPosition === AlbumsList.ROW_POSITION.TOP) {
            mtInstance.scrollToRow(0);
          } else {
            mtInstance.scrollToRow(data.length);
          }
        }
      }
    };
  }

  /**
   * calculates row classes
   *
   * @param {object} rowData data of a particular row
   * @return {string} class name for the row
   * @private
   */
  _rowClasses({rowData}) {
    const isDisabled = this.props.disabledRow(rowData.id);
    return classNames({
      'DisabledAlbumRow': isDisabled,
      'AlbumRow': !isDisabled,
      'AlbumSelected': this.props.activeAlbum === rowData.id
    });
  }

  /**
   * returns new array of albums
   * @return {array} array of album data
   * @private
   *
   */
  _getAlbumData() {
    let data = this._sortAlbumsData(this.props.albumsData);

    if (this.state.newAlbumRowMode) {
      if (this.props.newAlbumRowPosition === AlbumsList.ROW_POSITION.TOP) {
        data.unshift(NEW_ALBUM_ROW);
      } else {
        data.push(NEW_ALBUM_ROW);
      }
    }

    return data;
  }

  /**
   * @inheritdoc
   */
  render() {
    let data = this._getAlbumData();

    const albumsPanelHeader = (
      <AlbumsPanelHeader isDescending={this.state.isDescending}
                         onSortAlbumsClick={this._onSortAlbumsClick}
                         onNewAlbumButtonClick={this._onNewAlbumButtonClick}
      />);

    return (
      <div className="AlbumsPanelContainer">
        <ModalDeleteAlbum
          show={this.state.showAlbumDeletionModal}
          onExited={() => this._handleAlbumDeletionModalVisibility(false)}
          handleDeleteAlbumOnly={() => this._handleDeleteAlbum(true)}
          handleDeleteAlbumWithPhoto={() => this._handleDeleteAlbum(false)}
        />
        {this.props.showSpecialAlbum ?
          <SpecialAlbum id="gridSpecialAlbum"
                        isSpecialAlbumSelected={this.props.activeAlbum === ALL_AUX}
                        onRowClick={this.allPhotosRowClick}
                        itemName={T.translate('albumsPanel.allPhotos')}
                        svgId="grid-16"
          /> :
          null}
        {this.props.withHeader ? albumsPanelHeader : null}
        <input id="hiddenFileInput"
               type="file"
               multiple
               accept={ACCEPTED_IMAGE_FORMAT}
               onChange={e => this._uploadPhotos(e.target.files)}
        />
        <div style={{display: 'flex', flex: 'auto 1'}}>
          <AutoSizer>
            {
              ({width, height}) => {
                return (
                  <MatrixTable
                    className="AlbumList"
                    width={width}
                    emptyRenderer={
                      <div className="albumListStart">
                        <div className="centered">
                          {T.translate('albumsPanel.emptyAlbum')}
                        </div>
                      </div>
                    }
                    rowCount={data.length}
                    ref={this._matrixTableRef(data)}
                    height={height}
                    rowClassName={this._rowClasses}
                    rowWidth={width}
                    rowHeight={35}
                    data={data}
                    columns={this._columns}
                    headerHeight={this.props.headerHeight}
                    showSettings={false}
                    actionOptions={this._actionOptions}
                    onRowAction={this._handleRowAction}
                    onRowClick={this._onRowClick}
                    actionMode={MatrixTable.ActionMode.COLUMN}
                  />
                );
              }
            }
          </AutoSizer>
        </div>
        {this.props.showSpecialAlbum ?
          <SpecialAlbum isSpecialAlbumSelected={this.props.activeAlbum === TRASH}
                        onRowClick={this.trashRowClick}
                        itemName={T.translate('albumsPanel.deletedPhotos')}
                        svgId="trash"
          /> :
          null}
      </div>
    );
  }
}

AlbumsList.propTypes = {
  activeAlbum: PropTypes.string,
  disabledRow: PropTypes.func,
  onRowClick: PropTypes.func.isRequired,
  modifyAlbumName: PropTypes.func.isRequired,
  createAlbum: PropTypes.func.isRequired,
  deleteAlbum: PropTypes.func.isRequired,
  deleteAlbumWithPhotos: PropTypes.func.isRequired,
  newAlbumRowPosition: PropTypes.oneOf(_.values(AlbumsList.ROW_POSITION)),
  albumRowOptions: PropTypes.arrayOf(function(propValue, key, componentName, location, propFullName) {
    if (_.values(AlbumsList.ROW_ACTION_KEYS).indexOf(propValue[key]) === -1) {
      return new Error(
        'Invalid prop `' + propFullName + '` supplied to' +
        ' `' + componentName + '`. Validation failed.'
      );
    }
    return true;
  }),
  onNewAlbumRowClose: PropTypes.func,
  headerTitle: PropTypes.string,
  showSpecialAlbum: PropTypes.bool,
  withHeader: PropTypes.bool,
  columnWidth: PropTypes.number
};

AlbumsList.defaultProps = {
  columnWidth: 400,
  headerHeight: 0,
  headerTitle: 'Albums',
  albumsData: {},
  withHeader: true,
  newAlbumRowPosition: AlbumsList.ROW_POSITION.TOP,
  newAlbumRowMode: false,
  showSpecialAlbum: true,
  albumRowOptions: _.values(AlbumsList.ROW_ACTION_KEYS),
  onRowClick: _.noop,
  onNewAlbumRowClose: _.noop,
  disabledRow: _.stubFalse
};
