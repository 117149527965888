import { IssueForm } from './issueForm.appfw';
import { connect } from 'react-redux';
import { createIssue, closeIssueForm } from '~/redux/actions/issuesActions';

const mapStateToProps = ({photosSelection, issueForm}) => {
  return {
    photosSelection,
    showIssueForm: issueForm.showIssueForm
  };
};

const mapDispatchToProps = dispatch => {
  return {
    submitIssueForm: params => dispatch(createIssue(params)),
    closeIssueForm: () => dispatch(closeIssueForm())
  };
};

const IssueCreator = connect(
  mapStateToProps,
  mapDispatchToProps
)(IssueForm);

export {IssueCreator};
