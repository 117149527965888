import React from 'react';
import {Carousel, IconButton, Tooltip} from '@adsk/bim360-matrix-react-components';
import _ from 'lodash';

import PhotoDataEditor from '../propertyViews/photoAssetView/photoAssetDataView.redux';
import PhotoTopBar from './topBar.redux';
import T from '~/common/i18n';
import {
  ACTIVITY,
  TRASH,
  UPLOADED,
  CANCELLED,
  FETCH_FAILED,
  FETCHED
} from '~/common/constants';
import { ConnectedPhotoContent } from '../photoCell/photoContent/photoContent.redux';

/**
 * React component for a context rich PhotoViewer
 * @public
 */
export class PhotoViewer extends React.Component {
  /**
   * @inheritdoc
   */
  constructor(props) {
    super(props);

    this.start = 0;
    this.end = 10;
    this.containerDivRef = React.createRef();
    this.carousel = React.createRef();
    this._handleKeyPress = this._handleKeyPress.bind(this);
    this._beforeChange = this.beforeChange.bind(this);
    this._rotatePhotoCW = this._rotatePhoto.bind(this, true);
    this._rotatePhotoCCW = this._rotatePhoto.bind(this, false);
    this._setSlideIndex = this.setSlideIndex.bind(this);
    this._changeSlide = _.throttle(this._changeSlide.bind(this), 200);
  }

  /**
   * @inheritdoc
   */
  shouldComponentUpdate(nextProps) {
    const propsChanged = _.some(Object.keys(nextProps), key => {
      // Make sure we never rerender on photo or thumb status change.
      if (key === 'photosUploadStatus' || key === 'thumbnailsUploadStatus') {
        return false;
      }

      // While were here we might as well try and stop other useless rerender.
      return nextProps[key] !== this.props[key];
    });
    if (!this.props.showViewer && nextProps.showViewer) {
      const photoGuid = nextProps.displayedPhotos[nextProps.slideNumber];
      this.props.logActivity(ACTIVITY.VERBS.VIEW_PHOTO, photoGuid);
    }

    return propsChanged;
  }

  /**
   * @inheritdoc
   */
  componentDidUpdate() {
    if (this.props.showViewer) {
      this._downloadPhotos(this.props.displayedPhotos);
    }
    if (this.containerDivRef.current) {
      this.containerDivRef.current.focus();
    }
  }

  /**
   * Check if it should render thumbnail or not.
   * @private
   * @param {Object} thumbnailStatus Contains both fetchStatus and uploadStatus
   * @param {Object} photoStatus Contains both fetchStatus and uploadStatus
   * @return {Boolean} shouldRenderThumbnail
   */
  _shouldRenderThumbnail(thumbnailStatus, photoStatus) {
    return photoStatus.fetchStatus !== FETCHED &&
      thumbnailStatus.fetchStatus !== FETCH_FAILED &&
      thumbnailStatus.fetchStatus !== CANCELLED;
  }

  /**
   * @param {Image[]} photoIDs A list of photos from the sdk.
   * @return {EditablePhotoElement[]} A list of photo components.
   */
  wrapPhotos(photoIDs) {
    const PhotosComponents = [];

    for (let index = this.start; index < this.end; index++) {
      PhotosComponents.push(
        <ConnectedPhotoContent
          key={photoIDs[index]}
          photoId={photoIDs[index]}
          shouldRenderThumbnail={this._shouldRenderThumbnail}
          isResponsive
        />
      );
    }

    return PhotosComponents;
  }

  /**
   * Handles downloading the full photos
   */
  _downloadPhotos() {
    const previousPages = [];
    let downloadOrder = [];

    for (let index = this.start; index < this.end; index++) {
      if (downloadOrder.length || index === this.props.slideNumber) {
        downloadOrder.push(index);
      } else {
        previousPages.push(index);
      }
    }

    // we reorder the download so that the image currently displayed load first, followed by the image after it,
    // then the image before it.
    downloadOrder = [...downloadOrder, ..._.reverse(previousPages)];

    _.each(downloadOrder, index => {
      const guid = this.props.displayedPhotos[index];
      const thumbUploadStatus = this.props.thumbnailsUploadStatus[guid].status;

      if (thumbUploadStatus === UPLOADED) {
        // we first fetch the thumbnail, it will be prioritized by the download manager
        // and show up way before the full image.
        this.props.fetchThumbnail(this.props.displayedPhotos[index]);
      }

      const photoUploadStatus = this.props.photosUploadStatus[guid].status;
      if (photoUploadStatus === UPLOADED) {
        this.props.fetchPhoto(this.props.displayedPhotos[index]);
      }
    });

    // now that we queued our requests, calling this clear the active queue of any thumbnail request.
    // Pushing our more urgent requests there instead.
    this.props.deprioritizeThumbnailRequests();
  }

  /**
   * Handles rotating an image in the viewer
   * @param {bool} isClockwise Determines direction of rotation
   */
  _rotatePhoto(isClockwise) {
    // null parameter means get the active one.
    this.props.rotatePhoto(null, isClockwise);
  }

  /**
   * Handles determining if we are moving forward or backward
   * @param {Integer} previousIndex the image index we just left
   * @param {Integer} nextIndex the next image to display
   */
  beforeChange(previousIndex, nextIndex) {
    this.direction = nextIndex - previousIndex || 1;
    const photoGuid = this.props.displayedPhotos[this.start + nextIndex];
    this.props.changePhoto(photoGuid, this.start + nextIndex);
    this.props.logActivity(ACTIVITY.VERBS.VIEW_PHOTO, photoGuid);
    if (this.props.displayedPhotos.length > 10) {
      if ( nextIndex + this.start >= this.end - 1) {
        this.props.clearPhotosAndCancelFullsizeRequests(this.props.displayedPhotos.slice(this.start, this.end - 2));
        this.props.setPhotoIndex(this.end - 1);
      } else if (nextIndex <= 0) {
        this.props.clearPhotosAndCancelFullsizeRequests(this.props.displayedPhotos.slice(this.start + 2, this.end));
        this.props.setPhotoIndex(this.start);
      }
    }
  }

  /**
   * Initializes this.start, this.end, this.slideNumber, and this.slideIndex
   */
  setSlideIndex() {
    let start, end;
    this.direction = this.direction || 1;
    this.slideNumber = this.props.slideNumber;
    start = this.slideNumber - 2 * this.direction;
    end = this.slideNumber + 8 * this.direction;

    if (this.direction > 0) {
      this.start = Math.max(start, 0);
      this.end = Math.min(end, this.props.displayedPhotos.length);
    } else {
      this.start = Math.max(end, 0);
      this.end = Math.min(start, this.props.displayedPhotos.length);
    }

    if (this.props.displayedPhotos.length <= 10) {
      this.start = 0;
      this.end = this.props.displayedPhotos.length;
    }

    this.slideIndex = this.slideNumber - this.start;
  }

  /**
   * Handles keyboard events
   * @param {Event} event The keyboard event
   */
  _handleKeyPress(event) {
    switch (event.key) {
      case 'ArrowUp':
      case 'ArrowLeft': {
        // Previous
        this._changeSlide(false);
        break;
      }
      case 'ArrowDown':
      case 'ArrowRight': {
        // Next
        this._changeSlide(true);
        break;
      }
      case 'Escape':
        // Close
        this.props.closeModal();
        break;
      default:
        // no op
        break;
    }
  }

  /**
   * Handles changing the slide
   * @param {Boolean} isForward determines whether to go forward or back.
   */
  _changeSlide(isForward) {
    if (this.carousel && this.carousel.current) {
      if (isForward && this.slideNumber < this.props.displayedPhotos.length - 1) {
        this.carousel.current.next();
      } else if (!isForward && this.slideNumber > 0) {
        this.carousel.current.prev();
      }
    }
  }

  /**
   * @inheritdoc
   */
  render() {
    if (!this.props.showViewer) {
      return null;
    }
    this._changeSlide.cancel();

    this._setSlideIndex();
    let _photos = this.wrapPhotos(this.props.displayedPhotos);
    const isTrash = this.props.activeAlbum === TRASH;
    return (
      <div id="PhotoDetails" className="photoViewer">
        <div id="PhotoViewer"
          ref={this.containerDivRef}
          tabIndex={0}
          onKeyDown={this._handleKeyPress}
        >
          <PhotoTopBar/>
          <Carousel
            ref={this.carousel}
            key={'Carousel' + this.slideNumber}
            beforeChange={this._beforeChange}
            slide={this.slideIndex}
            timeout={0}
          >
            {_photos}
          </Carousel>
          <div className="imageToolbar">
            <Tooltip
              content={T.translate('controls.rotateLeft.tooltip')}
              place={Tooltip.Places.TOP}
              theme={Tooltip.Themes.DARK}
            >
              <IconButton
                svgId="icon_rotate-cw"
                onClick={this._rotatePhotoCW}
                disabled={isTrash}
                iconWidth="30px"
                iconHeight="30px"
                className="toolButton rotateLeft"
              />
            </Tooltip>

            <Tooltip
              content={T.translate('controls.rotateRight.tooltip')}
              place={Tooltip.Places.TOP}
              theme={Tooltip.Themes.DARK}
            >
              <IconButton
                svgId="icon_rotate-ccw"
                onClick = {this._rotatePhotoCCW}
                disabled={isTrash}
                iconWidth="30px"
                iconHeight="30px"
                className="toolButton rotateRight"
              />
            </Tooltip>
          </div>
        </div>
        <PhotoDataEditor onKeyDown={this.preventDefault}/>
      </div>
    );
  }
}
