import {
  OPEN_VIEWER,
  CLOSE_VIEWER,
  CHANGE_PHOTO_VIEWED,
  SET_PHOTO_INDEX
} from '~/common/constants';

/**
 * Handles opening the viewer
 * @param {photo[]} displayedPhotos a list of photos to display.
 * @param {Number} photoIndex the starting index in the list to open
 * @return {Object} A redux action
 */
export function openViewer(displayedPhotos, photoIndex) {
  return ({
    type: OPEN_VIEWER,
    displayedPhotos,
    photoIndex
  });
}

/**
 * Closes the photoViewer
 * @return {Object} Redux action to close the viewer
 */
export function closeViewer() {
  return ({
    type: CLOSE_VIEWER
  });
}

/**
 * Updates which photo is being displayed in the viewer
 * @param {String} id A guid identifying which photo is now in view
 * @param {String} index The index of the viewed photo in the list
 * @return {Object} Redux action to change the photo viewed
 */
export function changePhoto(id, index) {
  return ({
    type: CHANGE_PHOTO_VIEWED,
    id,
    index
  });
}

/**
 * Change starting index
 * @param {Number} index the index that defines the start
 * @return {Object} Redux action to update starting index
 */
export function setPhotoIndex(index) {
  return ({
    type: SET_PHOTO_INDEX,
    index
  });
}
